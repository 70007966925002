import { GracePeriod } from './GracePeriod';
import React, { useState } from 'react';
import { ToasterUtils } from '../../../../common';
import { addGracePeriod } from './api';
import Analytics from '../../../../utils/AnalyticsHelper';
import {
  CONFIRM_GRACE_PERIOD_ANALYTICS_EVENT,
  DECREMENT_GRACE_PERIOD_ANALYTICS_EVENT,
  GRACE_PERIOD_ANALYTICS_SEGMENT,
  GRACE_PERIOD_STEP,
  INCREMENT_GRACE_PERIOD_ANALYTICS_EVENT,
  MAX_GRACE_PERIOD,
  MIN_GRACE_PERIOD,
} from './constants';
import { GracePeriodPrompts } from './prompts';

interface Props {
  gracePeriod: number;
  loading: boolean;
  updateGracePeriod: (gracePeriod: number) => void;
}

export const GracePeriodContainer = ({ gracePeriod, loading, updateGracePeriod }: Props) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleIncrementButtonPress = () => {
    if (gracePeriod < MAX_GRACE_PERIOD) {
      const updatedGracePeriod = gracePeriod + GRACE_PERIOD_STEP;
      updateGracePeriod(updatedGracePeriod);
      recordIncrementGracePeriodAnalytic(updatedGracePeriod.toString());
    }
  };

  const handleDecrementButtonPress = () => {
    if (gracePeriod > MIN_GRACE_PERIOD) {
      const updatedGracePeriod = gracePeriod - GRACE_PERIOD_STEP;
      updateGracePeriod(updatedGracePeriod);
      recordDecrementGracePeriodAnalytic(updatedGracePeriod.toString());
    }
  };

  const handleConfirmButtonPress = async () => {
    setIsUpdating(true);
    try {
      await update(gracePeriod);
      updateGracePeriod(gracePeriod);
      recordSuccessfulUpdateAnalytic(gracePeriod.toString());
      showSuccess();
    } catch (e) {
      showError();
    } finally {
      setIsUpdating(false);
    }
  };

  const update = async (updatedGracePeriod: number) => {
    const res = await addGracePeriod(updatedGracePeriod);
    if (!res) {
      throw Error('Grace period update failed');
    }
    return res;
  };

  const recordSuccessfulUpdateAnalytic = (updatedGracePeriod: string) => {
    Analytics.trackEvent(
      CONFIRM_GRACE_PERIOD_ANALYTICS_EVENT,
      GRACE_PERIOD_ANALYTICS_SEGMENT,
      updatedGracePeriod
    );
  };

  const recordIncrementGracePeriodAnalytic = (updatedGracePeriod: string) => {
    Analytics.trackEvent(
      INCREMENT_GRACE_PERIOD_ANALYTICS_EVENT,
      GRACE_PERIOD_ANALYTICS_SEGMENT,
      updatedGracePeriod
    );
  };

  const recordDecrementGracePeriodAnalytic = (updatedGracePeriod: string) => {
    Analytics.trackEvent(
      DECREMENT_GRACE_PERIOD_ANALYTICS_EVENT,
      GRACE_PERIOD_ANALYTICS_SEGMENT,
      updatedGracePeriod
    );
  };

  const showError = () => {
    ToasterUtils.toast(
      GracePeriodPrompts.toastSaveErrorMsg,
      'error',
      GracePeriodPrompts.toastSaveErrorTitle
    );
  };

  const showSuccess = () => {
    ToasterUtils.toast(GracePeriodPrompts.toastMsg, 'success', GracePeriodPrompts.toastTitle);
  };

  return (
    <GracePeriod
      gracePeriod={gracePeriod}
      onConfirmButtonPress={handleConfirmButtonPress}
      onIncrementPress={handleIncrementButtonPress}
      onDecrementPress={handleDecrementButtonPress}
      loading={loading}
      saving={isUpdating}
    />
  );
};
