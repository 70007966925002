import { EmbedToken } from 'services/types';

export type EmbeddedReportProps = {
  embedToken: EmbedToken;
  userOrg: string;
  filterSurveyIds?: string[];
  filterMonths?: string[];
  filterModuleIds?: string[];
  selectedRitualId: string;
};

export enum PowerBIReport {
  Wellbeing = 'wellbeing',
  SiPillars = 'si_pillars',
  Adoption = 'adoption',
  Care = 'care',
  Modules = 'modules',
  Rituals = 'rituals',
}

export enum CardId {
  Wellbeing = 'workplace_wellbeing',
  Individual = 'individual_wellbeing',
  Activities = 'wellbeing_activities',
  Care = 'workplace_cares_about_my_wellbeing',
}

export enum TextId {
  Wellbeing = 'hub_workplace_wellbeing',
  Individual = 'hub_individual_wellbeing',
  Adoption = 'hub_app_adoption',
  Modules = 'hub_modules',
  Rituals = 'hub_rituals',
  Care = 'hub_workplace_cares_wellbeing',
  Overview = 'wellbeing_overview',
}

export enum SurveyName {
  Wellbeing = 'Workplace Temperature Check (for individuals)',
  Individual = '6 Pillars Survey',
}

export type ReportSectionProps = {
  reportSection: PowerBIReportSection | null;
};

export type PowerBIReportSection = {
  pageName: string;
  className: string;
};

export type PowerBIReportMap = { [key in PowerBIReport]: PowerBIReportSection };

export type SurveyCard = {
  surveyId: string;
  headerName: string;
  response: {
    current: number;
    max: number;
  };
  score: {
    current: number;
    max: number;
  };
  cardId: CardId;
  lastSentDate: string;
  closeOnDate: string;
  disabled: boolean;
  status: SurveyStatuses;
};

export enum WellbeingCard {
  Adoption = 'appAdoptionRate',
  Modules = 'modulesRate',
  Rituals = 'ritualsValueRating',
}

export type StatCard = {
  [WellbeingCard.Adoption]: number | null;
  [WellbeingCard.Modules]: number | null;
  [WellbeingCard.Rituals]: number | null;
  cardId: CardId;
  headerName: string;
  disabled: boolean;
};

export interface ScoreCardProps {
  variant?: CardVariant;
  header: string;
  score: Score;
  closeOnDate: string;
}

export enum CardVariant {
  WHITE = 'white',
  BLUE = 'blue',
  DISABLED = 'disabled',
}

export enum ScoreTypes {
  SCORE = 'score',
  PERCENTAGE = '%',
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  AVAILABLE_TODAY = 'availableToday',
  INDEFINITE_AVAILABLE = 'indefiniteAvailable',
}

export enum SurveyPageButtonIds {
  SEND = 'send',
  NEXT = 'next',
  UPLOAD = 'upload',
  PREVIEW = 'preview',
  DASHBOARD = 'dashboard',
}

export interface Score {
  scoreType: ScoreTypes;
  data: {
    percent?: ScorePercent;
    score?: {
      current: number;
      max: number;
    };
    survey?: {
      current: number;
      max: number;
    };
  };
}

export type ScorePercent = {
  [WellbeingCard.Adoption]: number;
  [WellbeingCard.Modules]: number;
  [WellbeingCard.Rituals]: number;
};

export type Report = {
  id: string;
  title: string;
};

export type Page = {
  cardId: CardId;
  textId: TextId;
  report: PowerBIReport;
  reports: Report[];
  disabled?: boolean;
  id: PageId;
};

export enum PageId {
  Wellbeing = 'workplace_wellbeing',
  Individual = 'individual_wellbeing',
  Adoption = 'app_adoption',
  Modules = 'modules',
  Rituals = 'rituals',
  Care = 'workplace_cares_about_my_wellbeing',
}

export type RecommendationData = {
  id: TextId;
  text: string;
  collectorId: string;
  timePeriod: string;
  expanded?: boolean;
  baselineReport?: string;
  responseCount: number;
  recipientCount: number;
  requiredRate: number;
  responsesRequiredToMeetRate: number;
  thresholdMet: boolean;
};

export type SurveyPage = {
  title: string;
  pageNumber: number;
  submitButton?: {
    text: string;
    action: SurveyActions;
  };
  component: SurveyComponents;
  message?: string;
  buttonId?: SurveyPageButtonIds;
};

export type Survey = {
  id: string;
  name: string;
  status: SurveyStatuses;
  last_sent_date: string;
  close_on_date?: string;
  closed_on_date?: string;
  preview_url: string;
  selected?: boolean;
  collector_id: string;
};

export enum SurveyTypes {
  MANAGE = 'manage',
  NEW = 'new',
}

export enum SurveyComponents {
  MANAGE_SURVEYS = 'ManageSurveys',
  ADD_RECIPIENTS = 'AddRecipients',
  REVIEW_AND_SEND = 'ReviewAndSend',
  SURVEY_SENT = 'SurveySent',
  SELECT_SURVEY = 'SelectSurvey',
  SELECT_DATE = 'SelectDate',
  CONFIRM_SEND = 'ConfirmSend',
}

export enum SurveyActions {
  NEXT = 'next',
  UPLOAD = 'upload',
  SEND_NOW = 'sendNow',
  CLOSE = 'close',
}

export enum AddRecipientMessageType {
  NORMAL = 'normal',
  BOLD = 'bold',
}

export type AddRecipientMessage = {
  type: AddRecipientMessageType;
  value: string;
};

export enum SurveyStatuses {
  OPEN = 'open',
  CLOSED = 'closed',
  AVAILABLE = 'available',
}

export enum ManageSurveyStatuses {
  OPEN = 'active',
  CLOSED = 'completed',
}

export type SurveyMenuItem = {
  isActive: boolean;
  label: string;
  status: SurveyStatuses;
  displayStatus: ManageSurveyStatuses;
};

export type TrendingItem = {
  label: string;
  value: number;
};

export enum FilterTypes {
  SURVEY = 'survey',
  TRENDING = 'trending',
  MODULES = 'modules',
  RITUAL_TEAMS = 'ritualTeams',
}

export type ModuleItem = {
  id: string;
  title: string;
};

export type HubRoute = {
  path: string;
  cardId?: CardId;
  pageId?: PageId;
  isDefault?: boolean;
};

export type RitualOverviewCard = {
  label: string;
  count: number;
};

export type AtAGlanceSection = {
  valuableMainFigure: string;
  valuableDeltaFigure: number;
  valuableDeltaText: string;
  oftenMainFigure: string;
  oftenDeltaFigure: number;
  oftenDeltaText: string;
  responseRateMainFigure: string;
  responseRateDeltaFigure: number;
  responseRateDeltaText: string;
  lastCheckin?: string;
  lastUpdated?: string;
  trigger?: string;
  frequency?: string;
  action?: string;
  id?: string;
};

export type AtAGlanceResponse = {
  id: string;
  name: string | undefined;
  trigger: string;
  action: string;
  frequency: string;
  valuableRate: number | null | undefined | string;
  valuableDelta: string;
  doingRate: number | null | undefined | string;
  doingDelta: string;
  responseRate: number | null | undefined | string;
  responseDelta: string;
  lastCheckin: string;
  lastUpdated: string;
};

export type RitualOverviewResponse = {
  count: number;
  order: number;
  title: string;
};

export enum PowerBIEvents {
  LOADED = 'loaded',
  RENDERED = 'rendered',
  VISUAL_RENDERED = 'visualRendered',
}

export type RitualTeam = {
  teamId: string;
  name: string;
};
