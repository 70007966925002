import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const hubBetaStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 128px)',
    },
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(3),
      minHeight: '5rem',
      width: '100%',
      marginTop: theme.spacing(-10),
      borderColor: theme.palette.grey[400],
    },
    surveyFilters: {
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
      marginBottom: '0.75rem',
      position: 'relative',
    },
    backButton: {
      cursor: 'pointer',
    },
    specificSurveyClass: {
      minWidth: '15rem',
    },
    menuItems: {
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
      marginTop: theme.spacing(18),
      marginBottom: theme.spacing(3),
    },
    menuItem: {
      marginRight: theme.spacing(8),
      display: 'inline-block',
      cursor: 'pointer',
    },
    menuItemDisabled: {
      cursor: 'default',
    },
    menuText: {
      color: colors.slateGrey,
      fontSize: 16,
      marginBottom: 4,
      fontWeight: 'bold',
      height: 20,
      letterSpacing: 0,
    },
    menuTextDisabled: {
      color: colors.gray1,
    },
    activeMenuText: {
      color: theme.palette.primary.main,
    },
    underline: {
      height: 2,
      marginTop: theme.spacing(2),
      backgroundColor: colors.blue,
      borderRadius: 1,
    },
    ritualsDetailsSection: {
      margin: '2rem 1.5rem 0.75rem 1.5rem',
    },
  })
);

export default hubBetaStyles;
