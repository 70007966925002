import React, { useEffect } from 'react';
import { Select, MenuItem, FormControl, SelectProps, Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Analytics from 'utils/AnalyticsHelper';
import { HubBetaPrompts } from 'prompts/prompts';
import { getFormattedDate } from '../../utils';
import { HUB_ANALYTICS } from '../../constants';
import { Survey, FilterTypes, TrendingItem, ModuleItem, RitualTeam } from '../../types';

import filterDropdownStyles from './styles';

export const FilterDropdown: React.FC<{
  items: Survey[] | TrendingItem[] | ModuleItem[] | RitualTeam[];
  selectedItem: string | number | undefined;
  setSelectedItem: Function;
  dataTestId: string;
  filterType: FilterTypes;
  filterLabel: string;
  hideLabel?: boolean;
}> = (props) => {
  const classes = filterDropdownStyles(props);
  const { items, selectedItem, setSelectedItem, dataTestId, filterType, filterLabel, hideLabel } =
    props;

  const iconComponent = (props: SelectProps) => {
    return <ExpandMoreIcon className={props.className + ' ' + classes.icon} />;
  };

  const selectClasses = [classes.select];
  let getListItems: Function = () => {};
  let getFilterValue: Function = () => {};

  switch (filterType) {
    case FilterTypes.MODULES:
      selectClasses.push(classes.modules);
      getFilterValue = () =>
        (items as ModuleItem[]).find((item) => item.id === selectedItem)?.title;
      getListItems = () =>
        (items as ModuleItem[]).map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item.title}
          </MenuItem>
        ));
      break;
    case FilterTypes.TRENDING:
      selectClasses.push(classes.trending);
      getFilterValue = () =>
        (items as TrendingItem[]).find((item) => item.value === selectedItem)?.label;
      getListItems = () =>
        (items as TrendingItem[]).map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ));
      break;
    case FilterTypes.RITUAL_TEAMS:
      selectClasses.push(classes.ritualTeams);
      getFilterValue = () =>
        (items as RitualTeam[]).find((item) => item.teamId === selectedItem)?.name;
      getListItems = () =>
        (items as RitualTeam[]).map((item) => (
          <MenuItem value={item.teamId} key={item.teamId}>
            {item.name}
          </MenuItem>
        ));
      break;
    default:
      selectClasses.push(classes.survey);
      getFilterValue = () =>
        getFormattedDate(
          (items as Survey[]).find((item) => item.collector_id === selectedItem)?.last_sent_date,
          HubBetaPrompts.filterDateFormat
        );
      getListItems = () =>
        (items as Survey[]).map((item) => (
          <MenuItem value={item.collector_id} key={item.collector_id}>
            {getFormattedDate(item.last_sent_date, HubBetaPrompts.filterDateFormat)}
          </MenuItem>
        ));
      break;
  }

  useEffect(() => {
    Analytics.trackEvent(HUB_ANALYTICS.DROPDOWN_EVENT, filterLabel, getFilterValue());
  }, [filterLabel, selectedItem]);

  return (
    <Box className={classes.root} data-testid={dataTestId}>
      <FormControl>
        <Select
          disableUnderline
          classes={{ root: selectClasses.join(' ') }}
          MenuProps={{
            classes: {
              paper: classes.paper,
              list: classes.list,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          IconComponent={iconComponent}
          value={selectedItem}
          renderValue={(val) => {
            return (
              <Box display="flex">
                {hideLabel ? null : (
                  <Typography variant="h4" component="h4" className={classes.bold}>
                    {filterLabel + ':'}
                  </Typography>
                )}
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.selectedText + ' ' + classes.bold}>
                  {getFilterValue()}
                </Typography>
              </Box>
            );
          }}
          onChange={(e) => setSelectedItem(e.target.value)}>
          {getListItems()}
        </Select>
      </FormControl>
    </Box>
  );
};
