import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Card } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReportSection } from 'utils/PowerBiReports';
import { colors } from 'styles/colors';
import EapServices from '../EapServices';
import { GroupsAndUsers } from './GroupsAndUsers';
import { getAndRefreshUsers } from 'pages/Settings/api';

interface GaSettingsItems {
  [x: string]: {
    label: string;
    id: string;
    component: () => JSX.Element;
  };
}

const ReportNavLink = (props: { url: string; isActive: boolean; onClick?: () => void }) => {
  const classes = useStyles(props);
  return (
    <div className={[classes.route, props.isActive ? classes.activeRoute : ''].join(' ')}>
      {props.isActive && <div className={classes.activeIndicator}></div>}
      <NavLink to={`ga-settings`} onClick={props.onClick} className={classes.routeText}>
        {props.url}
      </NavLink>
    </div>
  );
};

const GaSettingsItems: GaSettingsItems = {
  ['groupsAndUsers']: {
    label: 'Groups & Users',
    id: 'groupsAndUsers',
    component: () => <GroupsAndUsers />,
  },
  ['eapServices']: {
    label: 'EAP Services',
    id: 'eapServices',
    component: () => <EapServices />,
  },
};

export const GASettings: React.FC = () => {
  const classes = useStyles();
  const [activeRoute, setActiveRoute] = useState<string>('groupsAndUsers');

  useEffect(() => {
    getAndRefreshUsers();
  }, []);

  const GASettingsNavLinks = Object.keys(GaSettingsItems).map((key) => GaSettingsItems[key]);

  const links = GASettingsNavLinks.map((r) => (
    <ReportNavLink
      key={r.id}
      url={r.label}
      isActive={activeRoute === r.id}
      onClick={() => {
        setActiveRoute(r.id as ReportSection);
      }}
    />
  ));

  return (
    <Box className={classes.container}>
      <Box className={classes.sidebar}>{links}</Box>
      <Box className={classes.content}>
        <Card className={classes.card}>
          {GASettingsNavLinks.find((r) => r.id === activeRoute)?.component()}
        </Card>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '95%',
      height: 'auto',
      overflow: 'hidden',
      borderRadius: theme.spacing(1.5),
      border: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.spacing(1),
      backgroundColor: colors.lightGrey,
    },
    sidebar: {
      paddingTop: theme.spacing(12),
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: colors.white,
      flexBasis: '14%',
    },
    content: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      flexBasis: '90%',
    },
    card: {
      flex: 1,
      marginRight: theme.spacing(16),
      marginLeft: theme.spacing(16),
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
      height: '75vh',
    },
    route: {
      width: '100%',
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      position: 'relative',
    },
    routeText: {
      fontWeight: 'bold',
      color: colors.black,
      textDecoration: 'none',
    },
    activeRoute: {
      backgroundColor: theme.palette.grey[400],
    },
    activeIndicator: {
      width: '5px',
      height: '70%',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: theme.spacing(0.5),
    },
  })
);
