import { useState } from 'react';
import { EmailWithStatus } from './types';

export const useEmailListFromCsv = () => {
  const [emails, setEmails] = useState<EmailWithStatus[]>([]);

  const addEmailFile = async (file: File) => {
    const decoder = new TextDecoder('utf-8');
    const buffer = await file.arrayBuffer();
    const string = decoder.decode(buffer);
    const arr = string.split('\n');

    const cleanedArray = arr.map((csvLine) => {
      return {
        mail: csvLine.trim(),
      };
    });

    setEmails(cleanedArray);
  };

  const reset = () => {
    setEmails([]);
  };

  return {
    emails,
    addEmailFile,
    reset,
  };
};
