import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';
import { Toaster } from './Toaster';
import { ConfirmActionToaster } from './ConfirmActionToaster';

let snackbarRef: WithSnackbarProps;

export const ToasterConfig: React.FC = () => {
  snackbarRef = useSnackbar();
  return null;
};

export type ConfirmActionProps = {
  onConfirm: () => Promise<void>;
  title?: string;
  message?: string;
  confirmButtonText?: string;
};

const autoHideDuration = 5000;

export const ToasterUtils = {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  warning(msg: string) {
    this.toast(msg, 'warning');
  },
  info(msg: string) {
    this.toast(msg, 'info');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType = 'default', title?: string, duration?: number) {
    snackbarRef.closeSnackbar();
    snackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: duration || autoHideDuration,
      content: (key, message) => (
        <Toaster id={key} message={message as string} variant={variant} title={title} />
      ),
    });
  },
  close() {
    snackbarRef.closeSnackbar();
  },
  confirm({ onConfirm, title, message, confirmButtonText }: ConfirmActionProps) {
    snackbarRef.closeSnackbar();
    snackbarRef.enqueueSnackbar('', {
      autoHideDuration,
      content: (key) => (
        <ConfirmActionToaster
          id={key}
          variant={'confirm'}
          onConfirm={onConfirm}
          title={title}
          message={message}
          confirmButtonText={confirmButtonText}
        />
      ),
    });
  },
};
