import { FormikHelpers } from 'formik';

export interface ApiResponse {
  status: number;
  statusText: string;
  errorCode?: number;
  errorMessage?: string;
  details?: any;
  data?: any;
}

export interface LoginResponse extends ApiResponse {
  accessToken: string;
  expiry: number;
  refreshToken?: string;
}

export interface RitualsProps {
  id: string;
  comments: string;
  actionPlan: string;
  planDate: string;
  keyMessage: string;
  trigger: string;
  action: string;
  status: boolean;
}
export interface CompanyProps {
  sponsorName: string;
  sponsorRole: string;
  rituals: RitualsProps[];
}

export interface FormValues {
  planDate: string;
  sponsorname: string;
  sponsorRole: string;
  keyMessage: string;
  trigger: string;
  action: string;
  status: boolean;
  actionPlan: string;
  comments: string;
  id?: number;
}
export interface CreateCompanyRitualProps {
  location: {
    state: CompanyProps;
  };
}
export interface FormType {
  // onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  initialValues: FormValues;
  isEdit: boolean;
  isLoading: boolean;
  isDeleteLoading?: boolean;
  cancleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  deleteRituals: (event: React.MouseEvent<HTMLButtonElement>) => void;
  serverError: boolean;
}
// https://github.com/nestjs/nest/blob/master/packages/common/enums/http-status.enum.ts
export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  FOUND = 302,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

export interface EmbedToken {
  accessToken?: string;
  embedUrl: EmbedURL[];
  expiry?: Date;
  status?: number;
}

export interface EmbedURL {
  reportId?: string;
  reportName?: string;
  embedUrl?: string;
}

export interface LoginRawResponse {
  access_token: string;
  expires_in: number;
}

export interface SetNewPasswordRequestType {
  username: string;
  password: string;
  code: string;
  customUrl?: string;
}

export interface RecommendationCommentaryResponse {
  collector_id?: string;
  created_on: Date;
  id: number;
  modified_on: Date;
  org_id: string;
  org_name: string;
  page_id: string;
  status: string;
  text?: string;
  baseline_report?: string;
  time_period: string;
  response_count: number;
  recipient_count: number;
  responses_until_threshold_met: number;
  response_rate_threshold_to_meet: number;
  met_threshold: boolean;
}
