import React, { ReactElement } from 'react';
import { Card } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const modalCardStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '0.75rem',
      boxShadow: theme.shadows[10],
      padding: theme.spacing(13),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
  })
);

type ChildType = ReactElement | boolean | null;
type ChildTypeArray = (ReactElement | boolean | null)[];

export const GroovModalCard: React.FC<{
  children: ChildType | ChildTypeArray;
  style?: React.CSSProperties;
}> = (props) => {
  const classes = modalCardStyle(props);
  const { children } = props;

  return (
    <Card className={classes.card} style={props.style}>
      {children}
    </Card>
  );
};
