import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const InboxIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon>
      <svg
        width="23px"
        height="22px"
        viewBox="0 0 23 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>94302E13-8517-4C6A-8813-49600CE372FC@1.00x</title>
        <g id="Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin---Mobile-menu---closed" transform="translate(-49.000000, -195.000000)">
            <g id="icons-/-ic-inbox" transform="translate(48.000000, 194.000000)">
              <rect id="container" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M1,12.8077415 L4.70224779,3.54235955 C5.03821367,2.70155891 5.92494706,2.06797624 6.83621025,1.99634645 L7.00723108,1.98958785 L17.0497215,1.98958785 C17.9557167,1.98958785 18.8835125,2.57777108 19.2856064,3.38794069 L19.3547048,3.54235949 L23.0624455,12.8214879 L23,14.9925878 L23,20.009222 C23,21.6024779 21.7531892,22.9039918 20.1824186,22.9949248 L20.0066023,23 L3.99339768,23 C2.34338293,23 1,21.6567953 1,20.009222 L1,20.009222 L1,12.8077415 Z M7.41604368,15 L3,15 L3,20.009222 C3,20.5133846 3.38619349,20.9355385 3.87812554,20.9932739 L3.87812554,20.9932739 L3.99339768,21 L20.0066023,21 C20.5553426,21 21,20.5561268 21,20.009222 L21,20.009222 L21,15 L16.583,15 L16.4878796,15.2069769 C15.6844656,16.8396765 14.054605,17.911643 12.2291544,17.994788 L12.2291544,17.994788 L12,18 C10.0059576,18 8.21061264,16.8185744 7.41604368,15 L7.41604368,15 Z M17.0497215,3.98958785 L7.00723108,3.98958785 C6.87597001,3.98958785 6.67947707,4.10934157 6.59283008,4.22660677 L6.55947097,4.28446606 L3.075,12.9995878 L8.72477184,13 L9.24874942,14.1992568 C9.72584948,15.2912224 10.8029383,16 12,16 C13.1970617,16 14.2741505,15.2912224 14.7512505,14.1992568 L14.7512505,14.1992568 L15.2752281,13 L20.979,12.9995878 L17.4974816,4.28446606 C17.4540857,4.17586187 17.2641852,4.03679108 17.118183,3.99927298 L17.0497215,3.98958785 Z"
                id="fill-color"
                fill={props.color ? props.color : '#444752'}></path>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  </IconButton>
);
