import React from 'react';
import { Typography } from '@material-ui/core';
import { Input } from 'common';
import { eapDrawerStyles } from '../styles';

export interface DrawerInputProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  errorText?: string;
  multiline?: boolean;
  minRows?: number;
}

export const DrawerInput = (props: DrawerInputProps) => {
  const { label, errorText, placeholder, value, onChange, onBlur, multiline, minRows } = props;
  const { inputLabel, textInput, inputRoot, inputInner } = eapDrawerStyles();
  return (
    <>
      <Typography className={inputLabel}>{label}</Typography>
      <Input
        multiline={multiline}
        minRows={minRows}
        className={textInput}
        InputProps={{ classes: { root: inputRoot, input: inputInner } }}
        placeholder={placeholder}
        error={!!errorText}
        helperText={errorText}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};
