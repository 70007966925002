import React from 'react';
import { Table } from 'common/components/Table/Table';
import { eapTableStyles } from '../styles';
import { EapTableHeader } from './EapTableHeader';
import { EapTableBody } from './EapTableBody';

export interface EapTableRowData {
  eapId: string;
  name: string;
  url: string;
  phone: string;
}

export const EapTable = (props: {
  data: EapTableRowData[];
  onEllipsisEditClick: (eapId: string) => void;
  onEllipsisDeleteClick: (eapId: string) => void;
}) => {
  const { data, onEllipsisEditClick, onEllipsisDeleteClick } = props;
  const { table } = eapTableStyles();
  return (
    <Table className={table}>
      <EapTableHeader />
      <EapTableBody
        data={data}
        onEllipsisEditClick={onEllipsisEditClick}
        onEllipsisDeleteClick={onEllipsisDeleteClick}
      />
    </Table>
  );
};
