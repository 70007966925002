import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Card as GroovCard } from 'common';
import glanceSectionStyles from '../styles';
import { GlanceCardBody, GlanceCardBodyProps } from './GlanceCardBody';
import { GlanceSectionConstants } from '../constants';
import { colors } from 'styles/colors';
import { getTypographyVariantFromFontWeight } from 'utils/typographyUtils';

interface GlanceCardProps extends GlanceCardBodyProps, GlanceCardViewMoreDetailsProps {}

const GlanceCardTitle = () => {
  const classes = glanceSectionStyles();
  return (
    <Typography
      variant={getTypographyVariantFromFontWeight(400)}
      className={classes.atAGlanceBody}
      data-testid={'rituals_glance_card_title_label'}>
      {GlanceSectionConstants.body.title}
    </Typography>
  );
};

interface GlanceCardViewMoreDetailsProps {
  onViewMoreDetailsPressed?: () => void;
}

const GlanceCardViewMoreDetails: React.FC<GlanceCardViewMoreDetailsProps> = (props) => {
  const { onViewMoreDetailsPressed } = props;
  const classes = glanceSectionStyles();
  return onViewMoreDetailsPressed ? (
    <Box
      pt={'1.5rem'}
      mt={'1.25rem'}
      onClick={onViewMoreDetailsPressed}
      borderTop={1}
      borderLeft={0}
      borderRight={0}
      color={colors.mysticGrey}>
      <Typography
        variant={getTypographyVariantFromFontWeight(600)}
        className={classes.atAGlanceViewMoreDetails}
        data-testid={'rituals_glance_view_more_details_label'}>
        {GlanceSectionConstants.body.viewMoreDetails}
      </Typography>
    </Box>
  ) : null;
};

export const GlanceCard: React.FC<GlanceCardProps> = (props) => {
  const classes = glanceSectionStyles();
  return (
    <GroovCard style={{ padding: '1.5rem' }}>
      <Box className={classes.verticalFlexBox}>
        <GlanceCardTitle />
        <GlanceCardBody glanceCardBodyProps={props.glanceCardBodyProps} />
        <GlanceCardViewMoreDetails onViewMoreDetailsPressed={props.onViewMoreDetailsPressed} />
      </Box>
    </GroovCard>
  );
};
