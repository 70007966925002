import { contextualNudgePrompts } from 'prompts/prompts';
import React from 'react';
import { ContextualNudgeItemType } from './ContextualNudge.types';
import { dispatch } from 'store';
import { setNudges } from './ContextualNudge.slice';
import { useHistory } from 'react-router-dom';
import { RoutePath } from 'types/routes';
import { getAddContextualNudgeFormInitialValues } from './ContextualNudge.utils';
import { ContextualNudgeForm } from './ContextualNudgeForm';

export function AddContextualNudge() {
  const history = useHistory();

  const onSubmit = (values: ContextualNudgeItemType) => {
    dispatch(setNudges(values));
    history.push(RoutePath.ContextualNudge);
  };

  return (
    <ContextualNudgeForm
      title={contextualNudgePrompts.createPrompt}
      onSubmit={onSubmit}
      initialValues={getAddContextualNudgeFormInitialValues()}
    />
  );
}
