import React from 'react';
import { Card, Box, Typography } from '@material-ui/core';

import handsPhone from 'assets/icons/hands-phone.svg';
import handsPhoneDark from 'assets/icons/hands-phone-2.svg';
import { HubCardPrompts } from 'prompts/prompts';
import { LightButton, VerticalBox } from 'common/components';

import noDataSectionStyles from './styles';

export interface NoDataSectionLearnMoreButton {
  title: string;
  onClick: Function;
}

export enum NoDataSectionTheme {
  DARK = 'dark',
  LIGHT = 'light',
}
interface NoDataSectionProps {
  theme?: NoDataSectionTheme;
  title?: string;
  message?: string;
  learnMoreButton?: NoDataSectionLearnMoreButton;
}

export const NoDataSection: React.FC<NoDataSectionProps> = (props: NoDataSectionProps) => {
  const classes = noDataSectionStyles(props);
  const { theme, title, message, learnMoreButton } = props;
  const rootClasses = [classes.root];
  let handsPhoneSource = null;
  if (theme === NoDataSectionTheme.DARK) {
    handsPhoneSource = handsPhoneDark;
    rootClasses.push(classes.darkBackground);
  } else {
    handsPhoneSource = handsPhone;
    rootClasses.push(classes.lightBackground);
  }
  return (
    <Card className={rootClasses.join(' ')}>
      <Box flex="1">
        <img className={classes.handsPhone} src={handsPhoneSource} alt="No Data" />
      </Box>
      <VerticalBox flex="4" padding="0 1rem">
        <Typography
          variant="h2"
          component="h2"
          className={classes.title}
          data-testid="hub_no_data_title">
          {title ? title : HubCardPrompts.noDataTitle}
        </Typography>
        {message ? (
          <Typography variant="h4" component="h4" data-testid="hub_no_data_description">
            {message}
          </Typography>
        ) : null}
      </VerticalBox>
      <VerticalBox flex="1">
        {learnMoreButton ? (
          <LightButton
            color="primary"
            onClick={() => learnMoreButton.onClick()}
            data-testid="hub_no_data_learn_more_button"
            className={classes.learnMoreButton}>
            {learnMoreButton.title}
          </LightButton>
        ) : null}
      </VerticalBox>
    </Card>
  );
};
