import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ChevronRight, DeleteOutline, ExpandMore } from '@material-ui/icons';
import { AddCircleOutline } from 'assets/icons/add-circle-outline';
import { Button, IconButton, Input } from 'common';
import { RichTextEditor } from 'common/components/RichTextEditor/RichTextEditor';
import { FormLabelWithHelperText } from 'pages/ContextualNudge/components';
import { promptBuilder } from 'prompts/prompts';
import React, { ChangeEvent, useEffect, useState } from 'react';

export type CTAType = {
  name: string;
  description: string;
  id: string;
  expanded?: boolean;
};

export type CTASectionType = {
  value?: CTAType[];
  onChange?: (value: CTAType[]) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  showToolbar?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: any;
  partiallyEditable?: boolean;
  maxResponseTextLength?: number;
};

export function CTASection({
  value,
  onChange,
  onBlur,
  showToolbar = true,
  disabled = false,
  error,
  helperText,
  partiallyEditable = false,
  maxResponseTextLength,
}: Readonly<CTASectionType>) {
  const classes = useStyles();
  const [localCTA, setLocalCTA] = useState<CTAType[]>(value ?? []);
  useEffect(() => {
    if (value && value !== localCTA && !disabled) {
      setLocalCTA(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const updateData = setTimeout(() => {
      if (!disabled) {
        onChange?.(localCTA);
      }
    }, 500);

    return () => clearTimeout(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCTA]);

  const handleAddNewCTA = () => {
    const id = window.crypto.randomUUID();
    setLocalCTA([
      ...localCTA,
      {
        id,
        name: '',
        description: '',
        expanded: true,
      },
    ]);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
    const index = localCTA.findIndex((c) => c.id === id);
    const cta = localCTA.find((c) => c.id === id);
    if (cta) {
      setLocalCTA([
        ...localCTA.slice(0, index),
        {
          ...cta,
          [e.target.name]: e.target.value,
        },
        ...localCTA.slice(index + 1),
      ]);
    }
  };

  const handleExpansion = (id: string) => {
    const index = localCTA.findIndex((c) => c.id === id);
    const cta = localCTA.find((c) => c.id === id);

    if (cta) {
      setLocalCTA([
        ...localCTA.slice(0, index),
        {
          ...cta,
          expanded: !cta.expanded,
        },
        ...localCTA.slice(index + 1),
      ]);
    }
  };

  const handleDeleteCTA = (id: string) => {
    const index = localCTA.findIndex((c) => c.id === id);
    setLocalCTA([...localCTA.slice(0, index), ...localCTA.slice(index + 1)]);
  };

  return (
    <Box>
      <Box
        className={`${localCTA?.length > 0 ? classes.cardActive : classes.card} ${error && typeof helperText === 'string' ? classes.errorBorder : ''}`}>
        {localCTA.length > 0 ? (
          <Box>
            {localCTA.map((cta, index) => (
              <Accordion expanded={cta.expanded} key={cta.id} className={classes.accordion}>
                <AccordionSummary
                  classes={{
                    content: classes.summaryContent,
                    root: classes.summaryRoot,
                  }}>
                  <Container className={classes.container}>
                    <Box className={classes.ctaHeader}>
                      <Typography
                        variant="h4"
                        className={classes.title}
                        onClick={() => handleExpansion(cta.id)}>
                        Action Button {index + 1} {cta.expanded ? <ExpandMore /> : <ChevronRight />}
                      </Typography>
                      {!partiallyEditable && (
                        <IconButton onClick={() => handleDeleteCTA(cta.id)} disabled={disabled}>
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </Box>

                    <Box paddingBottom={1}>
                      {!cta.expanded && <Typography>{cta.name}</Typography>}
                    </Box>
                  </Container>
                </AccordionSummary>
                <AccordionDetails>
                  <Container className={classes.container}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormLabelWithHelperText
                          label={promptBuilder.promptCTAButtonTextLabel}
                          description={promptBuilder.promptCTAButtonTextLabelDescription}
                        />
                        <Input
                          value={cta.name}
                          type="text"
                          fullWidth
                          disabled={partiallyEditable || disabled}
                          onChange={(e) => handleChange(e, cta.id)}
                          onBlur={onBlur}
                          name="name"
                          error={
                            error &&
                            helperText?.[index] &&
                            typeof helperText?.[index] === 'object' &&
                            helperText?.[index].name
                          }
                          helperText={
                            error &&
                            helperText?.[index] &&
                            typeof helperText?.[index] === 'object' &&
                            helperText?.[index].name
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabelWithHelperText
                          label={promptBuilder.promptCTAResponseTextLabel}
                          description={promptBuilder.promptCTAResponseTextLabelDescription}
                        />
                        <Box className={classes.richTextBox}>
                          <RichTextEditor
                            value={cta.description}
                            onChange={(e: any) => handleChange(e, cta.id)}
                            onBlur={onBlur}
                            name="description"
                            showToolbar={showToolbar}
                            disabled={disabled}
                            maxHeight={250}
                            error={
                              error &&
                              helperText?.[index] &&
                              typeof helperText?.[index] === 'object' &&
                              helperText?.[index].description
                            }
                            helperText={
                              error &&
                              helperText?.[index] &&
                              typeof helperText?.[index] === 'object' &&
                              helperText?.[index].description
                            }
                            maxCharacters={maxResponseTextLength}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </AccordionDetails>
              </Accordion>
            ))}
            {showToolbar && !partiallyEditable && (
              <Box className={classes.addButtonBox}>
                <Button
                  className={classes.promptAction}
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewCTA}>
                  {promptBuilder.promptAddNewCTA}
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Box className={classes.emptyStateBox} onClick={handleAddNewCTA}>
            <Typography className={classes.emptySectionAction} variant="h5">
              {promptBuilder.promptAddCTADescription}
            </Typography>
            <IconButton className={classes.emptySectionButton}>
              <AddCircleOutline width={40} height={40} color="#565E74" />
            </IconButton>
            <Typography className={classes.emptySectionSubtitle} variant="subtitle1">
              {promptBuilder.promptAddCTA}
            </Typography>
          </Box>
        )}
      </Box>
      {localCTA.length === 0 && error && typeof helperText === 'string' && (
        <Typography className={classes.errorText}>{helperText}</Typography>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: 'auto',
      padding: theme.spacing(5),
      borderRadius: theme.spacing(2),
      backgroundColor: '#f4fbff',
      border: '2px dashed #4282fe',
    },
    cardActive: {
      margin: 'auto',
      padding: theme.spacing(5),
      borderRadius: theme.spacing(2),
      border: `1px solid #cccccf`,
    },
    summaryContent: {
      margin: '0px !important',
    },
    summaryRoot: {
      minHeight: '48px !important',
    },
    heading: {
      marginBottom: '20px',
    },
    container: {
      maxWidth: '100%',
    },
    title: {
      alignItems: 'center',
      display: 'flex',
    },
    maxLine: {
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
    },
    accordion: {
      boxShadow: 'none',
      borderRadius: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    richTextBox: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    deleteIconGrid: {
      marginTop: theme.spacing(4.5),
    },
    addButtonBox: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      minHeight: theme.spacing(25),
    },
    emptyStateBox: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: theme.spacing(58),
      backgroundColor: '#f4fbff',
      cursor: 'pointer',
    },
    errorText: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(2),
    },
    errorBorder: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    ctaHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    promptAction: {
      boxShadow: 'none',
    },
    emptySectionAction: {
      fontWeight: 700,
      color: '#565E74',
      marginBottom: theme.spacing(3),
      fontSize: '16px',
    },
    emptySectionButton: {
      width: 48,
      height: 48,
      marginBottom: theme.spacing(2),
    },
    emptySectionSubtitle: {
      fontWeight: 700,
      color: '#565E74',
      fontSize: '14px',
    },
  })
);
