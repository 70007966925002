export const GracePeriodPrompts = {
  title: 'Grace period',
  description:
    'When you remove people from your list, they will be granted a grace period during which they will have access to the fully-featured app. You can set below how long you would like this grace period to last, from a minimum of one week to a maximum of twelve weeks.',
  confirmButtonText: 'Confirm changes',
  toastTitle: 'Yay! Everything worked',
  toastMsg: 'Your changes have been saved',
  toastSaveErrorTitle: 'Uh oh. Something went wrong.',
  toastSaveErrorMsg: 'Please refresh your browser and try again',
  createNewRitualButtonText: 'Create new ritual',
  id: 'grace_period',
};
