import React, { ChangeEventHandler } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Input } from '.';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'styles/colors';

interface CommonGridProps {
  name: string;
  values: string;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  errors: boolean | undefined;
  placeholder: string;
  inputName: string;
  helperText: React.ReactNode;
  rows?: number;
  multiline?: boolean;
  type?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nametitle: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: colors.black,
      marginTop: theme.spacing(4),
      fontSize: 18,
    },
    inputInnerStyle: {
      paddingLeft: theme.spacing(5),
    },
    input: {
      paddingLeft: 1,
      color: colors.slateGrey,
    },
  })
);

const CommonRitualGrid = (props: CommonGridProps) => {
  const {
    name,
    values,
    handleChange,
    errors,
    placeholder,
    inputName,
    helperText,
    rows,
    multiline,
    type,
  } = props;
  const classes = useStyles();
  const getDataTestName = (name: string) => {
    return name
      .split(' ')
      .filter((e) => e.indexOf('.') === -1)
      .map((e) => e.toLocaleLowerCase())
      .join('_');
  };
  return (
    <Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h3"
            align="left"
            className={classes.nametitle}
            data-testid={'rituals_' + getDataTestName(name) + '_label'}>
            {name}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={12}>
          <Input
            inputProps={{
              className: classes.input,
            }}
            value={values}
            onChange={handleChange}
            fullWidth={true}
            name={inputName}
            multiline={multiline}
            rows={rows}
            placeholder={placeholder}
            error={errors}
            helperText={helperText}
            type={type}
            data-testid={'rituals_' + getDataTestName(name) + '_input'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommonRitualGrid;
