import * as React from 'react';
import EllipsisIcon from '@material-ui/icons/MoreVert';
import { colors } from 'styles/colors';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

export const VerticalEllipsis = (props: Props) => {
  const { width = 20, height = 20, color = colors.slateGrey } = props;
  return <EllipsisIcon style={{ width, height, color }} />;
};
