import React, { useEffect, useState } from 'react';
import PasswordInput from '../../common/components/PasswordInput';
import { CircularProgress, Grid } from '@material-ui/core';
import { Button, ToasterUtils } from '../../common';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import { RoutePath } from '../../types/routes';
import Typography from '@material-ui/core/Typography';
import { validatePassword } from '../../utils/PasswordHelper';
import prompts, { ADMIN_CREATION_ENDPOINT } from './prompts';
import { setNewPassword } from '../../services/api';

export const CreateAdminAccount = ({ history, location }: RouteComponentProps) => {
  // easier to keep these separate
  const [password, setPassword] = useState('');
  const [passwordErrorArray, setPasswordErrorArray] = useState([false, false]);
  const [apiError, setApiError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(prompts.ERRORS.RULES);
  const [userCode, setUserCode] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // get the code and email from the url params
    const code = queryString.parse(location.search).code as string;
    const email = queryString.parse(location.search).email as string;

    // if either of the url params dont exist then lets error out (as done in the set new password page)
    if (!email || !code) {
      history.replace(RoutePath.Error);
    } else {
      setUserCode(code);
      setUserEmail(email);
    }
  }, [history, location, location.search]);

  // assign the state on change of the fields
  const handlePasswordFieldInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleConfirmPasswordFieldInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.currentTarget.value);
  };

  const doPasswordsMatch = (userPW: string, userConfirmedPW: string) => {
    return userConfirmedPW === userPW;
  };

  const clearConfirmPasswordInput = () => {
    setConfirmPassword('');
  };

  // if the api has returned an error, or the error array contains a true boolean [e] then we have an error
  const hasError = () => {
    return apiError || passwordErrorArray.find((e) => e);
  };

  const assignErrorAndMessage = (errorMsg: string, errorArray = [false, false]) => {
    setPasswordErrorArray(errorArray);
    setErrorMessage(errorMsg);
  };

  const handleCreationResponse = (result: undefined | { status: number }) => {
    if (!result || result.status !== 200) {
      setSubmitting(false);
      ToasterUtils.error(prompts.TEXT.TOAST_FAILURE);
      setApiError(true);
      setErrorMessage(prompts.ERRORS.API);
    } else {
      ToasterUtils.success(prompts.TEXT.TOAST_SUCCESS);
      setTimeout(() => {
        history.replace(RoutePath.Login);
      }, 2000);
    }
  };

  const submitToAPI = async () => {
    setPasswordErrorArray([false, false]);
    setSubmitting(true);
    return setNewPassword({
      code: userCode,
      password,
      username: userEmail,
      customUrl: ADMIN_CREATION_ENDPOINT,
    });
  };

  // unashamedly took the workflow from the way google handles their password / confirm password inputs, as it seems
  // like a nice way of handling it
  const handleSubmitPassword = async () => {
    setApiError(false);
    // only one of these should happen - thanks sundar!
    if (!validatePassword(password)) {
      assignErrorAndMessage(prompts.ERRORS.RULES, [true, false]);
    } else if (!confirmPassword) {
      assignErrorAndMessage(prompts.ERRORS.CONFIRM, [false, true]);
    } else if (!doPasswordsMatch(password, confirmPassword)) {
      assignErrorAndMessage(prompts.ERRORS.MATCH, [false, true]);
      clearConfirmPasswordInput();
    } // we are all good yay!
    else {
      const result = await submitToAPI();
      handleCreationResponse(result);
    }
  };

  const renderSubmitOrSpinner = () => {
    return !submitting ? (
      <Button
        onClick={handleSubmitPassword}
        type="submit"
        disabled={submitting}
        style={{ marginTop: 16 }}>
        {prompts.TEXT.SUBMIT_BUTTON}
      </Button>
    ) : (
      <CircularProgress style={{ marginTop: 24 }} />
    );
  };

  return (
    <Grid container alignItems="center" direction="column">
      <Typography variant="h2" align="center" style={{ marginBottom: 16 }}>
        {prompts.TEXT.PAGE_TITLE}
      </Typography>
      <PasswordInput
        onChange={handlePasswordFieldInput}
        placeholder={prompts.PLACEHOLDERS.PASSWORD}
        error={passwordErrorArray[0]}
      />
      <PasswordInput
        onChange={handleConfirmPasswordFieldInput}
        placeholder={prompts.PLACEHOLDERS.CONFIRM_PASSWORD}
        error={passwordErrorArray[1]}
        value={confirmPassword}
      />
      <Typography variant="subtitle2" color={'error'} style={{ marginTop: 16 }}>
        {hasError() && errorMessage}
      </Typography>
      {renderSubmitOrSpinner()}
    </Grid>
  );
};
