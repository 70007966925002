import queryString from 'query-string';
import { getIntegrationAuthorization } from '../api';
import { GraphRedirectUrlParams } from '../types';

export const EMPTY_UUID = '00000000-0000-0000-0000-000000000000';
export const TEAMS_APP_TYPE = 'msteams';

export const MICROSOFT_APP_CLIENT_ID = process.env.REACT_APP_GROOV_INDEX_APP_CLIENT_ID || '';
export const MICROSOFT_APP_CONSENT_URL =
  'https://login.microsoftonline.com/organizations/adminconsent';

export const getStateForRedirect = async (orgId: string) => {
  try {
    return await getIntegrationAuthorization(orgId, TEAMS_APP_TYPE);
  } catch (error: any) {
    if (error.response?.status === 409) {
      return EMPTY_UUID;
    }
    return crypto.randomUUID();
  }
};

export const getRedirectUrlParams = (url: string): GraphRedirectUrlParams => {
  const params = queryString.parseUrl(url);

  const tenant = params.query['tenant'] as string | undefined;
  const state = params.query['state'] as string | undefined;
  const consent = params.query['admin_consent'] as string | undefined;

  const error = params.query['error'] as string | undefined;
  const errorDescription = params.query['error_description'] as string | undefined;
  const errorUri = params.query['error_uri'] as string | undefined;

  return {
    tenant,
    state,
    consent,
    error,
    errorDescription,
    errorUri,
  };
};

export const convertErrorTitleCase = (title?: string) => {
  const words = title?.split('_');

  let convertedString = '';

  words?.forEach((word) => {
    convertedString = convertedString + capitalizeFirstLetter(word) + ' ';
  });

  return convertedString;
};

export const formatConsentError = (params: GraphRedirectUrlParams) => {
  const { error, errorDescription, errorUri } = params;

  let queryParams;
  let extraInfo;

  if (errorUri) {
    queryParams = queryString.parseUrl(errorUri);
    const code = queryParams.query['code'] as string | undefined;
    extraInfo = code ? `Error code: ${code}` : undefined;
  }

  return { title: convertErrorTitleCase(error), message: errorDescription, extraInfo: extraInfo };
};

const capitalizeFirstLetter = (str: string) => {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};
