import * as React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const SetNewPasswordImage = ({ width = 55, height = 89 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 55 89">
      <title>{'img-reset-password'}</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M46 30.963C45.417 24.309 43.955-.286 29.913.003 15.871.29 4.166 4.922 3 38.485l.578 4.13 11.31.385-.775-10.591c0-5.496.588-19.384 6.436-21.987 0 0 6.43-2.915 9.947-2.027 5.707 1.441 4.974 16.78 5.561 22.28l.677 5.765 8.97-.386.296-5.091z"
          fill="#F2CF00"
        />
        <path fill="#FFF" d="M28 53.387L14.828 32h-3.055L7 39.584 10.815 60z" />
        <path
          d="M42.964 86.412C6.307 102.089 0 41.419 0 41.419l11.374-9.385c.388 8.708.935 56.799 26.54 55.54C63.52 86.313 52.755 30 52.755 30l-41.389 2.034"
          fill="#507EFB"
        />
        <path
          d="M26 66l4.61-14.069a4.76 4.76 0 01-.742-.429v-.046a6.13 6.13 0 01-.299-.162 5.867 5.867 0 01-2.13-4.614c0-3.138 2.336-5.68 5.167-5.68.923.002 1.826.27 2.597.77a6.022 6.022 0 011.97 4.702A6.035 6.035 0 0134.82 51h.173L42 65.086 26 66z"
          fill="#000"
        />
        <path
          d="M43 64.61L26 66l.22-.676 15.734-.926-6.878-14.137h-.17a6.104 6.104 0 002.31-4.542A6.114 6.114 0 0035.286 41a6.068 6.068 0 012.52 4.73 6.077 6.077 0 01-2.199 4.892h.17L43 64.611z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};
