import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { RitualCard } from '../RitualCard/RitualCard';

const useStyles = makeStyles(() =>
  createStyles({
    mainFlexBox: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingTop: '1rem',
    },
    ritualContainer: {
      padding: '16px 0',
    },
    header: {
      fontFamily: 'Averta',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
    },
  })
);

export type RitualCardData = {
  trigger: string;
  action: string;
  teamName: string;
  valuableRate: number;
};

export interface RitualCardContainerProps {
  rituals: RitualCardData[];
}

export const RitualCardContainer = ({ ...props }: RitualCardContainerProps) => {
  const { rituals } = props;
  const styles = useStyles(props);

  return (
    <div className={styles.mainFlexBox}>
      <Typography component="h3" className={styles.header}>
        Most valuable team rituals
      </Typography>
      {rituals.map((ritual) => (
        <div className={styles.ritualContainer} key={ritual.teamName}>
          <RitualCard
            trigger={ritual.trigger}
            action={ritual.action}
            teamName={ritual.teamName}
            valuableRate={ritual.valuableRate}></RitualCard>
        </div>
      ))}
    </div>
  );
};
