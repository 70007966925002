import { Grid, InputAdornment } from '@material-ui/core';
import { Input } from 'common';
import React, { useEffect, useState } from 'react';
import useDebouncedEffect from './useDebouncedEffect';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SearchSharp } from '@material-ui/icons';

export type SearchBarProps<T extends object> = {
  query?: string;
  records: T[];
  setResults?: (record: T[]) => void;
  onQueryChange?: (query: string) => void;
  onFocus?: () => void;
  searchKeys: (keyof T)[];
  fullWidth?: boolean;
  showSearchIcon?: boolean;
  size?: 'small' | 'medium';
  shouldClearQuery?: boolean;
};

export function SearchBar<T extends object>({
  records,
  setResults,
  searchKeys,
  onQueryChange,
  onFocus,
  query: initialQuery = '',
  fullWidth = false,
  showSearchIcon = false,
  size,
  shouldClearQuery = true,
}: SearchBarProps<T>) {
  const classes = useStyles();
  const [query, setQuery] = useState(initialQuery);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(e.target.value);
    onQueryChange?.(e.target.value);
  };

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  const handleOnFocus = () => {
    if (shouldClearQuery) {
      setQuery('');
      onFocus?.();
    }
  };

  useDebouncedEffect(
    () => {
      if (query) {
        setResults?.(searchFilter(records, searchKeys, query));
      } else {
        setResults?.(records);
      }
    },
    [query],
    500
  );

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} lg={fullWidth ? 12 : 6}>
        <Input
          value={query}
          onChange={handleOnChange}
          variant="outlined"
          placeholder="Search"
          onFocus={handleOnFocus}
          InputProps={{
            startAdornment: showSearchIcon ? (
              <InputAdornment position="start" className={classes.icon}>
                <SearchSharp />
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
          fullWidth
          size={size}
        />
      </Grid>
    </Grid>
  );
}

const getSub = <T extends object>(keys: (keyof T)[], obj: T): Partial<T> => {
  const newObj: Partial<T> = {};
  for (const key of keys) {
    if (key in obj) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export function searchFilter<T extends object>(
  records: T[],
  keys: (keyof T)[],
  searchString: string
): T[] {
  return records.filter((record) =>
    Object.values(getSub(keys, record)).join(' ').toLowerCase().includes(searchString.toLowerCase())
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      padding: theme.spacing(1),
    },
    icon: {
      width: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);
