import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CancelIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon>
      <svg
        width="17px"
        height="16px"
        viewBox="0 0 17 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>0BCF2C78-916E-429E-90E2-4E7B354B0FB6@1.00x</title>
        <g id="Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin---Mobile-menu---closed" transform="translate(-342.000000, -45.000000)">
            <g id="icons-/-ic-cancel" transform="translate(338.500000, 41.000000)">
              <rect id="container" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M4.29289322,4.29289322 C4.68341751,3.90236893 5.31658249,3.90236893 5.70710678,4.29289322 L5.70710678,4.29289322 L12,10.585 L18.2928932,4.29289322 C18.6834175,3.90236893 19.3165825,3.90236893 19.7071068,4.29289322 C20.0675907,4.65337718 20.0953203,5.22060824 19.7902954,5.61289944 L19.7071068,5.70710678 L13.415,12 L19.7071068,18.2928932 L19.7902954,18.3871006 C20.0953203,18.7793918 20.0675907,19.3466228 19.7071068,19.7071068 C19.3165825,20.0976311 18.6834175,20.0976311 18.2928932,19.7071068 L18.2928932,19.7071068 L12,13.415 L5.70710678,19.7071068 C5.31658249,20.0976311 4.68341751,20.0976311 4.29289322,19.7071068 C3.93240926,19.3466228 3.90467972,18.7793918 4.20970461,18.3871006 L4.29289322,18.2928932 L10.585,12 L4.29289322,5.70710678 L4.20970461,5.61289944 C3.90467972,5.22060824 3.93240926,4.65337718 4.29289322,4.29289322 Z"
                id="fill-color"
                fill={props.color ? props.color : '#343840'}></path>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  </IconButton>
);
