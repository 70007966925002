import React from 'react';
import { Typography, Box } from '@material-ui/core';

import { HubSurveyPrompts } from 'prompts/prompts';

import { Survey, SurveyStatuses } from '../../../../types';
import { getFormattedDate } from '../../../../utils';
import reviewAndSendStyles from './styles';

export const ReviewAndSend: React.FC<{ surveys: Array<Survey>; messageArr: Array<string> }> = (
  props
) => {
  const classes = reviewAndSendStyles(props);
  const { surveys, messageArr } = props;
  const label = HubSurveyPrompts.label;
  const selectedSurvey = surveys.find((survey) => survey.selected);
  return (
    <Box className={classes.root}>
      <Box className={classes.message}>
        {messageArr.map((message, index) => (
          <Typography key={index} variant="h4" component="p" className={classes.messageSegment}>
            {message}
          </Typography>
        ))}
      </Box>
      <Box className={classes.surveys}>
        {selectedSurvey && (
          <Box className={classes.survey}>
            <Box id="transition-modal-description" className={classes.content}>
              <Box className={classes.desc}>
                <Typography variant="h3" component="h3" className={classes.title}>
                  {selectedSurvey.name}
                </Typography>
                <Box className={classes.meta}>
                  {selectedSurvey.status === SurveyStatuses.AVAILABLE && (
                    <Typography variant="subtitle1" component="p" className={classes.metaAvailable}>
                      {label.available}
                    </Typography>
                  )}
                  {selectedSurvey.last_sent_date && (
                    <Typography variant="subtitle1" component="p" className={classes.metaData}>
                      {label.lastSentDate.replace(
                        '{date}',
                        getFormattedDate(selectedSurvey.last_sent_date)
                      )}
                    </Typography>
                  )}
                  {selectedSurvey.status === SurveyStatuses.OPEN &&
                    selectedSurvey.close_on_date && (
                      <Typography variant="subtitle1" component="p" className={classes.metaData}>
                        {label.closeOnDate.replace(
                          '{date}',
                          getFormattedDate(selectedSurvey.close_on_date)
                        )}
                      </Typography>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
