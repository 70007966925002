import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EmbedToken } from 'services/types';
import { AxiosError } from 'axios';
import { getPowerBIToken } from './../services/api';
import { RootState } from './../store/rootReducer';

interface PowerBIState {
  embedTokens: {
    [reportId: string]: EmbedToken;
  };
  currentRequestId: string;
  loading: 'pending' | 'idle' | 'fulfilled';
  error?: string;
}

const initialState: PowerBIState = {
  embedTokens: {},
  currentRequestId: '',
  loading: 'idle',
};

export const getPowerBIEmbedToken = createAsyncThunk<
  { reportId: string; token: EmbedToken },
  string,
  {
    state: RootState;
    rejectValue: string;
  }
>('getPowerBIEmbedToken', async (reportId: string, thunkAPI) => {
  try {
    const response = await getPowerBIToken(reportId);
    return { reportId, token: response.data as EmbedToken };
  } catch (error) {
    const err = error as AxiosError;
    return thunkAPI.rejectWithValue(err.message);
  }
});

const slice = createSlice({
  name: 'powerBI',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPowerBIEmbedToken.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getPowerBIEmbedToken.fulfilled, (state, { payload }) => {
        state.loading = 'idle';
        state.embedTokens[payload.reportId] = payload.token;
      })
      .addCase(getPowerBIEmbedToken.rejected, (state, action) => {
        state.loading = 'idle';
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export const { reducer } = slice;

export default slice;
export const selectPowerBIEmbedToken = (state: RootState, reportId: string) =>
  state.powerBI.embedTokens[reportId];
