import { HubRitualPrompts } from 'prompts/prompts';

export const GlanceSectionConstants = {
  sectionTitle: HubRitualPrompts.title.glanceSection,
  disclaimer: HubRitualPrompts.label.glanceDisclaimer,
  body: {
    title: HubRitualPrompts.title.glanceBody,
    viewMoreDetails: HubRitualPrompts.label.glanceViewMore,
    sectionOne: {
      title: HubRitualPrompts.title.glanceSectionOne,
      mainFigurePrimaryText: HubRitualPrompts.label.glanceSectionOneMainFigurePrimary,
      mainFigureSecondaryText: HubRitualPrompts.label.glanceSectionOneMainFigureSecondary,
    },
    sectionTwo: {
      title: HubRitualPrompts.title.glanceSectionTwo,
      mainFigurePrimaryText: HubRitualPrompts.label.glanceSectionTwoMainFigurePrimary,
      mainFigureSecondaryText: HubRitualPrompts.label.glanceSectionTwoMainFigureSecondary,
    },
    sectionThree: {
      title: HubRitualPrompts.title.glanceSectionThree,
      mainFigurePrimaryText: HubRitualPrompts.label.glanceSectionThreeMainFigurePrimary,
    },
  },
};
