import { action, payload as _payload, reducer } from 'ts-action';
import { SettingsState, UserRole } from './types';
import { on } from 'ts-action-immer';
import { DEFAULT_GRACE_PERIOD } from './constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSelectableManagers } from './api';
import { dispatch } from 'store';

export const setAutoEmail = action('SET_AUTO_INVITE', _payload<{ autoEmail: boolean }>());
export const setGracePeriod = action('SET_GRACE_PERIOD', _payload<{ gracePeriod: number }>());
export const setUsers = action('SET_USERS', _payload<{ records: UserRole[] }>());
export const setSelectableManagers = action('SET_MANAGERS', _payload<{ records: UserRole[] }>());
export const setLoading = action('SET_LOADING', _payload<{ loading: boolean }>());
export const setSettings = action(
  'SET_SETTINGS',
  _payload<{ autoEmail: boolean; gracePeriod: number }>()
);
export const initSettingState: SettingsState = {
  autoEmail: true,
  gracePeriod: DEFAULT_GRACE_PERIOD,
  loading: false,
  users: [],
  selectableManagers: [],
};

export const SettingsReducer = reducer(
  initSettingState,

  on(setAutoEmail, (state, { payload }) => {
    state.autoEmail = payload.autoEmail;
  }),
  on(setGracePeriod, (state, { payload }) => {
    state.gracePeriod = payload.gracePeriod;
  }),
  on(setLoading, (state, { payload }) => {
    state.loading = payload.loading;
  }),
  on(setUsers, (state, { payload }) => {
    state.users = payload.records;
  }),
  on(setSelectableManagers, (state, { payload }) => {
    state.selectableManagers = payload.records;
  }),
  on(setSettings, (state, { payload }) => {
    state.autoEmail = payload.autoEmail;
    state.gracePeriod = payload.gracePeriod;
  })
);

export const getManagers = createAsyncThunk(
  'settings/getManagers',
  async (_, { rejectWithValue }) => {
    try {
      const records = await getSelectableManagers();
      dispatch(setSelectableManagers({ records }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
