import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { colors } from 'styles/colors';
import { Card, ContainerLayout, Pagination, Button } from 'common';
import { instructions } from 'prompts/prompts';
import { NavLink } from 'common/components/NavLink';
import { manageRitualPrompts } from 'prompts/prompts';
import { useRouteMatch, useHistory, Switch, Route, Redirect } from 'react-router-dom';
import { ManageRitualRoute, RoutePath } from 'types/routes';
import { getActiveRituals } from 'services/api';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { RitualResponseType } from 'types/ritualTypes';
import { CompanyProps, RitualsProps } from 'services/types';

const PAGE_LIMIT = 5;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
    },
    cardWrapper: {
      margin: theme.spacing(4),
    },
    listContainer: {
      paddingTop: '15px !important',
      paddingBottom: '10px !important',
      marginTop: theme.spacing(2),
      justifyContent: 'center',
      backgroundColor: colors.whisperWhite,
      '&:hover': { backgroundColor: colors.whisperWhiteDark, cursor: 'pointer' },
    },
    headerContainer: {
      paddingTop: 18,
      fontSize: 10,
      paddingLeft: 15,
      paddingBottom: 12,
    },
    removeButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      border: '1px solid transparent',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
    headerText: {
      color: colors.gray1,
      fontSize: 16,
      fontWeight: 'bold',
    },
    headerMenu: {
      marginTop: 70,
      marginBottom: 12,
      display: 'flex',
      flex: 3,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    manageRitualDes: {
      marginTop: theme.spacing(7),
    },
    manageRitualCompany: {
      marginTop: theme.spacing(3),
    },
    manageRitual: {
      marginTop: 10,
    },
    buttonContainer: {
      justifyContent: 'center',
      display: 'flex',
      marginTop: 50,
      marginBottom: 50,
    },
    listTitleName: {
      paddingRight: theme.spacing(6),
      wordBreak: 'break-word',
      hyphens: 'auto',
      paddingLeft: theme.spacing(6),
    },
    ritualsContainer: {
      '&>:not(:last-child)': {
        borderBottom: '2px solid',
        borderBottomColor: colors.mysticGrey,
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    listItemBorder: {
      width: '100%',
    },
    listTitle: {
      fontFamily: 'Averta',
      fontWeight: 'normal',
      color: colors.darkGrey,
      marginLeft: 12,
    },
    sponserRole: {
      color: colors.slateGrey2,
      fontSize: 12,
      marginLeft: 12,
    },
    loaderContainer: {
      height: 200,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    confirmButton: {
      background: colors.blue,
      borderRadius: 12,
      height: 56,
      paddingLeft: 25,
      paddingRight: 25,
      marginRight: 16,
      color: colors.white,
      textTransform: 'none',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
  })
);

export const ManageRituals: React.FC = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const navigateToCreateCompany = () => {
    history.push(RoutePath.CreateCompany);
  };

  const [activeTab, setActiveTab] = useState('ActiveRitual');
  const [ritualData, setRitualData] = useState({} as RitualResponseType);
  const [companyRitualData, setCompanyRitualData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles(props);
  const [activePage, setActivePage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [orderBy] = useState('asc');

  useEffect(() => {
    const getRitualsData = async (active: boolean) => {
      try {
        setIsLoading(true);
        const resp = await getActiveRituals(active, PAGE_LIMIT, offset, orderBy);
        if (resp && resp.data) {
          setRitualData(resp.data);
          setCompanyRitualData(resp.data.companyRituals);
        }
      } catch (e) {
        console.log('Error: ', e);
      } finally {
        setIsLoading(false);
      }
    };
    if (activeTab === 'ActiveRitual') {
      getRitualsData(true);
    }
    if (activeTab === 'InActiveRitual') {
      getRitualsData(false);
    }
  }, [activeTab, offset, orderBy]);

  const changeTab = (tab: string) => {
    setActiveTab(tab);
    if (activeTab !== tab) {
      setOffset(0);
      setActivePage(1);
    }
  };
  const navigateToEditCompany = (currentId: string, currentRitual: CompanyProps) => {
    history.push({
      pathname: `/ritual/edit/${currentId}`,
      state: currentRitual,
    });
  };

  const renderNoMessage = () => {
    return (
      <Box p={10}>
        <Typography variant="body1" component="h2" align="center">
          {manageRitualPrompts.noDataFound}
        </Typography>
      </Box>
    );
  };

  const renderPagination = () => {
    if (ritualData) {
      return (
        <Pagination
          count={Math.ceil(ritualData.total / ritualData.limit)}
          page={activePage}
          onChange={(p) => {
            let _activePage = activePage;
            let _offset = offset;
            if (p === 'Next' && _activePage < Math.ceil(ritualData.total / ritualData.limit)) {
              _offset = _offset + 5;
              _activePage = activePage + 1;
            } else if (p === 'Prev' && _activePage > 1) {
              _offset = _offset - 5;
              _activePage = activePage - 1;
            } else if (typeof p === 'number') {
              _offset = p * PAGE_LIMIT - PAGE_LIMIT;
              _activePage = p;
            }
            setActivePage(_activePage);
            setOffset(_offset);
          }}
          pageName="rituals"
        />
      );
    }
  };

  const renderLoader = () => (
    <Grid container className={classes.loaderContainer}>
      <AnimatedSpinner height={30} width={30} />
    </Grid>
  );

  const renderCompanyHeader = () => {
    return (
      <Grid container className={classes.headerContainer}>
        <Grid item container xs={3} md={3}>
          <Typography
            className={classes.headerText}
            variant="h4"
            data-testid="rituals_header_executive_sponser_title">
            {manageRitualPrompts.executiveSponsor}
          </Typography>
        </Grid>
        <Grid container item xs={7} className={classes.ritualsContainer}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            className={classes.listItemBorder}>
            <Grid item xs={6}>
              <Typography
                className={classes.headerText}
                variant="h4"
                data-testid="rituals_header_triggers_title">
                {manageRitualPrompts.triggers}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                className={classes.headerText}
                variant="h4"
                data-testid="rituals_header_actions_title">
                {manageRitualPrompts.actions}
              </Typography>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={2} md={2} className={classes.removeButtonWrapper}></Grid>
      </Grid>
    );
  };

  const renderCompanyRituals = (item: CompanyProps, index: number) => {
    return (
      <Grid container direction="row" item xs={12} className={classes.listContainer} key={index}>
        <Grid item xs={3} md={3}>
          <Typography
            variant="h4"
            className={classes.listTitle}
            data-testid="rituals_data_sponser_name">
            {item.sponsorName}
          </Typography>
          <Typography
            variant="h6"
            className={classes.sponserRole}
            data-testid="rituals_data_sponser_role">
            {item.sponsorRole}
          </Typography>
        </Grid>
        <Grid container item xs={7} className={classes.ritualsContainer}>
          {item.rituals.map((element: RitualsProps) => (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              className={classes.listItemBorder}
              key={element.id}>
              <Grid item xs={6}>
                <Typography
                  variant="h4"
                  className={classes.listTitle}
                  data-testid="rituals_data_triggers">
                  {element.trigger}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant="h4"
                  className={classes.listTitle}
                  data-testid="rituals_data_actions">
                  {element.action}
                </Typography>
              </Grid>
            </Box>
          ))}
        </Grid>
        <Grid item xs={2} md={2} className={classes.removeButtonWrapper}>
          <Typography variant="h4">
            <ArrowForwardIosIcon
              onClick={() => navigateToEditCompany(item.rituals[0].id, item)}
              color={'primary'}
              data-testid="rituals_update_button"
            />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderRitualData = () => {
    return companyRitualData.map((item, index) => renderCompanyRituals(item, index));
  };

  return (
    <ContainerLayout>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.manageRitual}>
              <Typography
                className={classes.manageRitualCompany}
                variant="h2"
                data-testid="rituals_page_title">
                {manageRitualPrompts.manageCompany}
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.manageRitualDes}>
                {instructions.manageRitualPage.manageRituals}
              </Typography>
            </div>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.headerMenu}>
              <NavLink
                to={`${url}/${ManageRitualRoute.active_rituals}`}
                onClick={() => changeTab('ActiveRitual')}
                data-testid="rituals_active_tab">
                {manageRitualPrompts.navHeaders.active_rituals}
              </NavLink>
              <NavLink
                to={`${url}/${ManageRitualRoute.inactive_rituals}`}
                onClick={() => changeTab('InActiveRitual')}
                data-testid="rituals_inactive_tab">
                {manageRitualPrompts.navHeaders.inactive_rituals}
              </NavLink>
            </Grid>
            <Box>
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.bottomCardWrapper}
                spacing={2}>
                {renderCompanyHeader()}
                {isLoading ? (
                  renderLoader()
                ) : companyRitualData.length ? (
                  <React.Fragment>
                    {renderRitualData()}
                    {renderPagination()}
                  </React.Fragment>
                ) : (
                  renderNoMessage()
                )}
              </Grid>
            </Box>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.confirmButton}
                onClick={navigateToCreateCompany}
                data-testid="rituals_create_button">
                {manageRitualPrompts.createRitual}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
      <Switch>
        <Route path="/">
          <Redirect to={`${url}/${ManageRitualRoute.active_rituals}`} />
        </Route>
      </Switch>
    </ContainerLayout>
  );
};
