import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { OverviewCard } from '@groov/ui';

import { RitualOverviewCard } from '../../../../types';

import overviewStyles from './styles';

interface OverviewProps {
  cards: RitualOverviewCard[];
  title: string;
}

export const Overview: React.FC<OverviewProps> = (props) => {
  const classes = overviewStyles(props);
  const { cards, title } = props;

  return (
    <Box className={classes.overview}>
      <Typography variant="h1" component="h1">
        {title}
      </Typography>
      <Box className={classes.cards}>
        {cards.map((card) => (
          <OverviewCard
            key={card.label}
            count={card.count}
            title={card.label}
            styles={{ marginRight: '5rem', width: '14rem' }}
          />
        ))}
      </Box>
    </Box>
  );
};
