import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const DocumentIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon>
      <svg
        width="16px"
        height="20px"
        viewBox="0 0 16 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>24CAEB66-C32E-4ECC-87D8-C8EFC0FBFB02@1.00x</title>
        <g id="Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin---Mobile-menu---closed" transform="translate(-52.000000, -310.000000)">
            <g id="List-item-/-Help" transform="translate(0.000000, 296.000000)">
              <g id="icons-/-ic-document" transform="translate(48.000000, 12.000000)">
                <rect id="container" x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M14.8228298,2 L20,7.17085811 L20,18.9975267 C20,20.6532842 18.6512911,22 16.9975267,22 L16.9975267,22 L7.00247329,22 C5.34335167,22 4,20.6590485 4,18.9991283 L4,18.9991283 L4,5.00087166 C4,3.34686761 5.34485891,2 6.9964216,2 L6.9964216,2 L14.8228298,2 Z M13.996,4 L6.9964216,4 C6.45009717,4 6,4.45076944 6,5.00087166 L6,5.00087166 L6,18.9991283 C6,19.5536776 6.4471213,20 7.00247329,20 L7.00247329,20 L16.9975267,20 C17.5473831,20 18,19.548052 18,18.9975267 L18,18.9975267 L18,8 L13.996,4 Z M12,5 C12.5128358,5 12.9355072,5.38604019 12.9932723,5.88337887 L13,6 L13,9 L16,9 C16.5128358,9 16.9355072,9.38604019 16.9932723,9.88337887 L17,10 C17,10.5128358 16.6139598,10.9355072 16.1166211,10.9932723 L16,11 L11,11 L11,6 C11,5.44771525 11.4477153,5 12,5 Z"
                  id="fill-color"
                  fill={props.color ? props.color : '#444752'}></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  </IconButton>
);
