import { loginAction } from 'auth/auth.actions';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dispatch } from 'store';

export function Login() {
  const location = useLocation();
  useEffect(() => {
    const orgId = new URLSearchParams(location.search).get('org') || '';
    const role = 'OrgAdmin';
    dispatch(loginAction({ orgId, role }));
  }, []);

  return <div></div>;
}
