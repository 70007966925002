export type PowerBIReportDetails = {
  report_key: string;
  report_id?: string;
  report_section?: string;
  nav_header: string;
  nav_route: string;
};

export type PowerBIReportMap = {
  reports: PowerBIReportDetails[];
};

export const standardReports: PowerBIReportMap = {
  reports: [
    {
      report_key: 'key_insights',
      report_id: '4843d894-a6d1-4896-b869-89a388efe3ac',
      report_section: 'ReportSectionea2ee71bca84250c7eed',
      nav_header: 'Key Insights',
      nav_route: 'key_insights',
    },
    {
      report_key: 'tools_content',
      report_id: '4843d894-a6d1-4896-b869-89a388efe3ac',
      report_section: 'ReportSection0f5544e6b9489686e465',
      nav_header: 'Tools & Content',
      nav_route: 'tools_content',
    },
  ],
};

export const GAInsightsReportsMap: PowerBIReportMap = {
  reports: [
    {
      report_key: 'ga_insights_snapshot',
      report_id: process.env.REACT_APP_POWER_BI_GA_INSIGHT_ID,
      report_section: process.env.REACT_APP_POWER_BI_GA_INSIGHT_SECTION_SNAPSHOT,
      nav_header: 'Snapshot',
      nav_route: '',
    },
    {
      report_key: 'ga_insights_recharge',
      report_id: process.env.REACT_APP_POWER_BI_GA_INSIGHT_ID,
      report_section: process.env.REACT_APP_POWER_BI_GA_INSIGHT_SECTION_RECHARGE,
      nav_header: 'Recharge',
      nav_route: '',
    },
    {
      report_key: 'ga_insights_checkin',
      report_id: process.env.REACT_APP_POWER_BI_GA_INSIGHT_ID,
      report_section: process.env.REACT_APP_POWER_BI_GA_INSIGHT_SECTION_CHECKIN,
      nav_header: 'Check-in',
      nav_route: '',
    },
  ],
};

export enum ReportSection {
  Snapshot = 'ReportSection5e06813b06c154aca859',
  Recharge = 'ReportSection9ae7fd9e84de0b7d1e13',
  Checkin = 'ReportSection08731e1e3d120',
}
export const reportMap: PowerBIReportMap = {
  reports: [
    {
      report_key: 'workplace_highlights',
      report_id: '433c4364-9e7e-4638-8947-742856257de9',
      report_section: 'ReportSection3b9fd477af16dc97ee1e',
      nav_header: 'Workplace Highlights',
      nav_route: 'workplace_highlights',
    },
    {
      report_key: 'workplace_highlights_adoption',
      report_id: '6d975d20-2c51-4335-90f7-ee2dcdcd700a',
      report_section: 'ReportSectionb16d7eea027934460205',
      nav_header: 'Workplace Highlights - Adoption',
      nav_route: 'workplace_highlights_adoption',
    },
    {
      report_key: 'workplace_highlights_engagement',
      report_id: '6d975d20-2c51-4335-90f7-ee2dcdcd700a',
      report_section: 'ReportSection0459750492887bef9467',
      nav_header: 'Workplace Highlights - Engagement',
      nav_route: 'workplace_highlights_engagement',
    },
    {
      report_key: 'user_success_highlights',
      report_id: '83fd515f-36c0-4e9a-8571-9230d4def47e',
      report_section: 'ReportSectionb16d7eea027934460205',
      nav_header: 'User Success - Highlights',
      nav_route: 'user_success_highlights',
    },
    {
      report_key: 'user_success_trends',
      report_id: '83fd515f-36c0-4e9a-8571-9230d4def47e',
      report_section: 'ReportSection0459750492887bef9467',
      nav_header: 'User Success - User Trends',
      nav_route: 'user_success_trends',
    },
    {
      report_key: 'user_success_tools',
      report_id: '83fd515f-36c0-4e9a-8571-9230d4def47e',
      report_section: 'ReportSectionba850b023b6faf24670e',
      nav_header: 'User Success - Tools Trends',
      nav_route: 'user_success_tools',
    },
    {
      report_key: 'user_success_content',
      report_id: '83fd515f-36c0-4e9a-8571-9230d4def47e',
      report_section: 'ReportSection79befc03193278d268d4',
      nav_header: 'User Success - Content Trends',
      nav_route: 'user_success_content',
    },
    {
      report_key: 'user_success_support',
      report_id: '83fd515f-36c0-4e9a-8571-9230d4def47e',
      report_section: 'ReportSection77dce22240419a096b4e',
      nav_header: 'User Success - Support',
      nav_route: 'user_success_support',
    },
    {
      report_key: 'workplace_success_adoption',
      report_id: '6d975d20-2c51-4335-90f7-ee2dcdcd700a',
      report_section: 'ReportSectionb16d7eea027934460205',
      nav_header: 'Workplace Success - Adoption',
      nav_route: 'workplace_success_adoption',
    },
    {
      report_key: 'workplace_success_engagement',
      report_id: '6d975d20-2c51-4335-90f7-ee2dcdcd700a',
      report_section: 'ReportSection0459750492887bef9467',
      nav_header: 'Workplace Success - Engagement',
      nav_route: 'workplace_success_engagement',
    },
    {
      report_key: 'engagement_user_stats',
      report_id: '96f7446d-0185-486a-aeec-604a5a4263b0',
      report_section: 'ReportSection0459750492887bef9467',
      nav_header: 'Engagement - User Stats',
      nav_route: 'engagement_user_stats',
    },
    {
      report_key: 'engagement_customer_stats',
      report_id: '96f7446d-0185-486a-aeec-604a5a4263b0',
      report_section: 'ReportSection16b9564a25dbc3e83c25',
      nav_header: 'Engagement - Customer Stats',
      nav_route: 'engagement_customer_stats',
    },
    {
      report_key: 'engagement_activity_stats',
      report_id: '96f7446d-0185-486a-aeec-604a5a4263b0',
      report_section: 'ReportSectioncbda4e88570f9dc2335f',
      nav_header: 'Engagement - Activity Stats',
      nav_route: 'engagement_activity_stats',
    },

    {
      report_key: 'user_retention_overview',
      report_id: '831e42bd-27bb-4e0c-870f-a7ae3ceb6052',
      report_section: 'ReportSectionea27b80d60449ab0db20',
      nav_header: 'User Retention - Overview',
      nav_route: 'user_retention_overview',
    },
    {
      report_key: 'user_retention_content',
      report_id: '831e42bd-27bb-4e0c-870f-a7ae3ceb6052',
      report_section: 'ReportSection8abe64a672816000e132',
      nav_header: 'User Retention - Content',
      nav_route: 'user_retention_content',
    },
    {
      report_key: 'mood_tracker_reason',
      report_id: 'd6e8495b-0a18-4795-b064-28e396b51d5f',
      report_section: 'ReportSectioncba6e3d93ccec8330d30',
      nav_header: 'Mood Tracker Reason',
      nav_route: 'mood_tracker_reason',
    },
    {
      report_key: 'profile_type',
      report_id: 'd6e8495b-0a18-4795-b064-28e396b51d5f',
      report_section: 'ReportSection20f2c2de913e7396a298',
      nav_header: 'User Profile Types',
      nav_route: 'profile_type',
    },
    {
      report_key: 'aia_registrations',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSection9875198394d1490a09ce',
      nav_header: 'AIA - Member Registrations',
      nav_route: 'aia_registrations',
    },
    {
      report_key: 'aia_monthly_points',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSection',
      nav_header: 'AIA - Monthly Points',
      nav_route: 'aia_monthly_points',
    },
    {
      report_key: ' aia_tools_month',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSectiona996824a3801a3718ce2',
      nav_header: 'AIA - Times Tools Used per Month',
      nav_route: 'aia_tools_month',
    },
    {
      report_key: 'aia_tools_type',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSection4cbaf3ef097a9100aa1a',
      nav_header: 'AIA - Times Tools Used by Type',
      nav_route: 'aia_tools_type',
    },
    {
      report_key: 'aia_active_members',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSection837dacc05e100de5200c',
      nav_header: 'AIA - Active Members',
      nav_route: 'aia_active_members',
    },
    {
      report_key: 'aia_content_consumption_category',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSection0430272f1c451d840002',
      nav_header: 'AIA - Content Consumption by Category',
      nav_route: 'aia_content_consumption_category',
    },
    {
      report_key: 'aia_content_consumption_month',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSectiondcdc396b60736613a609',
      nav_header: 'AIA - Content Consumption per Month',
      nav_route: 'aia_content_consumption_month',
    },
    {
      report_key: 'aia_member_distribution',
      report_id: 'd68becad-5793-45bf-a26d-20cd942c1333',
      report_section: 'ReportSectionf07eca269c72c105846e',
      nav_header: 'AIA - Members Geographic Distribution',
      nav_route: 'aia_member_distribution',
    },
    {
      report_key: 'ga_insights',
      report_id: process.env.REACT_APP_POWER_BI_GA_INSIGHT_ID,
      report_section: process.env.REACT_APP_POWER_BI_GA_INSIGHT_SECTION_SNAPSHOT,
      nav_header: 'GA Insights',
      nav_route: 'ga-insights',
    },
  ],
};
