import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const selectDateStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      textAlign: 'center',
      width: '43.5rem',
    },
    messageParent: {
      display: 'flex',
      marginTop: theme.spacing(20),
      justifyContent: 'center',
    },
    bold: {
      fontWeight: 600,
    },
    messageSegment: {
      lineHeight: 1.5,
    },
    box: {
      backgroundColor: colors.snow,
      display: 'flex',
      borderRadius: theme.spacing(3),
      padding: theme.spacing(5),
      alignItems: 'center',
    },
    button: {
      height: theme.spacing(10),
      marginLeft: theme.spacing(4),
      padding: theme.spacing(2.5, 7),
    },
    datePickerButton: {
      height: theme.spacing(10),
      padding: theme.spacing(2.5, 7),
    },
    pickers: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(2.5),
    },
    reminder: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(10),
      justifyContent: 'space-between',
    },
    reminderInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    reminderTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2.5),
    },
  })
);

export default selectDateStyles;
