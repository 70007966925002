import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from './Button';
import { colors } from 'styles/colors';

interface ConfirmAlertProps {
  open: boolean;
  title: string;
  description?: any;
  cancelButtonText: string;
  confirmButtonText: string;
  handleCancel: () => void;
  handleConfirm: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      border: '1px solid rgb(237, 240, 245)',
    },
    title: {
      fontWeight: 900,
      color: 'rgb(37, 39, 43)',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
    dialogAction: {
      justifyContent: 'center',
      padding: theme.spacing(7),
    },
  })
);
export const ConfirmAlert: React.FC<ConfirmAlertProps> = (props: ConfirmAlertProps) => {
  const classes = useStyles(props);

  const {
    open,
    title,
    description,
    cancelButtonText,
    confirmButtonText,
    handleCancel,
    handleConfirm,
  } = props;
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 12,
            border: '1px solid rgb(237, 240, 245)',
            height: description ? 344 : 200,
            width: 375,
          },
        }}
        open={open}
        fullScreen={false}
        maxWidth={'xs'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}>
        <DialogTitle className={classes.title}>
          <Typography variant="h6" component="div" className={classes.title}>
            {title}
          </Typography>
        </DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleConfirm}
            style={{ background: colors.lightRed, color: colors.redWarn, marginRight: 20 }}
            fullWidth
            data-testid={'confirmation_' + confirmButtonText.toLocaleLowerCase() + '_button'}>
            {confirmButtonText}
          </Button>
          <Button
            onClick={handleCancel}
            fullWidth
            data-testid={'confirmation_' + cancelButtonText.toLocaleLowerCase() + '_button'}>
            {cancelButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
