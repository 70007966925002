import jwt_decode, { JwtPayload } from 'jwt-decode';

export interface AccessToken extends JwtPayload {
  auth_time: number;
  client_id: string;
  exp: number;
  expire_in: string;
  sub: string;
  org_id: string;
  role: string;
  sub_orgs: string;
  sub_status: SubscriptionStatus;
  sub_tier: SubscriptionType;
}

export enum SubscriptionType {
  FREE = 'FREE',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

export enum SubscriptionStatus {
  INVITED = 'INVITED',
  LINKED = 'LINKED',
  REMOVED = 'REMOVED',
  USER_INITIATED = 'USER_INITIATED',
  PAUSED = 'PAUSED',
  MOVED = 'MOVED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  GRACE_PERIOD = 'GRACE_PERIOD',
}

export const parseAccessToken = (token?: string): AccessToken | undefined => {
  if (token) {
    try {
      return jwt_decode<AccessToken>(token);
    } catch (error) {
      console.log("Couldn't parse token");
    }
  }
};
