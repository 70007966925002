import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Input } from 'common';
import { AddCircleOutline, DeleteOutline, EditOutlined } from '@material-ui/icons';
import { ContextualNudgePromptFormTypes, TriggerTypes } from '../ContextualNudge.types';
import { FormikProps } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import {
  getFilteredOptions,
  getSelectedPromptTrigger,
  promptTriggerOptions,
} from '../ContextualNudge.utils';

export type ManageTriggerRuleProps = {
  promptTriggers: TriggerTypes[];
  setPromptTriggers: React.Dispatch<React.SetStateAction<TriggerTypes[]>>;
  formikProps: FormikProps<ContextualNudgePromptFormTypes>;
};
export function ManageTriggerRules({
  promptTriggers,
  formikProps,
  setPromptTriggers,
}: Readonly<ManageTriggerRuleProps>) {
  const classes = useStyles();
  const { values, setFieldValue, handleChange, handleBlur } = formikProps;

  const handleAddPromptTrigger = () => {
    setPromptTriggers((value) => [
      ...value,
      {
        type: values.triggerType,
        condition: values.triggerCondition,
        value: values.triggerValue,
        id: `condition-${promptTriggers.length + 1}`,
      },
    ]);
    setFieldValue('triggerType', '');
    setFieldValue('triggerCondition', '');
    setFieldValue('triggerValue', '');
  };

  const handleRemovePromptTrigger = (index: number) => {
    const newPromptTriggers = [
      ...promptTriggers.slice(0, index),
      ...promptTriggers.slice(index + 1),
    ];
    setPromptTriggers(newPromptTriggers);
  };

  const handleEditPromptTrigger = (index: number) => {
    const trigger = promptTriggers[index];
    const newPromptTriggers = [
      ...promptTriggers.slice(0, index),
      ...promptTriggers.slice(index + 1),
    ];
    setPromptTriggers(newPromptTriggers);
    setFieldValue('triggerType', trigger.type);
    setFieldValue('triggerCondition', trigger.condition);
    setFieldValue('triggerValue', trigger.value);
  };
  const selectedPromptTriggers = promptTriggers.map((pt, index) => (
    <Box key={`${pt.type}-${pt.condition}-${pt.value}`} marginTop={2}>
      <Typography>{pt.id}</Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <Input name="triggerType" label="Criteria" fullWidth value={pt.type} disabled />
        </Grid>
        <Grid item xs={3}>
          <Input
            name="triggerCondition"
            label="Condition"
            fullWidth
            value={pt.condition}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Input name="triggerValue" label="Value" fullWidth value={pt.value} disabled />
        </Grid>
        <Grid item xs={1} className={classes.deleteButton}>
          <EditOutlined color="primary" onClick={() => handleEditPromptTrigger(index)} />
          <DeleteOutline color="error" onClick={() => handleRemovePromptTrigger(index)} />
        </Grid>
      </Grid>
    </Box>
  ));
  return (
    <div>
      {selectedPromptTriggers}
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <Autocomplete
            multiple={false}
            options={promptTriggerOptions.map((o) => o.label)}
            onSelect={handleChange}
            value={values.triggerType}
            filterSelectedOptions
            filterOptions={(op) => getFilteredOptions(op, values.triggerType)}
            renderInput={(params) => <Input {...params} label="Criteria" name="triggerType" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            multiple={false}
            options={getSelectedPromptTrigger(values.triggerType)?.conditions?.options ?? []}
            onSelect={handleChange}
            value={values.triggerCondition}
            filterOptions={(op) => getFilteredOptions(op, values.triggerCondition)}
            renderInput={(params) => (
              <Input {...params} label="Condition" name="triggerCondition" />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          {getSelectedPromptTrigger(values.triggerType)?.values?.isInput ? (
            <Input
              label="Value"
              name="triggerValue"
              type={getSelectedPromptTrigger(values.triggerType)?.values?.type}
              fullWidth
              value={values.triggerValue}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ) : (
            <Autocomplete
              multiple={false}
              options={getSelectedPromptTrigger(values.triggerType)?.values?.options ?? []}
              onSelect={handleChange}
              onBlur={handleBlur}
              value={values.triggerValue}
              filterOptions={(op) => getFilteredOptions(op, values.triggerValue)}
              renderInput={(params) => (
                <Input {...params} label="Value" name="triggerValue" fullWidth />
              )}
            />
          )}
        </Grid>
        <Grid item xs={1} className={classes.addButton}>
          <AddCircleOutline onClick={handleAddPromptTrigger} color="primary" />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      alignItem: 'center',
    },
    addButton: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      alignItem: 'center',
    },
  })
);
