import React from 'react';

import Markdown from 'markdown-to-jsx';
import { GroovModal } from 'common/components/Modal/GroovModal';
import { GroovModalCard } from 'common/components/Modal/GroovModalCard';
import { GroovModalCloseButton } from 'common/components/Modal/GroovModalCloseButton';

export const BaselineReportModal: React.FC<{
  openModal: boolean;
  setOpenModal: Function;
  baselineReportMarkdown: string;
}> = (props) => {
  const { openModal, setOpenModal, baselineReportMarkdown } = props;

  return (
    <GroovModal isModalShowing={openModal} style={{ opacity: 0.5 }}>
      <GroovModalCloseButton onPressed={() => setOpenModal(false)} />
      <GroovModalCard>
        <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
          <Markdown
            options={{
              overrides: {
                a: {
                  props: {
                    target: '_blank',
                  },
                },
              },
            }}>
            {baselineReportMarkdown}
          </Markdown>
        </div>
      </GroovModalCard>
    </GroovModal>
  );
};
