export enum Integration {
  Slack = 'slack',
  MsTeams = 'msteams',
  Ms365 = 'ms365',
  HubSpot = 'hubspot',
}

export enum IntegrationType {
  Connector = 'Connector',
  Channel = 'Channel',
}

export interface ServicePrincipalInstallationState extends ServicePrincipalInstallationResult {
  status: ServicePrincipalInstallationStatus;
}

export interface ServicePrincipalInstallationResult {
  title?: string;
  message?: string;
  extraInfo?: string;
}

export enum ServicePrincipalInstallationStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Success = 'Success',
  Error = 'Error',
  NoParams = 'No Params',
}

export interface GraphRedirectUrlParams {
  tenant?: string;
  state?: string;
  consent?: string;
  error?: string;
  errorDescription?: string;
  errorUri?: string;
}

export interface CustomerIntegration {
  id: string;
  integrationId: string;
  platform: string;
  orgId: string;
  properties: any[];
  data?: string;
}
