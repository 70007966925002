export class InvalidEmailError extends Error {}

export const hashEmail = (str: string): string => {
  if (!str) {
    throw new InvalidEmailError('Invalid Email');
  }

  str = str.trim().toLocaleLowerCase();
  let h = 0;
  const l = str.length;
  let i = 0;
  if (l > 0) {
    while (i < l) {
      h = ((h << 5) - h + str.charCodeAt(i++)) | 0;
    }
  }
  return h.toString();
};
