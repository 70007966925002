import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import { exchangeToken } from 'services/api';

const CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID ?? 'groov.adminportalsso';
const AUTHORITY_URL = process.env.REACT_APP_AUTHORITY ?? 'https://login.test.mentemia.com';
const APP_URL = process.env.REACT_APP_URL ?? 'https://localhost:44321';

const baseConfig: UserManagerSettings = {
  authority: AUTHORITY_URL,
  client_id: CLIENT_ID,
  redirect_uri: `${APP_URL}/auth-callback`,
  post_logout_redirect_uri: `${APP_URL}/logout-callback`,
  response_type: 'code',
  scope: 'openid profile',
  extraQueryParams: {},
};

let userManager = new UserManager(baseConfig);

type SignInParams = {
  orgId?: string | null;
  role?: string | null;
};

export const signIn = async (params: SignInParams) => {
  try {
    const { orgId, role } = params;
    const queryParams = { org: orgId, role: role } as any;

    const config = { ...baseConfig, extraQueryParams: { ...queryParams } };
    userManager = new UserManager(config);
    return await userManager.signinRedirect();
  } catch (error) {
    console.error('Error during sign-in', error);
  }
};

export const completeSignIn = async () => {
  try {
    return await userManager.signinRedirectCallback();
  } catch (error) {
    console.error('Error completing sign-in', error);
  }
};

export const signOut = async () => {
  try {
    await userManager.signoutRedirect();
  } catch (error) {
    console.error('Error during sign-out', error);
  }
};

export const completeSignOut = async (): Promise<void> => {
  try {
    await userManager.signoutRedirectCallback();
    await userManager.removeUser();
  } catch (error) {
    console.error('Error completing sign-out', error);
  }
};

export const getUser = async () => {
  try {
    const user = await userManager.getUser();
    return user;
  } catch (error) {
    console.error('Error getting user', error);
  }
};

export const getUserFromSessionStorage = () => {
  try {
    const session = sessionStorage.getItem(`oidc.user:${AUTHORITY_URL}:${CLIENT_ID}`);
    const oidcUser = session ? JSON.parse(session) : null;
    return oidcUser;
  } catch (error) {
    console.error('Error getting user from session storage', error);
  }
};

export const getFederatedLogin = async (accessToken: string) => {
  try {
    return await exchangeToken(accessToken);
  } catch (error) {
    console.error('Error getting federated token', error);
  }
};

export const refreshToken = () => {};
