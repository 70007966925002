import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const DownloadIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon>
      <svg
        width="24px"
        height="22px"
        viewBox="0 0 24 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>8D030D62-50C0-4C4F-95FF-8D3C7AEA3DE6@1.00x</title>
        <g id="Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin---Help" transform="translate(-180.000000, -425.000000)" stroke="#444752">
            <g id="List" transform="translate(133.000000, 192.000000)">
              <g id="List-item" transform="translate(32.000000, 216.000000)">
                <g id="ico-download" transform="translate(16.000000, 18.000000)">
                  <path
                    d="M7,14 L4,14 L4,14 C1.790861,14 0,12.209139 0,10 C0,7.790861 1.790861,6 4,6 C4.35840468,6 4.70579988,6.04713713 5.03632437,6.13555013 C5.01233106,5.92702603 5,5.71495305 5,5.5 C5,2.46243388 7.46243388,0 10.5,0 C13.0673313,0 15.2238156,1.7590449 15.8299648,4.1376465 C16.2052921,4.04765874 16.5970804,4 17,4 C19.7614237,4 22,6.23857625 22,9 C22,11.7614237 19.7614237,14 17,14 L15,14"
                    id="Combined-Shape"
                    strokeWidth="2"
                    strokeLinejoin="round"></path>
                  <line x1="11" y1="10" x2="11" y2="18" id="Path-58" strokeWidth="2"></line>
                  <polyline
                    id="Path-59"
                    fill={props.color ? props.color : '#444752'}
                    points="14 17 11 20 8 17"></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  </IconButton>
);
