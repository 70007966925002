import React, { useEffect, useMemo, useState } from 'react';
import { Card, ContainerLayout } from 'common';
import { Box, Grid, makeStyles, Tabs, Tab, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { UserResponses } from './UserResponses';
import { PromptInformation } from './PromptInformation';
import { DetailsFilters } from './DetailsFilters';
import { useAppSelector } from 'store';
import { PromptDetailsFilters } from 'pages/GroovVoice/GroovVoice';
import { PromptCTATypes } from 'pages/PromptBuilder';
import { SummaryBars } from './SummaryBars';

export const PromptDetails: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const promptDetails = useAppSelector((state) => state.groovVoice.currentSelectedPromptDetails);
  const prompts = useAppSelector((state) => state.groovVoice.prompts);

  const prompt = useMemo(() => prompts.find((p) => p.promptId.toString() === id), [id, prompts]);

  const promptResponses = promptDetails?.responses ?? [];
  const totalResponses = promptDetails?.totalResponses ?? 0;

  const isOpenTextType = prompt?.properties?.prompt_cta_type === PromptCTATypes.openEndedText;
  const [value, setValue] = useState(isOpenTextType ? 'responses' : 'summary');

  const handleChange = (_: unknown, newValue: string) => {
    setValue(newValue);
  };

  const isConfidentiallyHidden = prompt?.confidential && promptResponses.length === 0 && totalResponses !== 0;

  const renderNoResults = isConfidentiallyHidden ? <HiddenResults /> : <NoResults />;
   
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!prompt || !promptDetails) {
    return <></>;
  }
  
  return (
    <ContainerLayout>
      <Card className={classes.card}>
        <Grid container spacing={10}>
          <Grid item xs={12} lg={8}>
            <Box className={classes.root}>
              <PromptInformation prompt={prompt} promptDetails={promptDetails} />
              <Box my={6}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  {!isOpenTextType && <Tab label="Summary" value="summary" />}
                  <Tab label="Responses" value="responses" />
                </Tabs>
                <Box hidden={value !== 'summary'} my={6}>
                  <Card className={classes.responseCard}>
                    {!promptResponses.length && renderNoResults}
                    <SummaryBars actions={promptDetails.prompt.actions} responses={promptResponses ?? []} />
                  </Card>
                </Box>
                <Box hidden={value !== 'responses'} my={6}>
                  <Card className={classes.responseCard}>
                    {promptResponses.length ?
                    <UserResponses
                      responseList={promptResponses}
                      promptCTAType={prompt.properties.prompt_cta_type as PromptCTATypes}
                      isConfidential={prompt.confidential}
                    />
                    : renderNoResults
                    }
                  </Card>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box mt="40%">
              <DetailsFilters {...mapResponsePropertiesToOptions(promptDetails?.filters)} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </ContainerLayout>
  );
};

const HiddenResults = () => {
  return (
    <Box my={6} width={'80%'} alignSelf={'center'}>
      <Typography variant="body1" align="center">
      <em>
        To protect participant confidentiality, results for this item are hidden.
      </em>
      </Typography>
    </Box>
  );
}

const NoResults = () => {
  return (
    <Box my={6} width={'80%'} alignSelf={'center'}>
      <Typography variant="body1" align="center">
      <em>
        No results found for this item.
      </em>
      </Typography>
    </Box>
  );
}

const mapResponsePropertiesToOptions = (filters?: PromptDetailsFilters) => {
  const managerOptions = filters?.managers.filter((m) => Boolean(m.id));
  const officeLocationOptions = filters?.officeLocations.filter((o) => Boolean(o));
  const departmentOptions = filters?.departments.filter((o) => Boolean(o));
  const jobTitleOptions = filters?.jobTitles.filter((o) => Boolean(o));

  return {
    managerOptions,
    officeLocationOptions,
    departmentOptions,
    jobTitleOptions,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
    flex: 1,
  },
  card: {
    flex: 1,
    marginBottom: theme.spacing(12),
  },
  responseCard: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    gap: theme.spacing(12),
  },
}));
