import React from 'react';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ServicePrincipalInstallationState, ServicePrincipalInstallationStatus } from '../types';

export const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  title: {
    marginBottom: '16px',
  },
  body: {
    marginBottom: '8px',
  },
}));

export const Ms365RedirectPageBody: React.FC<{
  redirectState?: ServicePrincipalInstallationState;
}> = (props) => {
  const { redirectState } = props;
  const classes = useStyles();

  if (redirectState?.status === ServicePrincipalInstallationStatus.InProgress) {
    return (
      <Box className={classes.container}>
        <AnimatedSpinner width={40} height={40} />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        {redirectState?.title + '\n'}
      </Typography>
      <Typography variant="body1" className={classes.body}>
        {redirectState?.message + '\n'}
      </Typography>
      <Typography variant="body1" className={classes.body}>
        {redirectState?.extraInfo}
      </Typography>
    </Box>
  );
};
