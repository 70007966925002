import React, { useEffect, useReducer } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useFeatureFlags } from 'FeatureFlagContext';
import { Card, ContainerLayout, ToasterUtils } from '../../common';
import GracePeriod from './components/GracePeriod/';
import AutoEmail from './components/AutoEmail/';
import { getSettings } from './api';
import {
  initSettingState,
  setAutoEmail,
  setGracePeriod,
  setLoading,
  setSettings,
  SettingsReducer,
} from './state';
import { SettingsPrompts } from './prompts';
import GroovAnywhereTeamsOld from './components/GroovAnywhereTeamsOld';

export const Settings: React.FC = () => {
  const [state, dispatch] = useReducer(SettingsReducer, initSettingState);
  const featureFlags = useFeatureFlags();

  const enableTeamsOnboarding = featureFlags.gaNewOnboarding;

  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSettings = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const settings = await getSettings();
      dispatch(setSettings({ ...settings }));
    } catch (e) {
      showLoadingError();
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const showLoadingError = () => {
    ToasterUtils.toast(
      SettingsPrompts.toastLoadErrorMsg,
      'error',
      SettingsPrompts.toastLoadErrorTitle
    );
  };

  const handleAutoEmailUpdate = (autoEmail: boolean) => {
    dispatch(setAutoEmail({ autoEmail: autoEmail }));
  };

  const handleGracePeriodUpdate = (gracePeriod: number) => {
    dispatch(setGracePeriod({ gracePeriod }));
  };

  return (
    <ContainerLayout>
      <Card>
        <Typography variant="h2" style={{ marginTop: '0.5rem' }} data-testid="settings_page_title">
          {SettingsPrompts.title}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            {!enableTeamsOnboarding && <GroovAnywhereTeamsOld />}
            <GracePeriod
              gracePeriod={state.gracePeriod}
              loading={state.loading}
              updateGracePeriod={handleGracePeriodUpdate}
            />
            <AutoEmail
              autoEmail={state.autoEmail}
              loading={state.loading}
              updateAutoEmail={handleAutoEmailUpdate}
            />
          </Grid>
        </Grid>
      </Card>
    </ContainerLayout>
  );
};
