import { Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Button, Card, ContainerLayout, Input } from 'common';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { useFormik } from 'formik';
import { contextualNudgePrompts } from 'prompts/prompts';
import React, { useMemo, useState } from 'react';
import {
  ContextualNudgeItemType,
  ContextualNudgePromptFormTypes,
  OptionTypes,
  ScheduledRuleTypes,
  TriggerTypes,
} from './ContextualNudge.types';
import { generateCombinations, getFilteredOptions, groupOptions } from './ContextualNudge.utils';
import {
  FormLabelWithHelperText,
  ManagePromptActions,
  ManageScheduleRules,
  ManageTriggerRules,
} from './components';

export type ContextualNudgeFormProps = {
  title: string;
  onSubmit: (values: ContextualNudgeItemType) => void;
  initialValues: ContextualNudgePromptFormTypes;
  editMode?: boolean;
};

export function ContextualNudgeForm({
  title,
  onSubmit,
  initialValues,
  editMode = false,
}: Readonly<ContextualNudgeFormProps>) {
  const [promptTriggers, setPromptTriggers] = useState<TriggerTypes[]>(
    initialValues.triggers ?? []
  );
  const [promptOptions, setPromptOptions] = useState<OptionTypes[]>(initialValues.actions ?? []);
  const [scheduleRules, setScheduleRules] = useState<ScheduledRuleTypes[]>(
    initialValues.scheduleRules ?? []
  );
  const submitButtonContent = useMemo(
    () => (editMode ? contextualNudgePrompts.promptUpdate : contextualNudgePrompts.promptCreate),
    [editMode]
  );
  const classes = useStyles();

  const handleFormSubmit = (internalValues: ContextualNudgePromptFormTypes) => {
    onSubmit({
      name: internalValues.name,
      description: internalValues.description,
      triggers: promptTriggers,
      actions: promptOptions,
      scheduleRules,
      group: internalValues.group,
      promptDetails: internalValues.promptDetails,
      id: initialValues.id,
      triggerRelationship: internalValues.triggerRelationship,
      ruleRelationship: internalValues.ruleRelationship,
    });
  };
  const formikProps = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
  });
  const { values, isSubmitting, handleChange, handleBlur, handleSubmit } = formikProps;

  return (
    <ContainerLayout>
      <Card>
        <Typography
          className={classes.manageNudgeCompany}
          variant="h2"
          data-testid="nudges_page_title">
          {title}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.PromptName}
                description={contextualNudgePrompts.PromptNameDesc}
              />
              <Input
                fullWidth={true}
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={contextualNudgePrompts.PromptName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.PromptDescription}
                description={contextualNudgePrompts.PromptDescriptionDesc}
              />
              <Input
                fullWidth={true}
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={contextualNudgePrompts.PromptDescription}
                multiline
                minRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.PromptTrigger}
                description={contextualNudgePrompts.PromptTriggerDesc}
              />
              <ManageTriggerRules
                formikProps={formikProps}
                promptTriggers={promptTriggers}
                setPromptTriggers={setPromptTriggers}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.promptTriggerRelation}
                description={contextualNudgePrompts.promptTriggerRelationDesc}
              />
              <Autocomplete
                multiple={false}
                options={generateCombinations(promptTriggers.map((r) => r.id))}
                onSelect={handleChange}
                filterOptions={(op) => getFilteredOptions(op, values.triggerRelationship)}
                renderInput={(params) => <Input {...params} name="triggerRelationship" />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.PromptSchedule}
                description={contextualNudgePrompts.PromptScheduleDesc}
              />
              <ManageScheduleRules
                formikProps={formikProps}
                scheduleRules={scheduleRules}
                setScheduleRules={setScheduleRules}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.promptRuleRelation}
                description={contextualNudgePrompts.promptRuleRelationDesc}
              />
              <Autocomplete
                multiple={false}
                options={generateCombinations(scheduleRules.map((r) => r.id))}
                onSelect={handleChange}
                filterOptions={(op) => getFilteredOptions(op, values.ruleRelationship)}
                renderInput={(params) => <Input {...params} name="ruleRelationship" />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.PromptDetails}
                description={contextualNudgePrompts.PromptDetailsDesc}
              />
              <Input
                fullWidth={true}
                name="promptDetails"
                value={values.promptDetails}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={contextualNudgePrompts.PromptName}
                multiline
                minRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.PromptOptions}
                description={contextualNudgePrompts.PromptOptionsDesc}
              />
              <ManagePromptActions
                formikProps={formikProps}
                promptOptions={promptOptions}
                setPromptOptions={setPromptOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={contextualNudgePrompts.PromptGroup}
                description={contextualNudgePrompts.PromptGroupDesc}
              />
              <Autocomplete
                multiple={true}
                options={groupOptions}
                onSelect={handleChange}
                filterOptions={(op) => getFilteredOptions(op, values.group)}
                renderInput={(params) => <Input {...params} name="group" />}
              />
            </Grid>
            <Grid item xs={12} className={classes.flex} justifyContent="flex-end">
              <Button type="submit" disabled={isSubmitting} className={classes.createPrompt}>
                {isSubmitting ? <AnimatedSpinner height={30} width={30} /> : submitButtonContent}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </ContainerLayout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    manageNudgeCompany: {
      marginTop: theme.spacing(3),
    },
    manageNudge: {
      marginTop: theme.spacing(3),
    },
    createPrompt: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6),
      minWidth: theme.spacing(42),
    },
    flex: {
      display: 'flex',
    },
  })
);
