import { createSlice } from '@reduxjs/toolkit';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import {
  completeLoginAction,
  completeLogoutAction,
  getFederatedToken,
  loginFromExternalAction,
} from './auth.actions';
import { getUserFromSessionStorage } from './auth.services';

const oidcUser = getUserFromSessionStorage();
const accessToken = oidcUser?.access_token ?? '';
const refreshToken = oidcUser?.refresh_token ?? '';
const orgId = oidcUser?.profile.org_id ?? '';
const userId = oidcUser?.profile.sub ?? '';
const emailAddress = oidcUser?.profile.preferred_username ?? '';

export interface AuthState {
  loading: boolean;
  accessToken: string;
  userId: string;
  orgId: string;
  expiresAt: number;
  emailAddress: string;
  error: unknown;
  isAuthenticated: boolean;
  oidcAuth: {
    accessToken: string;
    userId: string;
    orgId: string;
    expiresAt: number;
    refreshToken: string;
    emailAddress: string;
  };
}

const initialState: AuthState = {
  loading: false,
  accessToken,
  userId,
  orgId,
  expiresAt: 0,
  emailAddress,
  error: null,
  isAuthenticated: false,
  oidcAuth: {
    accessToken,
    userId,
    orgId,
    expiresAt: 0,
    refreshToken,
    emailAddress,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(completeLoginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeLoginAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.oidcAuth.accessToken = payload?.access_token;
        const { org_id, sub } = parseAccessToken(payload?.access_token);
        state.oidcAuth.userId = sub;
        state.oidcAuth.orgId = org_id;
        state.oidcAuth.emailAddress = payload.email_address ?? '';
        state.oidcAuth.expiresAt = payload.expires_at ?? 0;
        state.oidcAuth.refreshToken = payload.refresh_token ?? '';
        state.isAuthenticated = true;
      })
      .addCase(completeLoginAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getFederatedToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFederatedToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.accessToken = payload?.accessToken;
        const { org_id, sub } = parseAccessToken(payload?.accessToken);
        state.userId = sub;
        state.orgId = org_id;
        state.expiresAt = Date.now() + payload.expiry * 1000;
        state.isAuthenticated = true;
      })
      .addCase(getFederatedToken.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(completeLogoutAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeLogoutAction.fulfilled, () => {
        return initialState;
      })
      .addCase(completeLogoutAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(loginFromExternalAction.fulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.expiresAt = new Date().getTime() + payload.expiry * 1000;
        state.isAuthenticated = true;
        const { sub, org_id } = parseAccessToken(payload.accessToken) || { sub: '', org_id: '' };
        state.userId = sub;
        state.orgId = org_id;
      })
      .addCase(loginFromExternalAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

interface AccessToken extends JwtPayload {
  sub: string;
  org_id: string;
  preferred_username: string;
}

const parseAccessToken = (token: string): AccessToken => {
  return jwt_decode<AccessToken>(token);
};

export const authReducer = authSlice.reducer;
export const { resetAuthState } = authSlice.actions;

export default authSlice;
