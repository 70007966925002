import { GroovModal } from 'common/components/Modal/GroovModal';
import { GroovModalCard } from 'common/components/Modal/GroovModalCard';
import { GroovModalCloseButton } from 'common/components/Modal/GroovModalCloseButton';
import React from 'react';

import { createStyles, makeStyles, Paper, Typography } from '@material-ui/core';

import bugIcon from '../../assets/icons/bug.svg';
import checkIcon from '../../assets/icons/check.svg';
import warnIcon from '../../assets/icons/warn.svg';
import { CreateReportMessageList } from './UploadReportMessageList';
import { CreateReportMessageText } from './UploadReportMessageText';

interface ReportProps {
  reportText: ReportMessage[];
}

export enum ReportGroup {
  Added,
  Errored,
  InValid,
}

export interface ReportMessage {
  group: ReportGroup;
  message: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    report: {
      marginTop: '2rem',
      overflow: 'auto',
      height: '100%',
    },
  })
);

function CreateReportText(props: ReportProps) {
  const { reportText } = props;
  const added = reportText.filter((group) => group.group == ReportGroup.Added);
  const errored = reportText.filter((group) => group.group == ReportGroup.Errored);
  const inValid = reportText.filter((group) => group.group == ReportGroup.InValid);

  return (
    <Typography component="div">
      <CreateReportMessageText
        reportGroup={added}
        icon={checkIcon}
        display={true}></CreateReportMessageText>
      <CreateReportMessageText
        reportGroup={errored}
        icon={warnIcon}
        display={errored.length !== 0}></CreateReportMessageText>
      <CreateReportMessageList
        reportGroup={inValid}
        icon={bugIcon}
        display={inValid.length !== 0}></CreateReportMessageList>
    </Typography>
  );
}

export const EmailUploadReportModal: React.FC<{
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  reportText: ReportMessage[];
}> = (props) => {
  const { openModal, setOpenModal, reportText } = props;
  const styles = useStyles(props);

  return (
    <GroovModal isModalShowing={openModal}>
      <GroovModalCloseButton onPressed={() => setOpenModal(false)} />
      <GroovModalCard>
        <Typography
          id="transition-modal-title"
          variant="h2"
          component="h2"
          data-testid="hub_modal_bulk_load_page_title">
          Results of bulk email load
        </Typography>
        <Paper elevation={0} className={styles.report}>
          {CreateReportText({ reportText })}
        </Paper>
      </GroovModalCard>
    </GroovModal>
  );
};
