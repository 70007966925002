import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { format } from 'date-fns';

import { colors } from 'styles/colors';
import { Card, ContainerLayout, Link, Pagination, ToasterUtils } from 'common';
import { downloadSubscriptionUsers, getSubscriptionUsers, removeUser } from 'services/api';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { SubscriptionUsersResponseType, UserType } from 'types/subscriptionTypes';
import SearchBar from 'material-ui-search-bar';
import { DownloadIcon } from 'assets/icons/download';
import { HttpStatus } from 'services/types';
import { toasterPrompts } from 'prompts/prompts';
import { emailHashHelper } from 'utils/EmailHashHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      maxWidth: 1200,
    },
    bottomCardWrapper: {
      paddingTop: theme.spacing(2),
    },
    downloadAction: {
      color: colors.royalBlue,
      fontFamily: 'Averta-Semibold',
      fontWeight: 500,
    },
    underline: {
      height: 2,
      marginTop: theme.spacing(2),
      backgroundColor: colors.blue,
      borderRadius: 1,
    },
    listContainer: {
      padding: theme.spacing(8),
      backgroundColor: colors.whisperWhite,
      marginBottom: 8,
    },
    listTitle: {
      fontFamily: 'Averta',
      fontWeight: 'normal',
      color: colors.darkGrey,
      textTransform: 'lowercase',
    },
    listDateWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      border: '1px solid transparent',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    removeButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      border: '1px solid transparent',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
    removeButton: {
      color: colors.redWarn,
      fontFamily: 'Averta-Semibold',
      fontWeight: 600,
      textAlign: 'right',
      cursor: 'pointer',
    },
    listDate: {
      fontFamily: 'Averta',
      fontWeight: 'normal',
      color: colors.slateGrey,
    },
    sortItem: {
      padding: theme.spacing(1),
    },
    listRightWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    inputText: {
      marginTop: 0,
      width: 312,
      paddingBottom: 4,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  })
);
const PAGE_LIMIT = 10;

export const Registration: React.FC = (props) => {
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [subscriptionUsersData, setSubscriptionUsersData] = useState(
    {} as SubscriptionUsersResponseType
  );
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    sortBy: 'email',
    order_by: 'ASC',
    limit: PAGE_LIMIT,
    offset: 0,
    subscription_status: 'INVITED,LINKED,GRACE_PERIOD',
    matchingEmail: '',
  });

  const open = Boolean(anchorEl);

  useEffect(() => {
    fetchSubscriptionUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const fetchSubscriptionUsers = async () => {
    setIsLoading(true);
    const resp = await getSubscriptionUsers(queryParams);
    if (resp.data) {
      setSubscriptionUsersData(resp.data);
    }
    setIsLoading(false);
  };

  const downloadCsv = async () => {
    const resp = await downloadSubscriptionUsers(queryParams);
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `SubscriptionUsers_${new Date().valueOf()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const clearEmailSearch = async () => {
    queryParams.matchingEmail = '';
    queryParams.offset = 0;
    fetchSubscriptionUsers();
  };

  const searchByEmail = async () => {
    queryParams.offset = 0;
    fetchSubscriptionUsers();
  };

  const setSort = (order: string) => {
    setQueryParams({ ...queryParams, order_by: order, offset: 0 });
    setActivePage(1);
    setAnchorEl(null);
  };

  const refreshList = (deletedEmail: string) => {
    setSubscriptionUsersData((prevState) => {
      const newState = { ...prevState };
      const deleteUserIndex = newState.users.findIndex((user) => user.email === deletedEmail);
      if (deleteUserIndex !== -1) {
        // If the user is only in an invited state then they will not be changed to a grace period state
        if (newState.users[deleteUserIndex].status === 'INVITED') {
          newState.users = newState.users.filter((user) => user.email !== deletedEmail);
        } else {
          newState.users[deleteUserIndex].status = 'GRACE_PERIOD';
        }
      }
      return newState;
    });
  };

  const onRemoveUser = (email: string) => {
    ToasterUtils.confirm({
      onConfirm: async () => {
        const { status } = await removeUser(email);
        if (status === HttpStatus.OK) {
          ToasterUtils.success(toasterPrompts.messages.user.removeSuccess);
          refreshList(email);
        }
      },
    });
  };

  const renderUserListItem = (user: UserType) => {
    if (user.status === 'GRACE_PERIOD') {
      return renderRemovedUser(user);
    }
    return renderActiveUser(user);
  };

  const renderListItemTitle = (email: string) => (
    <Grid item xs={12} md={10}>
      <Typography variant="h4" className={classes.listTitle}>
        {email}
      </Typography>
    </Grid>
  );

  const renderListItemDate = (created_on: string) => (
    <Grid item xs={12} md={2} className={classes.listDateWrapper}>
      <Typography variant="h4" className={classes.listDate}>
        {format(new Date(created_on), 'dd/MM/yy')}
      </Typography>
    </Grid>
  );

  const renderRemovedUser = (user: UserType) => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.listContainer}
        key={user.id}
        style={{ opacity: '40%' }}
        data-testid="people_item_removed">
        <Grid item container xs={9} md={9}>
          {renderListItemTitle(user.email)}
          {user.created_on && renderListItemDate(user.created_on)}
        </Grid>
      </Grid>
    );
  };

  const renderActiveUser = (user: UserType) => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.listContainer}
        key={user.id}
        data-testid="people_item_active">
        <Grid item container xs={9} md={9}>
          {renderListItemTitle(user.email)}
          {user.created_on && renderListItemDate(user.created_on)}
        </Grid>
        <Grid item xs={3} md={3} className={classes.removeButtonWrapper}>
          <Typography
            variant="h4"
            className={classes.removeButton}
            onClick={() => onRemoveUser(user.email)}
            data-testid="people_remove_button">
            Remove
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderLoader = () => (
    <Grid
      container
      style={{ height: 200, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
      <AnimatedSpinner height={30} width={30} />
    </Grid>
  );

  const renderUserList = () => {
    let hasFiltered = false;
    const renderedUsers: JSX.Element[] = [];
    for (const user of subscriptionUsersData.users) {
      // for loop instead of array map to avoid checking every item
      if (!hasFiltered && emailHashHelper.isLoggedInUserEmail(user.email)) {
        hasFiltered = true;
        continue;
      }
      renderedUsers.push(renderUserListItem(user));
    }
    return (
      <div>
        <Grid container justify="center" data-testid="people_item_list">
          {renderedUsers}
        </Grid>
      </div>
    );
  };

  const renderPagination = () => {
    if (subscriptionUsersData && subscriptionUsersData.total > PAGE_LIMIT) {
      return (
        <Pagination
          count={Math.ceil(subscriptionUsersData.total / subscriptionUsersData.limit)}
          page={activePage}
          onChange={(p) => {
            let offset = queryParams.offset;
            let _activePage = activePage;
            if (
              p === 'Next' &&
              _activePage < Math.ceil(subscriptionUsersData.total / subscriptionUsersData.limit)
            ) {
              offset = queryParams.offset + 5;
              _activePage = activePage + 1;
            } else if (p === 'Prev' && _activePage > 1) {
              offset = queryParams.offset - 5;
              _activePage = activePage - 1;
            } else if (typeof p === 'number') {
              offset = p * PAGE_LIMIT - PAGE_LIMIT;
              _activePage = p;
            }
            setActivePage(_activePage);
            setQueryParams({ ...queryParams, offset });
          }}
          pageName="people"
        />
      );
    }
  };

  return (
    <ContainerLayout>
      <Grid container>
        <Grid item xs={12}>
          <Card style={{ marginTop: 8 }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              className={classes.bottomCardWrapper}
              spacing={2}>
              <Grid item className={classes.title}>
                <Typography variant="h2" data-testid="people_page_title">
                  People list
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              component="p"
              style={{ marginTop: 19, marginBottom: 36 }}
              data-testid="people_page_description">
              View your current staff list / associated email address inventory.
            </Typography>

            <Grid container direction="row" style={{ marginBottom: 28 }}>
              <Typography variant="h4" style={{ marginRight: 8, fontWeight: 'bold' }}>
                Sort
              </Typography>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <List>
                  <ListItem key="asc">
                    <Link style={{ padding: 0 }} onClick={() => setSort('ASC')}>
                      <Typography variant="h4" className={classes.sortItem}>
                        Ascending
                      </Typography>
                    </Link>
                  </ListItem>
                  <ListItem key="desc">
                    <Link style={{ padding: 0 }} onClick={() => setSort('DESC')}>
                      <Typography variant="h4" className={classes.sortItem}>
                        Descending
                      </Typography>
                    </Link>
                  </ListItem>
                </List>
              </Popover>
              <Link
                onClick={(event) => setAnchorEl(event.currentTarget)}
                style={{ padding: 0, justifyContent: 'flex-start', marginTop: -1 }}
                data-testid="people_sort_dropdown">
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                  {'A - Z'}
                  <div className={classes.underline} />
                </Typography>
                {queryParams.order_by === 'ASC' ? (
                  <ExpandMoreIcon htmlColor={colors.darkGrey} />
                ) : (
                  <ExpandLessIcon htmlColor={colors.darkGrey} />
                )}
              </Link>
              <SearchBar
                className="inputText"
                value={queryParams.matchingEmail}
                onChange={(newValue) => (queryParams.matchingEmail = newValue)}
                onRequestSearch={() => searchByEmail()}
                onCancelSearch={() => clearEmailSearch()}
                data-testid="people_search_bar"
              />
              <Link
                style={{ padding: 0 }}
                onClick={() => downloadCsv()}
                data-testid="people_download_button">
                <div>
                  <DownloadIcon />
                </div>
              </Link>
            </Grid>

            {isLoading
              ? renderLoader()
              : Object.keys(subscriptionUsersData).length !== 0
                ? renderUserList()
                : null}
            {renderPagination()}
          </Card>
        </Grid>
      </Grid>
    </ContainerLayout>
  );
};
