import { styled } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';
import MaterialIconButton from '@material-ui/core/IconButton';
import { colors } from 'styles/colors';

export const Button = styled(MaterialButton)(({ theme }) => ({
  color: 'white',
  padding: theme.spacing(4, 12),
  borderRadius: theme.spacing(3),
  background: theme.palette.primary.main,
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

export const OutlinedButton = styled(MaterialButton)(({ theme }) => ({
  color: colors.white,
  padding: theme.spacing(3, 9),
  borderWidth: theme.spacing(0.5),
  borderColor: colors.white,
  borderStyle: 'solid',
  borderRadius: theme.spacing(3),
  background: colors.darkGrey,
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    background: colors.darkGrey,
  },
}));

export const LightOutlinedButton = styled(MaterialButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(2.5, 7),
  borderWidth: theme.spacing(0.5),
  borderColor: theme.palette.primary.main,
  borderStyle: 'solid',
  borderRadius: theme.spacing(3),
  background: colors.white,
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    background: colors.white,
  },
}));

export const DefaultButton = styled(MaterialButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(2.5, 7),
  borderWidth: theme.spacing(0.5),
  borderColor: theme.palette.grey[400],
  borderStyle: 'solid',
  borderRadius: theme.spacing(3),
  backgroundColor: colors.lightGrey,
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    background: colors.white,
  },
}));

export const LightButton = styled(MaterialButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(2.5, 7),
  borderWidth: theme.spacing(0.5),
  borderColor: colors.white,
  borderStyle: 'solid',
  borderRadius: theme.spacing(3),
  backgroundColor: colors.white,
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    background: colors.lightGrey,
  },
}));

export const IconButton = styled(MaterialIconButton)(() => ({
  width: 32,
  height: 32,
}));
