import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const addRecipientsStyles = makeStyles((theme: Theme) =>
  createStyles({
    addRecipients: {
      textAlign: 'center',
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(3),
      width: '43.5rem',
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    downloadSampleCSV: {
      color: colors.blue,
      fontFamily: 'Averta-Semibold',
      fontWeight: 600,
    },
    downloadSampleCSVLink: {
      display: 'inline-block',
    },
    bold: {
      fontWeight: 600,
    },
    message: {
      lineHeight: 1.5,
    },
  })
);

export default addRecipientsStyles;
