import React from 'react';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const FileIcon = (props: Props) => {
  const { width = 16, height = 20, color = '#444752' } = props;
  return <DescriptionIcon style={{ width: width, height: height, color: color }} />;
};
