import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export function AnalyticsInfo({
  label,
  value,
  subValue = '',
  color,
}: {
  label: string;
  value: string;
  subValue?: string;
  color?: string;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.infoBox} style={{ borderColor: color, backgroundColor: color }}>
      <Typography variant="subtitle1" color="textSecondary" className={classes.typeText}>
        {label}
      </Typography>
      <Typography className={classes.percentageBox}>
        {value}
        <span className={classes.percentageSpan}>{subValue}</span>
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  infoBox: {
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[200],
    height: 112,
    width: 137,
    borderRadius: 6,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  percentageBox: {
    fontSize: 40,
    fontWeight: 'lighter',
    textAlign: 'center',
    lineHeight: '42px',
    marginTop: theme.spacing(2),
  },
  percentageSpan: {
    fontSize: 20,
    fontWeight: 200,
    marginLeft: theme.spacing(1),
  },
  typeText: {
    fontWeight: 'lighter',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: 'black',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    fontSize: 14,
  },
}));
