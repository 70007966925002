import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { FilterType } from 'powerbi-models';

import { EmbeddedReportProps, ReportSectionProps, PageId, PowerBIEvents } from '../../types';
import powerBIReportStyles from './styles';

export const PowerBIReportsEmbed = (
  props: EmbeddedReportProps & ReportSectionProps & { activePageId: PageId }
) => {
  const {
    embedToken,
    userOrg,
    reportSection,
    filterSurveyIds,
    activePageId,
    filterMonths,
    filterModuleIds,
    selectedRitualId,
  } = props;
  const classes = powerBIReportStyles(props);
  const [reportsLoaded, setReportsLoaded] = useState(false);
  const filterProps = [
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: { table: '_orgid', column: 'orgid' },
      filterType: FilterType.Basic,
      operator: 'In',
      values: [userOrg.toString()],
    },
  ];
  if (filterSurveyIds && filterSurveyIds.length) {
    const newFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: { table: 'vw_transform_response', column: 'collector_id' },
      filterType: FilterType.Basic,
      operator: 'In',
      values: filterSurveyIds,
    };
    if (activePageId === PageId.Individual) {
      newFilter.target.table = 'sixpillar_favourscore_updated';
    }
    filterProps.push(newFilter);
  }

  if (filterModuleIds && filterModuleIds.length) {
    const newFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: { table: 'module_progress', column: 'module_id' },
      filterType: FilterType.Basic,
      operator: 'In',
      values: filterModuleIds,
    };
    filterProps.push(newFilter);
  }

  if (filterMonths && filterMonths.length) {
    const newFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: { table: 'DateTable', column: 'mmm yyyy' },
      filterType: FilterType.Basic,
      operator: 'In',
      values: filterMonths,
    };
    filterProps.push(newFilter);
  }

  if (selectedRitualId) {
    const newFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: { table: 'Ritual Team Overview', column: 'ritual_id' },
      filterType: FilterType.Basic,
      operator: 'In',
      values: [selectedRitualId],
    };
    filterProps.push(newFilter);
  }

  const paneProps = {
    filters: { expanded: false, visible: false },
    pageNavigation: { visible: false },
  };

  const visualSettings = {
    visualHeaders: [
      {
        settings: { visible: false },
      },
    ],
  };

  return (
    reportSection && (
      <Grid item xs={12} className={classes.root} data-testid={'hub_powerbi_' + activePageId}>
        <PowerBIEmbed
          cssClassName={reportSection?.className}
          embedConfig={{
            type: 'report',
            id: embedToken.embedUrl[0].reportId,
            embedUrl: embedToken.embedUrl[0].embedUrl,
            accessToken: embedToken.accessToken,
            tokenType: models.TokenType.Embed,
            pageName: reportSection?.pageName,
            filters: reportsLoaded ? filterProps : [],
            settings: {
              panes: paneProps,
              background: models.BackgroundType.Transparent,
              visualSettings,
              visualRenderedEvents: true,
            },
          }}
          eventHandlers={new Map([[PowerBIEvents.VISUAL_RENDERED, () => setReportsLoaded(true)]])}
        />
      </Grid>
    )
  );
};
