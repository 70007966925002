import { Grid, Menu, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useRouteMatch, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { NavLink } from 'common/components/NavLink';
import Analytics from 'utils/AnalyticsHelper';
import React, { useEffect } from 'react';
import { ContainerLayout, Card } from '../common';
import { OrgOverviewRoute } from 'types/routes';
import { Insights } from '../common/components/Insights';
import { useUserOrg } from 'utils/UseUserOrg';
import { reportMap, PowerBIReportDetails, standardReports } from 'utils/PowerBiReports';
import { OrgOverviewPrompts } from 'prompts/prompts';
import { StandardPowerBIReportRouteName } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerMenu: {
      marginTop: 24,
      marginBottom: 12,
      display: 'flex',
      flex: 3,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    myMenu: {
      paddingLeft: 10,
    },
    menuButton: {
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 0,
      marginTop: 5,
      marginBottom: 4,
      paddingLeft: 0,
      transition: 'none',
    },
    activeMenuButton: {
      color: theme.palette.primary.main,
    },
    underline: {
      height: 2,
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: 1,
      display: 'none',
    },
    activeUnderline: {
      display: 'block',
    },
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
  })
);

const ReportNavLink = (props: { url: string; reportDef: PowerBIReportDetails; onClick?: any }) => {
  return (
    <NavLink
      to={`${props.url}/${props.reportDef.nav_route}`}
      onClick={props.onClick}
      data-testid={'insights_' + props.reportDef.report_key + '_tab'}>
      {props.reportDef.nav_header}
    </NavLink>
  );
};

const REPORT_TITLE = 'BI Reports';
const INSIGHT_ANALYTICS_TAB_EVENT = 'InsightPageOpened';
const INSIGHT_ANALYTICS_TAB_SEGMENT = 'Page';

export const OrgOverview: React.FC = (props) => {
  const classes = useStyles(props);
  const { path, url } = useRouteMatch();

  const isAdmin = useUserOrg() === process.env.REACT_APP_ADMIN_ORG;
  const reportsToDisplay = isAdmin
    ? [...reportMap.reports, ...standardReports.reports]
    : standardReports.reports;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [buttonText, setButtonText] = React.useState(REPORT_TITLE);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (reportName: string) => {
    setButtonText(`${REPORT_TITLE} - ${reportName}`);
    setAnchorEl(null);
  };

  const handleReset = () => {
    setButtonText(`${REPORT_TITLE}`);
    setAnchorEl(null);
  };

  const handleClickedAway = () => {
    setAnchorEl(null);
  };

  const getDefaultReportRoute = () =>
    standardReports.reports.find((r) => r.report_key === StandardPowerBIReportRouteName.KeyInsights)
      ?.nav_route;

  const getRedirectPath = () => `${path}/${getDefaultReportRoute()}`;

  const standardNavLinks = Object.values(standardReports.reports).map((r) => (
    <ReportNavLink url={url} reportDef={r} onClick={handleReset} />
  ));
  const adminNavLinks = Object.values(reportMap.reports).map((r) => (
    <ReportNavLink
      url={url}
      reportDef={r}
      onClick={() => {
        handleClose(r.nav_header);
      }}
    />
  ));

  const adminLinkClasses = [classes.menuButton];
  const adminUnderlineClasses = [classes.underline];
  if (
    isAdmin &&
    pathname.indexOf(OrgOverviewRoute.highlights) === -1 &&
    pathname.indexOf(StandardPowerBIReportRouteName.KeyInsights) === -1 &&
    pathname.indexOf(StandardPowerBIReportRouteName.ToolsContent) === -1
  ) {
    adminLinkClasses.push(classes.activeMenuButton);
    adminUnderlineClasses.push(classes.activeUnderline);
  }

  useEffect(() => {
    for (const link of Object.values(standardReports.reports)) {
      if (pathname.includes(link.nav_route)) {
        Analytics.trackEvent(
          INSIGHT_ANALYTICS_TAB_EVENT,
          INSIGHT_ANALYTICS_TAB_SEGMENT,
          link.nav_header
        );
      }
    }
  }, [pathname]);

  return (
    <ContainerLayout>
      <Card>
        <Grid>
          <Typography
            variant="h2"
            component="h2"
            className={classes.title}
            data-testid="insights_page_title">
            {OrgOverviewPrompts.title}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.headerMenu}>
          {standardNavLinks}
          {isAdmin && (
            <Grid>
              <Button
                id="admin-reports-button"
                className={adminLinkClasses.join(' ')}
                onClick={handleClick}
                data-testid={'insights_bi_reports_tab'}>
                {buttonText}
              </Button>
              <div className={adminUnderlineClasses.join(' ')} />
            </Grid>
          )}

          <Menu id="admin-reports-menu" anchorEl={anchorEl} open={open} onClick={handleClickedAway}>
            <div id="admin-reports-menu-anchor" className={classes.myMenu}>
              {adminNavLinks}
            </div>
          </Menu>
        </Grid>

        <Switch>
          <Route path={`${path}/:reportId`}>
            <Insights reports={reportsToDisplay} />
          </Route>
          <Route path="/">
            <Redirect to={getRedirectPath()} />
          </Route>
        </Switch>
      </Card>
    </ContainerLayout>
  );
};
