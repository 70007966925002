import { Box, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { wrapperStyles } from './styles';

interface Props {
  title: string;
  description: string;
  id: string;
  icon?: () => React.JSX.Element;
}

interface TitleProps {
  title: string;
  id: string;
}

interface TitleWithIconProps extends TitleProps {
  icon?: () => React.JSX.Element;
}

const renderTitleComponent = ({ title, id }: TitleProps) => {
  return (
    <Typography variant="h3" data-testid={'settings_' + id + '_title'} style={{ marginTop: 2 }}>
      {title}
    </Typography>
  );
};

const renderTitle = ({ title, id, icon }: TitleWithIconProps) => {
  if (icon) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon()}
        {renderTitleComponent({ title, id })}
      </div>
    );
  }

  return renderTitleComponent({ title, id });
};

export const Setting: React.FC<Props> = (props) => {
  const classes = wrapperStyles(props);

  const renderSetting = () => {
    return (
      <>
        {renderTitle({ title: props.title, id: props.id, icon: props.icon })}
        <Typography
          variant="subtitle1"
          component="p"
          style={{ marginTop: 22 }}
          data-testid={'settings_' + props.id + '_description'}>
          {props.description}
        </Typography>
        <div className={classes.childContainer}>{props.children}</div>
      </>
    );
  };

  return (
    <Box mt={6} mb={8}>
      <Hidden smDown>
        <div className={classes.cardWrapper}>{renderSetting()}</div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileCardWrapper}>{renderSetting()}</div>
      </Hidden>
    </Box>
  );
};
