import { Button, Card, ContainerLayout } from 'common';
import React, { useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { promptBuilderList } from 'prompts/prompts';
import { PromptTable } from './PromptTable';
import history from 'utils/history';
import { RoutePath } from 'types/routes';
import { getAllPrompts } from './api';

export function PromptBuilderList() {
  const classes = useStyles();

  const handleCreatePrompt = () => {
    history.push(RoutePath.CreatePrompt);
  };

  useEffect(() => {
    try {
      getAllPrompts();
    } catch (error) {
      console.error('Failed to fetch prompt', error);
    }
  }, []);

  return (
    <ContainerLayout>
      <Card className={classes.card}>
        <Box className={classes.root}>
          <Typography variant="h2" className={classes.header}>
            {promptBuilderList.title}
          </Typography>
          <Button type="submit" size="small" onClick={handleCreatePrompt}>
            {promptBuilderList.createPrompt}
          </Button>
        </Box>
        <PromptTable />
      </Card>
    </ContainerLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
    height: '10vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    paddingBottom: theme.spacing(4),
  },
  filters: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  card: {
    flex: 1,
  },
}));
