import { AxiosRequestConfig } from 'axios';
import { BasicRequest } from '../../../../services/apiRequest';

const endpoint = 'gracePeriod';

export const addGracePeriod = async (gracePeriod: number): Promise<boolean> => {
  const config: AxiosRequestConfig = {
    url: endpoint,
    method: 'PATCH',
    data: { grace_period_weeks: gracePeriod },
  };

  const res = await BasicRequest(config);
  return res.status === 200;
};
