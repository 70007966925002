import React from 'react';
import { TableCell } from 'common/components/Table/TableCell';
import { VerticalEllipsis } from 'assets/icons/ellipsis-icon';
import { IconButton } from 'common';
import { EapEditMenu } from '../EapEditMenu';

export const EapTableMenuCell = (props: {
  eapId: string;
  onEllipsisEditClick: (eapId: string) => void;
  onEllipsisDeleteClick: (eapId: string) => void;
}) => {
  const { eapId, onEllipsisEditClick, onEllipsisDeleteClick } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    onEllipsisEditClick(eapId);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    onEllipsisDeleteClick(eapId);
  };

  return (
    <TableCell>
      <IconButton onClick={handleClick}>
        <VerticalEllipsis />
        <EapEditMenu
          isOpen={open}
          eapId={props.eapId}
          anchor={anchorEl}
          handleClose={handleClose}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      </IconButton>
    </TableCell>
  );
};
