import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { getTypographyVariantFromFontWeight } from 'utils/typographyUtils';
import glanceSectionStyles from '../styles';

interface GlanceCardSubsectionTitleProps {
  title: string;
}

export const GlanceCardSubsectionTitle: React.FC<GlanceCardSubsectionTitleProps> = (props) => {
  const { title } = props;
  const classes = glanceSectionStyles();
  return (
    <Box className={classes.horizontalFlexBox} minWidth={'6.25rem'}>
      <Typography
        variant={getTypographyVariantFromFontWeight(400)}
        className={classes.atAGlanceSubsectionTitle}
        data-testid={'rituals_glance_card_body_title_label'}>
        {title}
      </Typography>
    </Box>
  );
};
