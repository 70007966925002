import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, createStyles, Box } from '@material-ui/core';
// import {CompanyAccountsSummary} from 'common';
import { RoutePath } from 'types/routes';
import { colors } from 'styles/colors';
import theme from './theme';
import { RouteType } from 'types/routes';
import clsx from 'clsx';
import { useAppSelector } from 'store';
import { Header } from 'common';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hubRoot: {
      background: colors.darkGrey,
    },
  })
);

interface Props {
  children: React.ReactChild;
}
export default (props: Props) => {
  const classes = useStyles(props);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const routeType = useAppSelector((state) => state.appState?.routeType);
  const location = useLocation();
  const isHubBetaPageActive = location.pathname.indexOf(RoutePath.Hub) !== -1;

  const className = clsx(classes.root, isHubBetaPageActive && classes.hubRoot);

  const renderHeader = () => {
    if (isAuthenticated && routeType === RouteType.Private) {
      return (
        <Container maxWidth={false}>
          <Box displayPrint="none">
            <Header />
            {/* {!isHubBetaPageActive && <CompanyAccountsSummary />} */}
          </Box>
        </Container>
      );
    }

    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {renderHeader()}
      <Container maxWidth={false} className={className} disableGutters>
        {props.children}
      </Container>
    </ThemeProvider>
  );
};
