import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Button, IconButton } from 'common';
import { Drawer } from 'common/components/Drawer';
import { eapDrawerStyles } from '../styles';
import { CloseIcon } from 'assets/icons/close-icon';
import { EapDetails, EapDetailsWithId } from '../types';
import {
  DetailsInput,
  NameInput,
  PhoneInput,
  UrlInput,
  detailsErrorText,
  nameErrorText,
  phoneErrorText,
  urlErrorText,
} from './EapDrawerComponents';

interface EapDrawerProps {
  isOpen: boolean;
  buttonText: string;
  defaultValues?: EapDetailsWithId;
  onButtonClick: (eapDetails: EapDetails, defaultState?: EapDetailsWithId) => void;
  handleClose?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const EapDrawer = (props: EapDrawerProps) => {
  const { defaultValues, isOpen, handleClose, onButtonClick, buttonText } = props;
  const { headingGroup, heading, description, addEapButton } = eapDrawerStyles();

  const [name, setName] = React.useState<string>('');
  const [url, setUrl] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [details, setDetails] = React.useState<string>('');

  const [nameError, setNameError] = React.useState<string | undefined>();
  const [urlError, setUrlError] = React.useState<string | undefined>();
  const [phoneError, setPhoneError] = React.useState<string | undefined>();
  const [detailsError, setDetailsError] = React.useState<string | undefined>();

  React.useEffect(() => {
    setName(defaultValues?.name ?? '');
    setUrl(defaultValues?.url ?? '');
    setPhone(defaultValues?.phone ?? '');
    setDetails(defaultValues?.details ?? '');
  }, [defaultValues]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    const error = nameErrorText(event.target.value);
    setNameError(error);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    const error = urlErrorText(event.target.value);
    setUrlError(error);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    const error = phoneErrorText(event.target.value);
    setPhoneError(error);
  };

  const handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDetails(event.target.value);
    const error = detailsErrorText(event.target.value);
    setDetailsError(error);
  };

  const handleNameBlur = () => {
    const error = nameErrorText(name);
    setNameError(error);
  };

  const handleUrlBlur = () => {
    const error = urlErrorText(url);
    setUrlError(error);
  };

  const handlePhoneBlur = () => {
    const error = phoneErrorText(phone);
    setPhoneError(error);
  };

  const handleDetailsBlur = () => {
    const error = detailsErrorText(details);
    setDetailsError(error);
  };

  const onDrawerClose = (event: React.MouseEvent<HTMLElement>) => {
    setNameError(undefined);
    setUrlError(undefined);
    setPhoneError(undefined);
    setDetailsError(undefined);

    handleClose?.(event);
  };

  const onDrawerButtonClick = () => {
    onButtonClick({ name, url, phone, details }, defaultValues);
  };

  const isButtonDisabled =
    !!nameErrorText(name) ||
    !!urlErrorText(url) ||
    !!phoneErrorText(phone) ||
    !!detailsErrorText(details);

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={onDrawerClose}>
      <Box maxWidth={500} padding={'32px'}>
        <Box className={headingGroup}>
          <Typography className={heading}>EAP Contact Information</Typography>
          <IconButton onClick={onDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography className={description}>
          Contact information once added, employees will receive this through the Groov Anywhere
          experience.
        </Typography>
        <NameInput
          value={name}
          onChange={handleNameChange}
          errorText={nameError}
          onBlur={handleNameBlur}
        />
        <UrlInput
          value={url}
          onChange={handleUrlChange}
          errorText={urlError}
          onBlur={handleUrlBlur}
        />
        <PhoneInput
          value={phone}
          onChange={handlePhoneChange}
          errorText={phoneError}
          onBlur={handlePhoneBlur}
        />
        <DetailsInput
          value={details}
          onChange={handleDetailsChange}
          errorText={detailsError}
          onBlur={handleDetailsBlur}
        />
        <Button className={addEapButton} onClick={onDrawerButtonClick} disabled={isButtonDisabled}>
          {buttonText}
        </Button>
      </Box>
    </Drawer>
  );
};
