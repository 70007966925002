import React, { useEffect, useState } from 'react';
import './DragAndDrop.css';
import { CloseIcon } from 'assets/icons/close-icon';
import { IconButton } from '../Button';
import { CheckCircleIcon } from 'assets/icons/CheckCircleIcon';
import { FileIcon } from 'assets/icons/file-icon';

interface Props {
  onFilesSelected: (files: File[]) => void;
  minWidth?: number;
  minHeight?: number;
  handleValidateFile?: (file: File) => boolean | Promise<boolean>;
}

export const DragAndDrop = ({
  onFilesSelected,
  minWidth,
  minHeight,
  handleValidateFile,
}: Props) => {
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);

  const onButtonClick = () => {
    inputFileRef?.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);

      const filesAreValid = await Promise.all(
        newFiles.map((file) => {
          return handleValidateFile?.(file);
        })
      );

      if (filesAreValid.every((isValid) => isValid)) {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }
    }
  };
  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer?.files ?? [];
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const allCsv = newFiles.every((file) => {
        if (file && file.type === 'text/csv') {
          return true;
        }
        return false;
      });

      if (allCsv) {
        const filesAreValid = await Promise.all(
          newFiles.map((file) => {
            return handleValidateFile?.(file);
          })
        );
        if (filesAreValid.every((isValid) => isValid)) {
          setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
        return;
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  return (
    <section
      className="drag-drop"
      style={{ minWidth: minWidth, minHeight: minHeight, marginTop: '16px', height: '100%' }}>
      <div
        className={`document-uploader ${files.length > 0 ? 'upload-box active' : 'upload-box'}`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
        style={{ height: '100%' }}
        onClick={onButtonClick}>
        <>
          <div className="upload-info">
            <div>
              <p>Drag and drop your .csv files here</p>
            </div>
          </div>
          <IconButton style={{ width: 48, height: 48 }}>
            <FileIcon width={32} height={40} color={'#565E74'} />
          </IconButton>
          <input
            ref={inputFileRef}
            type="file"
            hidden
            id="browse"
            onChange={handleFileChange}
            accept=".csv"
            multiple
          />
          <label className="browse-btn">Browse files</label>
        </>

        {files.length > 0 && (
          <div className="file-list">
            <div className="file-list__container">
              {files.map((file, index) => (
                <div className="file-item" key={index}>
                  <div className="file-info">
                    <p>{file.name}</p>
                  </div>
                  <div className="file-actions">
                    <IconButton
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleRemoveFile(index);
                      }}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {files.length > 0 && (
          <div className="success-file">
            <CheckCircleIcon />
            <p>{files.length} file(s) selected</p>
          </div>
        )}
      </div>
    </section>
  );
};
