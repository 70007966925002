import { createSlice } from '@reduxjs/toolkit';
import { PromptSummary, PromptBuilderFormTypes } from './PromptBuilder.types';

export interface promptBuilderState {
  allPrompts: PromptSummary[];
  currentLocalPrompt?: PromptBuilderFormTypes | null;
  loading: boolean;
}

const initialState: promptBuilderState = {
  allPrompts: [],
  currentLocalPrompt: null,
  loading: false,
};

const slice = createSlice({
  name: 'promptBuilder',
  initialState,
  reducers: {
    setAllPrompts: (state, action: { payload: PromptSummary[] }) => {
      state.allPrompts = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: { payload: boolean }) => {
      state.loading = action.payload;
    },
    setCurrentLocalPrompt: (state, action: { payload: PromptBuilderFormTypes | null }) => {
      state.currentLocalPrompt = action.payload;
    },
    clearLocalPrompt: (state) => {
      state.currentLocalPrompt = null;
    },
  },
});

export const { reducer: promptBuilderReducer } = slice;
export const { setCurrentLocalPrompt, clearLocalPrompt, setAllPrompts, setLoading } = slice.actions;

export default slice;
