import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Input } from 'common';
import { AddCircleOutline, DeleteOutline, EditOutlined } from '@material-ui/icons';
import { ContextualNudgePromptFormTypes, ScheduledRuleTypes } from '../ContextualNudge.types';
import { FormikProps } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import {
  getFilteredOptions,
  getSelectedPromptRule,
  promptRuleOptions,
} from '../ContextualNudge.utils';

export type ManageScheduleRulesProps = {
  scheduleRules: ScheduledRuleTypes[];
  setScheduleRules: React.Dispatch<React.SetStateAction<ScheduledRuleTypes[]>>;
  formikProps: FormikProps<ContextualNudgePromptFormTypes>;
};
export function ManageScheduleRules({
  scheduleRules,
  formikProps,
  setScheduleRules,
}: Readonly<ManageScheduleRulesProps>) {
  const classes = useStyles();
  const { values, setFieldValue, handleChange, handleBlur } = formikProps;

  const handleAddPromptRule = () => {
    setScheduleRules((value) => [
      ...value,
      {
        type: values.ruleType,
        condition: values.ruleCondition,
        value: values.ruleValue,
        id: `condition-${scheduleRules.length + 1}`,
      },
    ]);
    setFieldValue('ruleType', '');
    setFieldValue('ruleCondition', '');
    setFieldValue('ruleValue', '');
  };
  const handleRemovePromptRule = (index: number) => {
    const newPromptRules = [...scheduleRules.slice(0, index), ...scheduleRules.slice(index + 1)];
    setScheduleRules(newPromptRules);
  };

  const handleEditPromptRule = (index: number) => {
    const rule = scheduleRules[index];
    const newPromptRules = [...scheduleRules.slice(0, index), ...scheduleRules.slice(index + 1)];
    setScheduleRules(newPromptRules);
    setFieldValue('ruleType', rule.type);
    setFieldValue('ruleCondition', rule.condition);
    setFieldValue('ruleValue', rule.value);
  };
  const selectedPromptRules = scheduleRules.map((pt, index) => (
    <Box key={`${pt.type}-${pt.condition}-${pt.value}`} marginTop={2}>
      <Typography>{pt.id}</Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <Input name="ruleType" label="Criteria" fullWidth value={pt.type} disabled />
        </Grid>
        <Grid item xs={3}>
          <Input name="ruleCondition" label="Condition" fullWidth value={pt.condition} disabled />
        </Grid>
        <Grid item xs={4}>
          <Input name="ruleValue" label="Value" fullWidth value={pt.value} disabled />
        </Grid>
        <Grid item xs={1} className={classes.deleteButton}>
          <EditOutlined color="primary" onClick={() => handleEditPromptRule(index)} />
          <DeleteOutline color="error" onClick={() => handleRemovePromptRule(index)} />
        </Grid>
      </Grid>
    </Box>
  ));
  return (
    <div>
      {selectedPromptRules}
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <Autocomplete
            multiple={false}
            options={promptRuleOptions.map((o) => o.label)}
            onSelect={handleChange}
            value={values.ruleType}
            filterSelectedOptions
            filterOptions={(op) => getFilteredOptions(op, values.ruleType)}
            renderInput={(params) => <Input {...params} label="Criteria" name="ruleType" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            multiple={false}
            options={getSelectedPromptRule(values.ruleType)?.conditions?.options ?? []}
            onSelect={handleChange}
            value={values.ruleCondition}
            filterOptions={(op) => getFilteredOptions(op, values.ruleCondition)}
            disabled={getSelectedPromptRule(values.ruleType)?.conditions?.disabled}
            renderInput={(params) => <Input {...params} label="Condition" name="ruleCondition" />}
          />
        </Grid>

        <Grid item xs={4}>
          {getSelectedPromptRule(values.ruleType)?.values?.isInput ? (
            <Input
              name="ruleValue"
              type={getSelectedPromptRule(values.ruleType)?.values?.type}
              disabled={getSelectedPromptRule(values.ruleType)?.values?.disabled}
              fullWidth
              value={values.ruleValue}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ) : (
            <Autocomplete
              multiple={false}
              options={getSelectedPromptRule(values.ruleType)?.values?.options ?? []}
              onSelect={handleChange}
              onBlur={handleBlur}
              value={values.ruleValue}
              filterOptions={(op) => getFilteredOptions(op, values.ruleValue)}
              disabled={getSelectedPromptRule(values.ruleType)?.values?.disabled}
              renderInput={(params) => (
                <Input {...params} label="Value" name="ruleValue" fullWidth />
              )}
            />
          )}
        </Grid>
        <Grid item xs={1} className={classes.addButton}>
          <AddCircleOutline onClick={handleAddPromptRule} color="primary" />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      alignItem: 'center',
    },
    addButton: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      alignItem: 'center',
    },
  })
);
