import React, { useState, useEffect } from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Markdown from 'markdown-to-jsx';
import { HubBetaPrompts, HubSurveyPrompts } from 'prompts/prompts';
import infoSectionStyles from './styles';
import { RecommendationData } from '../../types';
import { DefaultButton } from '../../../../common/components/Button';
import { getFormattedDate } from '../../utils';
import { LowResponseAlert } from './LowResponseAlert';

export const InfoSection: React.FC<{
  recommendations: RecommendationData[];
  dataTestId: string;
  openBaselineModal?: () => void;
  showBaselineText?: boolean;
  orgName: string;
}> = (props) => {
  const classes = infoSectionStyles(props);
  const { recommendations, dataTestId, showBaselineText = false } = props;
  const [filteredRecommendations, setFilteredRecommendations] = useState<RecommendationData[]>([]);
  const [visibleRecommendations, setVisibleRecommendations] = useState<RecommendationData[]>([]);
  useEffect(() => {
    const filtered = recommendations.filter((obj) => obj.timePeriod && obj.text);
    setFilteredRecommendations(filtered);
    filtered.length && setVisibleRecommendations([{ ...filtered[0], expanded: true }]);
  }, [recommendations]);
  const toggleRecommendationExpention = (i: number) => {
    setVisibleRecommendations([
      ...visibleRecommendations.map((recommendation, index) => {
        if (index === i) {
          recommendation.expanded = !recommendation.expanded;
        }
        return recommendation;
      }),
    ]);
  };
  const toggleVisibleRecommendations = () => {
    let updatedVisibleRecommendations = [...filteredRecommendations];
    if (visibleRecommendations.length > 1) {
      updatedVisibleRecommendations = [filteredRecommendations[0]];
    }
    updatedVisibleRecommendations[0].expanded = visibleRecommendations[0].expanded;
    setVisibleRecommendations(updatedVisibleRecommendations);
  };
  return (
    <Box className={classes.root}>
      {visibleRecommendations.map(
        (recommendation, index) =>
          recommendation.timePeriod &&
          recommendation.text && (
            <Accordion
              expanded={recommendation.expanded}
              className={classes.card}
              data-testid={dataTestId}
              key={index}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    className={classes.expandIcon}
                    data-testid="hub_recommendation_expand_collapse_icon"
                  />
                }
                onClick={() => toggleRecommendationExpention(index)}>
                <Typography variant="h3" component="h3" className={classes.infoTitle}>
                  {HubSurveyPrompts.label.recommendationTimePeriod.replace(
                    '{date}',
                    getFormattedDate(recommendation.timePeriod, HubBetaPrompts.filterDateFormat)
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div id="recommendation-accordian">
                  {!recommendation.thresholdMet &&
                  recommendation.responsesRequiredToMeetRate > 0 ? (
                    <LowResponseAlert
                      recipientCount={recommendation.recipientCount}
                      responseCount={recommendation.responseCount}
                      requiredRate={recommendation.requiredRate}
                      responsesRequiredToMeetRate={recommendation.responsesRequiredToMeetRate}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="recommendations">
                    <Markdown
                      options={{
                        overrides: {
                          a: {
                            props: {
                              target: '_blank',
                            },
                          },
                          callbackLink: {
                            component: InfoParagraphLink,
                            props: {
                              onPress: () => {
                                if (props.openBaselineModal) {
                                  props.openBaselineModal();
                                }
                              },
                            },
                          },
                        },
                      }}>
                      {createCommentaryMarkdown(recommendation, showBaselineText)}
                    </Markdown>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )
      )}
      {filteredRecommendations.length - 1 > 0 && (
        <Box className={classes.expandCollapseButton}>
          <DefaultButton
            color="primary"
            onClick={toggleVisibleRecommendations}
            data-testid="hub_recommendation_show_more_less_button">
            {visibleRecommendations.length > 1
              ? HubSurveyPrompts.buttonText.recommendationShowLess
              : filteredRecommendations.length - 1 > 1
                ? HubSurveyPrompts.buttonText.recommendationShowMore.replace(
                    '{count}',
                    (filteredRecommendations.length - 1).toString()
                  )
                : HubSurveyPrompts.buttonText.recommendationShowMore1.replace('{count}', '1')}
          </DefaultButton>
        </Box>
      )}
    </Box>
  );
};

const InfoParagraphLink: React.FC<{ children: string; onPress: () => void }> = (props) => {
  return (
    <div onClick={props.onPress}>
      <u>{props.children}</u>
    </div>
  );
};

const createCommentaryMarkdown = (
  recommendationData: RecommendationData,
  showBaselineText: boolean
) => {
  if (recommendationData.baselineReport && showBaselineText) {
    return recommendationData.text + '\n\n\n<callbackLink>Baseline Report</callbackLink>';
  }

  return recommendationData.text;
};
