import { AxiosRequestConfig } from 'axios';
import { BasicRequest } from '../../../../services/apiRequest';

const BASE_URL = `${process.env.REACT_APP_SERVICE_URL}`;
const platform = 'msteams';
export const addTenantId = async (orgId: string, tenantId: string) => {
  const authId = await getIntegrationAuthorization(orgId);
  const config: AxiosRequestConfig = {
    url: 'workplace-integration/Customers',
    method: 'POST',
    data: {
      integrationId: tenantId,
      authorizationId: authId,
      orgId: orgId,
      platform: platform,
    },
    baseURL: BASE_URL,
  };

  const res = await BasicRequest(config);
  return res.status === 200;
};

const getIntegrationAuthorization = async (orgId: string): Promise<string> => {
  const config: AxiosRequestConfig = {
    url: 'workplace-integration/integrationauthorizations',
    method: 'POST',
    data: {
      orgId: orgId,
      platform: platform,
    },
    baseURL: BASE_URL,
  };

  const res = await BasicRequest(config);
  return res.data.authorizationId;
};
