import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const surveyProgressStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressBar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    progressCount: {
      color: colors.black,
      marginBottom: theme.spacing(4),
      userSelect: 'none',
    },
    progressCircles: {
      display: 'flex',
      marginBottom: theme.spacing(6),
    },
    progressCircle: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: colors.lightBlue,
      borderRadius: '50%',
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75),
    },
    progressCircleActive: {
      backgroundColor: colors.blue,
    },
  })
);

export default surveyProgressStyles;
