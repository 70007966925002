import React from 'react';

import { createStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100px',
      height: '100%',
      color: 'green',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'right',
      justifyContent: `center`,
    },
    heroContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'right',
    },
    hero: {
      fontFamily: 'Averta',
      letterSpacing: '0em',
      fontWeight: 'bolder',
      fontSize: '32px',
      lineHeight: '42px',
      textAlign: 'right',
    },
    small: {
      fontFamily: 'Averta',
      letterSpacing: '0em',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'right',
    },
    symbol: {
      fontFamily: 'Averta',
      letterSpacing: '0em',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '38px',
      textAlign: 'right',
    },
  })
);

interface RitualCardHeroLabelProps {
  valuableRate: number;
  styles?: string;
}

const limit = (toLimit: number) => {
  const uBound = 100;
  const lBound = 0;

  return toLimit > uBound ? uBound : toLimit < lBound ? lBound : toLimit;
};

export const RitualCardHeroLabel = ({ ...props }: RitualCardHeroLabelProps) => {
  const { valuableRate } = props;
  const styles = useStyles(props);

  return (
    <div className={styles.container}>
      <div className={styles.heroContainer}>
        <Typography className={styles.hero}>{limit(valuableRate)}</Typography>
        <Typography className={styles.symbol}>%</Typography>
      </div>
      <Typography className={styles.small}>responded valuable</Typography>
    </div>
  );
};
