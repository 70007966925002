import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const glanceSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    glanceCard: {
      borderRadius: theme.spacing(3),
      width: '100%',
      boxShadow: 'none',
    },
    atAGlanceTitle: {
      color: theme.palette.common.black,
      fontSize: 16,
      marginBottom: '1.5rem',
      fontWeight: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
    },
    atAGlanceBody: {
      color: theme.palette.common.black,
      fontSize: 14,
      marginBottom: '1rem',
      fontWeight: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
    },
    atAGlanceSubsectionTitle: {
      color: theme.palette.grey[500],
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
      marginRight: '1rem',
    },
    mainFigure: {
      color: theme.palette.common.black,
      fontSize: 32,
      fontWeight: 'normal',
      letterSpacing: 0,
      width: '4.5rem',
      textAlign: 'left',
    },
    mainFigureText: {
      color: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0,
      marginTop: '1.125rem',
      textAlign: 'left',
      marginRight: '1rem',
    },
    mainFigureTextSecondary: {
      color: theme.palette.common.black,
      fontSize: 14,
      letterSpacing: 0,
      textAlign: 'left',
      marginRight: '1rem',
      fontWeight: 'bold',
    },
    deltaBox: {
      display: 'flex',
      flexDirection: 'row',
      width: '4.5rem',
      flexShrink: 1,
    },
    deltaBox2: {
      alignSelf: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    delta: {
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0,
      marginLeft: '0.5rem',
      textAlign: 'left',
    },
    deltaText: {
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0,
      maxWidth: '9.375rem',
      textAlign: 'left',
    },
    atAGlanceViewMoreDetails: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 'normal',
      letterSpacing: 0,
      cursor: 'pointer',
      textAlign: 'left',
    },
    atAGlanceViewMoreDetailsDisabled: {
      color: theme.palette.grey[100],
      fontSize: 16,
      fontWeight: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
    },
    disclaimer: {
      color: theme.palette.grey[700],
      fontSize: 14,
      marginTop: '1rem',
      fontWeight: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
    },
    verticalFlexBox: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    horizontalFlexBox: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
  })
);

export default glanceSectionStyles;
