import React from 'react';
import { Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FeedBackIcon } from './FeedbackIcon';
import { VariantType } from 'notistack';
import { ToasterUtils } from 'common';
import { CloseToasterButton } from './CloseToasterButton';
import { toasterStyles } from './styles/toasterStyles';
import { toasterPrompts } from 'prompts/prompts';

const useStyles = makeStyles(() => toasterStyles);

interface Props {
  message?: any;
  // description: string;
  id?: React.ReactText;
  open?: boolean;
  title?: any;
  description?: any;
  handleClose?: any;
  type?: string;
  variant: VariantType;
}

export const Toaster = React.forwardRef((props: Props, ref: any) => {
  const classes = useStyles();
  const { message, variant } = props;

  let title = '';
  switch (variant) {
    case 'success':
      title = toasterPrompts.titles.success;
      break;
    case 'error':
      title = toasterPrompts.titles.error;
      break;
    default:
      title = '';
  }

  const closeToaster = () => {
    ToasterUtils.close();
  };

  return (
    <div ref={ref}>
      <Grid container className={classes.snackbar} justify="center">
        <Grid className={classes.iconGrid}>
          <FeedBackIcon type={variant} />
        </Grid>
        <Grid style={{ flex: 1 }}>
          <div className={classes.title}>{props.title || title}</div>
          <div className={classes.description}>{message}</div>
        </Grid>
        <Grid style={{ margin: '16px' }}>
          <CloseToasterButton onClick={closeToaster} />
        </Grid>
      </Grid>
    </div>
  );
});
