import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const surveySentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(93),
    },
  })
);

export default surveySentStyles;
