import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const gracePeriodStyles = makeStyles((theme: Theme) =>
  createStyles({
    gracePeriodContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: 25,
    },
    gracePeriodButtonContainer: {
      width: '200px',
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      backgroundColor: 'rgb(251, 251, 253)',
      border: '1px solid rgb(237, 240, 245)',
      borderRadius: 12,
      marginRight: 16,
    },
    gracePeriodButton: {
      maxWidth: '32px',
      maxHeight: '32px',
      minWidth: '32px',
      minHeight: '32px',
      padding: '0',
      borderRadius: '16px',
    },
    gracePeriodButtonIcon: {
      padding: 10,
    },
    gracePeriodMinusIcon: {
      height: '2px',
      width: '12px',
      borderRadius: '1px',
      backgroundColor: theme.palette.common.white,
    },
    gracePeriodPlusIcon: {
      height: '2px',
      width: '12px',
      borderRadius: '1px',
      backgroundColor: theme.palette.common.white,
    },
    confirmButton: {
      width: '198px',
      padding: '16px 36px',
      whiteSpace: 'nowrap',
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
    },
    spacer: {
      marginTop: 24,
      width: '100%',
    },
  })
);
