import {
  getRecommendationTexts,
  getStatsResults,
  getSurveyResults,
  getSurveys,
  addContactsCSV,
  reviewAndSend,
  getModules,
  getRitualsTeams,
  getRitualStats,
  getRitualOverview,
  getTeamRituals,
  getTopTenRituals,
} from 'services/api';
import { format, subMonths, getMonth, getYear, isWithinInterval } from 'date-fns';
import moment from 'moment';
import { HubBetaPrompts, HubRitualPrompts } from 'prompts/prompts';

import {
  Score,
  SurveyCard,
  StatCard,
  RecommendationData,
  TextId,
  Survey,
  SurveyName,
  SurveyStatuses,
  ScoreTypes,
  CardVariant,
  ScorePercent,
  WellbeingCard,
  PageId,
  AtAGlanceResponse,
  RitualOverviewResponse,
  AtAGlanceSection,
  RitualOverviewCard,
} from './types';
import {
  STAT_CARD_ID,
  DEFAULT_SURVEY_DATA,
  DEFAULT_STAT_DATA,
  DEFAULT_RECOMMENDATION_ARRAY,
  cardTitle,
  DEFAULT_WELLBEING_DATA,
  DEFAULT_INDIVIDUAL_DATA,
  DEFAULT_CARE_DATA,
  DEFAULT_OVERVIEW_CARDS,
  RITUAL_TEAM_OVERVIEW_ID,
} from './constants';
import { colors } from 'styles/colors';
import { RecommendationCommentaryResponse } from 'services/types';
import { Page } from './types';
import { FeatureFlagSet } from 'FeatureFlagContext';
import { RitualCardData } from './components/RitualCardContainer/RitualCardContainer';

export const getFormattedScore = (score: number | null) => {
  return score && Math.round(score);
};

export const getScorePercent = (score: Score, percentKey?: keyof ScorePercent) => {
  return score.scoreType === ScoreTypes.PERCENTAGE
    ? score.data.percent && percentKey && score.data.percent[percentKey] !== null
      ? score.data.percent[percentKey]
      : null
    : getFormattedScore(
        (Number(score.data.score?.current || 0) / Number(score.data.score?.max || 1)) * 100
      );
};

export const calculateScoreColor = (score: Score, variant: CardVariant = CardVariant.BLUE) => {
  if (variant === CardVariant.WHITE && score.scoreType !== ScoreTypes.PERCENTAGE) {
    const percent = getScorePercent(score) || 0;
    switch (true) {
      case percent < 40:
        return colors.redWarn;
      case percent > 70:
        return colors.green;
    }
  }
};

export const calculateScorePercentage = (score: Score) => {
  const current: number = score.data.survey?.current as number;
  const max: number = score.data.survey?.max as number;
  return (current / max) * 100;
};

export const getSurveyData = async (orgId: string) => {
  const surveyResponse = await getSurveyResults(orgId);
  const statsResponse = await getStatsResults(orgId);

  return {
    surveyData:
      surveyResponse && surveyResponse.data && surveyResponse.data.length
        ? surveyResponse.data
        : DEFAULT_SURVEY_DATA,
    statData: statsResponse && statsResponse.data,
  };
};

export const sortByDate = (survey1: Survey, survey2: Survey) => {
  const survey1Date = survey1.last_sent_date;
  const survey2Date = survey2.last_sent_date;
  if (survey1Date && survey2Date && survey1Date < survey2Date) {
    return 1;
  } else if (survey1Date && survey2Date && survey1Date > survey2Date) {
    return -1;
  } else {
    return 0;
  }
};

const retrieveSurveyData = (
  surveyData: typeof DEFAULT_SURVEY_DATA,
  surveys: Array<Survey>,
  supportedSurveys: Array<any>
) => {
  if (
    supportedSurveys &&
    supportedSurveys.length &&
    surveys &&
    surveys.length &&
    surveyData &&
    surveyData.length
  ) {
    surveys.sort(sortByDate);
    const finalSurveyData: typeof DEFAULT_SURVEY_DATA = [];
    supportedSurveys.forEach((suppSurvey) => {
      const latestSurvey = surveys.find(
        (survey) =>
          survey.id === suppSurvey.survey_id &&
          (survey.status === SurveyStatuses.CLOSED || survey.status === SurveyStatuses.OPEN)
      );
      const latestSurveyData = latestSurvey
        ? surveyData
            .filter((sData: any) => sData.collector_id === latestSurvey?.collector_id)
            .map((obj) => ({ ...obj, status: latestSurvey.status }))
        : [];
      const newSurveyData: typeof DEFAULT_SURVEY_DATA = [];
      if (latestSurveyData.length) {
        newSurveyData.push(...latestSurveyData);
      } else {
        const defaultSurveyData: typeof DEFAULT_SURVEY_DATA = [];
        if (latestSurvey?.name === SurveyName.Wellbeing) {
          defaultSurveyData.push(DEFAULT_WELLBEING_DATA, DEFAULT_CARE_DATA);
        } else if (latestSurvey?.name === SurveyName.Individual) {
          defaultSurveyData.push(DEFAULT_INDIVIDUAL_DATA);
        }
        newSurveyData.push(
          ...defaultSurveyData.map((sData) => ({
            ...sData,
            surveyId: latestSurvey?.id || sData.surveyId,
            collector_id: latestSurvey?.collector_id || sData.collector_id,
            status: latestSurvey?.status || SurveyStatuses.CLOSED,
          }))
        );
      }
      const closedSurvey = surveys.find(
        (survey) => survey.id === suppSurvey.survey_id && survey.status === SurveyStatuses.CLOSED
      );
      finalSurveyData.push(
        ...newSurveyData.map((sData) => ({
          ...sData,
          last_sent_date: latestSurvey?.last_sent_date || '',
          close_on_date: latestSurvey?.close_on_date || '',
          disabled: !surveyData.find((sData) => sData.collector_id === closedSurvey?.collector_id),
        }))
      );
    });
    return finalSurveyData.length
      ? finalSurveyData.sort((surveyData1, surveyData2) => {
          // Preferentially show certain surveys

          return surveyData1.surveyId.localeCompare(surveyData2.surveyId, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        })
      : DEFAULT_SURVEY_DATA;
  }
  return DEFAULT_SURVEY_DATA;
};

const retrieveStatData = (statData: typeof DEFAULT_STAT_DATA, featureFlags: FeatureFlagSet) => {
  return statData &&
    (statData[WellbeingCard.Adoption] !== null ||
      statData[WellbeingCard.Modules] !== null ||
      statData[WellbeingCard.Rituals] !== null)
    ? {
        [WellbeingCard.Adoption]: getStatData(statData, WellbeingCard.Adoption),
        [WellbeingCard.Modules]: getStatData(statData, WellbeingCard.Modules),
        [WellbeingCard.Rituals]: featureFlags.enableHubWellbeingActivitiesRituals
          ? getStatData(statData, WellbeingCard.Rituals)
          : DEFAULT_STAT_DATA[WellbeingCard.Rituals],
        disabled: DEFAULT_STAT_DATA.disabled,
      }
    : DEFAULT_STAT_DATA;
};

const getStatData = (statData: typeof DEFAULT_STAT_DATA, cardType: WellbeingCard) => {
  return statData[cardType] !== null ? statData[cardType] : DEFAULT_STAT_DATA[cardType];
};

export const generateCards = (
  surveyResponse: any,
  surveys: Array<Survey>,
  supportedSurveys: Array<any>,
  pages: Page[],
  featureFlags: FeatureFlagSet
) => {
  const cards: Array<SurveyCard | StatCard> = [];
  if (surveyResponse && surveys) {
    const surveyData = retrieveSurveyData(surveyResponse.surveyData, surveys, supportedSurveys);
    const statData = retrieveStatData(surveyResponse.statData, featureFlags);
    pages.forEach((page) => {
      if (cards.findIndex((card) => card.cardId === page.cardId) !== -1) {
        return;
      }
      let newCard: SurveyCard | StatCard;
      if (page.cardId === STAT_CARD_ID) {
        newCard = {
          cardId: STAT_CARD_ID,
          [WellbeingCard.Adoption]: statData[WellbeingCard.Adoption],
          [WellbeingCard.Modules]: statData[WellbeingCard.Modules],
          [WellbeingCard.Rituals]: statData[WellbeingCard.Rituals],
          headerName: cardTitle[page.cardId],
          disabled:
            statData[WellbeingCard.Adoption] === null &&
            statData[WellbeingCard.Modules] === null &&
            statData[WellbeingCard.Rituals] === null,
        };
        cards.push(newCard);
      } else {
        const card =
          surveyData.find((data: any) => data.surveyType === page.cardId) ||
          DEFAULT_SURVEY_DATA.find((x) => x.surveyType === page.cardId);
        if (card) {
          newCard = {
            cardId: page.cardId,
            surveyId: card.surveyId,
            response: card.response,
            score: card.score,
            headerName: cardTitle[page.cardId],
            lastSentDate: card.last_sent_date,
            closeOnDate: card.close_on_date,
            disabled: card.disabled,
            status: card.status,
          };
          cards.push(newCard);
        }
      }
    });
  }
  return cards;
};

export const getRecommendationData = async (orgId: string) => {
  const recommendationResponse = await getRecommendationTexts(orgId);
  return (recommendationResponse?.data as RecommendationCommentaryResponse[]) || [];
};

export const isRecommendationWithinRange = (
  preMonths: number,
  recommendationDateString: string,
  endDateString?: string
) => {
  const endDate =
    endDateString && moment(endDateString).isValid() ? new Date(endDateString) : new Date();
  const startDate = subMonths(endDate, preMonths);
  const recommendationDate = new Date(recommendationDateString);
  return isWithinInterval(new Date(getYear(recommendationDate), getMonth(recommendationDate)), {
    start: new Date(getYear(startDate), getMonth(startDate)),
    end: new Date(getYear(endDate), getMonth(endDate)),
  });
};

export const getFilteredRecommendation = (
  recommendationArray: RecommendationData[],
  featureFlags: FeatureFlagSet,
  id?: TextId,
  filteredSurveyIds?: string[]
) => {
  switch (id) {
    case TextId.Wellbeing:
      return filterSurveyRecommendations(recommendationArray, id, filteredSurveyIds);
    case TextId.Individual:
      return filterSurveyRecommendations(recommendationArray, id, filteredSurveyIds);
    case TextId.Adoption:
      return featureFlags.enableHubAppUsageReports === true
        ? filterWellbeingActivities(recommendationArray, id)
        : ([] as RecommendationData[]);
    case TextId.Modules:
      return filterWellbeingActivities(recommendationArray, id);
    case TextId.Rituals:
      return filterWellbeingActivities(recommendationArray, id);
    case TextId.Care:
      return filterSurveyRecommendations(recommendationArray, id, filteredSurveyIds);
    case TextId.Overview:
      return filterOverviewRecommendations(recommendationArray, id);
    default:
      return [];
  }
};

const filterWellbeingActivities = (recommendationArray: RecommendationData[], id?: TextId) => {
  return recommendationArray.filter((obj: RecommendationData) => {
    return obj.id === id;
  });
};

const filterOverviewRecommendations = (recommendationArray: RecommendationData[], id?: TextId) => {
  const rArray =
    recommendationArray && recommendationArray.length
      ? recommendationArray
      : DEFAULT_RECOMMENDATION_ARRAY;

  const filteredRecommendations = rArray.filter((obj: RecommendationData) => {
    return obj.id === id;
  });

  return filteredRecommendations.length > 0
    ? filteredRecommendations
    : DEFAULT_RECOMMENDATION_ARRAY;
};

const filterSurveyRecommendations = (
  recommendationArray: RecommendationData[],
  id?: TextId,
  filteredSurveyIds?: string[]
) => {
  return id && filteredSurveyIds && filteredSurveyIds.length
    ? recommendationArray
        .filter(
          (obj: RecommendationData) =>
            obj.id === id && filteredSurveyIds.indexOf(obj.collectorId) !== -1
        )
        .sort((recommendation1: RecommendationData, recommendation2: RecommendationData) => {
          const recommendation1Date = moment(recommendation1.timePeriod).isValid()
            ? new Date(recommendation1.timePeriod)
            : new Date();
          const recommendation2Date = moment(recommendation2.timePeriod).isValid()
            ? new Date(recommendation2.timePeriod)
            : new Date();
          if (recommendation1Date < recommendation2Date) {
            return 1;
          } else if (recommendation1Date > recommendation2Date) {
            return -1;
          } else {
            return 0;
          }
        })
    : [];
};

export const getSurveysData = async (orgId: string, survey_ids: string[]) => {
  const surveysResponse = await getSurveys(orgId, survey_ids);
  return surveysResponse?.data || [];
};

export const uploadContactsCSV = async (file: string, orgId: string) => {
  const response = await addContactsCSV(file, orgId);
  return response?.data || {};
};

export const reviewAndSendData = async (
  surveyId: string,
  orgId: string,
  contactListId: string,
  collectorId: string | undefined,
  sendDate: string,
  emailSubject: string,
  emailTemplate: string,
  emailReminderDays: number[]
) => {
  const response = await reviewAndSend(
    surveyId,
    orgId,
    contactListId,
    collectorId,
    sendDate,
    emailSubject,
    emailTemplate,
    emailReminderDays
  );
  return response?.status;
};

export const getFormattedDate = (dateString?: string, suggestedFormat?: string) => {
  const date = dateString && moment(dateString).isValid() ? new Date(dateString) : new Date();
  return format(date, suggestedFormat ? suggestedFormat : HubBetaPrompts.dateFormat);
};

export const getFormattedTime = (dateString?: string) => {
  const date = dateString && moment(dateString).isValid() ? new Date(dateString) : new Date();
  return format(date, HubBetaPrompts.timeFormat);
};

export const numberOfDays = (toDateString: string, fromDateString?: string) => {
  let dateDiff = 0;
  if (toDateString) {
    const toDate = new Date(toDateString);
    const fromDate = fromDateString ? new Date(fromDateString) : new Date();
    dateDiff = (toDate.valueOf() - fromDate.valueOf()) / (24 * 60 * 60 * 1000);
  }
  return dateDiff;
};

export const filterSurveyIds = (endDateString: string, preMonths: number, surveys: Survey[]) => {
  const endDate = new Date(endDateString);
  const startDate = subMonths(endDate, preMonths);
  const filteredSurveys = surveys.filter((survey) => {
    const lastSentDate = new Date(survey.last_sent_date);
    if (lastSentDate >= startDate && lastSentDate <= endDate) {
      return survey;
    }
    return null;
  });
  return filteredSurveys.map((survey) => survey.collector_id);
};

export const getMonths = (preMonths: number) => {
  const currentDate = new Date();
  const months = [];
  for (let i = 0; i <= preMonths; i++) {
    months.push(subMonths(currentDate, i));
  }
  return months;
};

export const getModulesData = async (orgId: string) => {
  const modulesResponse = await getModules(orgId);
  return modulesResponse?.data || [];
};

export const getRitualTeams = async (orgId: string) => {
  const response = await getRitualsTeams(orgId);
  return response?.data?.teams || [];
};

export const isActivitiesStatDataAvailable = (pageId: PageId, statData?: ScorePercent) => {
  if (statData) {
    switch (pageId) {
      case PageId.Adoption:
        return !(statData[WellbeingCard.Adoption] == null);
      case PageId.Modules:
        return !(statData[WellbeingCard.Modules] === null);
      case PageId.Rituals:
        return !(statData[WellbeingCard.Rituals] === null);
    }
  }
  return false;
};

const getAtAGlanceMainFigures = (
  responseFigure: number | null | undefined | string,
  isResponseRate?: boolean
) => {
  let atAGlanceMainFigure: string = '';
  if (responseFigure !== null && responseFigure !== undefined) {
    if (Number(responseFigure) < 1) {
      atAGlanceMainFigure = HubRitualPrompts.label.valuableOftenLessThanOneText;
    } else {
      atAGlanceMainFigure = responseFigure.toString();
    }
  } else {
    if (isResponseRate) {
      atAGlanceMainFigure = '';
    } else {
      atAGlanceMainFigure = HubRitualPrompts.label.valuableOftenNoValueText;
    }
  }
  return atAGlanceMainFigure;
};

const transformAtAGlanceSection = (atAGlanceSectionResponse: AtAGlanceResponse, teamId: string) => {
  let atAGlanceSection: AtAGlanceSection = {
    valuableMainFigure: getAtAGlanceMainFigures(atAGlanceSectionResponse.valuableRate),
    valuableDeltaFigure: atAGlanceSectionResponse.valuableDelta
      ? Number(atAGlanceSectionResponse.valuableDelta)
      : 0,
    valuableDeltaText: HubRitualPrompts.label.glanceSectionValuableDeltaText,
    oftenMainFigure: getAtAGlanceMainFigures(atAGlanceSectionResponse.doingRate),
    oftenDeltaFigure: atAGlanceSectionResponse.doingDelta
      ? Number(atAGlanceSectionResponse.doingDelta)
      : 0,
    oftenDeltaText: HubRitualPrompts.label.glaceSectionOftenDeltaText,
    responseRateMainFigure: getAtAGlanceMainFigures(atAGlanceSectionResponse.responseRate, true),
    responseRateDeltaFigure: atAGlanceSectionResponse.responseDelta
      ? Number(atAGlanceSectionResponse.responseDelta)
      : 0,
    responseRateDeltaText: HubRitualPrompts.label.glanceSectionResponseRateDeltaText,
    lastUpdated: atAGlanceSectionResponse.lastUpdated,
    lastCheckin: atAGlanceSectionResponse.lastCheckin,
  };
  if (teamId !== RITUAL_TEAM_OVERVIEW_ID) {
    atAGlanceSection = {
      valuableMainFigure: atAGlanceSectionResponse.valuableRate?.toString() ?? '',
      valuableDeltaFigure: atAGlanceSectionResponse.valuableDelta
        ? Number(atAGlanceSectionResponse.valuableDelta)
        : 0,
      valuableDeltaText: '',
      oftenDeltaText: '',
      responseRateDeltaText: '',
      oftenMainFigure: atAGlanceSectionResponse.doingRate?.toString() ?? '',
      oftenDeltaFigure: atAGlanceSectionResponse.doingDelta
        ? Number(atAGlanceSectionResponse.doingDelta)
        : 0,
      responseRateMainFigure: atAGlanceSectionResponse.responseRate?.toString() ?? '',
      responseRateDeltaFigure: atAGlanceSectionResponse.responseDelta
        ? Number(atAGlanceSectionResponse.responseDelta)
        : 0,
      action: atAGlanceSectionResponse.action,
      trigger: atAGlanceSectionResponse.trigger,
      lastUpdated: atAGlanceSectionResponse.lastUpdated,
      lastCheckin: atAGlanceSectionResponse.lastCheckin,
      frequency: atAGlanceSectionResponse.frequency,
      id: atAGlanceSectionResponse.id,
    };
  }
  return atAGlanceSection;
};

const ritualsOverviewResponseSorter = (
  response1: RitualOverviewResponse,
  response2: RitualOverviewResponse
) => {
  if (response1.order > response2.order) {
    return 1;
  } else if (response1.order < response2.order) {
    return -1;
  } else {
    return 0;
  }
};

const transformOverviewCards = (ritualOverviewResponseArr: RitualOverviewResponse[]) => {
  const transformedOverviewCards: RitualOverviewCard[] = [];
  if (ritualOverviewResponseArr && ritualOverviewResponseArr.length) {
    ritualOverviewResponseArr.sort(ritualsOverviewResponseSorter).forEach((response) => {
      transformedOverviewCards.push({
        label: response.title,
        count: response.count,
      });
    });
    return transformedOverviewCards;
  }
  return DEFAULT_OVERVIEW_CARDS;
};

export const getRitualsAtAGlanceArea = async (orgId: string, teamId: string) => {
  const glanceResponse =
    teamId && teamId !== RITUAL_TEAM_OVERVIEW_ID
      ? await getTeamRituals(orgId, teamId)
      : await getRitualStats(orgId);
  return teamId !== RITUAL_TEAM_OVERVIEW_ID
    ? (glanceResponse?.data?.rituals?.map((response: AtAGlanceResponse) =>
        transformAtAGlanceSection(response, teamId)
      ) ?? [])
    : [transformAtAGlanceSection(glanceResponse?.data, teamId)];
};

export const getTopTenRitualsForOrg = async (orgId: string) => {
  const response = await getTopTenRituals(orgId);
  return response !== undefined ? transformTopTenRitualsResponse(response?.data?.rituals) : [];
};

const transformTopTenRitualsResponse = (data: AtAGlanceResponse[]) => {
  const retval: RitualCardData[] = data.map((item) => {
    return {
      trigger: item.trigger,
      action: item.action,
      teamName: item.name ?? '',
      valuableRate: Number(item.valuableRate),
    };
  });
  return retval;
};

export const getRitualsOverviewCards = async (orgId: string, teamId?: string) => {
  const overviewResponse = await getRitualOverview(
    orgId,
    teamId && teamId !== RITUAL_TEAM_OVERVIEW_ID ? teamId : ''
  );
  return transformOverviewCards(overviewResponse?.data?.overview);
};

export const overviewCardsValid = (overviewCards: RitualOverviewCard[]) => {
  if (overviewCards && overviewCards.length) {
    return overviewCards.filter((card) => card.count > 0).length;
  }
  return false;
};
