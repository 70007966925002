import * as React from 'react';
import { colors } from 'styles/colors';

type TriangleDownProps = {
  width?: number;
  height?: number;
  color?: string;
};

const TriangleDown = (props: TriangleDownProps) => {
  const { width = 12, height = 12, color = colors.redWarn } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      color={color}>
      <path d="M5 6h10l-5 9-5-9z" stroke={color} fill={color} />
    </svg>
  );
};

export default TriangleDown;
