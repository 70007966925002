import { Box } from '@material-ui/core';
import React from 'react';
import { GlanceCardSubsectionTitle } from './GlanceCardBodyTitle';
import { GlanceCardSubsectionMainFigureRow } from './GlanceCardBodyMainFigureRow';
import { GlanceCardSubsectionDeltaFigureRow } from './GlanceCardBodyDeltaFigureRow';
import glanceSectionStyles from '../styles';

interface GlanceCardBodyTitle {
  title: string;
}

const GlanceCardBodyTitle: React.FC<GlanceCardBodyTitle> = (props) => {
  const { title } = props;
  return <GlanceCardSubsectionTitle title={title} />;
};

interface GlanceCardBodyMainFigure {
  mainFigure: string;
  mainFigurePrimaryText: string;
  mainFigureSecondaryText?: string;
}

const GlanceCardBodyMainFigure: React.FC<GlanceCardBodyMainFigure> = (props) => {
  const { mainFigure, mainFigurePrimaryText, mainFigureSecondaryText } = props;
  return (
    <GlanceCardSubsectionMainFigureRow
      figure={mainFigure}
      figurePrimaryText={mainFigurePrimaryText}
      figureSecondaryText={mainFigureSecondaryText}
    />
  );
};

interface GlanceCardBodyDelta {
  deltaFigure: number;
  deltaText: string;
}

const GlanceCardBodyDelta: React.FC<GlanceCardBodyDelta> = (props) => {
  const { deltaFigure, deltaText } = props;
  return <GlanceCardSubsectionDeltaFigureRow figure={deltaFigure} figureText={deltaText} />;
};

interface GlanceCardSubsectionProps
  extends GlanceCardBodyTitle,
    GlanceCardBodyMainFigure,
    GlanceCardBodyDelta {}

export interface GlanceCardBodyProps {
  glanceCardBodyProps: GlanceCardSubsectionProps[];
}

export const GlanceCardBody: React.FC<GlanceCardBodyProps> = (props) => {
  const classes = glanceSectionStyles();
  return (
    <Box className={classes.verticalFlexBox}>
      <Box className={classes.horizontalFlexBox} mb={'0.5rem'}>
        {props.glanceCardBodyProps.map((glanceCardBodyProps) => {
          return <GlanceCardBodyTitle {...glanceCardBodyProps} />;
        })}
      </Box>
      <Box className={classes.horizontalFlexBox} mb={'0.75rem'}>
        {props.glanceCardBodyProps.map((glanceCardBodyProps) => {
          return <GlanceCardBodyMainFigure {...glanceCardBodyProps} />;
        })}
      </Box>
      <Box className={classes.horizontalFlexBox}>
        {props.glanceCardBodyProps.map((glanceCardBodyProps) => {
          return glanceCardBodyProps.deltaFigure !== 0 ? (
            <GlanceCardBodyDelta {...glanceCardBodyProps} />
          ) : null;
        })}
      </Box>
    </Box>
  );
};
