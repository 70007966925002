import React from "react";
import { PromptAction, PromptUserResponse } from "pages/GroovVoice/GroovVoice";
import { ResponseBar } from "./ResponseBar";
import { Box } from "@material-ui/core";

export const SummaryBars = ({actions, responses} : {actions: PromptAction[], responses: PromptUserResponse[]}) => {
  return (
    <Box>
      {actions.map((action, index) => {
        const count =
          responses?.filter((c) => c.text === action.action)
            ?.length ?? 0;

        const percentage = responses?.length
          ? (count / responses?.length) * 100
          : 0;
        return (
          <ResponseBar
            key={`${action.action}-${index}`}
            label={action.action}
            percentage={percentage}
            responseCount={count}
            index={index}
          />
        );
      })}
    </Box>
  );
}