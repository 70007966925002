import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChartIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 3V21.0062H21.0247"
          stroke={props.color ? props.color : '#565E74'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 19V12.5047C6 11.6737 6.6658 11 7.5 11C8.32843 11 9 11.6683 9 12.5047V19H6Z"
          fill={props.color ? props.color : '#565E74'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 19V5.4931C11 4.66848 11.6658 4 12.5 4C13.3284 4 14 4.66389 14 5.4931V19H11Z"
          fill={props.color ? props.color : '#565E74'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 19V8.49555C16 7.66958 16.6658 7 17.5 7C18.3284 7 19 7.67955 19 8.49555V19H16Z"
          fill={props.color ? props.color : '#565E74'}
        />
      </svg>
    </SvgIcon>
  </IconButton>
);
