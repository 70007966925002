import React from 'react';
import { Typography, Box } from '@material-ui/core';

import surveySentStyles from './styles';

export const SurveySent: React.FC<{ message?: string }> = (props) => {
  const classes = surveySentStyles(props);
  return (
    <Box className={classes.root}>
      <Typography variant="h4" component="p">
        {props.message}
      </Typography>
    </Box>
  );
};
