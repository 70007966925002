import React from 'react';
import { IntegrationCard, IntegrationCardProps } from '../../IntegrationCard';
import { IntegrationType, Integration } from '../../Common/types';
import TeamsLogo from 'assets/icons/teams-logo';
import { RoutePath } from 'types/routes';
import { History } from 'history';

interface Props extends IntegrationCardProps {
  history: History;
}

export const TeamsIntegrationCard: React.FC<Props> = (props) => {
  const integrationProps = {
    hasIntegration: false,
    showEnabled: props.hasIntegration,
    type: IntegrationType.Channel,
    integration: Integration.MsTeams,
    title: 'Teams',
    description: 'Install Groov Anywhere in Microsoft Teams.',
    icon: <TeamsLogo />,
    buttonText: 'Add Teams',
    onClick: () => {
      props.history.push(RoutePath.TeamsInstall);
    },
  };

  return <IntegrationCard {...integrationProps} />;
};
