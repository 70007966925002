import * as React from 'react';
import MaterialCheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from 'styles/colors';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

export const CheckCircleIcon = (props: Props) => {
  const { width = 20, height = 20, color = colors.positiveGreen } = props;
  return <MaterialCheckCircleIcon style={{ width, height, color }} />;
};
