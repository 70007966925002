import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { getTypographyVariantFromFontWeight } from 'utils/typographyUtils';
import glanceSectionStyles from '../styles';

interface GlanceCardSubsectionMainFigureRowProps {
  figure: string;
  figurePrimaryText: string;
  figureSecondaryText?: string;
}

export const GlanceCardSubsectionMainFigureRow: React.FC<GlanceCardSubsectionMainFigureRowProps> = (
  props
) => {
  const { figure, figurePrimaryText, figureSecondaryText } = props;
  const classes = glanceSectionStyles();
  return (
    <Box className={classes.horizontalFlexBox} minWidth={'6.25rem'}>
      <Typography
        variant={getTypographyVariantFromFontWeight(700)}
        className={classes.mainFigure}
        data-testid={'rituals_glance_card_body_main_figure_label'}>
        {figure}
      </Typography>
      <Box className={classes.verticalFlexBox}>
        <Typography
          variant={getTypographyVariantFromFontWeight(400)}
          className={classes.mainFigureText}
          data-testid={'rituals_glance_card_body_main_figure_primary_text_label'}>
          {figurePrimaryText}
        </Typography>
        {figureSecondaryText && (
          <Typography
            variant={getTypographyVariantFromFontWeight(400)}
            className={classes.mainFigureTextSecondary}
            data-testid={'rituals_glance_card_body_main_figure_secondary_text_label'}>
            {figureSecondaryText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
