import { useEffect } from 'react';

function useDebouncedEffect(callback: () => void, dependencies: any[], delay: number) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(dependencies || []), delay]);
}

export default useDebouncedEffect;
