import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const Link = styled(Button)(() => ({
  textTransform: 'none',
  '&:hover': {
    border: 'none',
    background: 'none',
  },
}));
