import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as Yup from 'yup';

import { colors } from 'styles/colors';
import { Button, Card, ConfirmAlert, ContainerLayout, Input, Link, ToasterUtils } from 'common';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import Analytics from '../utils/AnalyticsHelper';
import { addDomain, getDomains, removeDomain } from '../services/api';
import { DomainListType } from 'types/subscriptionTypes';
import { instructions, toasterPrompts } from 'prompts/prompts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      maxWidth: 1200,
    },
    bottomCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
    },
    inputContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: theme.spacing(6),
    },
    inputText: {
      marginTop: 0,
      width: 312,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    inputAdornmentOut: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 18,
      width: 18,
      borderRadius: 9,
      border: '2px solid',
      borderColor: colors.slateGrey2,
    },
    inputAdornmentIn: {
      backgroundColor: colors.slateGrey2,
      height: 10,
      width: 10,
      borderRadius: 5,
    },
    addDomainText: {
      color: colors.royalBlue,
      fontFamily: 'Averta-Semibold',
      fontWeight: 500,
    },
    confirmDescription: {
      fontSize: 16,
      lineHeight: 1.3,
      color: 'rgb(37, 39, 43)',
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    startAdornment: {
      marginLeft: '16px',
    },
  })
);

interface FormValues {
  domain: string;
}

export const ManageDomains: React.FC = (props) => {
  const classes = useStyles(props);
  const initialValues: FormValues = {
    domain: '',
  };

  const validationSchema = Yup.object().shape({
    domain: Yup.string().required('Required'),
  });
  const [loader, setLoader] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [removeDomainId, setRemoveDomainId] = useState('');
  const [addDomainGrid, setAddDomainGrid] = useState(false);
  const [domainsData, setDomainsData] = useState({} as DomainListType);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDomains();
  }, []);

  const addSubscriptionDomain = async (domain: string) => {
    const res = await addDomain(domain);
    Analytics.trackEvent('AddDomain');
    if (res.status === 201) {
      ToasterUtils.success(toasterPrompts.messages.domain.addSuccess);
      setDomainsData({ ...domainsData, domains: [...domainsData.domains, res.data] });
      setAddDomainGrid(false);
    }
  };

  const removeSubscriptionDomain = async () => {
    Analytics.trackEvent('RemoveDomain');
    setLoader(true);
    setConfirmAlert(false);
    const res = await removeDomain(removeDomainId);
    if (res.status === 200) {
      const domains = domainsData.domains.filter((domain) => domain.id !== res.data.id);
      setDomainsData({ ...domainsData, domains: domains });
      ToasterUtils.success(toasterPrompts.messages.domain.removeSuccess);
      setLoader(false);
      setRemoveDomainId('');
    }
  };

  const fetchDomains = async () => {
    setIsLoading(true);
    const resp = await getDomains();
    if (resp.data) {
      setDomainsData(resp.data);
    }
    setIsLoading(false);
  };

  const renderLoader = () => (
    <Grid container justify="center" alignItems="center" style={{ height: 100 }}>
      <AnimatedSpinner height={30} width={30} />
    </Grid>
  );

  const renderDomains = () => {
    return (
      domainsData &&
      domainsData.domains &&
      domainsData.domains.map((domain, index) => (
        <Grid container className={classes.inputContainer} spacing={4} key={domain.id}>
          <Grid item xs={12} sm={5}>
            <Input
              placeholder="@workplace.com"
              style={{ marginTop: 0 }}
              value={domain.name}
              className={classes.inputText}
              data-testid="domains_input_readonly"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.startAdornment}>
                    <div
                      className={classes.inputAdornmentOut}
                      style={{ borderColor: colors.green }}>
                      <div
                        className={classes.inputAdornmentIn}
                        style={{ backgroundColor: colors.green }}
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              style={{ background: colors.lightRed, color: colors.redWarn }}
              onClick={() => {
                setRemoveDomainId(domain.id);
                setConfirmAlert(true);
              }}
              data-testid="domains_remove_button">
              {loader && removeDomainId === domain.id ? (
                <AnimatedSpinner height={25} width={25} />
              ) : (
                'Remove'
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            {domainsData.domains.length === index + 1 && (
              <Link onClick={() => setAddDomainGrid(true)} data-testid="domains_add_domain_link">
                <Typography variant="h4" className={classes.addDomainText}>
                  Add another domain
                </Typography>
              </Link>
            )}
          </Grid>
        </Grid>
      ))
    );
  };

  return (
    <ContainerLayout>
      <Grid container>
        <Grid item xs={12}>
          <Card style={{ marginTop: 8 }}>
            <div className={classes.bottomCardWrapper}>
              <Typography variant="h2" className={classes.title} data-testid="domains_page_title">
                Domains
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                style={{ marginTop: 22 }}
                data-testid="domains_page_description">
                {instructions.manageDomainsPage.manageDomains}
              </Typography>
            </div>
            {isLoading ? renderLoader() : renderDomains()}
            {(addDomainGrid || (domainsData.domains && domainsData.domains.length === 0)) && (
              <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                  Analytics.trackEvent('AddDomain');
                  const { domain } = values;
                  setSubmitting(true);
                  await addSubscriptionDomain(domain);
                  values.domain = '';
                  setSubmitting(false);
                }}
                validationSchema={validationSchema}>
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container className={classes.inputContainer} spacing={4}>
                        <Grid item xs={12} sm={5}>
                          <Input
                            name="domain"
                            error={Boolean(errors.domain) && touched.domain}
                            placeholder="@workplace.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.domain}
                            className={classes.inputText}
                            data-testid="domains_input"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className={classes.startAdornment}>
                                  <div
                                    className={classes.inputAdornmentOut}
                                    style={{
                                      borderColor: values.domain ? colors.blue : '',
                                    }}>
                                    <div
                                      className={classes.inputAdornmentIn}
                                      style={{
                                        backgroundColor: values.domain ? colors.blue : '',
                                      }}
                                    />
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            type="submit"
                            style={{
                              background: values.domain ? colors.blue : colors.gray1,
                              padding: '16px 52px',
                            }}
                            data-testid="domains_enable_button">
                            {/* extra horizontal padding since Enable is shorter than Remove */}
                            {isSubmitting ? <AnimatedSpinner height={25} width={25} /> : 'Enable'}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            )}
          </Card>
        </Grid>
      </Grid>

      <ConfirmAlert
        open={confirmAlert}
        title="Are you certain?"
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        handleCancel={() => setConfirmAlert(false)}
        handleConfirm={() => removeSubscriptionDomain()}
      />
    </ContainerLayout>
  );
};
