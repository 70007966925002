import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useUserOrg } from '../../utils/UseUserOrg';
import { PowerBIEmbedComp } from './PowerBIEmbed';
import { useParams } from 'react-router-dom';
import { useEmbedTokenV2 } from 'utils/UseEmbedTokenV2';
import { PowerBIReportDetails } from 'utils/PowerBiReports';

export const Insights = (props: { reports: PowerBIReportDetails[] }) => {
  const [isLoaded, setLoaded] = useState(false);
  const userOrg = useUserOrg();
  const isAdmin = userOrg === process.env.REACT_APP_ADMIN_ORG;
  const { reportId } = useParams<{ reportId: string }>();
  const reportSection = props.reports.find((r) => r.nav_route === reportId)?.report_section ?? '';
  const reportKey = props.reports.find((r) => r.nav_route === reportId)?.report_id ?? '';

  const embedToken = useEmbedTokenV2(reportKey);

  useEffect(() => {
    if (embedToken) {
      if (embedToken.accessToken) {
        setLoaded(true);
      }
    }
  }, [embedToken]);

  const checkEmbedDetailsLoaded = () => {
    return !!(isLoaded && userOrg && embedToken && embedToken.accessToken && embedToken.embedUrl);
  };

  return checkEmbedDetailsLoaded() ? (
    <Box data-testid={'insights_powerbi_' + reportId}>
      <PowerBIEmbedComp
        embedToken={embedToken}
        userOrg={isAdmin ? undefined : userOrg}
        reportSection={reportSection}
        containerClassName={'report-style-class-insights'}
      />
    </Box>
  ) : (
    <div />
  );
};
