import * as React from 'react';

const NetworkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="2 2 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#444752"
    strokeWidth="0.6">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#CCCCCC"
      strokeWidth="0.43200000000000005"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 4.5C9.67157 4.5 9 5.17157 9 6V9C9 9.37302 9.13616 9.71423 9.36148 9.97666L6.77005 13H5.5C4.67157 13 4 13.6716 4 14.5V17.5C4 18.3284 4.67157 19 5.5 19H8.5C9.32843 19 10 18.3284 10 17.5V14.5C10 13.6716 9.32843 13 8.5 13H8.08713L10.248 10.4789C10.33 10.4928 10.4141 10.5 10.5 10.5H13.5C13.5859 10.5 13.67 10.4928 13.752 10.4789L15.9149 13.0024C15.1261 13.0465 14.5 13.7001 14.5 14.5V17.5C14.5 18.3284 15.1716 19 16 19H19C19.8284 19 20.5 18.3284 20.5 17.5V14.5C20.5 13.6716 19.8284 13 19 13H17.23L14.6385 9.97665C14.8638 9.71422 15 9.37301 15 9V6C15 5.17157 14.3284 4.5 13.5 4.5H10.5ZM16.9959 14C16.9988 14 17.0017 14 17.0045 14H19C19.2761 14 19.5 14.2239 19.5 14.5V17.5C19.5 17.7761 19.2761 18 19 18H16C15.7239 18 15.5 17.7761 15.5 17.5V14.5C15.5 14.2239 15.7239 14 16 14H16.9959ZM5.5 14H8.5C8.77614 14 9 14.2239 9 14.5V17.5C9 17.7761 8.77614 18 8.5 18H5.5C5.22386 18 5 17.7761 5 17.5V14.5C5 14.2239 5.22386 14 5.5 14ZM10 6C10 5.72386 10.2239 5.5 10.5 5.5H13.5C13.7761 5.5 14 5.72386 14 6V9C14 9.27614 13.7761 9.5 13.5 9.5H10.5C10.2239 9.5 10 9.27614 10 9V6Z"
        fill="#444752"></path>{' '}
    </g>
  </svg>
);
export default NetworkIcon;
