import React from 'react';
import { Grid, Container, Box } from '@material-ui/core';
interface Props {
  children: React.ReactNode;
}
export default ({ children }: Props) => (
  <Container style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
    <Grid container item xs={12} sm={6} md={4} lg={3} direction="column" alignItems="center">
      <Box component="div" mt={20}>
        <Grid container item justify="center" alignItems="center" direction="column">
          {children}
        </Grid>
      </Box>
    </Grid>
  </Container>
);
