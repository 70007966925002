import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const wrapperStyles = makeStyles((theme: Theme) =>
  createStyles({
    childContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: 25,
    },
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
      maxWidth: '70%',
    },
    mobileCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
      maxWidth: '100%',
    },
  })
);
