import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Card, ContainerLayout, ToasterUtils } from 'common';
import { useAuthState } from 'common/hooks/useAuthState';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { useHistory } from 'react-router-dom';
import { SlackIntegrationCard } from './Channels/Slack/SlackIntegrationCard';
import { TeamsIntegrationCard } from './Channels/Teams/TeamsIntegrationCard';
import { getCustomerIntegrations } from './Common/api';
import { CustomerIntegration, Integration } from './Common/types';

export const IntegrationsPage: React.FC = () => {
  const authState = useAuthState();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerIntegrations, setCustomerIntegrations] = useState<
    CustomerIntegration[] | undefined
  >(undefined);

  const getIntegrations = async () => {
    try {
      setIsLoading(true);
      const integrations = await getCustomerIntegrations(authState.orgId);
      setCustomerIntegrations(integrations);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      ToasterUtils.error('An error occured getting Integrations');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Getting integrations');
    getIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasIntegration = (integration: Integration) => {
    return !!customerIntegrations
      ?.map((integration) => {
        return integration.platform;
      })
      ?.includes(integration);
  };

  const cards = [
    <SlackIntegrationCard
      key={0}
      hasIntegration={hasIntegration(Integration.Slack)}
      authState={authState}
    />,
    <TeamsIntegrationCard
      key={1}
      hasIntegration={hasIntegration(Integration.MsTeams)}
      history={history}
    />,
  ];

  return (
    <ContainerLayout>
      <Card>
        <Typography
          variant="h2"
          style={{ marginTop: '0.5rem' }}
          data-testid="integrations_page_title">
          {'Manage Integrations'}
        </Typography>
        <Grid container spacing={2}>
          {isLoading ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <AnimatedSpinner width={64} height={64} />
              </Box>
            </Grid>
          ) : (
            customerIntegrations !== undefined &&
            customerIntegrations !== null &&
            cards.map((card, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
                  {card}
                </Grid>
              );
            })
          )}
        </Grid>
      </Card>
    </ContainerLayout>
  );
};
