import { hashEmail } from './hashEmail';

type IEmailHashHelper = {
  isLoggedInUserEmail: (email: string) => boolean;
  saveEmailHash: (email: string) => void;
  deleteEmailHash: () => void;
};

type EmailHashHelperProps = {
  store: globalThis.Storage;
  hasher: (str: string) => string;
};

export function EmailHashHelper({ store, hasher }: EmailHashHelperProps) {
  let _currentEmail: string;
  let _hash: string;
  const HashedEmailKey = 'HashedEmail';

  const exports = {} as IEmailHashHelper;

  exports.saveEmailHash = (currentEmail: string) => {
    _currentEmail = currentEmail;
    store.setItem(HashedEmailKey, hasher(_currentEmail));
  };

  exports.isLoggedInUserEmail = (emailToCompare: string) => {
    if (_currentEmail) return _currentEmail === emailToCompare;

    if (!_hash) _hash = store.getItem(HashedEmailKey) ?? '';
    return _hash === hasher(emailToCompare);
  };

  exports.deleteEmailHash = () => store.removeItem(HashedEmailKey);

  return exports;
}

export const emailHashHelper = EmailHashHelper({
  store: window.localStorage,
  hasher: hashEmail,
});
