import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const AddCircleOutline = (props: Props) => {
  const { width = 16, height = 20, color = '#444752' } = props;
  return <AddCircleOutlineIcon style={{ width: width, height: height, color: color }} />;
};
