import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from 'styles/colors';

export type FormLabelWithHelperTextProps = {
  label: string;
  description: string;
};
export function FormLabelWithHelperText({
  label,
  description,
}: Readonly<FormLabelWithHelperTextProps>) {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h4" align="left" className={classes.labelTitle}>
        {label}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {description}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelTitle: {
      textTransform: 'none',
      color: colors.black,
      marginTop: theme.spacing(4),
      fontSize: 18,
    },
    helpIcon: {
      marginLeft: '8px',
      fontSize: '18px',
      cursor: 'pointer',
    },
  })
);
