import { createBrowserHistory } from 'history';
import Analytics from './AnalyticsHelper';

const history = createBrowserHistory();

history.listen((location) => {
  Analytics.trackPageView(location.pathname + location.hash);
});

export default history;
