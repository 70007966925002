export const AutoEmailPrompts = {
  title: 'Welcome email',
  description:
    'When switched on, we will send an automated welcome email to each new person you add. NB: This feature will only send welcome emails to people you add individually or in a CSV upload. We cannot send welcome emails to people who gain access through the Manage Domain setting',
  toggleOnText: 'On',
  toggleOffText: 'Off',
  toastTitle: 'Yay! Everything worked',
  toastMsg: 'Your changes have been saved',
  toastSaveErrorTitle: 'Uh oh. Something went wrong.',
  toastSaveErrorMsg: 'Please refresh your browser and try again',
  id: 'auto_email',
};
