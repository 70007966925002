import React, { useEffect, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { PowerBIEmbedComp } from 'common/components/PowerBIEmbed';
import {
  GAInsightsReportsMap,
  PowerBIReportDetails,
  reportMap,
  ReportSection,
} from 'utils/PowerBiReports';
import { useEmbedTokenV2 } from 'utils/UseEmbedTokenV2';
import { useUserOrg } from 'utils/UseUserOrg';
import { colors } from 'styles/colors';
import { useAppSelector } from 'store';
import Analytics from 'utils/AnalyticsHelper';
import { RouteLabel } from 'types/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    route: {
      width: '100%',
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      position: 'relative',
    },
    routeText: {
      fontWeight: 'bold',
      color: colors.black,
      textDecoration: 'none',
    },
    activeRoute: {
      backgroundColor: theme.palette.grey[400],
    },
    activeIndicator: {
      width: '5px',
      height: '70%',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: '4px',
    },
    container: {
      backgroundColor: colors.white,
      display: 'flex',
      width: '95%',
      minHeight: '60vh',
      overflow: 'hidden',
      borderRadius: '1.2rem',
      border: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.spacing(4),
    },
    sidebar: {
      paddingTop: theme.spacing(18),
      flexBasis: '15%',
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },
    content: {
      flexBasis: '85%',
    },
    powerbiContainer: {
      margin: theme.spacing(3),
      marginTop: theme.spacing(8),
      border: '1px solid #e0e0e0',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
      backgroundColor: colors.lightGrey,
    },
  })
);
const ReportNavLink = (props: {
  url: string;
  reportDef: PowerBIReportDetails;
  isActive: boolean;
  onClick?: any;
}) => {
  const classes = useStyles(props);
  return (
    <div className={[classes.route, props.isActive ? classes.activeRoute : ''].join(' ')}>
      {props.isActive && <div className={classes.activeIndicator}></div>}
      <NavLink
        to={`${props.url}`}
        onClick={props.onClick}
        data-testid={'insights_' + props.reportDef.report_key + '_tab'}
        className={classes.routeText}>
        {props.reportDef.nav_header}
      </NavLink>
    </div>
  );
};
export const GAInsights: React.FC = () => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isActiveReportSection, setIsActiveReportSection] = useState<ReportSection>(
    ReportSection.Snapshot
  );
  const { url } = useRouteMatch();
  const userOrg = useUserOrg();
  const reportKey = reportMap.reports.find((r) => r.nav_route === 'ga-insights')?.report_id ?? '';
  const isAdmin = userOrg === process.env.REACT_APP_ADMIN_ORG;
  const { userId } = useAppSelector((state) => state.auth);

  const embedToken = useEmbedTokenV2(reportKey);

  const GAInsightsNavLinks = Object.values(GAInsightsReportsMap.reports).map((r) => (
    <ReportNavLink
      key={r.report_section}
      url={url}
      reportDef={r}
      isActive={isActiveReportSection === r.report_section}
      onClick={() => setIsActiveReportSection(r.report_section as ReportSection)}
    />
  ));

  useEffect(() => {
    if (embedToken) {
      if (embedToken.accessToken) {
        setIsLoaded(true);
      }
    }
  }, [embedToken]);

  useEffect(() => {
    const sessionStartTime = new Date().getTime();
    Analytics.trackPageViewWithDetails('GAInsights', userId);

    return () => {
      const sessionEndTime: number = new Date().getTime();
      const sessionDuration: number = (sessionEndTime - sessionStartTime) / 1000;

      const hours = Math.floor(sessionDuration / 3600);
      const minutes = Math.floor((sessionDuration % 3600) / 60);
      const seconds = Math.floor(sessionDuration % 60);

      const duration = `${hours}h ${minutes}m ${seconds}s`;
      Analytics.trackEventV2('SessionDuration', {
        pageName: RouteLabel.GAInsights,
        userId,
        sessionStartTime: new Date(sessionStartTime).toISOString(),
        sessionEndTime: new Date(sessionEndTime).toISOString(),
        duration,
      });
    };
  }, [userId]);

  const checkEmbedDetailsLoaded = () => {
    return !!(isLoaded && embedToken?.accessToken && embedToken.embedUrl);
  };

  return checkEmbedDetailsLoaded() ? (
    <Box className={classes.container}>
      <Box className={classes.sidebar}>{GAInsightsNavLinks}</Box>
      <Box className={classes.content}>
        <Box data-testid={'ga-insights'} className={classes.powerbiContainer}>
          <PowerBIEmbedComp
            embedToken={embedToken}
            userOrg={isAdmin ? undefined : userOrg}
            reportSection={isActiveReportSection as string}
            containerClassName={'report-style-class-ga-insights'}
            orgTableName="Customers"
          />
        </Box>
      </Box>
    </Box>
  ) : (
    <div />
  );
};
