import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GroovModal } from 'common/components/Modal/GroovModal';
import { GroovModalCloseButton } from 'common/components/Modal/GroovModalCloseButton';
import { GroovModalCard } from 'common/components/Modal/GroovModalCard';
import { Button, ToasterUtils } from 'common';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { EmailResultList } from '../../../../common/components/EmailResultList';
import { useInstallTeams } from './useInstallTeams';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '16px',
  },
  body: {
    marginBottom: '8px',
  },
  button: {
    width: '120px',
    marginTop: '8px',
    marginBottom: '8px',
    padding: '8px',
  },
  emailContainer: {
    marginTop: '8px',
    marginLeft: '16px',
    maxHeight: '100%',
    width: '100%',
    overflow: 'auto',
  },
}));

type Props = {
  showModal: boolean;
  closeModal: () => void;
  onModalClose?: () => void;
};

export const TeamsFullInstallModal = ({
  closeModal,
  showModal,
  onModalClose = () => {},
}: Props) => {
  const classes = useStyles();
  const onError = () => {
    ToasterUtils.error('An error occurred while processing your request. Please try again');
  };

  const {
    isLoading,
    installedUsers,
    installTeamsForOrganisation,
    reset: resetInstallTeams,
  } = useInstallTeams({ onError });

  const onCloseModal = () => {
    resetInstallTeams();
    onModalClose();
  };

  return (
    <GroovModal
      isModalShowing={showModal}
      style={{ opacity: 0.5 }}
      childStyle={{ width: '95%', height: '95%' }}
      backdropClick={onCloseModal}
      onModalClosed={onCloseModal}>
      <GroovModalCloseButton onPressed={closeModal} />
      <GroovModalCard style={{ padding: '32px', paddingBottom: '16px' }}>
        <Typography variant="h1" className={classes.title}>
          Install for Everyone
        </Typography>
        <Typography variant="body1" className={classes.body}>
          To install Groov Anywhere for Teams for all users in your organisation, simply initiate
          the installation process. The app will automatically appear in each user`&apos;`s Teams
          application within 24 hours. A list of users who received the installation will be
          available once the process is complete.
        </Typography>
        {!installedUsers && (
          <Button
            onClick={installTeamsForOrganisation}
            disabled={isLoading || !!installedUsers}
            className={classes.button}>
            {isLoading ? <AnimatedSpinner width={24} height={24} /> : 'Install'}
          </Button>
        )}

        {!!installedUsers && (
          <>
            <br />
            <Typography variant="h3" className={classes.body}>
              Installation complete!
            </Typography>
            <br />
            <Typography variant="h3" className={classes.body}>
              The Groov Anywhere app has been installed for the following users:
            </Typography>
            <Box className={classes.emailContainer}>
              <EmailResultList results={installedUsers} />
            </Box>
            <Button onClick={closeModal} disabled={isLoading} className={classes.button}>
              Close
            </Button>
          </>
        )}
      </GroovModalCard>
    </GroovModal>
  );
};
