export enum TeamsInstallationStatus {
  NotFound = 'Not Found',
  Conflict = 'Conflict',
  Success = 'Success',
  Failed = 'Failed',
}

export interface EmailWithStatus {
  mail: string;
  status?: TeamsInstallationStatus;
}
