import * as React from 'react';
import MaterialWarnIcon from '@material-ui/icons/Warning';
import { colors } from 'styles/colors';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

export const WarnIcon = (props: Props) => {
  const { width = 20, height = 20, color = colors.orange } = props;
  return <MaterialWarnIcon style={{ width, height, color }} />;
};
