import React from 'react';
import { PromptBuilderForm } from './PromptBuilderForm';
import { promptBuilder } from 'prompts/prompts';
import { useAppSelector } from 'store';
import { PromptCTATypes } from './PromptBuilder.types';

export function CreatePrompt() {
  const { currentLocalPrompt } = useAppSelector((state) => state.promptBuilder);

  return (
    <PromptBuilderForm
      title={promptBuilder.createPrompt}
      initialValues={{
        id: window.crypto.randomUUID(),
        name: '',
        body: '',
        ctaType: PromptCTATypes.custom,
        ctaValue: [],
        userList: [],
        promptDateTime: '',
        categoryId: '',
        isGroovVoice: false,
        isConfidential: true,
        responseToOpenText: undefined,
        promptTags: [],
        ...currentLocalPrompt,
      }}
    />
  );
}
