import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const selectSurveyStyles = makeStyles((theme: Theme) =>
  createStyles({
    surveys: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(3),
      height: '23.5rem',
      overflowY: 'auto',
    },
    survey: {
      display: 'flex',
      marginBottom: theme.spacing(8),
    },
    checkbox: {
      alignSelf: 'flex-start',
      flex: '0 0 42px',
      '&:disabled': {
        color: colors.gray1,
      },
    },
    content: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(4),
      borderBottom: '2px solid #979797',
      flex: '1',
    },
    desc: {
      display: 'flex',
      flexDirection: 'column',
      flex: '2',
      userSelect: 'none',
    },
    title: {
      fontSize: '1.25rem',
    },
    meta: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    preview: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    prevButton: {
      padding: theme.spacing(2.5, 7),
      height: theme.spacing(10),
    },
    metaAvailable: {
      marginRight: theme.spacing(25),
    },
    metaData: {
      marginRight: theme.spacing(12.5),
    },
    disabled: {
      color: colors.gray1,
    },
  })
);

export default selectSurveyStyles;
