import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  completeSignIn,
  completeSignOut,
  getFederatedLogin,
  signIn,
  signOut,
} from './auth.services';
import { RootState } from 'store/rootReducer';
import { LoginResponse } from 'services/types';
import { dispatch, purgeReduxStore } from 'store';
import { exchangeToken, getOrCreateUserProfileIfNotExists } from 'services/api';

type LoginThunkParams = {
  orgId?: string | null;
  role?: string | null;
};

interface UserData {
  access_token: string;
  refresh_token?: string;
  email_address?: string;
  expires_at?: number;
}

export const loginAction = createAsyncThunk<
  void,
  LoginThunkParams,
  {
    state: RootState;
  }
>('auth/login', async (params: LoginThunkParams, { rejectWithValue }) => {
  try {
    await signIn({ orgId: params.orgId, role: params.role });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const completeLoginAction = createAsyncThunk<
  UserData,
  void,
  {
    state: RootState;
  }
>('auth/completeLogin', async (_, { rejectWithValue }) => {
  try {
    const user = await completeSignIn();
    if (!user) {
      throw new Error('No user returned');
    }

    const userData: UserData = {
      access_token: user.access_token,
      refresh_token: user.refresh_token,
      email_address: user.profile.preferred_username,
      expires_at: user.expires_at,
    };
    await dispatch(getFederatedToken(userData.access_token!));
    return userData;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const logoutAction = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>('auth/logout', async (_, { rejectWithValue }) => {
  try {
    await purgeReduxStore();
    await signOut();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const completeLogoutAction = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>('auth/completeLogout', async (_, { rejectWithValue }) => {
  try {
    await purgeReduxStore();
    await completeSignOut();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFederatedToken = createAsyncThunk<
  LoginResponse,
  string,
  {
    state: RootState;
  }
>('auth/getFederatedToken', async (oidcAccessToken, { rejectWithValue }) => {
  try {
    const response = await getFederatedLogin(oidcAccessToken);
    if (!response) {
      throw new Error('No token returned');
    }
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const loginFromExternalAction = createAsyncThunk(
  'auth/loginFromExternalAction',
  async (payload: { accessToken: string }, { rejectWithValue }) => {
    try {
      return await exchangeToken(payload.accessToken);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserProfileAction = createAsyncThunk(
  'getUserProfileAction',
  async (_, { rejectWithValue }) => {
    try {
      return await getOrCreateUserProfileIfNotExists();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
