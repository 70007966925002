import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const confirmSendStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(2),
    },
    emailTemplate: {
      minWidth: '37.5rem',
      height: '22rem',
      overflowY: 'auto',
    },
  })
);

export default confirmSendStyles;
