import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const UndoIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon>
      <svg
        width="18px"
        height="17px"
        viewBox="0 0 18 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>3D5BC309-725F-4639-BF1C-ED86F90837B0@1.00x</title>
        <g id="Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin---Mobile-menu---closed" transform="translate(-51.000000, -368.000000)">
            <g id="icons-/-ic-undo" transform="translate(48.000000, 364.000000)">
              <rect id="container" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M9.73458723,14.3453115 C10.0950712,14.7057955 10.1228007,15.2730265 9.81777584,15.6653177 L9.73458723,15.7595251 C9.37410327,16.120009 8.80687221,16.1477386 8.414581,15.8427137 L8.32037367,15.7595251 L3.29289322,10.7320446 C2.93240926,10.3715606 2.90467972,9.80432957 3.20970461,9.41203837 L3.29289322,9.31783103 L8.31783103,4.29289322 C8.70835532,3.90236893 9.3415203,3.90236893 9.73204459,4.29289322 C10.0925286,4.65337718 10.1202581,5.22060824 9.8152332,5.61289944 L9.73204459,5.70710678 L6.439,9 L15,9 C18.3137084,9 21,11.6862916 21,15 C21,18.3137084 18.3137084,21 15,21 C14.4477153,21 14,20.5522847 14,20 C14,19.4477153 14.4477153,19 15,19 C17.209139,19 19,17.209139 19,15 C19,12.8578047 17.3160315,11.1089211 15.1996403,11.0048953 L15,11 L6.389,11 L9.73458723,14.3453115 Z"
                id="fill-color"
                fill={props.color ? props.color : '#444752'}></path>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  </IconButton>
);
