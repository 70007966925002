import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

export const autoEmailStyles = makeStyles(() =>
  createStyles({
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: 25,
    },
    switch: {
      marginRight: 16,
      marginLeft: 16,
    },
  })
);
