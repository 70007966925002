import { ToasterUtils } from '../common';
import { toasterPrompts } from '../prompts/prompts';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from 'store';
import sessionTimeoutSubject from './sessionTimeoutSubject';

const BASE_URL = `${process.env.REACT_APP_SERVICE_URL}/subscription`;

const getToken = () => {
  return store.getState().auth.accessToken;
};

const handleApiError = (error: any) => {
  if (error.response?.status === 401 && error.response?.data.message === 'Unauthorized') {
    sessionTimeoutSubject.next(1);
  } else if (error.response && error.response.data.error_description === 'Authentication Failed') {
    ToasterUtils.error(toasterPrompts.messages.login.invalidCredentials);
  }
  throw error;
};

export const BasicRequest = async (config: AxiosRequestConfig): Promise<AxiosResponse> => {
  const token = getToken();
  if (token) {
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  }

  const newConfig = {
    baseURL: BASE_URL,
    ...config,
  };

  return axios(newConfig).catch(handleApiError);
};
