export const TeamsStrings = {
  title: 'Groov Anywhere for Microsoft Teams',
  description: 'Enter your Tenant ID to install Groov Anywhere for your organisation.',
  addToSlackButtonText: 'Add to Teams',
  toastErrorTitle: 'Uh oh. Something went wrong.',
  toastErrorMsg: 'Please refresh your browser and try again',
  toastTitle: 'Yay! Everything worked',
  toastMsg: 'Your changes have been saved',
  addButtonText: 'Add',
  textInputPlaceholder: 'Enter tenant Id',
  id: 'groov_anywhere_teams',
};
