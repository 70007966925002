import React from 'react';
import { DrawerInput, DrawerInputProps } from './EapDrawerInput';

type FormInput = Pick<DrawerInputProps, 'value' | 'onChange' | 'errorText' | 'onBlur'>;

export const NameInput = (props: FormInput) => {
  return (
    <DrawerInput
      {...props}
      label="Eap service provider name"
      placeholder="Enter your provider name"
    />
  );
};

export const UrlInput = (props: FormInput) => {
  return (
    <DrawerInput
      {...props}
      label="URL"
      placeholder="Enter the EAP login URL or a session booking URL"
    />
  );
};

export const PhoneInput = (props: FormInput) => {
  return <DrawerInput {...props} label="Phone number" placeholder="Enter the EAP phone number" />;
};

export const DetailsInput = (props: FormInput) => {
  return (
    <DrawerInput
      {...props}
      multiline
      minRows={4}
      label="Additional Instructions"
      placeholder="E.g: If you don’t have your EAP login setup contact hr@company.com"
    />
  );
};

export const nameErrorText = (name: string) => {
  if (name.length < 1) {
    return 'A name is required';
  }

  return undefined;
};
export const urlErrorText = (url: string) => {
  if (url.length < 1) {
    return 'A website URL is required';
  }

  return undefined;
};
export const phoneErrorText = (phone: string) => {
  if (phone.length < 1) {
    return 'A phone number is required';
  }

  return undefined;
};

export const detailsErrorText = (details?: string) => {
  return undefined;
};
