import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const newSurveyStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      alignItems: 'center',
    },
    newSurveyInfoText: {
      color: colors.white,
      marginRight: theme.spacing(3),
      fontSize: '1rem',
    },
    newSurveyInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    newSurveyInfoIcon: {
      color: colors.white,
      height: '2rem',
      width: '2rem',
      marginRight: theme.spacing(3),
    },
    leftSection: {
      display: 'flex',
      alignItems: 'center',
    },
    rightSection: {
      display: 'flex',
      alignItems: 'center',
    },
    newSurveyButton: {
      minWidth: '12rem',
      marginLeft: theme.spacing(3),
    },
  })
);

export default newSurveyStyles;
