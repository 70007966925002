import React from 'react';
import { Typography } from '@material-ui/core';
import { TableRow } from 'common/components/Table/TableRow';
import { TableCell } from 'common/components/Table/TableCell';
import { eapTableStyles } from '../styles';

export const EapTableHeader = () => {
  const { tableHeader, tableHeaderCellFirst, tableHeaderCellLast, tableHeaderText } =
    eapTableStyles();
  return (
    <TableRow className={tableHeader}>
      <TableCell className={tableHeaderCellFirst}>
        <Typography className={tableHeaderText} noWrap>
          Provider name
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={tableHeaderText} noWrap>
          URL
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={tableHeaderText} noWrap>
          Phone number
        </Typography>
      </TableCell>
      <TableCell className={tableHeaderCellLast} />
    </TableRow>
  );
};
