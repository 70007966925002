import React from 'react';
import { Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from './Button';
import { colors } from 'styles/colors';

interface ConfirmAlertProps {
  open: boolean;
  title: string;
  description?: string;
  confirmButtonText: string;
  handleConfirm: Function;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      border: '1px solid rgb(237, 240, 245)',
    },
    title: {
      textAlign: 'center',
      fontWeight: 900,
      color: 'rgb(37, 39, 43)',
      fontSize: '18px',
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    description: {
      color: colors.slateGrey,
      textAlign: 'center',
      fontSize: '12px',
      paddingBottom: theme.spacing(2),
    },
    dialogAction: {
      justifyContent: 'center',
    },
    buttonStyle: {
      paddingLeft: theme.spacing(17.5),
      paddingRight: theme.spacing(17.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
      borderRadius: 12,
      border: '1px solid rgb(237, 240, 245)',
      width: theme.spacing(55),
      paddingBottom: theme.spacing(2.5),
    },
  })
);
export const SuccessConfirm: React.FC<ConfirmAlertProps> = (props: ConfirmAlertProps) => {
  const classes = useStyles(props);

  const { open, title, description, confirmButtonText, handleConfirm } = props;
  return (
    <div>
      <Dialog
        PaperProps={{
          className: classes.dialogPaper,
        }}
        open={open}
        fullScreen={false}
        maxWidth={'xs'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}>
        <DialogTitle>
          <Typography variant="h6" component="div" className={classes.title}>
            {title}
          </Typography>
        </DialogTitle>
        {description && (
          <Typography component="div" className={classes.description}>
            {description}
          </Typography>
        )}
        <DialogActions className={classes.dialogAction}>
          <Button
            className={classes.buttonStyle}
            onClick={() => handleConfirm()}
            data-testid="success_confirm_button">
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
