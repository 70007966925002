import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Input } from 'common';
import { AddCircleOutline, DeleteOutline, EditOutlined } from '@material-ui/icons';
import { ContextualNudgePromptFormTypes, OptionTypes } from '../ContextualNudge.types';
import { FormikProps } from 'formik';

export type ManagePromptActionsProps = {
  promptOptions: OptionTypes[];
  setPromptOptions: React.Dispatch<React.SetStateAction<OptionTypes[]>>;
  formikProps: FormikProps<ContextualNudgePromptFormTypes>;
};
export function ManagePromptActions({
  promptOptions,
  formikProps,
  setPromptOptions,
}: Readonly<ManagePromptActionsProps>) {
  const classes = useStyles();
  const { values, setFieldValue, handleChange, handleBlur } = formikProps;

  const handleAddPromptOption = () => {
    setPromptOptions((value) => [
      ...value,
      { questions: values.promptOptionQuestion, response: values.promptOptionResponse },
    ]);
    setFieldValue('promptOptionQuestion', '');
    setFieldValue('promptOptionResponse', '');
  };
  const handleRemovePromptOption = (index: number) => {
    const newPromptOptions = [...promptOptions.slice(0, index), ...promptOptions.slice(index + 1)];
    setPromptOptions(newPromptOptions);
  };

  const handleEditPromptOption = (index: number) => {
    const option = promptOptions[index];
    const newPromptOptions = [...promptOptions.slice(0, index), ...promptOptions.slice(index + 1)];
    setPromptOptions(newPromptOptions);
    setFieldValue('promptOptionQuestion', option.questions);
    setFieldValue('promptOptionResponse', option.response);
  };

  const selectedPromptOptions = promptOptions.map((po, index) => (
    <Grid key={po.questions} container spacing={4} alignItems="center">
      <Grid item xs={5}>
        <Input fullWidth={true} name="promptOptionQuestion" value={po.questions} disabled />
      </Grid>
      <Grid item xs={6}>
        <Input fullWidth={true} name="promptOptionResponse" value={po.response} disabled />
      </Grid>
      <Grid item xs={1} className={classes.deleteButton}>
        <EditOutlined color="primary" onClick={() => handleEditPromptOption(index)} />
        <DeleteOutline color="error" onClick={() => handleRemovePromptOption(index)} />
      </Grid>
    </Grid>
  ));

  return (
    <div>
      {selectedPromptOptions}
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={5}>
          <Input
            fullWidth={true}
            name="promptOptionQuestion"
            value={values.promptOptionQuestion}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Prompt Option"
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            fullWidth={true}
            name="promptOptionResponse"
            value={values.promptOptionResponse}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Prompt Response"
          />
        </Grid>
        <Grid item xs={1} className={classes.addButton}>
          <AddCircleOutline onClick={handleAddPromptOption} color="primary" />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      alignItem: 'center',
    },
    addButton: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-around',
      alignItem: 'center',
    },
  })
);
