import React, { useCallback, useEffect, useState } from 'react';
import { Backdrop, Card, Modal, Fade, Typography, Box, Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { useUserOrg } from 'utils/UseUserOrg';
import { Button } from 'common';
import { HubSurveyPrompts } from 'prompts/prompts';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import backBtn from 'assets/icons/btn-back.svg';

import {
  uploadContactsCSV,
  reviewAndSendData,
  getFormattedDate,
  getFormattedTime,
} from '../../utils';
import SurveyProgress from './components/SurveyProgress';
import SelectSurvey from './components/SelectSurvey';
import AddRecipients from './components/AddRecipients';
import ReviewAndSend from './components/ReviewAndSend';
import SelectDate from './components/SelectDate';
import SurveySent from './components/SurveySent';
import ManageSurveys from './components/ManageSurveys';
import ConfirmSend from './components/ConfirmSend';
import {
  SurveyPage,
  Survey,
  SurveyTypes,
  SurveyComponents,
  SurveyActions,
  AddRecipientMessage,
  AddRecipientMessageType,
  SurveyStatuses,
} from '../../types';
import {
  NEW_SURVEY_PAGES,
  MANGAE_SURVEY_PAGES,
  SURVEY_REMINDER_DAYS,
  SURVEY_REMINDER_TOGGLE_DEFAULT,
  HUB_ROUTES,
  HUB_MANAGE_ROUTES,
  MANGAE_SURVEY_MENU_ITEMS,
} from '../../constants';
import surveyModalStyles from './styles';
import { useAppDispatch, useAppSelector } from 'store';
import { triggerSurveyDataReload } from 'slices/appState';
import { GroovModalCloseButton } from 'common/components/Modal/GroovModalCloseButton';
import { GroovModal } from 'common/components/Modal/GroovModal';
import { GroovModalCard } from 'common/components/Modal/GroovModalCard';

export const SurveyModal: React.FC<{
  openModal: boolean;
  allSurveys: Array<Survey>;
  setOpenModal: (bool: boolean) => void;
}> = (props) => {
  const classes = surveyModalStyles(props);
  const { openModal, allSurveys, setOpenModal } = props;
  const userOrg = useUserOrg();
  const dispatch = useAppDispatch();
  const supportedSurveys = useAppSelector((state) => state.appState.orgSettings.supportedSurveys);
  const [surveys, setSurveys] = useState<Array<Survey>>(allSurveys);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [dataSendInitiated, setDataSendInitiated] = useState(false);
  const [recipients, setRecipients] = useState(0);
  const [contactListId, setContactListId] = useState('');
  const [surveyType, setSurveyType] = useState(SurveyTypes.MANAGE);
  const [surveyPages, setSurveyPages] = useState(MANGAE_SURVEY_PAGES);
  const [activePage, setActivePage] = useState<SurveyPage>(surveyPages[0]);
  const [sendDate, setSendDate] = useState(new Date());
  const [isSendDateReminderSelected, setIsSendDateReminderSelected] = useState(
    SURVEY_REMINDER_TOGGLE_DEFAULT
  );
  const addUserInputFile = React.useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const [menuItems, setMenuItems] = useState(MANGAE_SURVEY_MENU_ITEMS);

  const surveySelected = (surveyId: string, surveyArr?: Array<Survey>) => {
    const surveysCopy = surveyArr ? surveyArr : [...surveys];
    const updatedSurveys = surveysCopy.map((survey) => {
      let updatedSelectedVal = survey.selected;
      if (survey.id === surveyId) {
        updatedSelectedVal = !updatedSelectedVal;
      } else {
        updatedSelectedVal = false;
      }
      return {
        ...survey,
        selected: updatedSelectedVal,
      };
    });
    setSurveys(updatedSurveys);
  };

  const renderLoader = (message?: string) => (
    <Grid className={classes.loader}>
      <AnimatedSpinner height={30} width={30} />
      {message && <span className={classes.loaderMessage}>{message}</span>}
    </Grid>
  );

  useEffect(() => {
    if (surveys.length > 0) {
      setIsDataFetched(true);
    }
  }, [surveys]);

  const resetSurveys = () => {
    const surveysCopy = [...surveys];
    const resetSurveys = surveysCopy.map((survey) => ({ ...survey, selected: false }));
    setSurveys(resetSurveys);
    return resetSurveys;
  };

  const addMoreRecipients = useCallback((surveyId: string) => {
    surveySelected(surveyId, resetSurveys());
    submitAction();
  }, []);

  const openNewSurvey = useCallback(() => {
    resetSurveys();
    setSurveyType(SurveyTypes.NEW);
    setSurveyPages(NEW_SURVEY_PAGES);
    setActivePage(NEW_SURVEY_PAGES[0]);
  }, []);

  const getCloseDateString = (sendDate: Date) => {
    const closeDate = new Date(sendDate.toISOString());
    closeDate.setDate(closeDate.getDate() + 7);
    return closeDate.toISOString();
  };

  const getPageContent = () => {
    switch (activePage.component) {
      case SurveyComponents.SELECT_SURVEY:
        return <SelectSurvey surveys={surveys} onSurveySelection={surveySelected} />;
      case SurveyComponents.ADD_RECIPIENTS:
        const messageArr: Array<AddRecipientMessage> = [];
        if (surveyType === SurveyTypes.MANAGE) {
          const selectedSurveyName = surveys.find((survey) => survey.selected)?.name || '';
          messageArr.push({
            value:
              activePage.message?.substring(0, activePage.message?.indexOf('{surveyName}')) || '',
            type: AddRecipientMessageType.NORMAL,
          });
          messageArr.push({
            value: selectedSurveyName,
            type: AddRecipientMessageType.BOLD,
          });
          messageArr.push({
            value:
              activePage.message?.substring(activePage.message?.indexOf('{surveyName}') + 12) || '',
            type: AddRecipientMessageType.NORMAL,
          });
        } else {
          messageArr.push({
            value: activePage.message || '',
            type: AddRecipientMessageType.NORMAL,
          });
        }
        return <AddRecipients messageArr={messageArr} />;
      case SurveyComponents.REVIEW_AND_SEND:
        const message = activePage.message
          ?.replace('{recipients}', recipients.toString())
          .replace('{recipientLabel}', recipients > 1 ? 'recipients' : 'recipient')
          .replace('{selectedDate}', getFormattedDate(sendDate.toISOString()))
          .replace('{selectedTime}', getFormattedTime(sendDate.toISOString()))
          .replace('{calculatedDate}', getFormattedDate(getCloseDateString(sendDate)));
        const messageArray = message?.split('\n') || [];
        return <ReviewAndSend surveys={surveys} messageArr={messageArray} />;
      case SurveyComponents.SELECT_DATE:
        return (
          <SelectDate
            message={activePage.message || ''}
            selectedDate={sendDate}
            isReminderSelected={isSendDateReminderSelected}
            setSelectedDate={setSendDate}
            setReminderSelected={setIsSendDateReminderSelected}
          />
        );
      case SurveyComponents.SURVEY_SENT:
        return <SurveySent message={activePage.message} />;
      case SurveyComponents.CONFIRM_SEND:
        const selectedSurveyId = surveys.find((survey) => survey.selected)?.id;
        const supportedSurvey = supportedSurveys.find(
          (survey) => survey.survey_id === selectedSurveyId
        );
        return <ConfirmSend emailTemplate={supportedSurvey?.email_template} message="" />;
      default:
        return (
          <ManageSurveys
            surveys={surveys}
            messageArr={activePage.message?.split('\n') || []}
            menuItems={menuItems}
          />
        );
    }
  };

  const nextPage = () => {
    const activePageIndex = surveyPages.findIndex(
      (page) => page.pageNumber === activePage.pageNumber
    );
    setActivePage(surveyPages[activePageIndex + 1]);
  };

  const prevPage = () => {
    const activePageIndex = surveyPages.findIndex(
      (page) => page.pageNumber === activePage.pageNumber
    );
    const newActivePage = surveyPages[activePageIndex - 1];
    setActivePage(newActivePage);
    if (newActivePage.pageNumber === 0) {
      history.goBack();
    }
  };

  const submitAction = () => {
    switch (activePage.submitButton && activePage.submitButton.action) {
      case SurveyActions.CLOSE:
        history.goBack();
        break;
      case SurveyActions.NEXT:
        nextPage();
        break;
      case SurveyActions.UPLOAD:
        addUserInputFile.current?.click();
        break;
      case SurveyActions.SEND_NOW:
        sendNow();
        break;
    }
  };

  const onChangeFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setDataSendInitiated(true);
    const data = await uploadContactsCSV(file, userOrg);
    setDataSendInitiated(false);
    if (data.contact_list_id && data.contact_count > 0) {
      setRecipients(data.contact_count);
      setContactListId(data.contact_list_id);
      nextPage();
    }
  };

  const sendNow = async () => {
    setDataSendInitiated(true);
    const selectedSurveyId = surveys.find((survey) => survey.selected)?.id || '';
    const selectedSurveyCollectorId = surveys.find(
      (survey) => survey.selected && survey.status === 'open'
    )?.collector_id;
    const surveyWithTemplate = supportedSurveys.find(
      (survey) => survey.survey_id === selectedSurveyId
    );

    const status = await reviewAndSendData(
      selectedSurveyId,
      userOrg,
      contactListId,
      selectedSurveyCollectorId,
      sendDate.toISOString(),
      surveyWithTemplate?.email_subject || '',
      surveyWithTemplate?.email_template || '',
      isSendDateReminderSelected ? SURVEY_REMINDER_DAYS : []
    );
    setDataSendInitiated(false);
    if (status === 200) {
      dispatch(triggerSurveyDataReload());
      nextPage();
    }
  };

  const showCloseButton = () => {
    const activePageIndex = surveyPages.findIndex(
      (page) => page.pageNumber === activePage.pageNumber
    );
    return activePageIndex === 0 || activePageIndex === surveyPages.length - 1;
  };

  const renderSubmitButton = () => {
    return dataSendInitiated ? (
      renderLoader(
        activePage.submitButton?.action === SurveyActions.UPLOAD
          ? HubSurveyPrompts.label.loadingCSV
          : HubSurveyPrompts.label.sending
      )
    ) : (
      <Box className={classes.submit}>
        <Button
          className={classes.submitButton}
          onClick={submitAction}
          disabled={surveys.findIndex((survey) => survey.selected) === -1}
          data-testid={
            'hub_modal_survey_' + (activePage.buttonId ? activePage.buttonId : 'submit') + '_button'
          }>
          {activePage.submitButton?.action === SurveyActions.UPLOAD && (
            <input
              type="file"
              ref={addUserInputFile}
              style={{ display: 'none' }}
              accept=".csv"
              onChange={(e) => {
                onChangeFile(e);
                e.target.value = '';
              }}
            />
          )}
          {activePage.submitButton?.text}
        </Button>
      </Box>
    );
  };

  const menuItemClickHandler = useCallback((status: SurveyStatuses) => {
    const updatedMenuItems = [...menuItems].map((item) => {
      if (item.status === status) {
        return { ...item, isActive: true };
      } else {
        return { ...item, isActive: false };
      }
    });
    setMenuItems(updatedMenuItems);
  }, []);

  useEffect(() => {
    const hubManageRoute = HUB_MANAGE_ROUTES.find((route) => pathname.includes(route.path));
    const hubRoute = HUB_ROUTES.find((route) => pathname === route.path);
    if (hubRoute) {
      return;
    }
    if (!hubManageRoute) {
      const routablePath = HUB_MANAGE_ROUTES.find((route) => route.isDefault)?.path;
      routablePath && history.replace(routablePath);
      return;
    }
    if (pathname.includes('new')) {
      openNewSurvey();
    } else if (pathname.includes('manage')) {
      const routePath = HUB_MANAGE_ROUTES.find((route) => pathname.includes(route.path))?.path;
      const surveyId = routePath && pathname.substring(routePath.length + 1);
      surveyId && addMoreRecipients(surveyId);
    } else {
      const activeMenuItem = MANGAE_SURVEY_MENU_ITEMS.find((item) =>
        item.displayStatus.includes(pathname.substring(13))
      );
      if (activeMenuItem) {
        menuItemClickHandler(activeMenuItem.status);
      }
      setActivePage(surveyPages[0]);
    }
  }, [pathname, openNewSurvey, history, menuItemClickHandler, addMoreRecipients, surveyPages]);

  return (
    <GroovModal isModalShowing={openModal}>
      {showCloseButton() ? (
        <GroovModalCloseButton
          onPressed={() => {
            history.goBack();
            setOpenModal(false);
          }}
        />
      ) : (
        <img className={classes.modalIcon} onClick={prevPage} src={backBtn} alt="Back" />
      )}
      <GroovModalCard>
        <SurveyProgress
          active={activePage.pageNumber}
          total={surveyPages[surveyPages.length - 1].pageNumber - 1}
        />
        <Typography
          id="transition-modal-title"
          variant="h2"
          component="h2"
          className={classes.title}
          data-testid="hub_modal_survey_page_title">
          {activePage.title}
        </Typography>
        {isDataFetched ? getPageContent() : renderLoader()}
        {activePage.pageNumber > 0 && renderSubmitButton()}
      </GroovModalCard>
    </GroovModal>
  );
};
