import { useState } from 'react';
import { useAuthState } from 'common/hooks/useAuthState';
import { EmailWithStatus } from './types';
import { installTeamsAppByEmails } from './api';

interface Props {
  onError?: () => void;
}

export const useInstallTeams = ({ onError = () => {} }: Props) => {
  const authState = useAuthState();
  const [installedUsers, setInstalledUsers] = useState<EmailWithStatus[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const installTeams = async (emails?: string[]) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const result = await installTeamsAppByEmails(authState.orgId, emails);
      setInstalledUsers(result);
    } catch (error) {
      setInstalledUsers(undefined);
      setIsError(true);
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  const installTeamsForOrganisation = async () => {
    await installTeams();
  };

  const installTeamsForUsers = async (emails: string[]) => {
    await installTeams(emails);
  };

  const reset = () => {
    setIsLoading(false);
    setIsError(false);
    setInstalledUsers(undefined);
  };

  return {
    installedUsers,
    isLoading,
    isError,
    installTeamsForUsers,
    installTeamsForOrganisation,
    reset,
  };
};
