import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { formatNumber, getRandomColor } from 'pages/GroovVoice/GroovVoice.utils';

interface ResponseBarProps {
  label: string;
  percentage: number;
  responseCount: number;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    marginBottom: theme.spacing(8),
  },
  label: {
    flex: 1,
    fontWeight: 'bold',
    color: 'black',
  },
  barContainer: {
    flex: 2,
    backgroundColor: '#e0e0e0',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  bar: {
    borderRadius: theme.spacing(2),
    height: theme.spacing(4),
  },
  percentageText: {
    flex: 1,
    textAlign: 'right',
  },
}));

export const ResponseBar = ({ label, percentage, responseCount, index }: ResponseBarProps) => {
  const assignedColor = getRandomColor(index);
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.percentageText}>
          <span className={classes.label}> {formatNumber(percentage)}%</span> ({responseCount}{' '}
          response{responseCount !== 1 ? 's' : ''})
        </Typography>
      </Box>
      <Box className={classes.barContainer}>
        <Box
          className={classes.bar}
          aria-label={`${percentage}%`}
          role="progress"
          style={{
            backgroundColor: assignedColor,
            width: `${percentage}%`,
          }}
        />
      </Box>
    </Box>
  );
};
