import React from 'react';
import { Typography, Box } from '@material-ui/core';

import { HubSurveyPrompts } from 'prompts/prompts';
import { Link } from 'common';

import { AddRecipientMessage, AddRecipientMessageType } from '../../../../types';
import addRecipientsStyles from './styles';

export const AddRecipients: React.FC<{ messageArr: Array<AddRecipientMessage> }> = (props) => {
  const classes = addRecipientsStyles(props);
  const navigateTo = (url: string) => {
    window.open(url);
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.addRecipients}>
        {props.messageArr.map((message, index) => {
          const messageClasses = [classes.message];
          if (message.type === AddRecipientMessageType.BOLD) messageClasses.push(classes.bold);
          return (
            <Typography key={index} variant="h4" component="p" className={messageClasses.join(' ')}>
              {message.value}
            </Typography>
          );
        })}
        <Box>
          <Link>
            <Typography
              variant="h4"
              className={classes.downloadSampleCSV}
              onClick={() => navigateTo('/sample.csv')}
              data-testid="hub_modal_survey_download_csv_button">
              {HubSurveyPrompts.buttonText.downloadSampleCSV}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
