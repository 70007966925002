import React from 'react';
import { Grid } from '@material-ui/core';

interface Props {
  children: React.ReactNode;
}
export const ContainerLayout: React.FC<Props> = (props) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={10} lg={9}>
        {props.children}
      </Grid>
    </Grid>
  );
};
