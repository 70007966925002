import { Card, ContainerLayout } from 'common';
import React, { useEffect } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { ListFilters } from './ListPage/ListFilters';
import { getStore } from 'store';
import {
  fetchGroovVoicePrompts,
  getGroovVoiceFilters,
  resetPromptFilters,
} from '../GroovVoice.slice';
import PromptListPage from './ListPage/PromptListPage';
import { useDispatch } from 'react-redux';
import moment from 'moment';

export function GroovVoice() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createdFrom, createdTo } = getGroovVoiceFilters(getStore().getState());

  useEffect(() => {
    const groovVoiceSessionExists = sessionStorage.getItem('hasGroovVoiceSession');

    if (!groovVoiceSessionExists) {
      sessionStorage.setItem('hasGroovVoiceSession', 'true');
      dispatch(resetPromptFilters());
    }
  }, [dispatch]);

  useEffect(() => {
    const startTime = moment(createdFrom);
    const endTime = moment(createdTo);
    dispatch(fetchGroovVoicePrompts({ startTime, endTime }));
  }, [dispatch, createdFrom, createdTo]);

  return (
    <ContainerLayout>
      <Card className={classes.card}>
        <Box className={classes.root}>
          <Typography variant="h2" className={classes.header}>
            Groov Voice
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={10}>
            <PromptListPage />
            <Grid item xs={12} lg={4}>
              <ListFilters />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </ContainerLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
    height: '10vh',
  },
  header: {
    paddingBottom: theme.spacing(4),
  },
  card: {
    flex: 1,
    minHeight: '80vh',
  },
}));
