import { Box, Typography } from '@material-ui/core';
import React from 'react';
import TriangleUp from 'assets/icons/triangleUp';
import TriangleDown from 'assets/icons/triangleDown';
import glanceSectionStyles from '../styles';
import { getTypographyVariantFromFontWeight } from 'utils/typographyUtils';
import theme from 'theme';

enum TriangleType {
  Up,
  Down,
}

interface DeltaTriangleProps {
  triangleType: TriangleType;
  color?: string;
}

const DeltaTriangle: React.FC<DeltaTriangleProps> = (props) => {
  switch (props.triangleType) {
    case TriangleType.Up:
      return <TriangleUp color={props.color} />;
    case TriangleType.Down:
      return <TriangleDown color={props.color} />;
    default:
      return <TriangleDown color={props.color} />;
  }
};

const getTriangleType = (figure: number) => {
  if (figure < 0) {
    return TriangleType.Down;
  }

  return TriangleType.Up;
};

const getDeltaColor = (figure: number) => {
  if (figure < 0) {
    return theme.palette.warning.main;
  }

  return theme.palette.success.main;
};

interface GlanceCardSubsectionDeltaFigureRowProps {
  figure: number;
  figureText: string;
}

export const GlanceCardSubsectionDeltaFigureRow: React.FC<
  GlanceCardSubsectionDeltaFigureRowProps
> = (props) => {
  const { figure, figureText } = props;
  const classes = glanceSectionStyles();
  const color = getDeltaColor(figure);

  return (
    <Box className={classes.horizontalFlexBox} minWidth={'6.25rem'}>
      <Box className={classes.deltaBox}>
        <Box className={classes.deltaBox2}>
          <DeltaTriangle triangleType={getTriangleType(figure)} color={color} />
          <Typography
            variant={getTypographyVariantFromFontWeight(600)}
            className={classes.delta}
            data-testid={'rituals_delta_figure_label'}
            style={{ color: color }}>
            {Math.abs(figure)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.verticalFlexBox}>
        <Typography
          variant={getTypographyVariantFromFontWeight(400)}
          className={classes.deltaText}
          data-testid={'rituals_delta_text_label'}
          style={{ color: color }}>
          {figureText}
        </Typography>
      </Box>
    </Box>
  );
};
