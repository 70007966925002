import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const filterDropdownStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: '1.5rem',
    },
    select: {
      background: 'white',
      color: colors.blue,
      fontWeight: 200,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[400],
      borderRadius: '0.75rem',
      paddingLeft: '1.25rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
      '&:focus': {
        borderRadius: '0.75rem',
        background: 'white',
        borderColor: theme.palette.grey[400],
      },
    },
    survey: {
      minWidth: '15rem',
    },
    trending: {
      minWidth: '14rem',
    },
    modules: {
      minWidth: '15rem',
    },
    ritualTeams: {
      minWidth: '15rem',
    },
    icon: {
      color: colors.lightBlue,
      right: 12,
      position: 'absolute',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    paper: {
      borderRadius: '0.75rem',
      marginTop: '0.5rem',
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      background: 'white',
      '& li': {
        fontWeight: 200,
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    selectedText: {
      color: colors.blue,
      marginLeft: '0.25rem',
    },
  })
);

export default filterDropdownStyles;
