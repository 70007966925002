export const createPopupFeatures = (popupWidth?: number, popupHeight?: number) => {
  // eslint-disable-next-line no-restricted-globals
  const screenWidth = screen.width;
  // eslint-disable-next-line no-restricted-globals
  const screenHeight = screen.height;

  const width = popupWidth ?? 800;
  const height = popupHeight ?? 800;

  const left = screenWidth / 2 - width / 2;
  const top = screenHeight / 2 - height / 2;

  return `popup=true, modal=yes, alwaysRaised=yes, resizable=yes, width=${width}, height=${height}, left=${left}, top=${top}`;
};
