import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ToasterUtils } from 'common';
import { toasterPrompts } from 'prompts/prompts';
import store from 'store';
import sessionTimeoutSubject from './sessionTimeoutSubject';
const BASE_URL = `${process.env.REACT_APP_SERVICE_URL}/subscription`;
const EMBED_URL = process.env.REACT_APP_REPORT_AUTH_URL;

const getToken = () => {
  return store.getState()?.auth?.accessToken;
};

const handleError = (error: any) => {
  if (error.response?.status === 401 && error.response?.data.message === 'Unauthorized') {
    sessionTimeoutSubject.next(1);
  } else if (error.response) {
    if (error.response?.status === 404) return; // swallow 404 errors
    error.response.data.error_description === 'Authentication Failed'
      ? ToasterUtils.error(toasterPrompts.messages.login.invalidCredentials)
      : ToasterUtils.error(error.response.data.message);
  } else {
    ToasterUtils.error(error.message);
  }
  return false;
};

export const ApiRequest = async (config: AxiosRequestConfig): Promise<any> => {
  const token = getToken();

  if (token && config?.headers?.Authorization === undefined) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }

  const newConfig = {
    baseURL: BASE_URL,
    ...config,
  };
  return axios(newConfig)
    .then((res) => {
      return res;
    })
    .catch(handleError);
};

export const EmbedTokenRequest = async (
  config: AxiosRequestConfig,
  reportId?: string
): Promise<any> => {
  const url = reportId ? EMBED_URL + '/' + reportId : EMBED_URL;
  return ApiRequest({ ...config, baseURL: url });
};
