import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { HubRitualPrompts } from 'prompts/prompts';

import glanceSectionStyles from './styles';
import { GlanceCard } from './GlanceCard/GlanceCard';
import { GlanceSectionConstants } from './constants';
import { AtAGlanceSection } from '../../../../types';
import NoDataSection, { NoDataSectionTheme } from '../../../NoDataSection';

export interface GlanceSectionProps {
  glanceSection: AtAGlanceSection;
  onViewMoreDetailsPress?: () => void;
}

const createGlanceCardBodyProps = (glanceSectionProps: GlanceSectionProps) => {
  const {
    valuableMainFigure,
    valuableDeltaFigure,
    valuableDeltaText,
    oftenMainFigure,
    oftenDeltaFigure,
    oftenDeltaText,
    responseRateMainFigure,
    responseRateDeltaFigure,
    responseRateDeltaText,
  } = glanceSectionProps.glanceSection;
  return [
    {
      title: GlanceSectionConstants.body.sectionOne.title,
      mainFigure: valuableMainFigure,
      mainFigurePrimaryText: GlanceSectionConstants.body.sectionOne.mainFigurePrimaryText,
      mainFigureSecondaryText: GlanceSectionConstants.body.sectionOne.mainFigureSecondaryText,
      deltaFigure: valuableDeltaFigure,
      deltaText: valuableDeltaText,
    },
    {
      title: GlanceSectionConstants.body.sectionTwo.title,
      mainFigure: oftenMainFigure,
      mainFigurePrimaryText: GlanceSectionConstants.body.sectionTwo.mainFigurePrimaryText,
      mainFigureSecondaryText: GlanceSectionConstants.body.sectionTwo.mainFigureSecondaryText,
      deltaFigure: oftenDeltaFigure,
      deltaText: oftenDeltaText,
    },
    {
      title: GlanceSectionConstants.body.sectionThree.title,
      mainFigure: responseRateMainFigure,
      mainFigurePrimaryText: GlanceSectionConstants.body.sectionThree.mainFigurePrimaryText,
      deltaFigure: responseRateDeltaFigure,
      deltaText: responseRateDeltaText,
    },
  ];
};

export const GlanceSection: React.FC<GlanceSectionProps> = (props) => {
  const classes = glanceSectionStyles();

  return (
    <Box pt={'2rem'} pb={'1rem'}>
      <Typography
        variant="h3"
        className={classes.atAGlanceTitle}
        data-testid={'rituals_glance_label'}>
        {GlanceSectionConstants.sectionTitle}
      </Typography>
      {props.glanceSection.responseRateMainFigure ? (
        <>
          <GlanceCard
            glanceCardBodyProps={createGlanceCardBodyProps(props)}
            onViewMoreDetailsPressed={props.onViewMoreDetailsPress}
          />
          <Typography
            variant="body1"
            className={classes.disclaimer}
            data-testid={'rituals_glance_disclaimer_label'}>
            {GlanceSectionConstants.disclaimer}
          </Typography>
        </>
      ) : (
        <NoDataSection
          theme={NoDataSectionTheme.DARK}
          title={HubRitualPrompts.title.noDataCheckin}
        />
      )}
    </Box>
  );
};
