import React from 'react';
import { TableRow } from 'common/components/Table/TableRow';
import { eapTableStyles } from '../styles';
import { EapTableRowData } from './EapTable';
import { EapTableBodyCell } from './EapTableBodyCell';
import { EapTableMenuCell } from './EapTableEllipsisCell';

export const EapTableRow = (props: {
  data: EapTableRowData;
  onEllipsisEditClick: (eapId: string) => void;
  onEllipsisDeleteClick: (eapId: string) => void;
}) => {
  const { onEllipsisEditClick, onEllipsisDeleteClick } = props;
  const { eapId, name, url, phone } = props.data;
  const { tableBodyCell, tableBodyCellName, tableBodyRow } = eapTableStyles();
  return (
    <TableRow className={tableBodyRow}>
      <EapTableBodyCell text={name} typographyClassName={tableBodyCellName} />
      <EapTableBodyCell text={url} typographyClassName={tableBodyCell} />
      <EapTableBodyCell text={phone} typographyClassName={tableBodyCell} />
      <EapTableMenuCell
        eapId={eapId}
        onEllipsisEditClick={onEllipsisEditClick}
        onEllipsisDeleteClick={onEllipsisDeleteClick}
      />
    </TableRow>
  );
};
