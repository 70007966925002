import { AxiosRequestConfig } from 'axios';
import { ApiRequest } from 'services/request';
import {
  EapDetailsRequestDto,
  EapDetailsResponseDto,
  GetAllEapDetailsForOrgResponseDto,
} from './types';

const EAP_ENDPOINT = 'eapDetails';

export const getEapProviders = async (): Promise<GetAllEapDetailsForOrgResponseDto> => {
  const config: AxiosRequestConfig = {
    url: EAP_ENDPOINT,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result?.data;
};

export const createEapProvider = async (
  details: EapDetailsRequestDto
): Promise<EapDetailsResponseDto> => {
  const config: AxiosRequestConfig = {
    url: EAP_ENDPOINT,
    method: 'POST',
    data: { ...details },
  };
  const result = await ApiRequest(config);
  return result?.data;
};

export const editEapProvider = async (
  eap_id: string,
  details: EapDetailsRequestDto
): Promise<EapDetailsResponseDto> => {
  const config: AxiosRequestConfig = {
    url: `${EAP_ENDPOINT}/${eap_id}`,
    method: 'PATCH',
    data: { ...details },
  };
  const result = await ApiRequest(config);
  return result?.data;
};

export const deleteEapProvider = async (eap_id: string): Promise<boolean> => {
  const config: AxiosRequestConfig = {
    url: `${EAP_ENDPOINT}/${eap_id}`,
    method: 'DELETE',
  };
  const result = await ApiRequest(config);
  return true;
};
