import { completeLogoutAction } from 'auth/auth.actions';
import React from 'react';
import { useEffect } from 'react';
import { dispatch } from 'store';
import { RoutePath } from 'types/routes';
import history from 'utils/history';

export function LogoutCallback() {
  useEffect(() => {
    (async () => {
      await dispatch(completeLogoutAction());
      history.push(RoutePath.Login);
    })();
  }, []);

  return <div></div>;
}
