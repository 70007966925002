import React from 'react';
import { TableBody } from 'common/components/Table/TableBody';
import { eapTableStyles } from '../styles';
import { EapTableRowData } from './EapTable';
import { EapTableRow } from './EapTableRow';

interface EapTableBodyProps {
  data: EapTableRowData[];
  onEllipsisEditClick: (eapId: string) => void;
  onEllipsisDeleteClick: (eapId: string) => void;
}

export const EapTableBody = (props: EapTableBodyProps) => {
  const { data, onEllipsisEditClick, onEllipsisDeleteClick } = props;
  const { tableBody } = eapTableStyles();
  return (
    <TableBody className={tableBody}>
      {data.map((data) => (
        <EapTableRow
          key={`${data.eapId}_eap_row`}
          data={data}
          onEllipsisEditClick={onEllipsisEditClick}
          onEllipsisDeleteClick={onEllipsisDeleteClick}
        />
      ))}
    </TableBody>
  );
};
