import React from 'react';
import loadingSpinner from 'assets/animations/loading-spinner.json';
import { Grid } from '@material-ui/core';
import Lottie from 'lottie-react';

interface SpinnerProps {
  width?: number;
  height?: number;
}

export const AnimatedSpinner: React.FC<SpinnerProps> = ({ width, height }: SpinnerProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingSpinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Grid style={{ width, height }}>
      <Lottie {...defaultOptions} width={width} height={height} />
    </Grid>
  );
};

AnimatedSpinner.defaultProps = {
  width: 48,
  height: 48,
};
