import * as React from 'react';
import { colors } from 'styles/colors';

type TriangleUpProps = {
  width?: number;
  height?: number;
  color?: string;
};

const TriangleUp = (props: TriangleUpProps) => {
  const { width = 12, height = 12, color = colors.positiveGreen } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      color={color}>
      <path d="M15 14H5l5-9 5 9z" stroke={color} fill={color} />
    </svg>
  );
};

export default TriangleUp;
