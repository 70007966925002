const CreateAdminAccountPrompts = {
  ERRORS: {
    RULES:
      'Your password must be at least 6 characters long and a mix of numbers and letters in upper and lowercase.',
    MATCH: 'Those passwords didn’t match. Try again.',
    CONFIRM: 'Confirm your password.',
    API: 'The link is invalid or has expired. Please check and try again.',
  },
  PLACEHOLDERS: {
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
  },
  TEXT: {
    PAGE_TITLE: 'Set Administrator Password',
    SUBMIT_BUTTON: 'Submit Password',
    TOAST_FAILURE: 'The link is invalid or has expired. Please check and try again.',
    TOAST_SUCCESS: 'Password set successfully! Redirecting you to login.',
  },
};

export const ADMIN_CREATION_ENDPOINT = 'api/admin/password';

export default CreateAdminAccountPrompts;
