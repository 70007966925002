import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

type SettingsIconProps = {
  color?: string;
};
export const SettingsIcon = ({ color = '#444752' }: SettingsIconProps) => {
  return (
    <IconButton aria-label="delete" style={{ padding: 0 }}>
      <SvgIcon>
        <svg width={22} height={22} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.209 4.38A8.948 8.948 0 0110 3.223V2a1 1 0 011.01-1h1.98c.558 0 1.01.444 1.01 1v1.223a8.948 8.948 0 012.791 1.157l.866-.865a1 1 0 011.42.006l1.402 1.401c.394.394.4 1.028.006 1.421l-.865.866c.533.845.929 1.786 1.157 2.791H22a1 1 0 011 1.01v1.98c0 .558-.444 1.01-1 1.01h-1.223a8.948 8.948 0 01-1.157 2.791l.865.866a1 1 0 01-.006 1.42l-1.401 1.402c-.394.394-1.028.4-1.421.006l-.866-.865A8.948 8.948 0 0114 20.777V22a1 1 0 01-1.01 1h-1.98c-.558 0-1.01-.444-1.01-1v-1.223a8.948 8.948 0 01-2.791-1.157l-.866.865a1 1 0 01-1.42-.006L3.52 19.078a1.002 1.002 0 01-.006-1.421l.865-.866A8.948 8.948 0 013.223 14H2a1 1 0 01-1-1.01v-1.98c0-.558.444-1.01 1-1.01h1.223A8.948 8.948 0 014.38 7.209l-.865-.866a1 1 0 01.006-1.42L4.922 3.52a1.002 1.002 0 011.421-.006l.866.865zM12 16a4 4 0 100-8 4 4 0 000 8z"
            stroke={color}
            strokeWidth={2}
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      </SvgIcon>
    </IconButton>
  );
};
