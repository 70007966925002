import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const UserAltIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon>
      <svg
        width="22px"
        height="19px"
        viewBox="0 0 22 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>DB67C038-312F-4809-8C7C-4A6364AF9835@1.00x</title>
        <g id="Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin---Mobile-menu---closed" transform="translate(-49.000000, -143.000000)">
            <g id="List-item-/-Add-remove" transform="translate(0.000000, 128.000000)">
              <g id="icons-/-ic-user-alt" transform="translate(48.000000, 12.000000)">
                <rect id="container" x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M16.5511355,14.8181818 C19.1292528,14.8181818 20.8066661,15.9008205 21.6084404,17.6800708 C21.9848268,18.5153253 22.116548,19.3602389 22.108201,20.099527 L22.0917491,20.4619087 C22.0374748,21.0115201 21.5479287,21.4130702 20.9983172,21.3587959 C20.4879638,21.3083984 20.1052754,20.8826899 20.0966645,20.3820818 L20.1032249,20.2351448 C20.1300986,19.7323323 20.0509625,19.0919025 19.7850239,18.5017474 C19.3293956,17.4906438 18.4337155,16.8784082 16.8015249,16.8223928 L16.5511355,16.8181818 L7.45454545,16.8181818 C5.66016323,16.8181818 4.69629972,17.4400292 4.21873969,18.5012738 C3.98678244,19.0167343 3.89757316,19.5708243 3.89715045,20.0383131 L3.90400534,20.2602204 C3.95908249,20.8136765 3.55813849,21.3037192 3.00859463,21.3586736 C2.4983039,21.4097026 2.03931778,21.0676356 1.9323523,20.578513 L1.91043809,20.4217453 C1.90932831,20.4064994 1.90794403,20.3847239 1.90517547,20.3411731 C1.86215905,19.5470239 1.97766818,18.6077188 2.39489668,17.6805444 C3.16608465,15.9667934 4.75082138,14.8993377 7.1715226,14.8226178 L7.45454545,14.8181818 L16.5511355,14.8181818 Z M12,3 C15.06267,3 17.5454545,5.48278457 17.5454545,8.54545455 C17.5454545,11.6081245 15.06267,14.0909091 12,14.0909091 C8.93733002,14.0909091 6.45454545,11.6081245 6.45454545,8.54545455 C6.45454545,5.48278457 8.93733002,3 12,3 Z M12,5 C10.0418995,5 8.45454545,6.58735407 8.45454545,8.54545455 C8.45454545,10.503555 10.0418995,12.0909091 12,12.0909091 C13.9581005,12.0909091 15.5454545,10.503555 15.5454545,8.54545455 C15.5454545,6.58735407 13.9581005,5 12,5 Z"
                  id="fill-color"
                  fill={props.color ? props.color : '#22262E'}></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  </IconButton>
);
