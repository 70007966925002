import { Menu } from 'common/components/Menu/Menu';
import { MenuItem } from 'common/components/Menu/MenuItem';
import React from 'react';

interface EapEditMenuProps {
  isOpen: boolean;
  eapId: string;
  anchor?: Element | null;
  handleEditClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleDeleteClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
}

interface EapEditMenuItemProps {
  text: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const EapEditMenu = (props: EapEditMenuProps) => {
  const { anchor, handleEditClick, handleDeleteClick, handleClose } = props;

  const eapEditOptions: EapEditMenuItemProps[] = [
    { text: 'Edit', onClick: handleEditClick },
    { text: 'Delete', onClick: handleDeleteClick },
  ];

  return (
    <Menu
      id="eap-edit-menu"
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 200,
          width: '20ch',
        },
      }}>
      {eapEditOptions.map((option) => (
        <MenuItem key={option.text + '_' + props.eapId} onClick={option.onClick}>
          {option.text}
        </MenuItem>
      ))}
    </Menu>
  );
};
