import { AxiosRequestConfig } from 'axios';
import { BasicRequest } from '../../services/apiRequest';
import { SettingsType, UserRole } from './types';
import { ApiRequest } from 'services/request';
import { dispatch } from 'store';
import { getManagers, setUsers } from './state';

const BASE_URL = `${process.env.REACT_APP_SERVICE_URL}`;

export const getSettings = async (): Promise<SettingsType> => {
  const config: AxiosRequestConfig = {
    url: '/',
    method: 'GET',
  };

  const res = await BasicRequest(config);
  return { gracePeriod: res.data.grace_period_weeks, autoEmail: res.data.auto_invitation_email };
};

export const getAndRefreshUsers = async () => {
  const config: AxiosRequestConfig = {
    url: '/users/roles',
    method: 'GET',
    baseURL: `${BASE_URL}/workplace-integration`,
  };
  const res = await ApiRequest(config);
  dispatch(getManagers());
  dispatch(setUsers({ records: res.data.userRoles }));
};

export const getSelectableManagers = async () => {
  const config: AxiosRequestConfig = {
    url: '/users/managers',
    method: 'GET',
    baseURL: `${BASE_URL}/workplace-integration`,
  };
  const res = await ApiRequest(config);
  return res.data.managers;
};

export const updateUsersWithRole = async (records: UserRole[], roleId: string) => {
  const userRolesToAdd = records.map((rec) => {
    return {
      userId: rec.userId,
      roleId,
    };
  });
  const config: AxiosRequestConfig = {
    url: `userroles/bulkcreate`,
    method: 'POST',
    baseURL: `${BASE_URL}/workplace-integration`,
    data: {
      userRolesToAdd,
    },
  };
  await ApiRequest(config);
  await getAndRefreshUsers();
};

export const removeRoleFromUser = async (userId: string, roleId: string) => {
  const config: AxiosRequestConfig = {
    url: `users/${userId}/roles/${roleId}`,
    method: 'DELETE',
    baseURL: `${BASE_URL}/workplace-integration`,
  };
  await ApiRequest(config);
  await getAndRefreshUsers();
};

export const removeUser = async (userId: string) => {
  const config: AxiosRequestConfig = {
    url: `users/${userId}`,
    method: 'DELETE',
    baseURL: `${BASE_URL}/workplace-integration`,
  };
  await ApiRequest(config);
  await getAndRefreshUsers();
};
