import TurndownService from 'turndown';

const turndownService = new TurndownService({
  headingStyle: 'setext',
  bulletListMarker: '*',
  emDelimiter: '_',
  strongDelimiter: '**',
  linkStyle: 'inlined',
  linkReferenceStyle: 'full',
});

const strikeThroughDelimiter = '~~';

turndownService.addRule('strikethrough', {
  filter: ['del', 's'],
  replacement: (content) => `${strikeThroughDelimiter}${content}${strikeThroughDelimiter}`,
});

export const htmlToMarkdown = (html: string) => {
  return turndownService.turndown(html);
};
