import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import helpIcon from '../../assets/icons/ic-help.svg';
import checkmarkIcon from '../../assets/icons/ic-checkmark.svg';
import errorIcon from '../../assets/icons/ic-lightning.svg';
import { VariantType } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
  feedback: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(1),
  },
}));

interface Props {
  type: VariantType | 'confirm';
}

export const FeedBackIcon = (props: Props) => {
  const classes = useStyles();

  const iconStyle = (type: VariantType | 'confirm') => {
    switch (type) {
      case 'success':
        return { image: checkmarkIcon, backgroundColor: '#40B766' };
      case 'error':
        return { image: errorIcon, backgroundColor: '#ff564f' };
      case 'warning':
        return { image: helpIcon, backgroundColor: '#006AFF' };
      case 'confirm':
        return { image: errorIcon, backgroundColor: '#ff564f' };
      case 'info':
        return { image: helpIcon, backgroundColor: '#006AFF' };
      default:
        return {};
    }
  };

  const { image, backgroundColor } = iconStyle(props.type);
  return (
    <div className={classes.feedback} style={{ backgroundColor }}>
      <img src={image} alt="messageIcon" />
    </div>
  );
};
