import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Card } from 'common';
import { colors } from 'styles/colors';
import CheckIcon from 'assets/icons/CheckIcon';
import { IntegrationType, Integration } from './Common/types';

const useStyles = makeStyles<Theme, IntegrationProps>(() => ({
  container: {
    padding: '12px 12px 12px 12px',
  },
  iconContainer: {
    marginRight: '8px',
    height: '24px',
  },
  actionContainer: {
    borderColor: colors.mysticGrey,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '8px',
    paddingTop: '12px',
    height: '50px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: '140px',
    padding: '8px 8px',
    whiteSpace: 'nowrap',
    fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
    fontWeight: 400,
  },
  title: {
    marginTop: '2px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    marginRight: '8px',
  },
  description: {
    marginTop: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5,
    height: '124px',
  },
  label: {
    borderRadius: 32,
    width: '60px',
    padding: '0px 4px',
    backgroundColor: (props) => getIntegrationColor(props.type),
  },
  hasIntegrationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hasIntegrationText: {
    marginRight: '12px',
    marginTop: '2px',
  },
}));

export interface IntegrationCardProps {
  hasIntegration: boolean;
}

export interface IntegrationProps extends IntegrationCardProps {
  showEnabled: boolean;
  type: IntegrationType;
  integration: Integration;
  title: string;
  description: string;
  icon: React.JSX.Element;
  buttonText: string;
  onClick: () => void;
}

export const IntegrationCard: React.FC<IntegrationProps> = (props) => {
  const { title, description, icon, buttonText, onClick, type, hasIntegration, showEnabled } =
    props;
  const classes = useStyles(props);

  return (
    <Card className={classes.container}>
      <Box>
        <Box className={classes.header}>
          <Box className={classes.iconContainer}>{icon}</Box>
          <Typography
            variant="h3"
            data-testid={'integration_' + title + '_title'}
            className={classes.title}>
            {title}
          </Typography>
          {showEnabled ? <CheckIcon color="green" width={20} height={20} /> : null}
          <Box flex={1} />
          <Box className={classes.label} style={{ backgroundColor: getIntegrationColor(type) }}>
            <Typography
              variant="subtitle1"
              component="p"
              align="center"
              style={{ fontSize: '10px' }}>
              {type}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="subtitle1"
          component="p"
          className={classes.description}
          data-testid={'integration_' + title + '_description'}>
          {description}
        </Typography>
      </Box>
      <Box className={classes.actionContainer} borderTop={1}>
        {hasIntegration ? (
          <Box className={classes.hasIntegrationContainer}>
            <Typography
              variant="subtitle1"
              component="p"
              align="center"
              className={classes.hasIntegrationText}
              style={{ fontSize: '14px' }}>
              {'Enabled'}
            </Typography>
            <CheckIcon color="green" />
          </Box>
        ) : (
          <Button className={classes.button} onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Card>
  );
};

const getIntegrationColor = (type: IntegrationType) => {
  switch (type) {
    case IntegrationType.Channel:
      return colors.lightBlue;

    case IntegrationType.Connector:
      return colors.paleRed;

    default:
      return 'pink';
  }
};
