import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Modal,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { UserTable } from './UserTable';
import { UserRole } from '../../types';
import { SearchBar } from 'common';

export type AddUsersPopupProps = {
  onClose: () => void;
  open: boolean;
  onAddUsers?: (values: UserRole[]) => Promise<void>;
  records: UserRole[];
};

const searchKeys: (keyof UserRole)[] = ['email', 'name'];

export function AddUsersPopup({ open, onClose, onAddUsers, records }: AddUsersPopupProps) {
  const classes = useStyles();
  const [searchResults, setSearchResults] = useState<UserRole[]>([]);
  const [selectedUsers, setSelectedUser] = useState<UserRole[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleRemove = (id: string) => {
    setSelectedUser(selectedUsers.filter((r) => r.userId !== id));
  };

  const handleAddUsers = async () => {
    await onAddUsers?.(selectedUsers);
    setSelectedUser([]);
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description">
      <Box className={classes.modalBox}>
        {searchResults.length > 0 && isFocused && (
          <Paper className={classes.searchPopup}>
            <List className={classes.listBox}>
              {searchResults
                .filter((row) => selectedUsers.findIndex((r) => r.userId === row.userId) === -1)
                .map((rec) => (
                  <ListItem
                    key={rec.userId as string}
                    button
                    onClick={() => {
                      setSelectedUser((s) => [...s, rec]);
                      setIsFocused(false);
                    }}
                    className={classes.listItem}>
                    <ListItemText
                      primary={rec.name}
                      secondary={rec.email}
                      primaryTypographyProps={{ variant: 'body1' }}
                      secondaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
                    />
                  </ListItem>
                ))}
            </List>
          </Paper>
        )}
        <Box>
          <Box className={classes.header}>
            <Typography variant="h3">Add users to group</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <SearchBar
            records={records ?? []}
            searchKeys={searchKeys}
            setResults={(recs) => setSearchResults(recs)}
            onFocus={() => setIsFocused(true)}
            fullWidth
          />

          <UserTable rows={selectedUsers} maxHeight={300} onRemove={handleRemove} />
        </Box>
        <Box className={classes.buttonBox}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.addButton}
            onClick={handleAddUsers}
            disabled={selectedUsers.length === 0}>
            Add user
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    maxWidth: 600,
    maxHeight: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchPopup: {
    position: 'absolute',
    top: '26%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1300,
    width: '100%',
    maxWidth: 500,
    maxHeight: 300,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  listBox: {
    maxHeight: 300,
    overflowY: 'auto',
    height: 'auto',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  listItem: {
    padding: theme.spacing(2, 4),
  },
  tableWrapper: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    textTransform: 'none',
    padding: theme.spacing(4, 12),
    borderRadius: theme.shape.borderRadius * 3,
  },
}));
