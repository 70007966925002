import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getEndOfCurrentDay,
  getRolling30Days,
  getGroovVoicePrompts,
  getAndSetCurrentSelectedPromptDetails,
} from './GroovVoice.utils';
import { PromptDetails, PromptItemType } from './GroovVoice.d';
import { RootState } from 'store/rootReducer';
import axios from 'axios';
import { Moment } from 'moment';
import { dispatch } from 'store';
import { CommonSelectorOptionType } from 'common';

export interface GroovVoiceState {
  prompts: PromptItemType[];
  filteredPrompts: PromptItemType[];
  searchFilteredPrompts: PromptItemType[];
  loading: boolean;
  error: string | null;
  searchQuery: string;
  filters: {
    createdFrom: string;
    createdTo: string;
    promptType: CommonSelectorOptionType[];
    states: string[];
    types: string[];
    tags: CommonSelectorOptionType[];
    category: CommonSelectorOptionType[];
  };
  detailScreenFilters: DetailScreenFilters;
  currentSelectedPromptDetails?: PromptDetails;
  isApplyingFilters?: boolean;
}

export type DetailScreenFilters = {
  manager: CommonSelectorOptionType[];
  officeLocation: CommonSelectorOptionType[];
  department: CommonSelectorOptionType[];
  jobTitle: CommonSelectorOptionType[];
};

export const initialFilterState = {
  states: ['Active'],
  types: [],
  createdFrom: getRolling30Days().format('YYYY-MM-DDTHH:mm:ss'),
  createdTo: getEndOfCurrentDay().format('YYYY-MM-DDTHH:mm:ss'),
  promptType: [],
  category: [],
  tags: [],
};

export const initialDetailsScreenFilterState = {
  manager: [],
  officeLocation: [],
  department: [],
  jobTitle: [],
};
const initialState: GroovVoiceState = {
  prompts: [],
  filteredPrompts: [],
  searchFilteredPrompts: [],
  loading: false,
  error: null,
  searchQuery: '',
  filters: initialFilterState,
  detailScreenFilters: initialDetailsScreenFilterState,
  isApplyingFilters: false,
};

export const fetchGroovVoicePrompts = createAsyncThunk(
  'groovVoice/fetchPrompts',
  async ({ startTime, endTime }: { startTime?: Moment; endTime?: Moment }, { rejectWithValue }) => {
    try {
      const response = await getGroovVoicePrompts(startTime, endTime);
      return response.data;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response ? err.response.data : err.message);
      } else if (err instanceof Error) {
        console.error(`General error: ${err.name} - ${err.message}`);
      } else {
        console.error(`An unknown error occurred: ${JSON.stringify(err)}`);
      }
    }
  }
);

export const applyDetailsScreenFilters = createAsyncThunk(
  'groovVoice/applyDetailsScreenFilters',
  async (filters: DetailScreenFilters, { getState, rejectWithValue }) => {
    try {
      dispatch(setIsApplyingFilters(true));
      const rootState = getState() as RootState;

      const state = rootState?.groovVoice;
      dispatch(setDetailsScreenFilters(filters));
      await getAndSetCurrentSelectedPromptDetails(
        state.currentSelectedPromptDetails?.prompt.id as unknown as string,
        filters
      );
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response ? err.response.data : err.message);
      } else if (err instanceof Error) {
        console.error(`General error: ${err.name} - ${err.message}`);
      } else {
        console.error(`An unknown error occurred: ${JSON.stringify(err)}`);
      }
    }
  }
);

const applyFilters = (prompts: PromptItemType[], filters: GroovVoiceState['filters']) => {
  return prompts?.filter((prompt) => {
    const matchesType =
      filters.types.length === 0 || filters.types.includes(prompt.properties?.prompt_cta_type);

    const matchesPromptCategory =
      filters.category.length === 0 ||
      filters.category.filter((category) => prompt.categoryId.toString() === category.id.toString())
        .length > 0;

    const matchesTags =
      filters.tags.length === 0 ||
      prompt.tags?.some((ptag) => filters.tags.some((tag) => ptag === tag.id));

    return matchesType && matchesTags && matchesPromptCategory;
  });
};

const slice = createSlice({
  name: 'groovVoice',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const newFilters = { ...state.filters, ...action.payload };
      state.filters = newFilters;
      state.filteredPrompts = applyFilters(state.prompts, newFilters);
    },
    setDetailsScreenFilters: (state, action) => {
      const newFilters = { ...state.detailScreenFilters, ...action.payload };
      state.detailScreenFilters = newFilters;
    },
    resetDetailsScreenFilters: (state) => {
      state.detailScreenFilters = initialDetailsScreenFilterState;
    },
    setIsApplyingFilters: (state, action) => {
      state.isApplyingFilters = action.payload;
    },
    toggleState: (state, action) => {
      const stateId = action.payload;
      if (state.filters.states.includes(stateId)) {
        state.filters.states = state.filters.states.filter((id) => id !== stateId);
      } else {
        state.filters.states.push(stateId);
      }
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    toggleType: (state, action) => {
      const typeId = action.payload;
      if (typeId && state.filters.types.includes(typeId)) {
        state.filters.types = state.filters.types.filter((id) => id !== typeId);
      } else {
        state.filters.types.push(typeId);
      }
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    toggleCategory: (state, action) => {
      const typeId = action.payload;
      if (state.filters.types.includes(typeId)) {
        state.filters.types = state.filters.types.filter((id) => id !== typeId);
      } else {
        state.filters.types.push(typeId);
      }
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    setSearchFilteredPrompts: (state, action) => {
      state.searchFilteredPrompts = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setCurrentSelectedPromptDetails: (state, action) => {
      state.currentSelectedPromptDetails = action.payload;
      state.isApplyingFilters = false;
    },
    resetPromptFilters: (state) => {
      state.filters = initialFilterState;
      state.filteredPrompts = applyFilters(state.prompts, state.filters);
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroovVoicePrompts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGroovVoicePrompts.fulfilled, (state, action) => {
        state.loading = false;
        state.prompts = action.payload;
        state.filteredPrompts = applyFilters(action.payload, state.filters);
      })
      .addCase(fetchGroovVoicePrompts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const getGroovVoiceFilters = (state: RootState) => {
  return state?.groovVoice.filters;
};
export const { reducer: groovVoiceReducer, actions } = slice;
export const {
  setFilters,
  toggleState,
  toggleType,
  setSearchFilteredPrompts,
  setSearchQuery,
  setDetailsScreenFilters,
  resetDetailsScreenFilters,
  setCurrentSelectedPromptDetails,
  resetPromptFilters,
  setIsApplyingFilters,
} = actions;

export default slice;
