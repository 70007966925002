import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const scoreCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '0.75rem',
      height: '100%',
      width: '100%',
      boxShadow: '2px 7px 12px 0px rgba(64, 63, 63, 0.41)',
      transition: 'transform 0.1s ease-in-out',
      '&:hover': {
        transform: 'scale3d(1.03, 1.03, 1)',
      },
      borderStyle: 'solid',
      borderWidth: theme.spacing(0.25),
      borderColor: theme.palette.primary.main,
    },

    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },

    content: {
      justifyItems: 'center',
      textAlign: 'center',
      height: '9.25rem',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      paddingBottom: '1rem',
      paddingRight: '1rem',
      paddingTop: '1.4rem',
      paddingLeft: '1rem',
    },

    progressRoot: {
      fontSize: '0.75rem',
      margin: '0.5rem 1rem 0.5rem 1rem',
    },

    noDataContainer: {
      fontSize: '1rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      marginTop: '-0.5rem',
      fontFamily: 'Averta,Helvetica,Verdana,sans-serif',
    },

    percent: {
      fontSize: '1rem',
      fontFamily: 'Averta-ExtraBold,Helvetica,Verdana,sans-serif',
      position: 'absolute',
    },
    scoreRoot: {
      fontFamily: 'Averta-ExtraBold,Helvetica,Verdana,sans-serif',
      fontSize: '2.25rem',
      fontWeight: 800,
      marginRight: '0.75rem',
    },
    progressStat: {
      margin: '0px',
      fontFamily: 'Averta-SemiBold,Helvetica,Verdana,sans-serif',
      fontSize: '1.25rem',
      fontWeight: 300,
    },
    container: {
      minHeight: '14.75rem',
    },
    percentBox: {
      minHeight: '3.25rem',
    },
  })
);

export default scoreCardStyles;
