import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Card, Box, Container, Typography } from '@material-ui/core';
import { RitualDetailsSection } from '@groov/ui';
import { useLocation, useHistory } from 'react-router-dom';
import { useUserOrg } from 'utils/UseUserOrg';
import { useEmbedToken } from 'utils/UseEmbedToken';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import Analytics from 'utils/AnalyticsHelper';
import { HubBetaPrompts, HubCardPrompts, HubRitualPrompts } from 'prompts/prompts';
import { UndoIcon } from 'assets/icons/undo';
import { colors } from 'styles/colors';

import { StatsCardContainer } from './components/ScoreCard/StatsCardContainer';
import PowerBIReportsEmbed from './components/PowerBIReportsEmbed';
import NewSurvey from './components/NewSurvey';
import InfoSection from './components/InfoSection';
import FilterDropdown from './components/FilterDropdown';
import SurveyModal from './components/SurveyModal';
import Rituals from './components/Rituals';
import NoDataSection from './components/NoDataSection';
import {
  reportMap,
  REPORT_ID,
  RITUALS_REPORT_ID,
  OVERVIEW_TEXT,
  DEFAULT_RECOMMENDATION_ARRAY,
  TRENDING_ITEMS,
  TRENDING_ITEMS_ACTIVITIES,
  STAT_CARD_ID,
  pageTitle,
  MODULES_ALL_ID,
  HUB_ROUTES,
  HUB_MANAGE_ROUTES,
  getPages,
  HUB_ANALYTICS,
  RITUAL_TEAMS_DEFAULT,
  RITUALS_TEAM_PAGE_NAME,
  RITUAL_TEAM_OVERVIEW_ID,
} from './constants';
import {
  getSurveyData,
  generateCards,
  getRecommendationData,
  getFilteredRecommendation,
  getSurveysData,
  sortByDate,
  filterSurveyIds,
  getMonths,
  getFormattedDate,
  getModulesData,
  isActivitiesStatDataAvailable,
  getRitualsOverviewCards,
  getRitualsAtAGlanceArea,
  getRitualTeams,
  getTopTenRitualsForOrg,
} from './utils';
import {
  SurveyCard,
  StatCard,
  Page,
  RecommendationData,
  CardId,
  Survey,
  SurveyStatuses,
  TrendingItem,
  FilterTypes,
  ModuleItem,
  PageId,
  TextId,
  RitualOverviewCard,
  AtAGlanceSection,
  RitualTeam,
} from './types';

import hubBetaStyles from './styles';
import { useAppSelector } from 'store';
import { BaselineReportModal } from './components/BaselineReportModal/BaselineReportModal';
import { RecommendationCommentaryResponse } from 'services/types';
import { useFeatureFlags } from 'FeatureFlagContext';
import { RitualCardData } from './components/RitualCardContainer/RitualCardContainer';

export const HubBeta: React.FC = (props) => {
  const classes = hubBetaStyles(props);
  const orgName = useAppSelector((state) => state.appState.orgSettings.orgName);
  const [activePage, setActivePage] = useState<Page>();
  const [filteredPages, setFilteredPages] = useState<Page[]>([]);
  const [isCardsFetched, setIsCardsFetched] = useState(false);
  const [isRecommendationArrayFetched, setIsRecommendationArrayFetched] = useState(false);
  const [isReportsFetched, setIsReportsFetched] = useState(false);
  const userOrg = useUserOrg();
  const [selectedRitualTeam, setSelectedRitualTeam] = useState('');
  const [embedToken] = useEmbedToken(
    activePage?.id === PageId.Rituals ? RITUALS_REPORT_ID : REPORT_ID
  );
  const reportSection = activePage?.report
    ? activePage?.id === PageId.Rituals && selectedRitualTeam !== RITUAL_TEAM_OVERVIEW_ID
      ? { ...reportMap[activePage?.report], pageName: RITUALS_TEAM_PAGE_NAME }
      : reportMap[activePage?.report]
    : null;
  const [cards, setCards] = useState<Array<SurveyCard | StatCard>>([]);
  const [cardsResponse, setCardsResponse] = useState<any>();
  const [recommendationArray, setRecommendationArray] = useState<RecommendationData[]>([]);
  const [filteredRecommendation, setFilteredRecommendation] = useState<RecommendationData[]>([]);
  const supportedSurveys = useAppSelector((state) => state.appState.orgSettings.supportedSurveys);
  const reloadSurveyFlag = useAppSelector((state) => state.appState.reloadSurveyFlag);
  const isSurveyAvailable = supportedSurveys.length > 0;
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>('');
  const [surveys, setSurveys] = useState<Array<Survey>>([]);
  const [filteredSurveys, setFilteredSurveys] = useState<Array<Survey>>([]);
  const [ritualTeams, setRitualTeams] = useState<Array<RitualTeam>>([]);
  const [openSurveyModal, setOpenSurveyModal] = React.useState(false);
  const [selectedTrendingValue, setSelectedTrendingValue] = useState<number>(-1);
  const [filteredSurveyIds, setFilteredSurveyIds] = useState<Array<string>>([]);
  const [trendingItems, setTrendingItems] = useState<Array<TrendingItem>>(TRENDING_ITEMS);
  const [moduleItems, setModuleItems] = useState<Array<ModuleItem>>([]);
  const [selectedModuleId, setSelectedModuleId] = useState<string>('');
  const [filteredMonths, setFilteredMonths] = useState<string[]>([]);
  const [openBaselineReportModal, setOpenBaselineReportModal] = useState<boolean>(false);
  const [baselineReport, setBaselineReport] = useState<string>(``);
  const { pathname } = useLocation();
  const history = useHistory();
  const flags = useFeatureFlags();
  const [ritualsOverviewCards, setRitualsOverviewCards] = useState<RitualOverviewCard[]>([]);
  const [ritualsAtAGlanceArea, setRitualsAtAGlanceArea] = useState<AtAGlanceSection[]>([]);
  const [ritualsTopTen, setRitualsTopTen] = useState<RitualCardData[]>([]);
  const [ritualsShowCharts, setRitualsShowCharts] = useState(false);
  const [selectedAtAGlanceSection, setSelectedAtAGlanceSection] = useState<AtAGlanceSection | null>(
    null
  );

  const cardClickHandler = useCallback(
    (cardId: CardId, routePageId?: PageId) => {
      const filteredPages = getPages(flags).filter((page: Page) => page.cardId === cardId);
      const updatedFilteredPages = filteredPages.map((page) => {
        if (page.cardId === CardId.Activities) {
          return {
            ...page,
            disabled:
              page.disabled || !isActivitiesStatDataAvailable(page.id, cardsResponse?.statData),
          };
        }
        return page;
      });
      setFilteredPages(updatedFilteredPages);
      const enabledFilteredPages = updatedFilteredPages.filter((page) => !page.disabled);
      let newActivePage = null;
      if (enabledFilteredPages.length) {
        newActivePage = enabledFilteredPages.find((page) => page.id === routePageId);
        if (newActivePage) {
          setActivePage(newActivePage);
        } else {
          const newRoutePath = HUB_ROUTES.find(
            (route) => route.pageId === enabledFilteredPages[0].id
          )?.path;
          newRoutePath && history.replace(newRoutePath);
        }
      }
      Analytics.trackEvent(
        HUB_ANALYTICS.TAB.EVENT,
        HUB_ANALYTICS.TAB.SEGMENT,
        pageTitle[newActivePage?.id || enabledFilteredPages[0].id]
      );
      setRitualsShowCharts(false);
    },
    [cardsResponse, history, flags]
  );

  const shouldShowBaselineText = () => {
    return baselineReport.length > 0;
  };

  const createBaselineReport = (recommendationResponse: RecommendationCommentaryResponse[]) => {
    const recommendationsWithBaselineReport = recommendationResponse.filter((recommendation) => {
      return recommendation.baseline_report;
    });

    let baselineReportText = '';

    if (recommendationsWithBaselineReport.length > 0) {
      const sixPillarsPart = recommendationsWithBaselineReport.find((recommendation) => {
        return recommendation.page_id === TextId.Individual;
      })?.baseline_report;

      const temperatureCheckPart = recommendationsWithBaselineReport.find((recommendation) => {
        return recommendation.page_id === TextId.Wellbeing;
      })?.baseline_report;

      if (sixPillarsPart) {
        baselineReportText += sixPillarsPart;
        if (temperatureCheckPart) {
          baselineReportText += '\n\n' + temperatureCheckPart;
        }
      }
    }
    return baselineReportText;
  };

  const ritualsViewMorePressed = (atAGlanceSection?: AtAGlanceSection) => {
    atAGlanceSection && setSelectedAtAGlanceSection(atAGlanceSection);
    setRitualsShowCharts(true);
  };

  const onRitualsBackPressed = () => {
    setRitualsShowCharts(false);
  };

  useEffect(() => {
    setSelectedAtAGlanceSection(null);
  }, [selectedRitualTeam]);

  useEffect(() => {
    if (userOrg) {
      getSurveysData(
        userOrg,
        supportedSurveys.map((survey) => survey.survey_id)
      ).then((surveysResponseData) => {
        if (surveysResponseData.length > 0) {
          setSurveys(surveysResponseData);
        }
      });
      getSurveyData(userOrg).then((surveyResponse) => {
        if (surveyResponse.surveyData.length < 1) {
          console.error(HubBetaPrompts.errorTextCards);
        } else {
          setCardsResponse(surveyResponse);
        }
      });
      getRecommendationData(userOrg).then((recommendationResponse) => {
        const recommendationArray: RecommendationData[] = recommendationResponse.map(
          (recommendationObj: any) => ({
            id: recommendationObj.page_id,
            text: recommendationObj.text,
            collectorId: recommendationObj.collector_id || '',
            timePeriod: recommendationObj.time_period,
            baselineReport: recommendationObj.baseline_report,
            responseCount: recommendationObj.response_count,
            recipientCount: recommendationObj.recipient_count,
            requiredRate: recommendationObj.response_rate_threshold_to_meet,
            responsesRequiredToMeetRate: recommendationObj.responses_until_threshold_met,
            thresholdMet: recommendationObj.met_threshold,
          })
        );
        setRecommendationArray(
          recommendationArray.length ? recommendationArray : DEFAULT_RECOMMENDATION_ARRAY
        );
        setBaselineReport(createBaselineReport(recommendationResponse));
      });
      getModulesData(userOrg).then((modulesResponseData) => {
        if (modulesResponseData.length > 0) {
          const modulesData = [
            {
              id: MODULES_ALL_ID,
              title: 'All',
            },
          ];
          modulesData.push(
            ...modulesResponseData.map((module: ModuleItem) => ({
              id: module.id,
              title: module.title,
            }))
          );
          setModuleItems(modulesData);
        }
      });
      getRitualTeams(userOrg).then((ritualTeams) =>
        setRitualTeams([...RITUAL_TEAMS_DEFAULT, ...ritualTeams])
      );
    }
  }, [userOrg, supportedSurveys, reloadSurveyFlag]);

  useEffect(() => {
    getRitualsOverviewCards(userOrg, selectedRitualTeam).then((overviewCards) =>
      setRitualsOverviewCards(overviewCards)
    );
    getRitualsAtAGlanceArea(userOrg, selectedRitualTeam).then((glanceArea) =>
      setRitualsAtAGlanceArea(glanceArea)
    );
  }, [userOrg, selectedRitualTeam]);

  useEffect(() => {
    getTopTenRitualsForOrg(userOrg).then((rituals) => setRitualsTopTen(rituals));
  }, [userOrg]);

  useEffect(() => {
    const newCards = generateCards(
      cardsResponse,
      surveys,
      supportedSurveys,
      getPages(flags),
      flags
    );
    setCards(newCards);
    return;
  }, [surveys, cardsResponse, supportedSurveys, flags]);

  useEffect(() => {
    if (pathname.includes('surveys')) {
      if (
        pathname.includes('manage') ||
        HUB_MANAGE_ROUTES.find((route) => route.path === pathname)
      ) {
        setOpenSurveyModal(true);
      } else {
        history.replace(HUB_MANAGE_ROUTES[0].path);
      }
      return;
    }
    openSurveyModal && setOpenSurveyModal(false);
    const enabledCards = cards.filter((card) => !card.disabled);
    let selectedCard = null;
    let selectedRoute = null;
    if (enabledCards.length) {
      selectedRoute = HUB_ROUTES.find((route) => route.path === pathname);
      const routeCardId = selectedRoute?.cardId;
      selectedCard = routeCardId && enabledCards.find((card) => card.cardId === routeCardId);
      if (!selectedCard) {
        selectedCard = enabledCards[0];
        const selectedCardId = selectedCard.cardId;
        selectedRoute = HUB_ROUTES.find((route) => route.cardId === selectedCardId);
        selectedRoute?.path && history.replace(selectedRoute?.path);
      }
    }
    if (selectedCard && selectedRoute) {
      cardClickHandler(selectedCard.cardId, selectedRoute.pageId);
    }
  }, [
    surveys,
    cardsResponse,
    cardClickHandler,
    supportedSurveys,
    pathname,
    cards,
    history,
    openSurveyModal,
  ]);

  useEffect(() => {
    if (cards.length > 0) {
      setIsCardsFetched(true);
    }
  }, [cards, surveys]);

  useEffect(() => {
    if (recommendationArray.length > 0) {
      setIsRecommendationArrayFetched(true);
    }
  }, [recommendationArray]);

  useEffect(() => {
    if (userOrg && embedToken && embedToken.accessToken && embedToken.embedUrl) {
      setIsReportsFetched(true);
    }
  }, [userOrg, embedToken]);

  useEffect(() => {
    if (surveys && surveys.length && activePage && activePage.cardId && cards && cards.length) {
      const selectedCard = cards.find((card) => card.cardId === activePage.cardId);
      if (selectedCard) {
        let updatedTrendingItems = TRENDING_ITEMS;
        const filteredSurveyArr = surveys
          .filter(
            (survey) =>
              survey.id === (selectedCard as SurveyCard).surveyId &&
              survey.last_sent_date &&
              survey.status === SurveyStatuses.CLOSED
          )
          .sort(sortByDate);
        if (selectedCard.cardId === STAT_CARD_ID) {
          updatedTrendingItems = TRENDING_ITEMS_ACTIVITIES;
          if (activePage.id === PageId.Modules) {
            moduleItems.length > 0 && setSelectedModuleId(moduleItems[0].id);
          } else {
            setSelectedModuleId('');
          }
          if (activePage.id === PageId.Rituals) {
            ritualTeams.length > 0 && setSelectedRitualTeam(ritualTeams[0].teamId);
          } else {
            setSelectedRitualTeam('');
          }
          setSelectedTrendingValue(-1);
        } else {
          setSelectedTrendingValue(0);
        }
        setTrendingItems(updatedTrendingItems);
        setFilteredSurveys(filteredSurveyArr ? filteredSurveyArr : []);
        setSelectedSurveyId(
          filteredSurveyArr && filteredSurveyArr.length && filteredSurveyArr[0].collector_id
            ? filteredSurveyArr[0].collector_id
            : ''
        );
      }
    }
  }, [surveys, activePage, cards, moduleItems, ritualTeams]);

  useEffect(() => {
    const selectedSurvey = filteredSurveys.find(
      (survey) => survey.collector_id === selectedSurveyId
    );
    switch (selectedTrendingValue) {
      case -1:
        setFilteredSurveyIds(filteredSurveys.map((survey) => survey.collector_id));
        break;
      default:
        selectedSurvey &&
          setFilteredSurveyIds(
            filterSurveyIds(selectedSurvey.last_sent_date, selectedTrendingValue, filteredSurveys)
          );
        break;
    }
    if (activePage?.id === PageId.Adoption || activePage?.id === PageId.Modules) {
      setFilteredMonths(
        getMonths(selectedTrendingValue).map((month) =>
          getFormattedDate(month.toISOString(), HubBetaPrompts.recommendationDateFormat)
        )
      );
    }
  }, [selectedSurveyId, selectedTrendingValue, filteredSurveys, activePage]);

  useEffect(() => {
    setFilteredRecommendation(
      getFilteredRecommendation(recommendationArray, flags, activePage?.textId, filteredSurveyIds)
    );
  }, [recommendationArray, filteredSurveyIds, activePage]);

  const renderLoader = (height: number) => (
    <Grid
      container
      style={{ height: height, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
      <AnimatedSpinner height={30} width={30} />
    </Grid>
  );

  const renderMenuItem = (page: Page) => {
    const menuItemClasses = [classes.menuItem];
    const menuTextClasses = [classes.menuText];
    const menuUnderlineClasses = [];
    if (page.disabled) {
      menuItemClasses.push(classes.menuItemDisabled);
      menuTextClasses.push(classes.menuTextDisabled);
    } else if (activePage?.id === page.id) {
      menuTextClasses.push(classes.activeMenuText);
      menuUnderlineClasses.push(classes.underline);
    }
    return (
      <div
        className={menuItemClasses.join(' ')}
        onClick={() => {
          if (!page.disabled) {
            const routablePath = HUB_ROUTES.find((route) => route.pageId === page.id)?.path;
            routablePath && history.replace(routablePath);
          }
        }}
        key={page.id}
        data-testid={page.id + '_tab'}>
        <Typography component="h2" align="center" className={menuTextClasses.join(' ')}>
          {pageTitle[page.id]}
        </Typography>
        <div className={menuUnderlineClasses.join(' ')} />
      </div>
    );
  };

  return (
    <>
      <Container maxWidth="lg" disableGutters className={classes.root}>
        <Grid container>
          {isRecommendationArrayFetched ? (
            <NewSurvey
              text={getFilteredRecommendation(recommendationArray, flags, OVERVIEW_TEXT)[0].text}
              isSurveyAvailable={isSurveyAvailable}
            />
          ) : (
            renderLoader(100)
          )}
          {isCardsFetched ? (
            <Box zIndex="mobileStepper" style={{ width: '100%' }}>
              {cards.find((card) => !card.disabled) ? null : (
                <NoDataSection message={HubCardPrompts.noDataMessage} />
              )}
              <StatsCardContainer cards={cards} activeCardId={activePage?.cardId} />
            </Box>
          ) : (
            renderLoader(200)
          )}

          <Card className={classes.container}>
            {filteredPages.length > 1 && (
              <Box className={classes.menuItems}>
                {filteredPages.map((page) => renderMenuItem(page))}
              </Box>
            )}
            <Box
              display="flex"
              className={classes.surveyFilters}
              style={{ marginTop: filteredPages.length <= 1 ? '4.5rem' : '2rem' }}>
              <>
                {activePage?.id === PageId.Rituals && ritualsShowCharts ? (
                  <>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      data-testid="hub_rituals_back_button"
                      className={classes.backButton}
                      onClick={onRitualsBackPressed}>
                      <UndoIcon color={colors.slateGrey} /> {HubRitualPrompts.buttonText.back}
                    </Typography>
                  </>
                ) : null}
                {filteredSurveys && filteredSurveys.length > 0 && selectedSurveyId ? (
                  <FilterDropdown
                    items={filteredSurveys}
                    selectedItem={selectedSurveyId}
                    setSelectedItem={setSelectedSurveyId}
                    dataTestId="hub_filter_current_survey"
                    filterType={FilterTypes.SURVEY}
                    filterLabel={HubBetaPrompts.filterSurveyLabel}
                  />
                ) : null}
                {activePage?.id === PageId.Rituals &&
                !ritualsShowCharts &&
                ritualTeams &&
                ritualTeams.length &&
                selectedRitualTeam ? (
                  <FilterDropdown
                    items={ritualTeams}
                    selectedItem={selectedRitualTeam}
                    setSelectedItem={setSelectedRitualTeam}
                    dataTestId="hub_filter_ritual_teams"
                    filterType={FilterTypes.RITUAL_TEAMS}
                    filterLabel={HubBetaPrompts.filterRitualTeamsLabel}
                    hideLabel={true}
                  />
                ) : null}
                {(activePage?.id === PageId.Adoption ||
                  activePage?.id === PageId.Care ||
                  activePage?.id === PageId.Individual ||
                  activePage?.id === PageId.Wellbeing) &&
                  isCardsFetched &&
                  cards.find((card) => !card.disabled) &&
                  activePage?.textId !== TextId.Modules &&
                  activePage?.textId !== TextId.Rituals && (
                    <FilterDropdown
                      items={trendingItems}
                      selectedItem={selectedTrendingValue}
                      setSelectedItem={setSelectedTrendingValue}
                      dataTestId="hub_filter_trending"
                      filterType={FilterTypes.TRENDING}
                      filterLabel={HubBetaPrompts.filterTrendingLabel}
                    />
                  )}
                {false && moduleItems && moduleItems.length && selectedModuleId ? (
                  <FilterDropdown
                    items={moduleItems}
                    selectedItem={selectedModuleId}
                    setSelectedItem={setSelectedModuleId}
                    dataTestId="hub_filter_modules"
                    filterType={FilterTypes.MODULES}
                    filterLabel={HubBetaPrompts.filterModulesLabel}
                  />
                ) : null}
              </>
            </Box>
            {isRecommendationArrayFetched && filteredRecommendation.length > 0 && (
              <InfoSection
                orgName={orgName}
                recommendations={filteredRecommendation}
                dataTestId={'hub_recommendation_' + activePage?.id}
                showBaselineText={shouldShowBaselineText()}
                openBaselineModal={() => {
                  setOpenBaselineReportModal(true);
                }}
              />
            )}
            {activePage?.id === PageId.Rituals && !ritualsShowCharts ? (
              <>
                {ritualsOverviewCards.length && ritualsAtAGlanceArea.length ? (
                  <Rituals
                    overviewTitle={
                      selectedRitualTeam === RITUAL_TEAM_OVERVIEW_ID
                        ? HubRitualPrompts.title.overview
                        : (ritualTeams.find((team) => team.teamId === selectedRitualTeam)?.name ??
                          '')
                    }
                    overviewCards={ritualsOverviewCards}
                    glanceArea={ritualsAtAGlanceArea}
                    viewMorePressed={ritualsViewMorePressed}
                    selectedRitualTeam={selectedRitualTeam}
                    topTenRituals={{
                      rituals: ritualsTopTen,
                    }}
                  />
                ) : null}
              </>
            ) : (
              <>
                {selectedAtAGlanceSection ? (
                  <Box className={classes.ritualsDetailsSection}>
                    <RitualDetailsSection
                      ritual={{
                        id: selectedAtAGlanceSection.id ?? '',
                        trigger: selectedAtAGlanceSection.trigger ?? '',
                        action: selectedAtAGlanceSection.action ?? '',
                        checkinFrequency: selectedAtAGlanceSection.frequency ?? '',
                        lastUpdateTime: selectedAtAGlanceSection.lastCheckin
                          ? new Date(selectedAtAGlanceSection.lastCheckin)
                          : new Date(),
                        teamId: selectedRitualTeam,
                      }}
                    />
                  </Box>
                ) : null}
                {isReportsFetched ? (
                  <PowerBIReportsEmbed
                    embedToken={embedToken}
                    userOrg={userOrg}
                    reportSection={reportSection}
                    filterSurveyIds={filteredSurveyIds}
                    filterModuleIds={
                      selectedModuleId === MODULES_ALL_ID
                        ? []
                        : // moduleItems.filter(mod => mod.id !== MODULES_ALL_ID).map(mod => mod.id)
                          [selectedModuleId]
                    }
                    activePageId={activePage?.id || PageId.Wellbeing}
                    filterMonths={filteredMonths}
                    selectedRitualId={
                      selectedAtAGlanceSection && selectedAtAGlanceSection.id
                        ? selectedAtAGlanceSection.id
                        : ''
                    }
                  />
                ) : null}
                {!isReportsFetched ? renderLoader(300) : null}
              </>
            )}
          </Card>
        </Grid>
      </Container>
      {openSurveyModal && (
        <SurveyModal
          openModal={openSurveyModal}
          setOpenModal={setOpenSurveyModal}
          allSurveys={surveys}
        />
      )}
      {openBaselineReportModal && (
        <BaselineReportModal
          openModal={openBaselineReportModal}
          setOpenModal={setOpenBaselineReportModal}
          baselineReportMarkdown={baselineReport}
        />
      )}
    </>
  );
};
