import React from 'react';
import { Button, Card } from '../../../../common';
import { eapProviderStyles } from './styles';
import { Setting } from '../Setting';
import { EapServicePrompts } from './prompts';
import { Box } from '@material-ui/core';
import { EapTable, EapTableRowData } from './EapTable/EapTable';
import { EapDrawer } from './EapDrawer/EapDrawer';
import { EapDetails, EapDetailsWithId } from './types';
import { VestIcon } from 'assets/icons/vest-icon';

interface Props {
  eapProviders: EapTableRowData[];
  isDrawerOpen: boolean;
  eapDrawerButtonText: string;
  values?: EapDetailsWithId;
  onSettingsButtonPress: () => void;
  onDrawerButtonClick: (eapDetails: EapDetails, defaultState?: EapDetailsWithId) => void;
  onEllipsisEditClick: (eapId: string) => void;
  onEllipsisDeleteClick: (eapId: string) => void;
  handleDrawerClose: () => void;
}

export const EapServices: React.FC<Props> = (props) => {
  const {
    handleDrawerClose,
    onEllipsisEditClick,
    onEllipsisDeleteClick,
    onDrawerButtonClick,
    onSettingsButtonPress,
    eapProviders,
    isDrawerOpen,
    eapDrawerButtonText,
    values,
  } = props;
  const classes = eapProviderStyles(props);
  const { addToSlackButtonText, id } = EapServicePrompts;

  const SettingsButton = () => {
    return (
      <Button
        className={classes.addEapButton}
        onClick={onSettingsButtonPress}
        data-testid={'settings_' + id + '_add_eap_button'}>
        {addToSlackButtonText}
      </Button>
    );
  };

  const EapProvideTable = () => {
    return eapProviders.length > 0 ? (
      <Card style={{ padding: '16px 16px 4px 16px', marginTop: '16px', maxHeight: '60%' }}>
        <EapTable
          data={eapProviders}
          onEllipsisEditClick={onEllipsisEditClick}
          onEllipsisDeleteClick={onEllipsisDeleteClick}
        />
      </Card>
    ) : null;
  };

  return (
    <Setting {...EapServicePrompts} icon={EapServiceIcon}>
      <Box flexDirection={'column'} flex={1}>
        <SettingsButton />
        <EapProvideTable />
        <EapDrawer
          isOpen={isDrawerOpen}
          handleClose={handleDrawerClose}
          buttonText={eapDrawerButtonText}
          defaultValues={values}
          onButtonClick={onDrawerButtonClick}
        />
      </Box>
    </Setting>
  );
};

const EapServiceIcon = () => {
  return (
    <Box style={{ marginRight: 12 }}>
      <VestIcon />
    </Box>
  );
};
