import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';
import { Card, Link, ContainerLayout, DefaultButton } from 'common';
import { DownloadIcon } from 'assets/icons/download';
import { instructions } from 'prompts/prompts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      maxWidth: 1200,
    },
    bottomCardWrapper: {
      paddingTop: theme.spacing(2),
    },
    downloadAction: {
      color: colors.royalBlue,
      fontFamily: 'Averta-Semibold',
      fontWeight: 500,
    },
    underline: {
      width: 26,
      height: 2,
      backgroundColor: colors.blue,
      borderRadius: 1,
    },
    listContainer: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: 18,
      borderRadius: 12,
      paddingBottom: 18,
      backgroundColor: colors.whisperWhite,
      marginTop: theme.spacing(6),
    },
    listRightWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    listTitle: {
      fontFamily: 'Averta',
      fontWeight: 'normal',
      color: colors.darkGrey,
      marginLeft: theme.spacing(4),
    },
    listDate: {
      fontFamily: 'Averta',
      fontWeight: 'normal',
      fontSize: 12,
      color: colors.slateGrey,
    },
  })
);

export const Help: React.FC = (props) => {
  const classes = useStyles(props);

  useEffect(() => {});

  const navigateTo = (url: string) => {
    window.open(url);
  };
  const CSVDownloadComponent = () => (
    <Grid
      container
      direction="row"
      justify="space-between"
      //key={index}
      item
      xs={12}
      className={classes.listContainer}
      spacing={2}>
      <Grid item xs={12} md={6}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DownloadIcon />
          <Typography variant="h4" className={classes.listTitle}>
            Demo CSV file
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.listRightWrapper}>
        <Link style={{ padding: 0 }}>
          <Typography
            variant="h4"
            className={classes.downloadAction}
            onClick={() => navigateTo('/groov_bulk_example.csv')}
            data-testid="help_download_button">
            Download
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );

  return (
    <ContainerLayout>
      <Grid container>
        <Grid item xs={12}>
          <Card style={{ marginTop: 8 }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              className={classes.bottomCardWrapper}>
              <Grid item>
                <Typography variant="h2" data-testid="help_page_title">
                  Need assistance?
                </Typography>
              </Grid>
              <Grid item>
                <DefaultButton
                  color="primary"
                  onClick={() => navigateTo('https://groovnow.com/groov-customer-service/')}
                  data-testid="help_contact_support_button">
                  Contact support
                </DefaultButton>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              component="p"
              style={{ marginTop: 22 }}
              data-testid="help_page_description">
              {instructions.helpPage.needAssistance}
            </Typography>
            <CSVDownloadComponent />
          </Card>
        </Grid>
      </Grid>
    </ContainerLayout>
  );
};
