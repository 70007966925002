import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

export const VerticalBox: React.FC<BoxProps> = (props) => {
  const completeBoxProps = {
    display: 'flex',
    flexDirection: 'column',
    ...props,
  };
  return <Box {...completeBoxProps}>{props.children}</Box>;
};
