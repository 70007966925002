import React from 'react';

import { createStyles, makeStyles, Typography } from '@material-ui/core';

import { ReportMessage } from './EmailUploadReportModal';

interface ReportMessageProps {
  reportGroup: ReportMessage[];
  icon: string;
  display: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      marginRight: '1rem',
      borderRadius: '25%',
      height: '32px',
      width: '32px',
    },
    message: {
      paddingBottom: '0.5rem',
    },
    flex: {
      display: 'flex',
      padding: '1rem',
    },
  })
);

export function CreateReportMessageList(props: ReportMessageProps) {
  const { reportGroup, icon, display } = props;
  const styles = useStyles(props);
  const headingText = createHeadingText(reportGroup);

  if (display) {
    return (
      <Typography component="div" className={styles.flex}>
        <Typography component="div">
          <Typography component="img" src={icon} className={styles.icon}></Typography>
        </Typography>
        <Typography component="div">
          <Typography component="p" className={styles.message}>
            {headingText}
          </Typography>
          <Typography component="ul">
            <Typography component="li">
              {reportGroup.map((item) => (
                <li key={item.message} className={styles.message}>
                  {item.message}
                </li>
              ))}
            </Typography>
          </Typography>
        </Typography>
      </Typography>
    );
  }
  return <Typography component="div" style={{ display: 'none' }}></Typography>;
}
function createHeadingText(reportGroup: ReportMessage[]) {
  if (reportGroup.length === 1) {
    return `The following email was not processed because it was invalid:`;
  }
  return `The following ${reportGroup.length} emails were not processed because they were invalid:`;
}
