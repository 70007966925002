import React from 'react';

import { createStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontFamily: 'Averta',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#000000',
    },
    small: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
    },
    medium: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '30px',
    },
    large: {
      fontWeight: 'bolder',
      fontSize: '24px',
      lineHeight: '36px',
    },
  })
);

interface RitualCardLabelProps {
  text: string;
  size?: 'small' | 'medium' | 'large';
  styles?: string;
}

export const RitualCardLabel = ({ ...props }: RitualCardLabelProps) => {
  const { text } = props;
  const styles = useStyles(props);

  const styleSize = props.size ?? 'small';
  return <Typography className={[styles.label, styles[styleSize]].join(' ')}>{text}</Typography>;
};
