import React from 'react';
import { Button } from '../../../../common';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/RemoveRounded';
import { AnimatedSpinner } from '../../../../common/components/AnimatedSpinner';
import { gracePeriodStyles } from './styles';
import { Setting } from '../Setting';
import { GracePeriodPrompts } from './prompts';
import { Hidden } from '@material-ui/core';

interface Props {
  gracePeriod: number;
  onConfirmButtonPress: () => void;
  onIncrementPress: () => void;
  onDecrementPress: () => void;
  loading: boolean;
  saving: boolean;
}

export const GracePeriod: React.FC<Props> = (props) => {
  const { gracePeriod, onConfirmButtonPress, onDecrementPress, onIncrementPress, loading, saving } =
    props;
  const classes = gracePeriodStyles(props);
  const { confirmButtonText, id } = GracePeriodPrompts;

  const renderLoader = () => {
    return <AnimatedSpinner height={30} width={30} />;
  };

  const renderGracePeriodControl = () => {
    return (
      <>
        <div className={classes.gracePeriodButtonContainer}>
          <Button
            onClick={onDecrementPress}
            className={classes.gracePeriodButton}
            disabled={loading}
            data-testid={'settings_' + id + '_decrement_button'}>
            <MinusIcon />
          </Button>
          <div data-testid={'settings_' + id + '_count'}>
            {loading ? renderLoader() : gracePeriod}
          </div>
          <Button
            onClick={onIncrementPress}
            className={classes.gracePeriodButton}
            disabled={loading}
            data-testid={'settings_' + id + '_increment_button'}>
            <AddIcon />
          </Button>
        </div>
        <Hidden mdUp>
          <div className={classes.spacer} />
        </Hidden>
        <Button
          className={classes.confirmButton}
          onClick={onConfirmButtonPress}
          disabled={saving || loading}
          data-testid={'settings_' + id + '_confirm_button'}>
          {saving ? renderLoader() : confirmButtonText}
        </Button>
      </>
    );
  };

  return <Setting {...GracePeriodPrompts}>{renderGracePeriodControl()}</Setting>;
};
