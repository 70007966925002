import { useAppSelector } from 'store';
import { Links, RouteLabel } from 'types/routes';

export const useHeaderLinks = (links: Links): Links => {
  const navigationItems = useAppSelector((state) => state.appState.orgSettings.navigationItems);
  return Object.keys(links).reduce((acc: Links, key: string) => {
    const link = links[key as RouteLabel];

    if (link && navigationItems?.includes(link.id)) {
      acc[key as RouteLabel] = link;
    }
    return acc;
  }, {} as Links);
};
