import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

export const GridIcon = (props?: any) => (
  <IconButton aria-label="delete" style={{ padding: 0 }}>
    <SvgIcon {...props}>
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>D2714BEB-2DF5-4595-91CF-5776E4F1B85B@1.00x</title>
        <g id="Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin---Mobile-menu---closed" transform="translate(-50.000000, -254.000000)">
            <g id="List-item-/-Manage-Domains" transform="translate(0.000000, 240.000000)">
              <g id="icons-/-ic-grid" transform="translate(48.000000, 12.000000)">
                <rect id="container" x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M20,2 C21.1045695,2 22,2.8954305 22,4 L22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 L4,2 Z M20,4 L4,4 L4,20 L20,20 L20,4 Z M17,13 C17.5522847,13 18,13.4477153 18,14 L18,17 C18,17.5522847 17.5522847,18 17,18 L14,18 C13.4477153,18 13,17.5522847 13,17 L13,14 C13,13.4477153 13.4477153,13 14,13 L17,13 Z M10,13 C10.5522847,13 11,13.4477153 11,14 L11,17 C11,17.5522847 10.5522847,18 10,18 L7,18 C6.44771525,18 6,17.5522847 6,17 L6,14 C6,13.4477153 6.44771525,13 7,13 L10,13 Z M10,6 C10.5522847,6 11,6.44771525 11,7 L11,10 C11,10.5522847 10.5522847,11 10,11 L7,11 C6.44771525,11 6,10.5522847 6,10 L6,7 C6,6.44771525 6.44771525,6 7,6 L10,6 Z M17,6 C17.5522847,6 18,6.44771525 18,7 L18,10 C18,10.5522847 17.5522847,11 17,11 L14,11 C13.4477153,11 13,10.5522847 13,10 L13,7 C13,6.44771525 13.4477153,6 14,6 L17,6 Z"
                  id="fill-color"
                  fill={props.color ? props.color : '#444752'}></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  </IconButton>
);
