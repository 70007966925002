import { ServicePrincipalInstallationResult } from '../types';

export const CustomerCreateSuccess: ServicePrincipalInstallationResult = {
  title: 'Groov Anywhere Install Successful',
  message: 'Groov Anywhere has been installed successfully',
  extraInfo: 'You may now close this window',
};

export const CustomerCreateError: ServicePrincipalInstallationResult = {
  title: 'Groov Anywhere Installation Error',
  message: 'An error occured while linking your organization to Groov anywhere',
  extraInfo: 'Please contact Groov support at support@groovnow.com',
};

export const PermissionOnlySuccess: ServicePrincipalInstallationResult = {
  title: 'Groov Anywhere Permissions Updated Successfully',
  message: 'Groov Anywhere Permissions have been updated successfully',
  extraInfo: 'You may now close this window',
};

export const ConsentUnknownError: ServicePrincipalInstallationResult = {
  title: 'Groov Anywhere Permission Error',
  message: 'An error occured whle granting permissions to Groov Anywhere',
  extraInfo: 'If you continue to receive this message, please reach out to support@groovnow.com',
};

export const NoParams: ServicePrincipalInstallationResult = {
  title: '',
  message:
    'To install integrate Groov Anywhere with Microsoft 365 or update permissions, please visit the Integrations page',
  extraInfo: 'Please navigate away from this page',
};
