import { AxiosRequestConfig } from 'axios';
import { BasicRequest } from '../../../../services/apiRequest';

const emailEndpoint = 'autoinvitationemail';

export const setAutoEmail = async (autoEmail: boolean): Promise<boolean> => {
  const config: AxiosRequestConfig = {
    url: emailEndpoint,
    method: 'PATCH',
    data: { auto_invitation_email: autoEmail },
  };

  const res = await BasicRequest(config);
  return res.status === 200;
};
