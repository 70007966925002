import { Card as GroovCard } from 'common';
import React from 'react';

import { Box, createStyles, makeStyles } from '@material-ui/core';

import { RitualCardHeroLabel } from './RitualCardHeroLabel';
import { RitualCardLabel } from './RitualCardLabel';

const useStyles = makeStyles(() =>
  createStyles({
    horizontalFlexBox: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    mainFlexBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexBasis: '300%',
      height: '100%',
    },
  })
);

interface RitualCardProps {
  action: string;
  trigger: string;
  teamName: string;
  valuableRate: number;
}

export const RitualCard = ({ ...props }: RitualCardProps) => {
  const { trigger, action, teamName, valuableRate } = props;
  const styles = useStyles(props);

  return (
    <GroovCard
      style={{
        padding: '1.5rem',
        borderRadius: '12px',
        borderColor: '#E6EBF3',
        height: '175px',
        boxSizing: 'border-box',
      }}>
      <Box className={styles.horizontalFlexBox}>
        <div className={styles.mainFlexBox}>
          <RitualCardLabel text={trigger} />
          <RitualCardLabel text={action} size="medium" />
          <RitualCardLabel text={teamName} />
        </div>
        <div>
          <RitualCardHeroLabel valuableRate={valuableRate} />
        </div>
      </Box>
    </GroovCard>
  );
};
