import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Results, ResultsProps } from '@groov/ui';
import { HubRitualPrompts } from 'prompts/prompts';

import Overview from './components/Overview';
import { GlanceSectionConstants } from './components/RitualsGlance/constants';
import Analytics from '../../../../utils/AnalyticsHelper';
import { GlanceSection } from './components/RitualsGlance/GlanceSection';
import NoDataSection, { NoDataSectionLearnMoreButton, NoDataSectionTheme } from '../NoDataSection';
import { AtAGlanceSection, RitualOverviewCard } from '../../types';
import { overviewCardsValid } from '../../utils';
import { HUB_ANALYTICS, RITUAL_LEARN_URL_DEFAULT, RITUAL_TEAM_OVERVIEW_ID } from '../../constants';
import ritualsStyles from './styles';
import {
  RitualCardContainer,
  RitualCardContainerProps,
} from '../RitualCardContainer/RitualCardContainer';

interface RitualsProps {
  overviewTitle: string;
  overviewCards: RitualOverviewCard[];
  glanceArea: AtAGlanceSection[];
  viewMorePressed: Function;
  selectedRitualTeam: string;
  topTenRituals: RitualCardContainerProps;
}

const Rituals: React.FC<RitualsProps> = (props) => {
  const classes = ritualsStyles(props);
  const {
    overviewCards,
    glanceArea,
    viewMorePressed,
    selectedRitualTeam,
    overviewTitle,
    topTenRituals,
  } = props;
  const learnMoreUrl = useMemo(() => {
    if (process.env.REACT_APP_HUB_RITUAL_LEARN_URL) {
      return process.env.REACT_APP_HUB_RITUAL_LEARN_URL;
    } else {
      return RITUAL_LEARN_URL_DEFAULT;
    }
  }, []);

  const learnMoreButton: NoDataSectionLearnMoreButton = {
    title: HubRitualPrompts.buttonText.noDataLearnMore,
    onClick: () => {
      Analytics.trackEvent(HUB_ANALYTICS.RITUALS.EMPTY_STATE_LEARN_MORE_EVENT);
      window.open(learnMoreUrl);
    },
  };

  const viewMoreDetailsPressed = (atAGlanceSection?: AtAGlanceSection) => {
    Analytics.trackEvent(
      HUB_ANALYTICS.RITUALS.VIEW_MORE_DETAILS_EVENT,
      HUB_ANALYTICS.RITUALS.VIEW_MORE_DETAILS_SEGMENT,
      atAGlanceSection && atAGlanceSection.id
        ? atAGlanceSection.id
        : HubRitualPrompts.label.ritualTeamsOveriew
    );
    viewMorePressed(atAGlanceSection);
  };

  return (
    <Box className={classes.rituals}>
      {overviewCardsValid(overviewCards) ? (
        <>
          <Overview cards={overviewCards} title={overviewTitle} />
          {selectedRitualTeam === RITUAL_TEAM_OVERVIEW_ID ? (
            <Box>
              <GlanceSection
                glanceSection={glanceArea[0]}
                onViewMoreDetailsPress={() => viewMoreDetailsPressed()}
              />
              {topTenRituals.rituals.length > 0 ? (
                <RitualCardContainer rituals={topTenRituals.rituals} />
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <Box pt={'2rem'}>
              <Typography
                variant="h3"
                className={classes.atAGlanceTitle}
                data-testid={'rituals_glance_label'}>
                {GlanceSectionConstants.sectionTitle}
              </Typography>
              {glanceArea.map((glanceSection) => {
                const {
                  action,
                  oftenDeltaFigure,
                  oftenMainFigure,
                  responseRateDeltaFigure,
                  responseRateMainFigure,
                  valuableDeltaFigure,
                  valuableMainFigure,
                  frequency,
                  lastCheckin,
                  lastUpdated,
                  trigger,
                } = glanceSection;
                const results: ResultsProps = {
                  trigger: trigger ?? '',
                  action: action ?? '',
                  frequency: frequency ?? '',
                  valuableRate: Number(valuableMainFigure),
                  valuableDelta: valuableDeltaFigure,
                  doingRate: Number(oftenMainFigure),
                  doingDelta: oftenDeltaFigure,
                  responseRate: Number(responseRateMainFigure),
                  responseDelta: responseRateDeltaFigure,
                  lastCheckin: lastCheckin ?? 'none',
                  lastUpdated: lastUpdated ?? '',
                };
                const details = {
                  onClick: () => viewMoreDetailsPressed(glanceSection),
                };
                return (
                  <Box className={classes.results}>
                    <Results results={results} details={details} />
                  </Box>
                );
              })}
            </Box>
          )}
        </>
      ) : (
        <NoDataSection
          theme={NoDataSectionTheme.DARK}
          title={HubRitualPrompts.title.noDataOverview}
          learnMoreButton={learnMoreButton}
        />
      )}
    </Box>
  );
};

export default Rituals;
