import React, { useState } from 'react';
import { GroovAnywhereTeamsOld } from './GroovAnywhereTeamsOld';
import { addTenantId } from './apiOld';
import { ToasterUtils } from '../../../../common';
import { TeamsStrings } from './stringsOld';
import { useAuthState } from '../../../../common/hooks/useAuthState';

export const GroovAnywhereTeamsContainerOld = () => {
  const authState = useAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const onAddTenantId = async (tenantId: string) => {
    try {
      setIsLoading(true);
      await addTenantId(authState.orgId, tenantId);
      showSuccess();
    } catch (e) {
      showError();
    } finally {
      setIsLoading(false);
    }
  };

  const showError = () => {
    ToasterUtils.toast(TeamsStrings.toastErrorMsg, 'error', TeamsStrings.toastErrorTitle);
  };

  const showSuccess = () => {
    ToasterUtils.toast(TeamsStrings.toastMsg, 'success', TeamsStrings.toastTitle);
  };

  return <GroovAnywhereTeamsOld onAddTenantId={onAddTenantId} isLoading={isLoading} />;
};
