import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthState } from 'common/hooks/useAuthState';
import {
  MICROSOFT_APP_CLIENT_ID,
  MICROSOFT_APP_CONSENT_URL,
  getStateForRedirect,
} from 'pages/Integrations/Common/Microsoft/microsoftUtils';
import { createPopupFeatures } from 'utils/WindowHelper';
import { getCustomerIntegrations } from 'pages/Integrations/Common/api';
import { CustomerIntegration, Integration } from 'pages/Integrations/Common/types';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { CheckCircleIcon } from 'assets/icons/CheckCircleIcon';
import { Button, Card, ContainerLayout, ToasterUtils } from '../../../../common';
import { TeamsCohortInstallModal } from './TeamsCohortInstallModal';
import { TeamsFullInstallModal } from './TeamsFullInstallModal';

const CLIENT_ID = MICROSOFT_APP_CLIENT_ID;
const CONSENT_URL = MICROSOFT_APP_CONSENT_URL;

export const useStyles = makeStyles(() => ({
  button: {
    width: '190px',
    height: '52px',
    padding: '8px 8px',
    whiteSpace: 'nowrap',
    fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
    fontWeight: 400,
  },
  buttonContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginBottom: '24px',
  },
  instructionTitle: {
    flex: 1,
    marginBottom: '8px',
  },
  body: {
    marginBottom: '8px',
  },
  uploadContainer: {
    height: '250px',
  },
  servicePrincipalEnabled: {
    display: 'flex',
    flexDirection: 'row',
  },
  servicePrincipalInstallContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
  },
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  removeCsv: {
    marginLeft: '16px',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
  },
}));

export const TeamsInstallPage = () => {
  const [showCohortModal, setShowCohortModal] = useState<boolean>(false);
  const [showOrgwideModal, setShowOrgwideModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerIntegrations, setCustomerIntegrations] = useState<
    CustomerIntegration[] | undefined
  >(undefined);

  const classes = useStyles();
  const authState = useAuthState();

  const getIntegrations = async () => {
    try {
      setIsLoading(true);
      const integrations = await getCustomerIntegrations(authState.orgId);
      setCustomerIntegrations(() => integrations);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      ToasterUtils.error('An error occured getting Integrations');
    } finally {
      setIsLoading(false);
    }
  };

  const hasIntegration = (integrations?: CustomerIntegration[]) => {
    return !!integrations
      ?.map((integration) => {
        return integration.platform;
      })
      ?.includes(Integration.MsTeams);
  };

  useEffect(() => {
    getIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkWithGroov = async () => {
    const integrations = await getCustomerIntegrations(authState.orgId);
    setCustomerIntegrations(() => integrations);
    if (!hasIntegration(integrations)) {
      const state = await getStateForRedirect(authState.orgId);
      window.open(
        `${CONSENT_URL}?client_id=${CLIENT_ID}&state=${state}`,
        '_blank',
        createPopupFeatures()
      );
    }
  };

  const openCohortModal = () => {
    setShowCohortModal(true);
  };

  const openOrgwideModal = () => {
    setShowOrgwideModal(true);
  };

  const closeCohortModal = () => {
    setShowCohortModal(false);
  };

  const closeOrgwideModal = () => {
    setShowOrgwideModal(false);
  };

  const Title = () => {
    return (
      <Typography variant="h1" className={classes.title}>
        Groov Anywhere for Teams
      </Typography>
    );
  };

  const ServicePrincipalSection = () => {
    return (
      <Box className={classes.servicePrincipalInstallContainer}>
        <Typography variant="h3" className={classes.body}>
          Connect Teams
        </Typography>
        {hasIntegration(customerIntegrations) ? (
          <>
            <Typography variant="body1" className={classes.body}>
              Groov Anywhere for Teams has already been connected for your organisation.
            </Typography>
            <Box className={classes.servicePrincipalEnabled}>
              <Typography variant="body1" className={classes.body}>
                <b>Connected</b>
              </Typography>
              <Box marginRight={'8px'} />
              <CheckCircleIcon />
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body1" className={classes.body}>
              To connect Groov Anywhere, you must be a <b>Microsoft Global Administrator</b> and you
              will be asked to provide consent.
            </Typography>
            <Button onClick={handleLinkWithGroov} disabled={isLoading} className={classes.button}>
              {'Connect Teams'}
            </Button>
          </>
        )}
      </Box>
    );
  };

  const InstallInstructions = () => {
    return (
      <>
        <Typography variant="h3" className={classes.instructionTitle}>
          Install for your employees
        </Typography>
      </>
    );
  };

  const ButtonGroup = () => {
    return (
      <Box className={classes.buttonContainer}>
        <Button onClick={openCohortModal} disabled={isLoading} className={classes.button}>
          {'Install for select users'}
        </Button>
        <Box marginLeft={'12px'} />
        <Button onClick={openOrgwideModal} disabled={isLoading} className={classes.button}>
          {'Install for everyone'}
        </Button>
      </Box>
    );
  };

  return (
    <ContainerLayout>
      <Card>
        <Title />
        {!isLoading ? (
          <>
            <TeamsCohortInstallModal showModal={showCohortModal} closeModal={closeCohortModal} />
            <TeamsFullInstallModal showModal={showOrgwideModal} closeModal={closeOrgwideModal} />
            <ServicePrincipalSection />
            {hasIntegration(customerIntegrations) && (
              <>
                <InstallInstructions />
                <ButtonGroup />
              </>
            )}
          </>
        ) : (
          <Box className={classes.spinnerContainer}>
            <AnimatedSpinner width={64} height={64} />
          </Box>
        )}
      </Card>
    </ContainerLayout>
  );
};
