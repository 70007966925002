import { useEffect, useState } from 'react';
import { EmbedToken } from 'services/types';
import { getPowerBIToken } from '../services/api';

export const useEmbedToken = (reportId?: string) => {
  const [embedToken, setEmbedToken] = useState<EmbedToken>({ embedUrl: [{}] });
  useEffect(() => {
    async function getEmbedUrl() {
      const resp = await getPowerBIToken(reportId);
      if (resp.status === 200) {
        setEmbedToken(resp.data);
      }
    }
    getEmbedUrl();
  }, [reportId]);
  return [embedToken];
};
