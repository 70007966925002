import { Chip, makeStyles, TextField } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Autocomplete, AutocompleteClassKey } from '@material-ui/lab';
import React from 'react';

export type SearchAndSelectProps = {
  options: Array<{ id: string | number; label: string }>;
  value?: any[];
  handleOnChange?: (event: React.ChangeEvent<{}>, value: any[]) => void;
  hideSelected?: boolean;
  disabled?: boolean;
  placeholder?: string;
  classes?: Partial<ClassNameMap<AutocompleteClassKey>>;
  className?: string;
};

export function SearchAndSelect({
  options,
  value,
  handleOnChange,
  hideSelected = false,
  disabled,
  placeholder,
  classes,
  className,
}: SearchAndSelectProps) {
  const defaultClasses = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any[]) => {
    const uniqueValues = newValue.filter(
      (v, index, self) => index === self.findIndex((t) => t.id === v.id)
    );
    handleOnChange?.(event, uniqueValues);
  };

  const filteredOptions = React.useMemo(
    () => options.filter((option) => !value?.some((selected) => selected.id === option.id)),
    [options]
  );

  return (
    <Autocomplete
      multiple
      freeSolo
      disabled={disabled}
      options={hideSelected ? filteredOptions : options}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, selected) => option.id === selected.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const defaultTagProps = getTagProps({ index });
          const tagProps = disabled
            ? { ...defaultTagProps, onDelete: undefined }
            : { ...defaultTagProps };
          return (
            <Chip label={option?.label} {...tagProps} color="primary" style={{ padding: '4px' }} />
          );
        })
      }
      value={value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder={placeholder} />
      )}
      fullWidth
      classes={{
        inputRoot: defaultClasses.inputRoot,
        ...classes,
      }}
      className={className ? className : defaultClasses.root}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  inputRoot: {
    '&.MuiOutlinedInput-root': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    '& .MuiChip-root': {
      margin: theme.spacing(1),
    },
  },
}));
