import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { OrgUser } from 'common/components/FileUpload/FileUpload';
import moment from 'moment';
import { ApiRequest } from 'services/request';
import { PromptSummary, PromptCTATypes, PromptItemResponse } from './PromptBuilder.types';
import { dispatch } from 'store';
import { setAllPrompts, setLoading } from './promptBuilder.slice';
import { promptTypes } from '../GroovVoice';

const BASE_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;

type PromptUser = {
  Email: string;
  OrgName: string;
};

type PromptPayload = {
  promptActions: PromptAction[];
  promptText: string;
  promptName: string;
  openTextResponse?: boolean;
  categoryId: string;
  isGroovVoice: boolean;
  isConfidential: boolean;
  responseToOpenText?: string;
  promptTags: string[];
  properties: Record<string, string>;
  scheduleDateTime: string;
  recipients: PromptUser[];
};

export type PromptAction = {
  action: string;
  response: string;
};

export type CreatePromptParams = {
  triggerId: string;
  promptName: string;
  promptText: string;
  promptActions: PromptAction[];
  promptTags: string[];
  isGroovVoice: boolean;
  isConfidential: boolean;
  categoryId: string;
  responseToOpenText?: string;
  openTextResponse: boolean;
  promptCTAType: PromptCTATypes;
  scheduleTime: string;
  users: OrgUser[];
};

export const createPrompt = async (params: CreatePromptParams): Promise<AxiosResponse> => {
  const {
    promptName,
    promptText,
    promptActions,
    categoryId,
    isGroovVoice,
    responseToOpenText,
    openTextResponse,
    promptTags,
    promptCTAType,
    isConfidential,
    scheduleTime,
    users,
  } = params;

  const dateTimeString = moment(scheduleTime).format('YYYY-MM-DDTHH:mm:ssZ');

  const recipients = users.map((user) => ({
    Email: user.Email,
    OrgName: user.OrgId,
  }));

  const payload: PromptPayload = {
    promptActions,
    openTextResponse,
    promptText,
    promptName,
    categoryId,
    promptTags,
    isGroovVoice,
    isConfidential,
    responseToOpenText,
    scheduleDateTime: dateTimeString,
    recipients,
    properties: {
      prompt_cta_type: promptCTAType,
      prompt_schedule_time: scheduleTime,
    },
  };

  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: 'content/v1/prompt',
    method: 'POST',
    data: payload,
  };

  const result = await ApiRequest(config);
  return result;
};

interface Meta {
  total_count: number;
}

interface PromptResponse {
  meta: Meta;
  data: PromptSummary[];
}

export const getPrompts = async ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}): Promise<PromptResponse | undefined> => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: 'content/v1/prompts',
    method: 'GET',
    params: { limit, offset },
  };
  const response = await ApiRequest(config);

  if (response?.status === 200) {
    return response.data satisfies PromptSummary;
  }
  return;
};

export const getSelectedPrompt = async (id: string): Promise<PromptItemResponse | undefined> => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `content/v1/prompt/${id}`,
    method: 'GET',
  };
  const response = await ApiRequest(config);

  if (response?.status === 200) {
    return response.data satisfies PromptItemResponse;
  }
  return;
};

export const getAllPrompts = async (): Promise<void> => {
  const limit = 200;
  let offset = 0;
  let allPrompts: PromptSummary[] = [];
  let total_count = 0;

  dispatch(setLoading(true));

  const firstResult = await getPrompts({ limit, offset });

  if (firstResult) {
    const { meta, data: firstBatch } = firstResult;
    total_count = meta.total_count;
    allPrompts = [...firstBatch];

    while (allPrompts.length < total_count) {
      offset += limit;

      const nextResult = await getPrompts({ limit, offset });
      if (nextResult) {
        const nextBatch = nextResult.data;
        allPrompts = [...allPrompts, ...nextBatch];
      }
    }

    const processedPrompts = allPrompts.map(
      (p: PromptSummary) =>
        ({
          ...p,
          ...p.properties,
          status: moment(p?.properties?.prompt_schedule_time).isAfter() ? 'Scheduled' : 'Completed',
          promptType:
            promptTypes.find((type) => type.id === p?.properties?.prompt_cta_type)?.label ||
            'Unknown',
        }) satisfies PromptSummary
    );

    dispatch(setAllPrompts(processedPrompts));
  }
};

export const getPromptAttributes = async (): Promise<AxiosResponse<PromptAttributeResponse>> => {
  const getTags = true;
  const getCategories = true;

  const attributes = [...(getTags ? ['tags'] : []), ...(getCategories ? ['categories'] : [])];

  const paramString = `attributes=${attributes.join(',')}`;

  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `content/v1/prompt-attributes?${paramString}`,
    method: 'GET',
  };

  const result = await ApiRequest(config);
  return result;
};

export interface PromptAttributeResponse {
  tags: PromptTagResponse[];
  categories: PromptCategoryResponse[];
}

export interface PromptTagResponse {
  id: number;
  key: string;
  name: string;
}

export interface PromptCategoryResponse {
  id: number;
  key: string;
  display_name: string;
  sort: string;
}

export const deletePrompt = async (promptId: string): Promise<boolean> => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `content/v1/prompt/${promptId}`,
    method: 'DELETE',
  };
  const response = await ApiRequest(config);

  if (response?.status === 204) {
    return true;
  }
  return false;
};
