import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { NavLink as RCTNavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';
import { colors } from 'styles/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(8),
    },
    routeText: {
      color: colors.slateGrey,
      fontSize: 16,
      marginBottom: 4,
      marginLeft: 0,
      padding: 0,
      fontWeight: 'bold',
      height: 20,
      letterSpacing: 0,
      textDecoration: 'none',
    },
    activeRouteText: {
      color: theme.palette.primary.main,
    },
    underline: {
      height: 2,
      marginTop: theme.spacing(2),
      backgroundColor: colors.blue,
      borderRadius: 1,
    },
  })
);

export const NavLink = (props: NavLinkProps) => {
  const classes = useStyles(props);
  const match = useRouteMatch(props.to as string);

  return (
    <div className={classes.root}>
      <RCTNavLink
        {...props}
        activeClassName={classes.activeRouteText}
        className={classes.routeText}
      />
      {match && <div className={classes.underline} />}
    </div>
  );
};
