import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const reviewAndSendStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(20),
    },
    messageSegment: {
      lineHeight: 1.5,
    },
    surveys: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
      height: '13rem',
      overflowY: 'auto',
    },
    survey: {
      display: 'flex',
      marginBottom: theme.spacing(8),
    },
    content: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: '2px solid #979797',
      flex: '1',
    },
    desc: {
      display: 'flex',
      flexDirection: 'column',
      userSelect: 'none',
    },
    title: {
      fontSize: '1.25rem',
    },
    meta: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    metaAvailable: {
      marginRight: theme.spacing(25),
    },
    metaData: {
      marginRight: theme.spacing(12.5),
    },
  })
);

export default reviewAndSendStyles;
