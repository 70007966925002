import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps } from 'react-router';
import Layout from 'common/components/AuthScreenLayout';
import { dispatch } from 'store';
import { logoutAction } from 'auth';

interface ErrorPage extends RouteComponentProps {}

const ErrorPage: React.FC<ErrorPage> = () => {
  useEffect(() => {
    setTimeout(() => dispatch(logoutAction()), 5000);
  }, []);
  return (
    <Layout>
      <Typography variant="h2" component="h1" align="center" gutterBottom>
        An error has occured
      </Typography>
    </Layout>
  );
};

export default ErrorPage;
