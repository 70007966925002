import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContextualNudgeItemType } from './ContextualNudge.types';
import { dispatch, useAppSelector } from 'store';
import { setNudges } from './ContextualNudge.slice';
import { RoutePath } from 'types/routes';
import { ContextualNudgeForm } from './ContextualNudgeForm';
import { contextualNudgePrompts } from 'prompts/prompts';
import { getEditContextualNudgeFormInitialValues } from './ContextualNudge.utils';

export function EditContextualNudge() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { nudges } = useAppSelector((state) => state.contextualNudge);
  const nudge = useMemo(() => nudges[id], [id, nudges]);

  const onSubmit = (values: ContextualNudgeItemType) => {
    dispatch(setNudges(values));
    history.push(RoutePath.ContextualNudge);
  };

  return (
    <ContextualNudgeForm
      editMode
      title={contextualNudgePrompts.updatePrompt}
      onSubmit={onSubmit}
      initialValues={getEditContextualNudgeFormInitialValues(nudge)}
    />
  );
}
