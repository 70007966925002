import React, { useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GroovModal } from 'common/components/Modal/GroovModal';
import { GroovModalCloseButton } from 'common/components/Modal/GroovModalCloseButton';
import { GroovModalCard } from 'common/components/Modal/GroovModalCard';
import { Button, ToasterUtils } from 'common';
import { CsvContentsList } from 'common/components/CsvContentsList';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { colors } from 'styles/colors';
import { EmailResultList } from 'common/components/EmailResultList';
import { useInstallTeams } from './useInstallTeams';
import { useEmailListFromCsv } from './useEmailListFromCsv';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '16px',
  },
  body: {
    marginBottom: '8px',
  },
  button: {
    width: '120px',
    marginTop: '16px',
    marginBottom: '8px',
    padding: '8px',
  },
  emailContainer: {
    marginTop: '8px',
    marginLeft: '16px',
    maxHeight: '100%',
    width: '100%',
    overflow: 'auto',
  },
  link: {
    color: colors.blue,
    cursor: 'pointer',
  },
}));

const CsvFileUploadButton = ({ onFileChange }: { onFileChange: (file?: File) => void }) => {
  const classes = useStyles();
  const fileRef = useRef<HTMLInputElement>(null);

  const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files ? event.target.files[0] : undefined;
    onFileChange(file);
  };

  const handleAddEmailsClick = () => {
    fileRef.current?.click();
  };

  return (
    <>
      <input
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          onChangeFile(e);
          e.target.value = '';
        }}
        accept=".csv"
      />
      <Button onClick={handleAddEmailsClick} className={classes.button}>
        Upload CSV
      </Button>
    </>
  );
};

type Props = {
  showModal: boolean;
  closeModal: () => void;
  onModalClose?: () => void;
};

export const TeamsCohortInstallModal = ({
  closeModal,
  showModal,
  onModalClose = () => {},
}: Props) => {
  const classes = useStyles();
  const onError = () => {
    ToasterUtils.error('An error occurred while processing your request. Please try again');
  };

  const {
    isLoading,
    installedUsers,
    installTeamsForUsers,
    reset: resetInstallTeams,
  } = useInstallTeams({ onError });

  const { emails, addEmailFile, reset: resetEmailCSV } = useEmailListFromCsv();

  const [usersFile, setUsersFile] = useState<File | undefined>(undefined);

  const handleAddFile = async (file?: File) => {
    if (file) {
      setUsersFile(file);
      await addEmailFile(file);
    }
  };

  const handleRemoveEmailsClick = () => {
    setUsersFile(undefined);
  };

  const handleInstallClick = async () => {
    if (emails && emails.length > 0) {
      await installTeamsForUsers(emails.map((email) => email.mail));
    }
  };

  const hasEmails = !!usersFile && !!emails && emails.length > 0;

  const onCloseModal = () => {
    resetInstallTeams();
    resetEmailCSV();
    setUsersFile(undefined);
    onModalClose();
  };

  return (
    <GroovModal
      isModalShowing={showModal}
      style={{ opacity: 0.5 }}
      childStyle={{ width: '95%', height: '95%' }}
      backdropClick={onCloseModal}
      onModalClosed={onCloseModal}>
      <GroovModalCloseButton onPressed={closeModal} />
      <GroovModalCard style={{ padding: '32px', paddingBottom: '16px' }}>
        <Typography variant="h1" className={classes.title}>
          Install for Select Users
        </Typography>
        <Typography variant="body1" className={classes.body}>
          To install Groov Anywhere for Teams for a select group of users, please upload a CSV file
          containing their email addresses. Ensure the CSV file does not include a header, and that
          each email address is on a separate line. The app will automatically appear in each
          specified user`&apos;`s Teams application within 24 hours. A list of users who received
          the installation will be available once the process is complete.
        </Typography>
        <Typography
          variant="body1"
          className={classes.link}
          onClick={() => window.open('/groov_anywhere_example.csv')}
          display="inline">
          <u>
            <b>Download a sample CSV file</b>
          </u>
        </Typography>

        {!installedUsers && (
          <>
            <CsvFileUploadButton onFileChange={handleAddFile} />
            <Box className={classes.emailContainer}>
              <CsvContentsList
                fileName={usersFile?.name}
                onCloseClick={handleRemoveEmailsClick}
                results={emails}
              />
            </Box>
            <Button
              onClick={handleInstallClick}
              disabled={!hasEmails || isLoading}
              className={classes.button}>
              {isLoading ? <AnimatedSpinner width={24} height={24} /> : 'Install'}
            </Button>
          </>
        )}

        {!!installedUsers && (
          <>
            <br />
            <Typography variant="h3" className={classes.body}>
              Installation complete!
            </Typography>
            <br />
            <Typography variant="h3" className={classes.body}>
              The Groov Anywhere app has been installed for the following users:
            </Typography>
            <Box className={classes.emailContainer}>
              <EmailResultList results={installedUsers} />
            </Box>
            <Button onClick={closeModal} disabled={isLoading} className={classes.button}>
              Close
            </Button>
          </>
        )}
      </GroovModalCard>
    </GroovModal>
  );
};
