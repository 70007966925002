import { ContextualNudgeItemType } from './ContextualNudge.types';

export const getNudges = (state: Record<string, ContextualNudgeItemType>) => {
  return Object.values(state);
};

export const getAddContextualNudgeFormInitialValues = () => {
  return {
    id: window.crypto.randomUUID(),
    name: '',
    description: '',
    group: '',

    triggerType: '',
    triggerCondition: '',
    triggerValue: '',
    triggers: [],
    triggerRelationship: '',

    ruleType: '',
    ruleCondition: '',
    ruleValue: '',
    scheduleRules: [],
    ruleRelationship: '',

    actions: [],
    promptOptionQuestion: '',
    promptOptionResponse: '',

    promptDetails: '',
  };
};

export const getEditContextualNudgeFormInitialValues = (nudge: ContextualNudgeItemType) => {
  return {
    name: nudge.name,
    description: nudge.description,
    group: nudge.group,
    promptDetails: nudge.promptDetails,
    id: nudge.id,
    scheduleRules: nudge.scheduleRules,
    triggerRelationship: nudge.triggerRelationship,
    ruleRelationship: nudge.ruleRelationship,
    actions: nudge.actions,
    triggers: nudge.triggers,
    promptOptionQuestion: '',
    promptOptionResponse: '',
    triggerType: '',
    triggerCondition: '',
    triggerValue: '',
    ruleType: '',
    ruleCondition: '',
    ruleValue: '',
  };
};

export const promptTriggerOptions = [
  {
    label: 'Meeting Duration',
    conditions: {
      options: ['Equals', 'Less than', 'Greater than'],
      type: 'text',
    },
    values: {
      options: ['0:30', '0:60', '0:90'],
      type: 'text',
    },
  },
  {
    label: 'Meeting Participants',
    conditions: {
      options: ['Equals', 'Less than', 'Greater than'],
      type: 'text',
    },
    values: {
      options: [],
      type: 'number',
      isInput: true,
    },
  },
  {
    label: 'Meeting Location',
    conditions: {
      options: ['Is'],
      type: 'text',
    },
    values: {
      options: [
        'Auckland',
        'Wellington',
        'Christchurch',
        'San Francisco',
        'Santa Monica',
        'Virtual',
      ],
      type: 'text',
    },
  },
  {
    label: 'Lead Status',
    conditions: {
      options: ['Equals', 'Less than', 'Greater than'],
      type: 'text',
    },
    values: {
      options: ['10%', '30%', '50%', '70%', '90%', '100%'],
      type: 'number',
      disabled: true,
    },
  },
  {
    label: 'Task List',
    conditions: {
      options: ['Equals', 'Less than', 'Greater than'],
      type: 'text',
    },
    values: {
      options: [],
      type: 'number',
      isInput: true,
    },
  },
];

export const promptRuleOptions = [
  {
    label: 'Prompt Timing',
    conditions: {
      options: ['Equals'],
      type: 'text',
    },
    values: {
      options: ['Start of day', 'Immediately'],
      type: 'text',
    },
  },
  {
    label: 'Not on a call/meeting',
    conditions: {
      options: [],
      type: 'text',
      disabled: true,
    },
    values: {
      options: [],
      type: 'number',
      disabled: true,
    },
  },
  {
    label: 'Scheduled',
    conditions: {
      options: ['Equals'],
      type: 'text',
    },
    values: {
      options: [],
      type: 'datetime-local',
      isInput: true,
    },
  },
];

export const groupOptions = ['All', 'Managers', 'Leadership', 'Auckland HQ', 'Sales Department'];

export const promptScheduleOptions = [
  {
    label: 'Immediate',
    conditions: {
      type: 'text',
      show: false,
    },
  },
  {
    label: 'Scheduled',
    conditions: {
      type: 'datetime-local',
      show: true,
    },
  },
];

export const getSelectedPromptTrigger = (value: string) => {
  return promptTriggerOptions.filter((op) => op.label === value)?.[0];
};

export const getSelectedPromptRule = (value: string) => {
  return promptRuleOptions.filter((op) => op.label === value)?.[0];
};

export const getFilteredOptions = (options: string[], value: string) => {
  return options.filter((o) => o.toLowerCase().includes(value.toLowerCase()));
};

export const getSelectedScheduleOption = (value: string) => {
  return promptScheduleOptions.filter((op) => op.label === value)?.[0];
};

export function generateCombinations(conditions: string[]): string[] {
  const allCombinations: string[] = [];

  for (let r = 1; r <= conditions.length; r++) {
    for (const combo of combinations(conditions, r)) {
      if (combo.length === 1) {
        allCombinations.push(combo[0]);
      } else {
        const andCombination = combo.join(' AND ');
        const orCombination = combo.join(' OR ');
        allCombinations.push(andCombination, orCombination);
      }
    }
  }
  if (conditions.length > 2) {
    for (let i = 0; i < conditions.length; i++) {
      for (let j = i + 1; j < conditions.length; j++) {
        if (conditions[i] !== conditions[j]) {
          allCombinations.push(
            `(${conditions[i]} AND ${conditions[j]}) AND ${conditions.filter((_, index) => index !== i && index !== j).join(' AND ')}`
          );
          allCombinations.push(
            `(${conditions[i]} AND ${conditions.filter((_, index) => index !== i && index !== j).join(' AND ')}) AND ${conditions[j]}`
          );

          // OR combinations
          allCombinations.push(
            `(${conditions[i]} OR ${conditions[j]}) OR ${conditions.filter((_, index) => index !== i && index !== j).join(' OR ')}`
          );
          allCombinations.push(
            `(${conditions[i]} OR ${conditions.filter((_, index) => index !== i && index !== j).join(' OR ')}) OR ${conditions[j]}`
          );
        }
      }
    }
  }

  return Array.from(new Set(allCombinations));
}

function combinations(arr: string[], r: number): string[][] {
  function generateCombination(index: number, start: number, selected: string[]): string[][] {
    const combinations: string[][] = [];

    if (selected.length === r || index === arr.length) {
      if (selected.length === r) {
        combinations.push([...selected]);
      }
      return combinations;
    }

    for (let i = start; i < arr.length; i++) {
      combinations.push(...generateCombination(index + 1, i + 1, [...selected, arr[i]]));
    }
    return combinations;
  }

  return generateCombination(0, 0, []);
}
