import * as React from 'react';
import { RouteType } from 'types/routes';

export type Action = { type: 'setRouteType'; payload: RouteType };

export type Dispatch = (action: Action) => void;
type State = {
  codeVerified: boolean;
  voucherCode: string;
  username: string;
  resetPasswordCode: string;
  routeType: RouteType;
};
type CountProviderProps = { children: React.ReactNode };
export enum LocalStorageKey {
  UserToken = 'userToken',
  OrgId = 'orgId',
}

const AppStateContext = React.createContext<State | undefined>(undefined);
const AppDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function AppReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setRouteType': {
      return { ...state, routeType: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function AppProvider({ children }: CountProviderProps) {
  const [state, dispatch] = React.useReducer(AppReducer, {
    codeVerified: false,
    voucherCode: '',
    username: '',
    resetPasswordCode: '',

    routeType: RouteType.Public,
  });
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppDispatchProvider');
  }
  return context;
}

export { AppProvider, useAppState, useAppDispatch };
