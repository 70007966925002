import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const surveyModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '0.75rem',
      boxShadow: theme.shadows[10],
      padding: theme.spacing(13),
      display: 'flex',
      flexDirection: 'column',
      width: '60rem',
      height: '45rem',
    },
    modalIcon: {
      color: colors.white,
      height: '3.25rem',
      width: '3.25rem',
      marginBottom: theme.spacing(10),
      cursor: 'pointer',
    },
    title: {
      textAlign: 'center',
      userSelect: 'none',
    },
    submit: {
      textAlign: 'center',
      marginTop: theme.spacing(11),
    },
    submitButton: {
      '&:disabled': {
        color: colors.white,
        backgroundColor: colors.gray1,
      },
    },
    loader: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      marginTop: theme.spacing(11),
    },
    loaderMessage: {
      marginLeft: theme.spacing(2.5),
    },
  })
);

export default surveyModalStyles;
