import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const ritualStyles = makeStyles((theme: Theme) =>
  createStyles({
    rituals: {
      minHeight: '30rem',
      margin: '2.75rem 1.5rem 2rem',
    },
    atAGlanceTitle: {
      color: theme.palette.common.black,
      fontSize: 16,
      marginBottom: '1.5rem',
      fontWeight: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
    },
    results: {
      marginBottom: '2rem',
    },
  })
);

export default ritualStyles;
