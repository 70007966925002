import React from 'react';
import { Grid, createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Analytics from '../../utils/AnalyticsHelper';
import { colors } from '../../styles/colors';
const styles = createStyles({
  boldText: {
    fontWeight: 600,
    fontSize: 14,
    color: colors.blue,
    textDecoration: 'none',
  },
});

interface FooterProps {
  classes: {
    boldText: string;
  };
}

const contactus_data = {
  contactus_url: 'https://groovnow.com/groov-customer-service/',
  contactus_link_text: 'Contact Support',
  contactus_text: 'Having Trouble?',
  customer_service_text: 'Customer Service',
};

const supportUrl = contactus_data.contactus_url;

const CustomerServiceFooter: React.FC<FooterProps> = ({ classes }) => {
  const handleClick = () => {
    Analytics.trackEvent('ContactSupportClicked');
  };
  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <a
          className={classes.boldText}
          onClick={handleClick}
          href={supportUrl}
          target="_blank"
          rel="noopener noreferrer">
          {contactus_data.customer_service_text}
        </a>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomerServiceFooter);
