import { useEffect } from 'react';
import { getPowerBIEmbedToken } from 'slices/powerBI';
import { useAppDispatch, useAppSelector } from 'store';

export const useEmbedTokenV2 = (reportId: string) => {
  const dispatch = useAppDispatch();
  const powerBIEmbedToken = useAppSelector((state) => state.powerBI.embedTokens[reportId]);
  useEffect(() => {
    dispatch(getPowerBIEmbedToken(reportId));
  }, [reportId]);

  return powerBIEmbedToken;
};
