import { Box, Container, Typography } from '@material-ui/core';
import { Button, SearchBar } from 'common';
import React, { useEffect, useState } from 'react';
import { AddUsersPopup } from './AddUsersPopup';
import { UserTable } from './UserTable';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { UserRole } from '../../types';

export type UserManagementProps = {
  onAddUser?: (values: UserRole[]) => Promise<void>;
  users: UserRole[];
  title: string;
  subTitle: string;
  selectableUsers?: UserRole[];
  onRemoveUser?: (values: string) => Promise<void>;
};

const searchKeys: (keyof UserRole)[] = ['email', 'name'];

export function UserManagement({
  title,
  subTitle,
  onAddUser,
  onRemoveUser,
  selectableUsers,
  users,
}: UserManagementProps) {
  const classes = useStyles();
  const [showAddUsersPopup, setShowAddUsersPopup] = useState(false);
  const [searchResults, setSearchResults] = useState<UserRole[]>([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setSearchResults(users);
    setQuery('');
  }, [users]);

  return (
    <Container>
      <AddUsersPopup
        open={showAddUsersPopup}
        onAddUsers={onAddUser}
        onClose={() => setShowAddUsersPopup(false)}
        records={selectableUsers ?? []}
      />
      <Box className={classes.headerContainer}>
        <Typography variant="h3">{title}</Typography>
        {onAddUser && selectableUsers && (
          <Button onClick={() => setShowAddUsersPopup(true)}>
            <Typography>Add user</Typography>
          </Button>
        )}
      </Box>
      <Typography variant="body1">{subTitle}</Typography>
      <SearchBar
        records={users ?? []}
        searchKeys={searchKeys}
        setResults={(recs) => setSearchResults(recs)}
        onQueryChange={setQuery}
        query={query}
      />
      <UserTable rows={query.length > 0 ? searchResults : users} onRemove={onRemoveUser} />
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      height: 50,
    },
  })
);
