import React from 'react';
import { TableCell } from 'common/components/Table/TableCell';
import { Typography } from '@material-ui/core';

export const EapTableBodyCell = (data: {
  text?: string;
  tableCellClassName?: string;
  typographyClassName?: string;
}) => {
  return (
    <TableCell className={data.tableCellClassName}>
      <Typography className={data.typographyClassName} noWrap>
        {data.text}
      </Typography>
    </TableCell>
  );
};
