import React from 'react';
import { Typography, Box } from '@material-ui/core';

import surveyProgressStyles from './styles';

export const SurveyProgress: React.FC<{ active: number; total: number }> = (props) => {
  const classes = surveyProgressStyles(props);
  const { active, total } = props;
  if (!active || !total || active <= 0 || active > total) {
    return null;
  }
  const formCircles = () => {
    const circles = [];
    for (let i = 0; i < total; i++) {
      const circleClasses = [classes.progressCircle];
      if (i + 1 === active) {
        circleClasses.push(classes.progressCircleActive);
      }
      circles.push(<Box key={i} className={circleClasses.join(' ')} />);
    }
    return circles;
  };
  return (
    <Box className={classes.progressBar}>
      <Typography
        variant="subtitle1"
        component="p"
        className={classes.progressCount}
        data-testid="hub_modal_survey_progress_count">
        {active + '/' + total}
      </Typography>
      <Box className={classes.progressCircles} data-testid="hub_modal_survey_progress_bar">
        {formCircles()}
      </Box>
    </Box>
  );
};
