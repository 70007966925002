import React, { useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography, InputAdornment, Paper } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';
import { Link, Button, Input, Card, ToasterUtils, ContainerLayout } from 'common';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { addUser, removeUser, addUserCSV, removeUserCSV } from '../../services/api';

import { UserAltIcon } from 'assets/icons/user-alt';
import Analytics from '../../utils/AnalyticsHelper';
import { instructions, ManageUserTilePrompts, toasterPrompts } from 'prompts/prompts';
import { emailHashHelper } from 'utils/EmailHashHelper';
import { AxiosResponse } from 'axios';
import { EmailUploadReportModal, ReportGroup, ReportMessage } from './EmailUploadReportModal';
import { CSVProcessingAddedResponse, CSVProcessingRemovedResponse } from './types';
import {
  processEmailUploadAddedResponse,
  processEmailUploadRemovedResponse,
} from './EmailUploadResponseProcessor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      maxWidth: 1200,
    },
    bottomCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
    },
    inputContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(6),
    },
    bulkInputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: theme.spacing(10),
    },
    bulkInputAction: {
      color: theme.palette.primary.main,
      fontFamily: 'Averta-Semibold',
      fontWeight: 500,
      marginLeft: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
      },
    },
    inputText: {
      marginTop: 0,
      width: 312,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    serverError: {
      width: '100%',
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(2),
    },
    snackbar: {
      width: 480,
      height: 80,
      background: 'rgb(255, 255, 255)',
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.5)',
      borderRadius: 12,
    },
    userTile: {
      marginTop: theme.spacing(6),
    },
    title: {
      marginTop: theme.spacing(4),
    },
  })
);
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
});

type ManageUserType = 'ADD' | 'REMOVE';

interface FormikCustomValues {
  email: string;
}

interface TileComponentTitleProps {
  title: string;
  instructions: string;
  manageUserType: ManageUserType;
}

interface ManageUserButtonProps {
  isSubmitting: boolean;
  email: string;
  buttonText: string;
}

interface ManageUserCsvLinkProps {
  fileRef?: React.LegacyRef<HTMLInputElement>;
  manageUserType: ManageUserType;
  onBulkChangeClick: () => void;
  bulkChangeLoaderText: string;
  bulkChangeLoaderState: boolean;
}
type FormButtonGroupProps = ManageUserButtonProps & ManageUserCsvLinkProps;

type ManageUserFormProps = Omit<FormButtonGroupProps, 'isSubmitting' | 'email'> & {
  placeholderText: string;
  onSubmit: (
    values: FormikCustomValues,
    props: FormikHelpers<FormikCustomValues>
  ) => void | Promise<any>;
};

type ManageUserTileComponentProps = TileComponentTitleProps & ManageUserFormProps;

export const ManageUsers: React.FC = (props) => {
  const classes = useStyles(props);
  const addUserInputFile = useRef<HTMLInputElement>(null);
  const removeUserInputFile = useRef<HTMLInputElement>(null);
  const [bulkAddLoader, setBulkAddLoader] = useState(false);
  const [bulkRemoveLoader, setBulkRemoveLoader] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadMessage, setUploadMessage] = useState([{ group: ReportGroup.Added, message: '' }]);

  const CSVMesages = {
    success(msg: ReportMessage[]) {
      this.message(msg, 'success');
    },
    message(msg: ReportMessage[], type: string) {
      setUploadMessage(msg);
    },
  };

  const addUserEmail = async (email: string) => {
    const res = await addUser(email);
    if (res.status === 201) {
      ToasterUtils.success(toasterPrompts.messages.user.addSuccess);
    }
  };

  const removeUserEmail = async (email: string) => {
    if (emailHashHelper.isLoggedInUserEmail(email)) {
      return ToasterUtils.error(toasterPrompts.messages.user.removeOneselfError);
    }
    const res = await removeUser(email);
    if (res.status === 200) {
      ToasterUtils.success(toasterPrompts.messages.user.removeSuccess);
    }
  };

  const bulkAddCsv = () => {
    addUserInputFile.current?.click();
  };

  const bulkRemoveCsv = () => {
    removeUserInputFile.current?.click();
  };

  const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>, type: ManageUserType) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files ? event.target.files[0] : undefined;
    switch (type) {
      case 'ADD':
        await handleAddFile(file);
        break;
      case 'REMOVE':
        await handleRemoveFile(file);
        break;
      default:
        break;
    }
  };

  const handleAddFile = async (file?: File) => {
    if (file) {
      setBulkAddLoader(true);
      const res = await addUserCSV(file);
      setBulkAddLoader(false);
      handleChangeFileAddResponse(res);
    }
  };

  const handleRemoveFile = async (file?: File) => {
    if (file) {
      setBulkRemoveLoader(true);
      const res = await removeUserCSV(file);
      setBulkRemoveLoader(false);

      handleChangeFileRemoveResponse(res);
    }
  };

  const handleChangeFileAddResponse = (response: AxiosResponse<any>) => {
    if (response.status === 201) {
      const messages = processEmailUploadAddedResponse(response.data as CSVProcessingAddedResponse);
      CSVMesages.success(messages);
      setUploadSuccess(true);
    }
  };

  const handleChangeFileRemoveResponse = (response: AxiosResponse<any>) => {
    if (response.status === 200) {
      const messages = processEmailUploadRemovedResponse(
        response.data as CSVProcessingRemovedResponse
      );
      CSVMesages.success(messages);
      setUploadSuccess(true);
    }
  };

  const TileComponentTitle = ({ title, instructions, manageUserType }: TileComponentTitleProps) => {
    return (
      <div className={classes.bottomCardWrapper}>
        <Typography
          variant="h3"
          data-testid={'manage_' + manageUserType.toLocaleLowerCase() + '_title'}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          style={{ marginTop: 22 }}
          data-testid={'manage_' + manageUserType.toLocaleLowerCase() + '_description'}>
          {instructions}
        </Typography>
      </div>
    );
  };

  const ManageUserButton = ({ isSubmitting, email, buttonText }: ManageUserButtonProps) => {
    return (
      <Button
        disabled={isSubmitting}
        type="submit"
        style={{
          width: 130,
          backgroundColor: email ? colors.blue : colors.lightBlue,
        }}
        data-testid={'manage_' + buttonText.toLocaleLowerCase() + '_button'}>
        {isSubmitting ? <AnimatedSpinner height={25} width={25} /> : buttonText}
      </Button>
    );
  };

  const ManageUserCsvLink = ({
    fileRef,
    manageUserType,
    onBulkChangeClick,
    bulkChangeLoaderState,
    bulkChangeLoaderText,
  }: ManageUserCsvLinkProps) => {
    return (
      <Link data-testid={'manage_' + manageUserType.toLocaleLowerCase() + '_bulk_link'}>
        <input
          type="file"
          ref={fileRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            onChangeFile(e, manageUserType);
            e.target.value = '';
          }}
          accept=".csv"
        />

        <Typography variant="h4" className={classes.bulkInputAction} onClick={onBulkChangeClick}>
          {bulkChangeLoaderState ? (
            <AnimatedSpinner height={25} width={25} />
          ) : (
            bulkChangeLoaderText
          )}
        </Typography>
      </Link>
    );
  };

  const ManageUserTileFormButtons = ({
    isSubmitting,
    email,
    buttonText,
    fileRef,
    manageUserType,
    onBulkChangeClick,
    bulkChangeLoaderState,
    bulkChangeLoaderText,
  }: FormButtonGroupProps) => {
    return (
      <div className={classes.bulkInputWrapper}>
        <ManageUserButton isSubmitting={isSubmitting} email={email} buttonText={buttonText} />
        <ManageUserCsvLink
          fileRef={fileRef}
          manageUserType={manageUserType}
          onBulkChangeClick={onBulkChangeClick}
          bulkChangeLoaderState={bulkChangeLoaderState}
          bulkChangeLoaderText={bulkChangeLoaderText}
        />
      </div>
    );
  };

  const InputIcon = () => {
    return (
      <InputAdornment position="start" style={{ marginLeft: '16px' }}>
        <UserAltIcon color={colors.gray1} />
      </InputAdornment>
    );
  };

  const ManageUserTileForm = ({
    buttonText,
    fileRef,
    manageUserType,
    onBulkChangeClick,
    bulkChangeLoaderState,
    bulkChangeLoaderText,
    onSubmit,
    placeholderText,
  }: ManageUserFormProps) => {
    const initialFormikValues: FormikCustomValues = { email: '' };
    return (
      <Formik
        initialValues={initialFormikValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {(props) => {
          const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } =
            props;
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.inputContainer}>
                <Input
                  error={Boolean(errors.email) && touched.email}
                  name="email"
                  placeholder={placeholderText}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.email && touched.email && 'Email Required'}
                  className={classes.inputText}
                  InputProps={{
                    startAdornment: InputIcon(),
                  }}
                  data-testid={'manage_' + manageUserType.toLocaleLowerCase() + '_email_input'}
                />
                <ManageUserTileFormButtons
                  isSubmitting={isSubmitting}
                  email={values.email}
                  buttonText={buttonText}
                  fileRef={fileRef}
                  manageUserType={manageUserType}
                  onBulkChangeClick={onBulkChangeClick}
                  bulkChangeLoaderState={bulkChangeLoaderState}
                  bulkChangeLoaderText={bulkChangeLoaderText}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  const ManageUserTileComponent = ({
    title,
    instructions,
    buttonText,
    fileRef,
    manageUserType,
    onBulkChangeClick,
    bulkChangeLoaderState,
    bulkChangeLoaderText,
    onSubmit,
    placeholderText,
  }: ManageUserTileComponentProps) => {
    return (
      <Grid item xs={12} className={classes.userTile}>
        <TileComponentTitle
          title={title}
          instructions={instructions}
          manageUserType={manageUserType}
        />
        <ManageUserTileForm
          buttonText={buttonText}
          fileRef={fileRef}
          manageUserType={manageUserType}
          onBulkChangeClick={onBulkChangeClick}
          bulkChangeLoaderState={bulkChangeLoaderState}
          bulkChangeLoaderText={bulkChangeLoaderText}
          onSubmit={onSubmit}
          placeholderText={placeholderText}
        />
        <EmailUploadReportModal
          openModal={uploadSuccess}
          setOpenModal={setUploadSuccess}
          reportText={uploadMessage}></EmailUploadReportModal>
      </Grid>
    );
  };

  const AddIndividualTileComponent = () => {
    const onAddSubmit = async (
      values: FormikCustomValues,
      { setSubmitting }: FormikHelpers<FormikCustomValues>
    ) => {
      Analytics.trackEvent('AddUserEmail');
      const { email } = values;
      setSubmitting(true);
      await addUserEmail(email);
      values.email = '';
      setSubmitting(false);
    };

    return (
      <ManageUserTileComponent
        title={ManageUserTilePrompts.addTile.title}
        instructions={instructions.addOrRemovePage.addIndividual}
        onSubmit={onAddSubmit}
        placeholderText={ManageUserTilePrompts.addTile.placeholder}
        buttonText={ManageUserTilePrompts.addTile.buttonText}
        fileRef={addUserInputFile}
        manageUserType={'ADD' as ManageUserType}
        bulkChangeLoaderState={bulkAddLoader}
        bulkChangeLoaderText={ManageUserTilePrompts.addTile.linkText}
        onBulkChangeClick={() => bulkAddCsv()}
      />
    );
  };

  const RemoveIndividualTileComponent = () => {
    const onRemoveSubmit = async (
      values: FormikCustomValues,
      { setSubmitting }: FormikHelpers<FormikCustomValues>
    ) => {
      Analytics.trackEvent('RemoveUserEmail');
      const { email } = values;
      setSubmitting(true);
      await removeUserEmail(email);
      values.email = '';
      setSubmitting(false);
    };

    return (
      <ManageUserTileComponent
        title={ManageUserTilePrompts.removeTile.title}
        instructions={instructions.addOrRemovePage.removeIndividual}
        onSubmit={onRemoveSubmit}
        placeholderText={ManageUserTilePrompts.removeTile.placeholder}
        buttonText={ManageUserTilePrompts.removeTile.buttonText}
        fileRef={removeUserInputFile}
        manageUserType={'REMOVE' as ManageUserType}
        bulkChangeLoaderState={bulkRemoveLoader}
        bulkChangeLoaderText={ManageUserTilePrompts.removeTile.linkText}
        onBulkChangeClick={() => bulkRemoveCsv()}
      />
    );
  };

  return (
    <ContainerLayout>
      <Card>
        <Grid>
          <Typography
            variant="h2"
            component="h2"
            className={classes.title}
            data-testid="manage_page_title">
            {ManageUserTilePrompts.title}
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <AddIndividualTileComponent />
          <RemoveIndividualTileComponent />
        </Grid>
      </Card>
    </ContainerLayout>
  );
};
