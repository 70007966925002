import React from 'react';
import { Box } from '@material-ui/core';

import confirmSendStyles from './styles';

export const ConfirmSend: React.FC<{ emailTemplate?: string; message: string }> = (props) => {
  const classes = confirmSendStyles(props);
  const { emailTemplate, message } = props;
  return (
    <Box className={classes.root}>
      <Box className={classes.emailTemplate} data-testid="hub_modal_survey_email_template">
        {emailTemplate ? (
          <Box dangerouslySetInnerHTML={{ __html: emailTemplate }}></Box>
        ) : (
          <Box>{message}</Box>
        )}
      </Box>
    </Box>
  );
};
