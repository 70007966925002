import * as React from 'react';
import { useFlags, useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';

const FEATURE_FLAG_CLIENT_ID = process.env.REACT_APP_FEATURE_FLAG_CLIENT_ID || '';

interface LDFeatureFlags {
  enableHubWellbeingActivitiesRituals: boolean;
  enableHubAppUsageReports: boolean;
  useNewAppHeader: boolean;
  groovAnywhereUserSubsetTeams: boolean;
  groovAnywhereOffboarding: boolean;
  gaNewOnboarding: boolean;
  gaDisableCreateApp: boolean;
  groovAnywhereIntegrations: boolean;
  enableContextualNudge: boolean;
}

export interface FeatureFlagSet {
  enableHubWellbeingActivitiesRituals: boolean;
  enableHubAppUsageReports: boolean;
  useNewAppHeader: boolean;
  groovAnywhereUserSubsetTeams: boolean;
  groovAnywhereOffboarding: boolean;
  gaNewOnboarding: boolean;
  gaDisableCreateApp: boolean;
  groovAnywhereIntegrations: boolean;
  enableContextualNudge: boolean;
}

const defaultFeatureFlags: FeatureFlagSet = {
  enableHubWellbeingActivitiesRituals: false,
  enableHubAppUsageReports: false,
  useNewAppHeader: false,
  groovAnywhereUserSubsetTeams: false,
  groovAnywhereOffboarding: false,
  gaNewOnboarding: false,
  gaDisableCreateApp: false,
  groovAnywhereIntegrations: false,
  enableContextualNudge: false,
};

/* Note: To receive a flag to LaunchDarkly, you must provide the flag key in the flags object below*/

export const useFeatureFlagProvider = (component: React.ComponentType<{}>) => {
  return withLDProvider({
    clientSideID: FEATURE_FLAG_CLIENT_ID,
    options: {
      sendLDHeaders: true,
      /* ... */
    },
    deferInitialization: false,
    flags: {
      'enable-hub-app-usage-reports': true,
      'enable-hub-wellbeing-activities-rituals': true,
      'use-new-app-header': true,
      'groov-anywhere-user-subset-teams': true,
      'groov-anywhere-offboarding': true,
      'ga-new-onboarding': true,
      'ga-disable-create-app': true,
      'groov-anywhere-integrations': true,
      'enable-contextual-nudge': true,
    },
  })(component);
};

export const useFeatureFlagClient = () => {
  return useLDClient();
};

export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = React.useState<LDFeatureFlags>(defaultFeatureFlags);
  const ldFeatureFlags = useFlags() as LDFeatureFlags;

  React.useEffect(() => {
    const featureFlagSet: FeatureFlagSet = { ...defaultFeatureFlags, ...ldFeatureFlags };
    setFeatureFlags(featureFlagSet);
  }, [ldFeatureFlags]);

  return featureFlags;
};
