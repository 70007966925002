import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateEditRitual } from 'common/components/CreateEditRituals';
import { createCompanyRitual, deleteCompanyritual, editCompanyRitual } from 'services/api';
import moment from 'moment';
import { ToasterUtils } from 'common';
import { FormValues, CreateCompanyRitualProps } from 'services/types';
import { SuccessConfirm } from 'common/components/SuccessConfirm';
import { manageRitualPrompts } from 'prompts/prompts';
import { RoutePath } from '../types/routes';

export const CreateCompanyRituals = (props: CreateCompanyRitualProps) => {
  const history = useHistory();
  const [companyRitualId, setCompanyRitualId] = useState('');
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [initialValues, setIntialValues] = useState<FormValues>({
    planDate: '',
    sponsorname: '',
    sponsorRole: '',
    keyMessage: '',
    trigger: '',
    action: '',
    status: false,
    actionPlan: '',
    comments: '',
  });

  useEffect(() => {
    if (props.location.state !== undefined) {
      const { sponsorName, sponsorRole, rituals } = props.location.state;
      setCompanyRitualId(rituals[0].id);
      setIntialValues({
        planDate: rituals[0].planDate,
        sponsorname: sponsorName,
        sponsorRole: sponsorRole,
        keyMessage: rituals[0].keyMessage,
        trigger: rituals[0].trigger,
        action: rituals[0].action,
        status: rituals[0].status,
        actionPlan: rituals[0].actionPlan,
        comments: rituals[0].comments,
      });
    }
  }, [props.location.state]);

  const deleteCompanyRitualById = async () => {
    setIsDeleteLoading(true);
    const res = await deleteCompanyritual(companyRitualId);
    setIsDeleteLoading(false);
    if (res.status === 200) {
      history.push(RoutePath.ManageRituals);
    }
  };

  const deleteRituals = () => {
    if (companyRitualId) {
      ToasterUtils.confirm({
        onConfirm: async () => {
          deleteCompanyRitualById();
        },
      });
    }
  };

  const onEditSubmit = async (values: FormValues) => {
    const {
      sponsorname,
      planDate,
      sponsorRole,
      keyMessage,
      trigger,
      action,
      status,
      actionPlan,
      comments,
    } = values;
    const str = moment(planDate).format('DD/MM/YYYY');
    const fdate = str.split('/');
    const dobj = new Date(parseInt(fdate[2]), parseInt(fdate[1]) - 1, parseInt(fdate[0]));
    const plannedDate = dobj.toISOString();
    setIsLoading(true);
    const res = await editCompanyRitual(
      plannedDate,
      sponsorname,
      sponsorRole,
      keyMessage,
      trigger,
      action,
      status,
      actionPlan,
      comments,
      companyRitualId
    );
    if (res.status !== 200 && res.status !== 201) {
      setServerError(true);
    }
    setIsLoading(false);
    if (res.status === 200 || res.status === 201) {
      setConfirmAlert(true);
      // history.goBack();
    }
  };

  const onCreateSubmit = async (values: FormValues) => {
    const {
      sponsorname,
      planDate,
      sponsorRole,
      keyMessage,
      trigger,
      action,
      status,
      actionPlan,
      comments,
    } = values;
    const plannedDate = planDate;
    setIsLoading(true);
    const res = await createCompanyRitual(
      plannedDate,
      sponsorname,
      sponsorRole,
      keyMessage,
      trigger,
      action,
      status,
      actionPlan,
      comments
    );
    if (res.status !== 200 && res.status !== 201) {
      setServerError(true);
    }
    setIsLoading(false);
    if (res.status === 200 || res.status === 201) {
      setConfirmAlert(true);
    }
  };

  const handlegoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <CreateEditRitual
        deleteRituals={deleteRituals}
        serverError={serverError}
        initialValues={initialValues}
        isLoading={isLoading}
        isDeleteLoading={isDeleteLoading}
        isEdit={companyRitualId ? true : false}
        cancleClick={() => history.goBack()}
        onSubmit={companyRitualId ? onEditSubmit : onCreateSubmit}
      />
      <SuccessConfirm
        open={confirmAlert}
        title={
          companyRitualId
            ? manageRitualPrompts.create.editSuccessTitle
            : manageRitualPrompts.create.createSuccessTitle
        }
        description={manageRitualPrompts.create.description}
        confirmButtonText={manageRitualPrompts.create.confirmButtonText}
        handleConfirm={() => handlegoBack()}
      />
    </div>
  );
};
