import React from 'react';
import { Typography, Box, Checkbox } from '@material-ui/core';

import { HubSurveyPrompts } from 'prompts/prompts';
import { Button } from 'common';
import Analytics from 'utils/AnalyticsHelper';

import { Survey, SurveyStatuses } from '../../../../types';
import { getFormattedDate } from '../../../../utils';
import selectSurveyStyles from './styles';
import { HUB_ANALYTICS } from '../../../../constants';

export const SelectSurvey: React.FC<{ surveys: Array<Survey>; onSurveySelection: Function }> = (
  props
) => {
  const classes = selectSurveyStyles(props);
  const { surveys, onSurveySelection } = props;
  const label = HubSurveyPrompts.label;
  const buttonText = HubSurveyPrompts.buttonText;
  const { BUTTON_PREVIEW_EVENT, BUTTON_PREVIEW_SEGMENT } = HUB_ANALYTICS.MANAGE_SURVEY;
  return (
    <Box className={classes.surveys}>
      {surveys
        .filter(
          (survey) =>
            survey.status === SurveyStatuses.OPEN || survey.status === SurveyStatuses.AVAILABLE
        )
        .map((survey: Survey, index) => {
          const titleClasses = [classes.title];
          const availableActiveClasses = [];
          const lastSentClasses = [classes.metaData];
          const closeDateClasses = [classes.metaData];
          if (survey.status === SurveyStatuses.OPEN) {
            titleClasses.push(classes.disabled);
            availableActiveClasses.push(classes.metaData);
            availableActiveClasses.push(classes.disabled);
            lastSentClasses.push(classes.disabled);
            closeDateClasses.push(classes.disabled);
          } else {
            availableActiveClasses.push(classes.metaAvailable);
          }
          return (
            <Box className={classes.survey} key={index}>
              <Checkbox
                checked={survey.selected || false}
                onChange={() => onSurveySelection(survey.id)}
                className={classes.checkbox}
                disabled={survey.status === SurveyStatuses.OPEN}
                data-testid="hub_modal_survey_select_box"
              />
              <Box id="transition-modal-description" className={classes.content}>
                <Box className={classes.desc}>
                  <Typography variant="h3" component="h3" className={titleClasses.join(' ')}>
                    {survey.name}
                  </Typography>
                  <Box className={classes.meta}>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      className={availableActiveClasses.join(' ')}>
                      {survey.status === SurveyStatuses.OPEN ? label.active : label.available}
                    </Typography>
                    {survey.last_sent_date && (
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={lastSentClasses.join(' ')}>
                        {label.lastSentDate.replace(
                          '{date}',
                          getFormattedDate(survey.last_sent_date)
                        )}
                      </Typography>
                    )}
                    {survey.status === SurveyStatuses.OPEN && survey.close_on_date ? (
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={closeDateClasses.join(' ')}>
                        {label.closeOnDate.replace(
                          '{date}',
                          getFormattedDate(survey.close_on_date)
                        )}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
                <Box className={classes.preview}>
                  <Button
                    color="primary"
                    className={classes.prevButton}
                    onClick={() => {
                      Analytics.trackEvent(
                        BUTTON_PREVIEW_EVENT,
                        BUTTON_PREVIEW_SEGMENT,
                        survey.name
                      );
                      window.open(survey.preview_url);
                    }}
                    data-testid="hub_modal_survey_preview_button">
                    {buttonText.preview}
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};
