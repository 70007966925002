import { lowerCase } from 'lodash';
import { UserRole } from 'pages/Settings/types';

export const sortRecords = (
  records: UserRole[],
  orderBy: keyof UserRole,
  order: 'asc' | 'desc'
) => {
  return records.slice().sort((a, b) => {
    const isAsc = order === 'asc';
    if (lowerCase(a[orderBy] as string) < lowerCase(b[orderBy] as string)) {
      return isAsc ? -1 : 1;
    }
    if (lowerCase(a[orderBy] as string) > lowerCase(b[orderBy] as string)) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });
};
