import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { CloseIcon } from 'assets/icons/close-icon';
import { EmailResultList } from 'common/components/EmailResultList';
import { EmailWithStatus } from 'pages/Integrations/Channels/Teams/types';
import { IconButton } from './Button';

export const useStyles = makeStyles(() => ({
  emailListItem: {
    marginBottom: '4px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: '24px',
  },
  emailListContainer: {
    marginBottom: '4px',
    overflow: 'auto',
  },
  removeCsv: {
    marginLeft: '16px',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
  },
  emailContainer: {
    flexDirection: 'row',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  container: {
    flexDirection: 'column',
    display: 'flex',
    flex: '1 1',
  },
}));

interface Props {
  title?: string;
  fileName?: string;
  results?: EmailWithStatus[];
  closeButtonDisabled?: boolean;
  onCloseClick?: () => void;
}

export const CsvContentsList = (props: Props) => {
  const classes = useStyles();
  const { title, fileName, results, closeButtonDisabled, onCloseClick } = props;

  return (
    <Box className={classes.emailContainer}>
      <Box className={classes.container}>
        <Typography variant="h3">{title}</Typography>
        <Box style={{ flexDirection: 'row', display: 'flex', flex: '0 0', marginBottom: '8px' }}>
          {fileName ? (
            <>
              <Typography>{fileName}</Typography>
              <IconButton
                className={classes.removeCsv}
                disabled={closeButtonDisabled}
                style={{ marginLeft: '16px' }}
                onClick={onCloseClick}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <Typography>No file uploaded</Typography>
          )}
        </Box>
        <EmailResultList results={results} />
      </Box>
    </Box>
  );
};
