import React from 'react';
import { useEffect } from 'react';

import { decryptString } from '@groov/ui';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAppDispatch } from '../store';
import { getUserProfileAction, loginFromExternalAction } from 'auth';
import Analytics from 'utils/AnalyticsHelper';
import { emailHashHelper } from 'utils/EmailHashHelper';
import { parseAccessToken } from 'utils/JwtTokenHelper';
import { getOrganizationSettings } from 'slices/appState';
import { useFeatureFlagClient } from 'FeatureFlagContext';
import { useLocation } from 'react-router-dom';

export const externalLoginProvider = (Component: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const featureFlagClient = useFeatureFlagClient();
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
      const externalAccessToken = new URLSearchParams(location.search).get('key') || '';

      const relogIn = async () => {
        const key = process.env.REACT_APP_TOKEN_EXCHANGE_ENCRYPTION_KEY || '';
        const decryptedAccessToken = decryptString(key, decodeURIComponent(externalAccessToken));
        const loginResult = await dispatch(
          loginFromExternalAction({ accessToken: decryptedAccessToken })
        );
        const { accessToken } = unwrapResult(loginResult);
        const user = parseAccessToken(accessToken);
        if (accessToken && user && user.role && user.role.includes('OrgAdmin')) {
          Analytics.trackEvent('LoginCompleted');
          Analytics.setUserProperty('UserId', user.sub);
          Analytics.setUserProperty('OrgId', user.org_id);

          dispatch(getOrganizationSettings(user.org_id));
          const getUserProfileResult = await dispatch(getUserProfileAction());
          const userProfileResult = unwrapResult(getUserProfileResult);
          const { email } = userProfileResult;

          emailHashHelper.saveEmailHash(email);
          await featureFlagClient?.identify({
            email,
            key: user.sub,
            custom: {
              orgId: user.org_id,
              tier: user.sub_tier,
              userId: user.sub,
            },
          });
        }
      };

      const hasAccessToken = !!externalAccessToken;
      if (hasAccessToken) {
        relogIn();
      }
    }, [dispatch, location, featureFlagClient]);

    return <Component {...(props as {})} />;
  };
};
