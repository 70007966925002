import React, { useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Button } from './Button';
import LockImage from '../../assets/images/ic-lock.svg';
import { colors } from '../../styles/colors';
import { Input, defaultTextInputStyle } from './TextInput';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface PasswordInputProps {
  error: boolean;
  placeholder?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const passwordInputStyle = (theme: Theme) => {
  const { inputRoot, root, inner } = defaultTextInputStyle(theme);
  return {
    inputRoot: { ...inputRoot, paddingRight: 4 },
    root: { ...root },
    inner: { ...inner },
    buttonHide: {
      backgroundColor: colors.snow,
    },
    buttonShow: {
      backgroundColor: colors.blue,
    },

    hoverHide: {
      '&:hover, &.Mui-focusVisible': { backgroundColor: colors.blue },
    },
    hoverShow: {
      '&:hover, &.Mui-focusVisible': { backgroundColor: colors.snow },
    },
    lock: {
      width: 20,
      height: 20,
    },
    startAdornment: {
      marginLeft: '16px',
    },
  };
};

const useStyles = makeStyles(passwordInputStyle);

const PasswordInput: React.FC<PasswordInputProps> = ({ ...props }) => {
  const styles = useStyles(props);
  const [showPassword, setShowPassword] = useState(false);

  const renderInputAdornmentStart = () => (
    <InputAdornment position="start" className={styles.startAdornment}>
      <img className={styles.lock} src={LockImage} alt="" />
    </InputAdornment>
  );

  const renderInputAdornmentEnd = () => (
    <InputAdornment position="end">
      <Button
        style={{ paddingTop: 12, paddingBottom: 12, paddingRight: 32, paddingLeft: 32, width: 92 }}
        classes={{
          root: showPassword ? styles.hoverHide : styles.hoverShow,
        }}
        onClick={() => {
          setShowPassword(!showPassword);
        }}>
        {showPassword ? 'Hide' : 'Show'}
      </Button>
    </InputAdornment>
  );

  return (
    <Input
      placeholder={props.placeholder}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        startAdornment: renderInputAdornmentStart(),
        endAdornment: renderInputAdornmentEnd(),
        classes: {
          root: styles.inputRoot,
          input: styles.inner,
        },
      }}
      {...props}
    />
  );
};

export default PasswordInput;
