import { FeatureFlagSet } from 'FeatureFlagContext';
import {
  HubCardPrompts,
  HubSurveyPrompts,
  HubBetaPrompts,
  HubRitualPrompts,
} from 'prompts/prompts';
import {
  PowerBIReportMap,
  Page,
  PowerBIReport,
  CardId,
  TextId,
  SurveyPage,
  SurveyComponents,
  SurveyActions,
  SurveyMenuItem,
  SurveyStatuses,
  ManageSurveyStatuses,
  TrendingItem,
  SurveyPageButtonIds,
  PageId,
  WellbeingCard,
  HubRoute,
  RitualOverviewCard,
  RitualTeam,
} from './types';

export const reportMap: PowerBIReportMap = {
  [PowerBIReport.Wellbeing]: {
    pageName: 'ReportSection29df1ed3f297d442ba66',
    className: 'report-style-class-wellbeing',
  },
  [PowerBIReport.SiPillars]: {
    pageName: 'ReportSection279bd3a56e1201300c07',
    className: 'report-style-class-sipillars',
  },
  [PowerBIReport.Adoption]: {
    pageName: 'ReportSection07d916108897a659a125',
    className: 'report-style-class-adoption',
  },
  [PowerBIReport.Modules]: {
    pageName: 'ReportSection1f0df84c67ec33a71a00',
    className: 'report-style-class-modules',
  },
  [PowerBIReport.Rituals]: {
    pageName: 'ReportSectionf1354b2fd6f2e017f90d',
    className: 'report-style-class-modules',
  },
  [PowerBIReport.Care]: {
    pageName: 'ReportSectiond241edf57987cb7d1e2d',
    className: 'report-style-class-care',
  },
};

export const RITUALS_TEAM_PAGE_NAME = 'ReportSectionc0340c7bc3a001ef0492';

export const cardTitle = {
  [CardId.Wellbeing]: HubCardPrompts.workplace_wellbeing,
  [CardId.Individual]: HubCardPrompts.individual_wellbeing,
  [CardId.Activities]: HubCardPrompts.wellbeing_activities,
  [CardId.Care]: HubCardPrompts.workplace_cares_my_wellbeing,
};

export const pageTitle = {
  [PageId.Wellbeing]: HubCardPrompts.workplace_wellbeing,
  [PageId.Individual]: HubCardPrompts.individual_wellbeing,
  [PageId.Adoption]: HubCardPrompts.app_adoption,
  [PageId.Modules]: HubCardPrompts.modules,
  [PageId.Rituals]: HubCardPrompts.rituals,
  [PageId.Care]: HubCardPrompts.workplace_cares_my_wellbeing,
};

export const STAT_CARD_ID = CardId.Activities;

export const SURVEY_REMINDER_TOGGLE_DEFAULT = true;

export const MODULES_ALL_ID = 'modules_all';

export const RITUAL_LEARN_URL_DEFAULT = 'https://www.resources.groovnow.com/ritual-ideas';

export const OVERVIEW_TEXT = TextId.Overview;

export const REPORT_ID =
  process.env.REACT_APP_HUB_REPORT_ID || '1bd9e074-b271-44ac-a585-ebfb7fc9656f';

export const RITUALS_REPORT_ID = '200750d2-2a96-4909-a2c9-17cc9e9efe7e';

const WellbeingPage = {
  id: PageId.Wellbeing,
  cardId: CardId.Wellbeing,
  textId: TextId.Wellbeing,
  report: PowerBIReport.Wellbeing,
  reports: [
    {
      id: 'wellbeing_1',
      title: 'Workplace mental wellbeing score over time',
    },
    {
      id: 'wellbeing_2',
      title: 'Distribution of scores',
    },
    {
      id: 'wellbeing_3',
      title: 'Average scores by question',
    },
  ],
};

const IndividualPage = {
  id: PageId.Individual,
  cardId: CardId.Individual,
  textId: TextId.Individual,
  report: PowerBIReport.SiPillars,
  reports: [
    {
      id: 'individual_1',
      title: 'Individuals mentel wellbeing score over time',
    },
    {
      id: 'individual_2',
      title: 'Distribution of scores',
    },
    {
      id: 'individual_3',
      title: 'Average scores by question',
    },
  ],
};

const AdoptionPage = {
  id: PageId.Adoption,
  cardId: CardId.Activities,
  textId: TextId.Adoption,
  report: PowerBIReport.Adoption,
  reports: [
    {
      id: 'adoption_1',
      title: 'Adoption Rate Trend',
    },
    {
      id: 'adoption_2',
      title: '3-days app user retention',
    },
    {
      id: 'adoption_3',
      title: '30-days app user retention',
    },
    {
      id: 'adoption_4',
      title: 'Top 10 content pieces',
    },
    {
      id: 'adoption_5',
      title: 'Activity by pillar',
    },
  ],
};

const ModulesPage = {
  id: PageId.Modules,
  cardId: CardId.Activities,
  textId: TextId.Modules,
  report: PowerBIReport.Modules,
  reports: [],
  disabled: false,
};

const RitualsPage = {
  id: PageId.Rituals,
  cardId: CardId.Activities,
  textId: TextId.Rituals,
  report: PowerBIReport.Rituals,
  reports: [],
};

const CarePage = {
  id: PageId.Care,
  cardId: CardId.Care,
  textId: TextId.Care,
  report: PowerBIReport.Care,
  reports: [
    {
      id: 'care_1',
      title: 'My workplaces cares about my wellbeing',
    },
    {
      id: 'care_2',
      title: 'Distribution of scores',
    },
  ],
};

export const getPages = (featureFlags: FeatureFlagSet): Array<Page> => {
  return [
    WellbeingPage,
    IndividualPage,
    AdoptionPage,
    ModulesPage,
    { ...RitualsPage, disabled: !featureFlags.enableHubWellbeingActivitiesRituals },
    CarePage,
  ];
};

export const DEFAULT_RECOMMENDATION_ARRAY = [
  {
    id: OVERVIEW_TEXT,
    text: 'Default overall text',
    collectorId: '',
    timePeriod: '',
    responseCount: 0,
    recipientCount: 0,
    requiredRate: 0,
    responsesRequiredToMeetRate: 0,
    thresholdMet: true,
  },
];

export const DEFAULT_WELLBEING_DATA = {
  surveyId: 's1',
  collector_id: 'c1',
  surveyType: CardId.Wellbeing,
  score: {
    current: 0,
    max: 0,
  },
  response: {
    current: 0,
    max: 0,
  },
  last_sent_date: '',
  close_on_date: '',
  disabled: true,
  status: SurveyStatuses.CLOSED,
};

export const DEFAULT_INDIVIDUAL_DATA = {
  surveyId: 's2',
  collector_id: 'c2',
  surveyType: CardId.Individual,
  score: {
    current: 0,
    max: 0,
  },
  response: {
    current: 0,
    max: 0,
  },
  last_sent_date: '',
  close_on_date: '',
  disabled: true,
  status: SurveyStatuses.CLOSED,
};

export const DEFAULT_CARE_DATA = {
  surveyId: 's1',
  collector_id: 'c3',
  surveyType: CardId.Care,
  score: {
    current: 0,
    max: 0,
  },
  response: {
    current: 0,
    max: 0,
  },
  last_sent_date: '',
  close_on_date: '',
  disabled: true,
  status: SurveyStatuses.CLOSED,
};

export const DEFAULT_SURVEY_DATA = [
  DEFAULT_WELLBEING_DATA,
  DEFAULT_INDIVIDUAL_DATA,
  DEFAULT_CARE_DATA,
];

export const DEFAULT_STAT_DATA = {
  [WellbeingCard.Adoption]: null,
  [WellbeingCard.Modules]: null,
  [WellbeingCard.Rituals]: null,
  disabled: true,
};

const surveyTitle = HubSurveyPrompts.title;
const surveyButtonText = HubSurveyPrompts.buttonText;
const surveyMessage = HubSurveyPrompts.message;
const surveyLabel = HubSurveyPrompts.label;

export const NEW_SURVEY_PAGES: Array<SurveyPage> = [
  {
    title: surveyTitle.selectSurvey,
    pageNumber: 1,
    submitButton: {
      text: surveyButtonText.next,
      action: SurveyActions.NEXT,
    },
    component: SurveyComponents.SELECT_SURVEY,
    buttonId: SurveyPageButtonIds.NEXT,
  },
  {
    title: surveyTitle.addRecipients,
    pageNumber: 2,
    submitButton: {
      text: surveyButtonText.uploadCSV,
      action: SurveyActions.UPLOAD,
    },
    component: SurveyComponents.ADD_RECIPIENTS,
    message: surveyMessage.addRecipients,
    buttonId: SurveyPageButtonIds.UPLOAD,
  },
  {
    title: surveyTitle.selectDate,
    pageNumber: 3,
    submitButton: {
      text: surveyButtonText.next,
      action: SurveyActions.NEXT,
    },
    component: SurveyComponents.SELECT_DATE,
    message: surveyMessage.selectDate,
    buttonId: SurveyPageButtonIds.NEXT,
  },
  {
    title: surveyTitle.reviewAndSend,
    pageNumber: 4,
    submitButton: {
      text: surveyButtonText.preview,
      action: SurveyActions.NEXT,
    },
    component: SurveyComponents.REVIEW_AND_SEND,
    message: surveyMessage.reviewAndSend,
    buttonId: SurveyPageButtonIds.PREVIEW,
  },
  {
    title: surveyTitle.pleaseConfirm,
    pageNumber: 5,
    submitButton: {
      text: surveyButtonText.confirmSend,
      action: SurveyActions.SEND_NOW,
    },
    component: SurveyComponents.CONFIRM_SEND,
    buttonId: SurveyPageButtonIds.SEND,
  },
  {
    title: surveyTitle.surveySent,
    pageNumber: 6,
    submitButton: {
      text: surveyButtonText.backToDashboard,
      action: SurveyActions.CLOSE,
    },
    component: SurveyComponents.SURVEY_SENT,
    message: surveyMessage.surveySent,
    buttonId: SurveyPageButtonIds.DASHBOARD,
  },
];

export const MANGAE_SURVEY_PAGES: Array<SurveyPage> = [
  {
    title: surveyTitle.manageSurveys,
    pageNumber: 0,
    component: SurveyComponents.MANAGE_SURVEYS,
    submitButton: {
      text: surveyButtonText.addMoreRecipients,
      action: SurveyActions.NEXT,
    },
    message: surveyMessage.manageSurvey,
  },
  {
    title: surveyTitle.addMoreRecipients,
    pageNumber: 1,
    submitButton: {
      text: surveyButtonText.uploadCSV,
      action: SurveyActions.UPLOAD,
    },
    component: SurveyComponents.ADD_RECIPIENTS,
    message: surveyMessage.addMoreRecipients,
    buttonId: SurveyPageButtonIds.UPLOAD,
  },
  {
    title: surveyTitle.reviewAndSend,
    pageNumber: 2,
    submitButton: {
      text: surveyButtonText.sendNow,
      action: SurveyActions.SEND_NOW,
    },
    component: SurveyComponents.REVIEW_AND_SEND,
    message: surveyMessage.manageReviewAndSend,
    buttonId: SurveyPageButtonIds.SEND,
  },
  {
    title: surveyTitle.surveySent,
    pageNumber: 3,
    submitButton: {
      text: surveyButtonText.backToDashboard,
      action: SurveyActions.CLOSE,
    },
    component: SurveyComponents.SURVEY_SENT,
    message: surveyMessage.manageSurveySent,
    buttonId: SurveyPageButtonIds.DASHBOARD,
  },
];

export const MANGAE_SURVEY_MENU_ITEMS: Array<SurveyMenuItem> = [
  {
    isActive: true,
    label: surveyLabel.activeSurveys,
    status: SurveyStatuses.OPEN,
    displayStatus: ManageSurveyStatuses.OPEN,
  },
  {
    isActive: false,
    label: surveyLabel.completeSurveys,
    status: SurveyStatuses.CLOSED,
    displayStatus: ManageSurveyStatuses.CLOSED,
  },
];

export const SURVEY_REMINDER_DAYS: number[] = [5];

const trendingFilterLabels = HubBetaPrompts.trendingFilterLabels;
export const TRENDING_ITEMS: TrendingItem[] = [
  {
    label: trendingFilterLabels.none,
    value: 0,
  },
  {
    label: trendingFilterLabels.prev1,
    value: 1,
  },
  {
    label: trendingFilterLabels.prev2,
    value: 2,
  },
  {
    label: trendingFilterLabels.prev3,
    value: 3,
  },
  {
    label: trendingFilterLabels.prev6,
    value: 6,
  },
  {
    label: trendingFilterLabels.prev12,
    value: 12,
  },
  {
    label: trendingFilterLabels.all,
    value: -1,
  },
];

export const TRENDING_ITEMS_ACTIVITIES: TrendingItem[] = [
  {
    label: trendingFilterLabels.prev1,
    value: 1,
  },
  {
    label: trendingFilterLabels.prev2,
    value: 2,
  },
  {
    label: trendingFilterLabels.prev3,
    value: 3,
  },
  {
    label: trendingFilterLabels.prev6,
    value: 6,
  },
  {
    label: trendingFilterLabels.prev12,
    value: 12,
  },
  {
    label: trendingFilterLabels.all,
    value: -1,
  },
];

export const HUB_ROUTES: HubRoute[] = [
  {
    path: '/hub',
    isDefault: true,
  },
  {
    path: '/hub/wellbeing',
    cardId: CardId.Wellbeing,
    pageId: PageId.Wellbeing,
  },
  {
    path: '/hub/individual',
    cardId: CardId.Individual,
    pageId: PageId.Individual,
  },
  {
    path: '/hub/activities/app',
    cardId: CardId.Activities,
    pageId: PageId.Adoption,
  },
  {
    path: '/hub/activities/modules',
    cardId: CardId.Activities,
    pageId: PageId.Modules,
  },
  {
    path: '/hub/activities/rituals',
    cardId: CardId.Activities,
    pageId: PageId.Rituals,
  },
  {
    path: '/hub/care',
    cardId: CardId.Care,
    pageId: PageId.Care,
  },
];

export const HUB_MANAGE_ROUTES: HubRoute[] = [
  {
    path: '/hub/surveys/active',
    isDefault: true,
  },
  {
    path: '/hub/surveys/completed',
  },
  {
    path: '/hub/surveys/new',
  },
  {
    path: '/hub/surveys/manage',
  },
];

export const DEFAULT_OVERVIEW_CARDS: RitualOverviewCard[] = [
  {
    label: HubRitualPrompts.label.companyRituals,
    count: 0,
  },
  {
    label: HubRitualPrompts.label.teamRituals,
    count: 0,
  },
  {
    label: HubRitualPrompts.label.recentRituals,
    count: 0,
  },
];

export const HUB_ANALYTICS = {
  RITUALS: {
    VIEW_MORE_DETAILS_EVENT: 'HubRitualsViewMoreDetailsClicked',
    VIEW_MORE_DETAILS_SEGMENT: 'Ritual Id',
    EMPTY_STATE_LEARN_MORE_EVENT: 'HubRitualsEmptyStateLearnMoreClicked',
  },
  TAB: {
    EVENT: 'HubPageOpened',
    SEGMENT: 'Page',
  },
  DROPDOWN_EVENT: 'HubDropdownSelected',
  MANAGE_SURVEY: {
    BUTTON_OPEN_EVENT: 'HubManageSurveyButtonClicked',
    BUTTON_NEW_EVENT: 'HubManageNewSurveyButtonClicked',
    BUTTON_ADD_RECIPIENTS_EVENT: 'HubManageAddRecipientButtonClicked',
    BUTTON_ADD_RECIPIENTS_SEGMENT: 'Survey',
    BUTTON_PREVIEW_EVENT: 'HubManagePreviewButtonClicked',
    BUTTON_PREVIEW_SEGMENT: 'Survey',
    TAB_EVENT: 'HubManageTabPressed',
    TAB_SEGMENT: 'Tab',
  },
};

export const RITUAL_TEAM_OVERVIEW_ID = '-';

export const RITUAL_TEAMS_DEFAULT: RitualTeam[] = [
  {
    teamId: RITUAL_TEAM_OVERVIEW_ID,
    name: HubRitualPrompts.label.ritualTeamsOveriew,
  },
];
