import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ScoreCard } from './ScoreCard';
import {
  ScoreTypes,
  SurveyCard,
  StatCard,
  CardId,
  CardVariant,
  ScorePercent,
  WellbeingCard,
  SurveyStatuses,
} from '../../types';
import { STAT_CARD_ID, HUB_ROUTES } from '../../constants';
import { numberOfDays, getFormattedScore } from '../../utils';
import scoreCardStyles from './styles';

interface StatsCardContainerProps {
  cards: Array<SurveyCard | StatCard>;
  activeCardId?: CardId;
}

export const StatsCardContainer: React.FC<StatsCardContainerProps> = (props) => {
  const classes = scoreCardStyles(props);
  const { cards, activeCardId } = props;
  const history = useHistory();

  const getScoreType = (card: SurveyCard | StatCard) => {
    if (card.cardId === STAT_CARD_ID) {
      const statCard = card as StatCard;
      const adoptionCard = statCard[WellbeingCard.Adoption];
      const modulesCard = statCard[WellbeingCard.Modules];
      const ritualsCard = statCard[WellbeingCard.Rituals];
      if (
        (adoptionCard && adoptionCard > 0) ||
        (modulesCard && modulesCard > 0) ||
        (ritualsCard && ritualsCard > 0)
      )
        return ScoreTypes.PERCENTAGE;
      return ScoreTypes.UNAVAILABLE;
    }
    const surveyCard = card as SurveyCard;
    if (surveyCard.status === SurveyStatuses.CLOSED && surveyCard.response.max > 0) {
      return ScoreTypes.SCORE;
    } else if (surveyCard.lastSentDate) {
      if (numberOfDays(surveyCard.closeOnDate) >= 1) {
        return ScoreTypes.AVAILABLE;
      } else if (numberOfDays(surveyCard.closeOnDate) > 0) {
        return ScoreTypes.AVAILABLE_TODAY;
      } else {
        return ScoreTypes.INDEFINITE_AVAILABLE;
      }
    }
    return ScoreTypes.UNAVAILABLE;
  };

  const getVariant = (card: SurveyCard | StatCard): CardVariant => {
    if (card.cardId === activeCardId) return CardVariant.WHITE;
    return card.disabled ? CardVariant.DISABLED : CardVariant.BLUE;
  };
  const handleCardClick = (card: SurveyCard | StatCard) => {
    if (!card.disabled) {
      const routablePath = HUB_ROUTES.find((route) => route.cardId === card.cardId)?.path;
      routablePath && history.push(routablePath);
    }
  };

  const getScoreData = (card: SurveyCard | StatCard) => {
    if (card.cardId === STAT_CARD_ID) {
      const statCard = card as StatCard;
      return {
        percent: {
          [WellbeingCard.Adoption]: getFormattedScore(statCard[WellbeingCard.Adoption]),
          [WellbeingCard.Modules]: getFormattedScore(statCard[WellbeingCard.Modules]),
          [WellbeingCard.Rituals]: getFormattedScore(statCard[WellbeingCard.Rituals]),
        } as ScorePercent,
      };
    }
    const surveyCard = card as SurveyCard;
    return {
      survey: {
        current: surveyCard.response.current,
        max: surveyCard.response.max,
      },
      score: {
        current: surveyCard.score.current,
        max: surveyCard.score.max,
      },
    };
  };

  return (
    <div>
      <Grid container spacing={3}>
        {cards.map((card: SurveyCard | StatCard) => {
          const variant = getVariant(card);
          const scoreType = getScoreType(card);

          return (
            <Grid
              item
              xs={3}
              key={card.cardId}
              onClick={() => {
                handleCardClick(card);
              }}
              className={classes.container}
              data-testid={'hub_card_' + card.cardId}>
              <ScoreCard
                key={'hub_card_' + card.cardId}
                variant={variant}
                score={{
                  scoreType: scoreType,
                  data: getScoreData(card),
                }}
                header={card.headerName}
                closeOnDate={(card as SurveyCard).closeOnDate}></ScoreCard>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
