import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';
import { CompanyAccountsSummary, Card, Pagination, Link } from 'common';
import { DownloadIcon } from 'assets/icons/download';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      maxWidth: 1200,
    },
    bottomCardWrapper: {
      paddingTop: theme.spacing(2),
    },
    downloadAction: {
      color: colors.royalBlue,
      fontFamily: 'Averta-Semibold',
      fontWeight: 500,
    },
    underline: {
      width: 26,
      height: 2,
      backgroundColor: colors.blue,
      borderRadius: 1,
    },
    listContainer: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: 18,
      borderRadius: 12,
      paddingBottom: 18,
      backgroundColor: colors.whisperWhite,
      marginTop: 8,
    },
    listRightWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    listTitle: {
      fontFamily: 'Averta',
      fontWeight: 'normal',
      color: colors.darkGrey,
      marginLeft: theme.spacing(4),
    },
    listDate: {
      fontFamily: 'Averta',
      fontWeight: 'normal',
      fontSize: 12,
      color: colors.slateGrey,
    },
  })
);

export const Invoice: React.FC = (props) => {
  const classes = useStyles(props);

  useEffect(() => {});

  const renderListItem = (index: number) => (
    <Grid
      container
      direction="row"
      justify="space-between"
      key={index}
      item
      xs={12}
      className={classes.listContainer}
      spacing={2}>
      <Grid item xs={12} md={6}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DownloadIcon />
          <Typography variant="h4" className={classes.listTitle}>
            Item title, likely a date
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.listRightWrapper}>
        <Link style={{ padding: 0 }}>
          <Typography variant="h4" className={classes.downloadAction}>
            Download
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={12} md={10} lg={9}>
        <Grid container>
          <Grid item xs={12}>
            <CompanyAccountsSummary />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card style={{ marginTop: 8 }}>
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.bottomCardWrapper}>
                <Grid item>
                  <Typography variant="h3">Your list of items</Typography>
                </Grid>
                <Grid item>
                  <Link style={{ padding: 0 }}>
                    <Typography variant="h4" className={classes.downloadAction}>
                      Manage Billing
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              <Typography variant="h4" style={{ marginTop: 22 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non porta est.
                Simple proin vegitarianity is cararius metus urna quite.
              </Typography>

              {[...Array(10)].map((keys, index) => renderListItem(index))}

              <Pagination count={10} page={1} onChange={() => {}} pageName="invoice" />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
