import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Analytics from 'utils/AnalyticsHelper';
import { HubSurveyPrompts } from 'prompts/prompts';
import { LightOutlinedButton, Button } from 'common';

import { Survey, SurveyMenuItem, SurveyStatuses, ManageSurveyStatuses } from '../../../../types';
import { HUB_MANAGE_ROUTES, MANGAE_SURVEY_MENU_ITEMS, HUB_ANALYTICS } from '../../../../constants';
import { getFormattedDate } from '../../../../utils';
import manageSurveysStyles from './styles';

export const ManageSurveys: React.FC<{
  surveys: Array<Survey>;
  menuItems: typeof MANGAE_SURVEY_MENU_ITEMS;
  messageArr: Array<string>;
}> = (props) => {
  const classes = manageSurveysStyles(props);
  const { surveys, messageArr, menuItems } = props;
  const label = HubSurveyPrompts.label;
  const buttonText = HubSurveyPrompts.buttonText;

  const history = useHistory();
  const {
    TAB_EVENT,
    TAB_SEGMENT,
    BUTTON_NEW_EVENT,
    BUTTON_ADD_RECIPIENTS_EVENT,
    BUTTON_PREVIEW_EVENT,
    BUTTON_PREVIEW_SEGMENT,
    BUTTON_ADD_RECIPIENTS_SEGMENT,
  } = HUB_ANALYTICS.MANAGE_SURVEY;

  const getActiveMenuStatus = useCallback(
    (isDisplay?: boolean) => {
      const activeMenuItem = menuItems.find((item) => item.isActive);
      let status: SurveyStatuses | ManageSurveyStatuses =
        activeMenuItem?.status || SurveyStatuses.OPEN;
      if (isDisplay) {
        status = activeMenuItem?.displayStatus || ManageSurveyStatuses.OPEN;
      }
      return status;
    },
    [menuItems]
  );

  const getDisplayableSurveys = useCallback(() => {
    const activeMenuItemStatus = getActiveMenuStatus();
    return surveys.filter((survey) => survey.status === activeMenuItemStatus);
  }, [getActiveMenuStatus, surveys]);

  const [displayableSurveys, setDisplayableSurveys] = useState(getDisplayableSurveys());

  useEffect(() => {
    setDisplayableSurveys(getDisplayableSurveys());
  }, [menuItems, getDisplayableSurveys]);

  const renderMenuItem = (item: SurveyMenuItem, index: number) => (
    <div
      className={`${classes.selected} ${classes.menuItem}`}
      onClick={() => {
        const routablePath = HUB_MANAGE_ROUTES.find((route) =>
          route.path.includes(item.displayStatus)
        )?.path;
        routablePath && history.replace(routablePath);
        Analytics.trackEvent(TAB_EVENT, TAB_SEGMENT, item.label);
      }}
      key={index}
      data-testid={'hub_modal_survey_' + item.displayStatus + '_tab'}>
      <Typography
        component="h2"
        align="center"
        className={`${classes.routeText} ${item.isActive ? classes.activeRouteText : ''}`}>
        {item.label}
      </Typography>
      <div className={item.isActive ? classes.underline : ''} />
    </div>
  );
  return (
    <>
      <Box className={classes.header}>
        <Box>{menuItems.map((menuItem, index) => renderMenuItem(menuItem, index))}</Box>
        <Button
          className={classes.button}
          onClick={() => {
            const routablePath = HUB_MANAGE_ROUTES.find((route) =>
              route.path.includes('new')
            )?.path;
            routablePath && history.replace(routablePath);
            Analytics.trackEvent(BUTTON_NEW_EVENT);
          }}
          data-testid="hub_modal_survey_new_survey_button">
          {buttonText.newSurvey}
        </Button>
      </Box>
      <Box className={classes.surveys}>
        {displayableSurveys && displayableSurveys.length > 0 ? (
          displayableSurveys.map((survey: Survey, index) => {
            return (
              <Box className={classes.survey} key={index}>
                <Box id="transition-modal-description" className={classes.content}>
                  <Box className={classes.desc}>
                    <Typography variant="h3" component="h3" className={classes.title}>
                      {survey.name}
                    </Typography>
                    <Box className={classes.meta}>
                      {survey.last_sent_date && (
                        <Typography variant="subtitle1" component="p" className={classes.metaData}>
                          {label.lastSentDate.replace(
                            '{date}',
                            getFormattedDate(survey.last_sent_date)
                          )}
                        </Typography>
                      )}
                      {(survey.status === SurveyStatuses.OPEN ||
                        survey.status === SurveyStatuses.CLOSED) &&
                      survey.close_on_date ? (
                        <Typography variant="subtitle1" component="p" className={classes.metaData}>
                          {survey.status === SurveyStatuses.OPEN
                            ? label.closeOnDate.replace(
                                '{date}',
                                getFormattedDate(survey.close_on_date)
                              )
                            : label.closedOnDate.replace(
                                '{date}',
                                getFormattedDate(survey.close_on_date)
                              )}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                  <Box className={classes.right}>
                    {survey.status === SurveyStatuses.OPEN && (
                      <LightOutlinedButton
                        className={classes.button}
                        onClick={() => {
                          const routablePath = HUB_MANAGE_ROUTES.find((route) =>
                            route.path.includes('manage')
                          )?.path;
                          routablePath && history.push(routablePath + '/' + survey.id);
                          Analytics.trackEvent(
                            BUTTON_ADD_RECIPIENTS_EVENT,
                            BUTTON_ADD_RECIPIENTS_SEGMENT,
                            survey.name
                          );
                        }}
                        data-testid="hub_modal_survey_add_recipient_button">
                        {buttonText.addMoreRecipients}
                      </LightOutlinedButton>
                    )}
                    <LightOutlinedButton
                      className={classes.button}
                      onClick={() => {
                        Analytics.trackEvent(
                          BUTTON_PREVIEW_EVENT,
                          BUTTON_PREVIEW_SEGMENT,
                          survey.name
                        );
                        window.open(survey.preview_url);
                      }}
                      data-testid="hub_modal_survey_preview_button">
                      {buttonText.preview}
                    </LightOutlinedButton>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box className={classes.messageParent}>
            <Box className={classes.message}>
              {messageArr.map((message, index) => (
                <Typography
                  key={index}
                  variant="h4"
                  component="p"
                  className={classes.messageSegment}>
                  {message.replace('{surveyType}', getActiveMenuStatus(true))}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
