import React from 'react';
import { Typography } from '@material-ui/core';
import Switch from 'react-ios-switch';
import { AnimatedSpinner } from '../../../../common/components/AnimatedSpinner';
import { autoEmailStyles } from './styles';
import { Setting } from '../Setting';
import { AutoEmailPrompts } from './prompts';

interface Props {
  autoEmail: boolean;
  onAutoEmailChange: () => void;
  loading: boolean;
}
export const AutoEmail: React.FC<Props> = (props) => {
  const classes = autoEmailStyles(props);
  const { toggleOffText, toggleOnText, id } = AutoEmailPrompts;
  const renderSwitch = () => {
    return (
      <>
        <Typography variant="h4">{toggleOffText}</Typography>
        <div className={classes.switch} data-testid={'settings_' + id + '_on_off_switch'}>
          {props.loading ? (
            <AnimatedSpinner height={30} width={30} />
          ) : (
            <Switch
              onColor="#006AFF"
              checked={props.autoEmail}
              onChange={props.onAutoEmailChange}
              disabled={props.loading}
            />
          )}
        </div>
        <Typography variant="h4">{toggleOnText}</Typography>
      </>
    );
  };

  return <Setting {...AutoEmailPrompts}>{renderSwitch()}</Setting>;
};
