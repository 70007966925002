import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Button, FormLabel, makeStyles, Typography } from '@material-ui/core';
import { useAppSelector } from 'store';
import { isEqual } from 'lodash';
import {
  applyDetailsScreenFilters,
  DetailScreenFilters,
  initialDetailsScreenFilterState,
} from '../../GroovVoice.slice';
import { SearchAndSelect } from '../Common/SearchAndSelect';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { CommonSelectorOptionType } from 'common';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: '1px solid',
  },
  innerBox: {
    padding: theme.spacing(2),
  },
  sectionBox: {
    padding: theme.spacing(1),
  },
  label: {
    color: '#000',
  },
  dateField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  selectField: {
    height: 40,
    marginTop: theme.spacing(1),
  },
  chip: {
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
}));

export interface DetailFiltersProps {
  managerOptions?: {
    id: string;
    name: string;
  }[];
  officeLocationOptions?: string[];
  departmentOptions?: string[];
  jobTitleOptions?: string[];
}

export function DetailsFilters(props: Readonly<DetailFiltersProps>) {
  const {
    managerOptions = [],
    officeLocationOptions = [],
    departmentOptions = [],
    jobTitleOptions = [],
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { detailScreenFilters, isApplyingFilters } = useAppSelector(
    (state) => state.groovVoice,
    isEqual
  );

  const [localFilters, setLocalFilters] = useState<DetailScreenFilters>(detailScreenFilters);

  const { manager, officeLocation, department, jobTitle } = localFilters;

  const handleSelectChange = (name: string, value: CommonSelectorOptionType[]) => {
    const newFilters = { ...localFilters, ...{ [name]: value } };
    setLocalFilters(newFilters);
  };

  const handleApplyFilters = () => {
    dispatch(applyDetailsScreenFilters(localFilters));
  };

  const handleResetFilters = () => {
    dispatch(applyDetailsScreenFilters(initialDetailsScreenFilterState));
    setLocalFilters(initialDetailsScreenFilterState);
  };

  useEffect(() => {
    return () => {
      dispatch(applyDetailsScreenFilters(initialDetailsScreenFilterState));
      setLocalFilters(initialDetailsScreenFilterState);
    };
  }, [dispatch]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.container}>
        <Box className={classes.innerBox} my={2}>
          <Typography variant="h3">Filters</Typography>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Manager</FormLabel>
            <SearchAndSelect
              options={managerOptions.map((o) => ({ id: o.id, label: o.name }))}
              value={manager}
              handleOnChange={(_, value) => handleSelectChange('manager', value)}
              placeholder={manager?.length > 0 ? undefined : 'Manager'}
            />
          </Box>
          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Office Location</FormLabel>
            <SearchAndSelect
              options={officeLocationOptions.map((o) => ({ id: o, label: o }))}
              value={officeLocation}
              handleOnChange={(_, value) => handleSelectChange('officeLocation', value)}
              placeholder={officeLocation?.length > 0 ? undefined : 'Office Location'}
            />
          </Box>
          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Department</FormLabel>
            <SearchAndSelect
              options={departmentOptions.map((o) => ({ id: o, label: o }))}
              value={department}
              handleOnChange={(_, value) => handleSelectChange('department', value)}
              placeholder={department?.length > 0 ? undefined : 'Department'}
            />
          </Box>
          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Job Title</FormLabel>
            <SearchAndSelect
              options={jobTitleOptions.map((o) => ({ id: o, label: o }))}
              value={jobTitle}
              handleOnChange={(_, value) => handleSelectChange('jobTitle', value)}
              placeholder={jobTitle?.length > 0 ? undefined : 'Job Title'}
            />
          </Box>
          <Box
            className={classes.sectionBox}
            my={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gridGap={6}>
            <Button
              variant="outlined"
              color="secondary"
              disabled={
                isEqual(initialDetailsScreenFilterState, detailScreenFilters) || isApplyingFilters
              }
              size="small"
              style={{ width: 150 }}
              onClick={handleResetFilters}>
              Reset Filters
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              disabled={isEqual(localFilters, detailScreenFilters)}
              size="small"
              style={{ width: 150 }}
              onClick={handleApplyFilters}>
              {isApplyingFilters ? (
                <AnimatedSpinner height={20} width={20} />
              ) : (
                <Box>Apply Filters</Box>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
}
