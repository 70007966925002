import * as React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const VestIcon = (props: Props) => {
  const { width = 24, height = 24 } = props;
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <path
        fill="#994308"
        d="M17.988 2.184C15 2.184 12.679 1.092 11.618 0v15.292h12.741V0C23.297 1.092 21 2.184 17.988 2.184z"
      />
      <path
        fill="#EB7421"
        d="M28.909 7.958v-4.79c0-1.083-.674-2.026-1.635-2.289L24.055 0l-6.067 14.589L11.921 0L8.702.879c-.961.263-1.635 1.207-1.635 2.289v4.789c0 3.663-2.716 6.632-6.067 6.632v19.036c0 1.206.951 2.185 2.123 2.185h11.831c1.675 0 3.034-1.485 3.034-3.316c0 1.831 1.358 3.316 3.034 3.316h11.831c1.173 0 2.124-.978 2.124-2.185V14.589c-3.352 0-6.068-2.969-6.068-6.631z"
      />
      <path
        fill="#FCFB65"
        d="M1 29h16.988v3H1zm33.976 3H17.988v-3h16.988zM1 22h16.988v3H1zm33.976 3H17.988v-3h16.988z"
      />
      <path fill="#FCFB65" d="M12 23V.19L11.921 0L9 .798V23zm15 0V.805L24.055 0L24 .133V23z" />
      <path
        fill="#994308"
        d="M16.926 11v23.995a3.425 3.425 0 0 0 1.062-2.501c0 .979.395 1.849 1.012 2.456V11h-2.074z"
      />
    </svg>
  );
};
