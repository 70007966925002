import { CommonSelectorOptionType } from 'common';
import { OrgUser } from 'common/components/FileUpload/FileUpload';

export type CtaValue = {
  name: string;
  description: string;
  id: string;
  expanded?: boolean;
};

export type PromptBuilderFormTypes = {
  id: string;
  name: string;
  body: string;
  ctaType: PromptCTATypes;
  ctaValue?: CtaValue[];
  userList: OrgUser[];
  promptDateTime: string;
  categoryId: string;
  isGroovVoice: boolean;
  isConfidential: boolean;
  responseToOpenText?: string;
  promptTags: CommonSelectorOptionType[];
};

export type PromptBuilderFormProps = {
  title: string;
  initialValues: PromptBuilderFormTypes;
  editMode?: boolean;
};

export enum PromptCTATypes {
  custom = 'action_buttons',
  sixPtScale = '6pt_scale',
  openEndedText = 'open_text',
  customAndOpenEndedText = 'action_w_open_text',
  sixPtScaleAndOpenEndedText = '6pt_scale_w_open_text',
}

export interface PromptSummary {
  name: string | null;
  confidential: boolean;
  category: { id: string };
  groov_voice: boolean;
  id: number;
  prompt: string;
  created_on: string;
  properties: Properties;
  promptType?: string;
  status: string;
  prompt_schedule_time: string;
  prompt_cta_type: string;
}

interface Properties {
  prompt_cta_type: string;
  prompt_schedule_time: string;
}

type Action = {
  id: number;
  action: string;
  response: string;
};

type Trigger = {
  id: number;
  event: string;
};

type Tag = {
  prompt_tags_id: number;
};

type Recipient = {
  recipientEmail: string;
  orgId: string;
};

export interface PromptItemSummary extends PromptSummary {
  response_to_open_text: string | null;
  open_text: boolean;
  trigger: Trigger;
  tags: Tag[];
  actions: Action[];
}

export interface PromptItemResponse {
  prompt: string;
  recipients: Recipient[];
}
