import React from 'react';
import { FormHelperText, Grid, Typography, Box } from '@material-ui/core';
import { instructions, manageRitualPrompts } from 'prompts/prompts';
import { ContainerLayout, Card, Button, Link } from 'common';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { colors } from 'styles/colors';
import { Input } from '.';
import Switch from 'react-ios-switch';
import { Formik } from 'formik';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import moment from 'moment';
import CommonRitualGrid from './CommonRitualGrid';
import { FormType } from 'services/types';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(3),
    },
    executive: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(8),
    },
    planing: {
      textTransform: 'none',
      marginTop: theme.spacing(12),
    },
    labelStyle: {
      fontWeight: 'bold',
      fontSize: 14,
      color: colors.black,
    },
    manageRitualDes: {
      marginTop: theme.spacing(7),
      textTransform: 'none',
    },
    managepallnigtext: {
      marginTop: theme.spacing(4),
      textTransform: 'none',
    },
    card: {
      marginTop: theme.spacing(15),
      '@media (min-width:780px)': {
        paddingRight: theme.spacing(15),
        paddingLeft: theme.spacing(15),
      },
    },
    buttonContainer: {
      justifyContent: 'center',
      display: 'flex',
      marginTop: 50,
    },
    linkContainer: {
      marginTop: theme.spacing(3),
      justifyContent: 'center',
      display: 'flex',
      marginRight: theme.spacing(5),
    },
    nametitle: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: colors.black,
      marginTop: theme.spacing(4),
      fontSize: 18,
    },
    ritualText: {
      textTransform: 'none',
      color: colors.slateGrey,
      marginTop: theme.spacing(4),
      fontSize: 16,
    },
    input: {
      paddingLeft: 1,
      color: colors.slateGrey,
    },
    serverError: {
      width: '100%',
      textAlign: 'center',
      paddingTop: theme.spacing(2),
    },
    confirmButton: {
      background: colors.blue,
      borderRadius: 12,
      height: theme.spacing(12.5),
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
      marginRight: theme.spacing(6),
      color: colors.white,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    deleteButton: {
      background: colors.white,
      borderWidth: 2,
      borderRadius: 12,
      borderColor: theme.palette.primary.main,
      height: 50,
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
      marginRight: theme.spacing(6),
      color: theme.palette.primary.main,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        background: colors.white,
      },
    },
    headerView: {
      marginTop: 40,
    },
    subTitleView: {
      marginBottom: 20,
    },
    cancelButton: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 'bold',
    },
    //todo we need to check style sheet
    planningTitle: {
      fontWeight: 'bold',
      color: colors.black,
    },
    ritualSwitchContainer: {
      border: '1px solid lightgray',
      borderRadius: 12,
      height: theme.spacing(15),
      marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      justifyContent: 'space-between',
      flexDirection: 'row',
      display: 'flex',
    },
    switchStyle: {
      marginTop: theme.spacing(3.5),
    },
  })
);

const validationSchema = Yup.object().shape({
  planDate: Yup.string().required('Required'),
  sponsorname: Yup.string().required('Required'),
  sponsorRole: Yup.string().required('Required'),
  keyMessage: Yup.string().required('Required'),
  trigger: Yup.string().required('Required'),
  action: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
  actionPlan: Yup.string().required('Required'),
  comments: Yup.string().required('Required'),
});
export const CreateEditRitual = (props: FormType) => {
  const {
    onSubmit,
    initialValues,
    isEdit,
    isLoading,
    isDeleteLoading,
    serverError,
    cancleClick,
    deleteRituals,
  } = props;
  const classes = useStyles();
  return (
    <ContainerLayout>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize>
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      className={classes.title}
                      data-testid="rituals_page_title">
                      {manageRitualPrompts.manageCompany}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      className={classes.manageRitualDes}
                      data-testid="rituals_page_description">
                      {instructions.manageRitualPage.manageRituals}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid className={classes.card}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h3"
                          component="h3"
                          gutterBottom
                          data-testid={'rituals_' + (isEdit ? 'update' : 'create') + '_title'}>
                          {isEdit
                            ? manageRitualPrompts.create.editRitual
                            : manageRitualPrompts.create.createRitual}
                        </Typography>
                      </Grid>
                      <CommonRitualGrid
                        name={`1. ${manageRitualPrompts.create.trigger}`}
                        values={values.trigger}
                        handleChange={handleChange}
                        inputName="trigger"
                        placeholder={manageRitualPrompts.placeholder.trigger}
                        errors={touched.trigger && Boolean(errors.trigger)}
                        helperText={touched.trigger && errors.trigger}
                      />
                      <CommonRitualGrid
                        multiline={true}
                        name={`2. ${manageRitualPrompts.create.action}`}
                        values={values.action}
                        handleChange={handleChange}
                        inputName="action"
                        rows={2}
                        placeholder={manageRitualPrompts.placeholder.action}
                        errors={touched.action && Boolean(errors.action)}
                        helperText={touched.action && errors.action}
                      />
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="h3"
                            align="left"
                            className={classes.nametitle}
                            data-testid="rituals_executive_sponser_label">
                            3. {manageRitualPrompts.create.executiveSponser}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                              <Input
                                inputProps={{
                                  className: classes.input,
                                }}
                                value={values.sponsorname}
                                onChange={handleChange}
                                fullWidth={true}
                                name="sponsorname"
                                placeholder={manageRitualPrompts.placeholder.sponsorName}
                                error={touched.sponsorname && Boolean(errors.sponsorname)}
                                helperText={touched.sponsorname && errors.sponsorname}
                                data-testid="rituals_executive_sponser_name_input"
                              />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Input
                                inputProps={{
                                  className: classes.input,
                                }}
                                value={values.sponsorRole}
                                onChange={handleChange}
                                fullWidth={true}
                                name="sponsorRole"
                                placeholder={manageRitualPrompts.placeholder.sponsorRole}
                                error={touched.sponsorRole && Boolean(errors.sponsorRole)}
                                helperText={touched.sponsorRole && errors.sponsorRole}
                                data-testid="rituals_executive_sponser_role_label"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.headerView}>
                        <Typography
                          variant="h3"
                          className={classes.planningTitle}
                          data-testid="rituals_planning_label">
                          {manageRitualPrompts.create.planning}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.subTitleView}>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          className={classes.managepallnigtext}
                          data-testid="rituals_planning_text">
                          {manageRitualPrompts.create.planningText}
                        </Typography>
                      </Grid>
                      <CommonRitualGrid
                        name={manageRitualPrompts.create.keyMessage}
                        values={values.keyMessage}
                        handleChange={handleChange}
                        inputName="keyMessage"
                        placeholder={manageRitualPrompts.placeholder.ketMessage}
                        errors={touched.keyMessage && Boolean(errors.keyMessage)}
                        helperText={touched.keyMessage && errors.keyMessage}
                        multiline={true}
                      />
                      <CommonRitualGrid
                        name={manageRitualPrompts.create.plannedDate}
                        values={
                          isEdit ? moment(values.planDate).format('yyyy-MM-DD') : values.planDate
                        }
                        handleChange={handleChange}
                        inputName="planDate"
                        placeholder={manageRitualPrompts.create.plannedDatePlaceholder}
                        errors={touched.planDate && Boolean(errors.planDate)}
                        helperText={touched.planDate && errors.planDate}
                        type={'date'}
                      />
                      <CommonRitualGrid
                        name={manageRitualPrompts.create.comms}
                        values={values.comments}
                        handleChange={handleChange}
                        inputName="comments"
                        multiline={true}
                        placeholder={manageRitualPrompts.placeholder.ketMessage}
                        errors={touched.comments && Boolean(errors.comments)}
                        helperText={touched.comments && errors.comments}
                      />
                      <CommonRitualGrid
                        name={manageRitualPrompts.create.actionPlan}
                        values={values.actionPlan}
                        handleChange={handleChange}
                        inputName="actionPlan"
                        multiline={true}
                        placeholder={manageRitualPrompts.placeholder.ketMessage}
                        errors={touched.actionPlan && Boolean(errors.actionPlan)}
                        helperText={touched.actionPlan && errors.actionPlan}
                      />
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="h3"
                            align="left"
                            className={classes.nametitle}
                            data-testid="rituals_status_label">
                            {manageRitualPrompts.create.ritualStatus}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <div className={classes.ritualSwitchContainer}>
                            <Typography align="left" className={classes.ritualText}>
                              {values.status
                                ? manageRitualPrompts.placeholder.activeStatus
                                : manageRitualPrompts.placeholder.inActiveStatus}
                            </Typography>
                            <Box data-testid="rituals_status_switch">
                              <Switch
                                onColor={colors.blue}
                                className={classes.switchStyle}
                                checked={values.status}
                                onChange={(value: boolean) => setFieldValue('status', value)}
                              />
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                      {serverError && (
                        <FormHelperText error={true} className={classes.serverError}>
                          {manageRitualPrompts.errorMessage}
                        </FormHelperText>
                      )}
                      <div className={classes.buttonContainer}>
                        <Button
                          className={classes.confirmButton}
                          type="submit"
                          data-testid={'rituals_' + (isEdit ? 'update' : 'create') + '_button'}>
                          {isLoading ? (
                            <AnimatedSpinner height={30} width={30} />
                          ) : isEdit ? (
                            manageRitualPrompts.create.updateRitual
                          ) : (
                            manageRitualPrompts.create.createRitual
                          )}
                        </Button>
                        {isEdit ? (
                          <Button
                            className={classes.deleteButton}
                            variant="outlined"
                            onClick={deleteRituals}
                            data-testid="rituals_delete_button">
                            {isDeleteLoading ? (
                              <AnimatedSpinner height={30} width={30} />
                            ) : (
                              manageRitualPrompts.create.deleteRitual
                            )}
                          </Button>
                        ) : null}
                      </div>
                      <div className={classes.linkContainer}>
                        <Link onClick={cancleClick} data-testid="rituals_cancel_button">
                          <div className={classes.cancelButton}>
                            {manageRitualPrompts.create.cancel}
                          </div>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </form>
          );
        }}
      </Formik>
    </ContainerLayout>
  );
};
