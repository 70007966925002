//In case multiple API calls return 401 at the same time

import store from 'store';
import { emailHashHelper } from 'utils/EmailHashHelper';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToasterUtils } from 'common';
import { toasterPrompts } from 'prompts/prompts';
import { logoutAction } from 'auth/auth.actions';

const sessionTimeoutSubject = new Subject<number>();

sessionTimeoutSubject.pipe(debounceTime(500)).subscribe({
  next: () => {
    ToasterUtils.error(toasterPrompts.messages.token.expireError);
    emailHashHelper.deleteEmailHash();
    store.dispatch(logoutAction());
  },
});

export default sessionTimeoutSubject;
