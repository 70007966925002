import { ReportGroup, ReportMessage } from './EmailUploadReportModal';
import { CSVProcessingAddedResponse, CSVProcessingRemovedResponse } from './types';

export const processEmailUploadAddedResponse = (
  processingResponse: CSVProcessingAddedResponse
): ReportMessage[] => {
  const messages: ReportMessage[] = [];

  switch (processingResponse.added.length) {
    case 0: {
      messages.push({ group: ReportGroup.Added, message: `There were no new emails linked.` });
      break;
    }
    case 1: {
      messages.push({
        group: ReportGroup.Added,
        message: `One new email was linked to your organisation.`,
      });
      break;
    }
    default: {
      messages.push({
        group: ReportGroup.Added,
        message: `There were ${processingResponse.added.length} new emails linked to your orgnaisation.`,
      });
    }
  }

  switch (processingResponse.errored.length) {
    case 0: {
      break;
    }
    case 1: {
      messages.push({
        group: ReportGroup.Errored,
        message: `One email was not linked because it is already active or linked.`,
      });
      break;
    }
    default: {
      messages.push({
        group: ReportGroup.Errored,
        message: `${processingResponse.errored.length} emails were not linked because they are already active or linked.`,
      });
    }
  }

  switch (processingResponse.validationErrors.length) {
    case 0: {
      break;
    }
    default: {
      processingResponse.validationErrors.forEach((validationError) => {
        messages.push({ group: ReportGroup.InValid, message: validationError });
      });
    }
  }
  return messages;
};

export const processEmailUploadRemovedResponse = (
  processingResponse: CSVProcessingRemovedResponse
): ReportMessage[] => {
  const messages: ReportMessage[] = [];

  switch (processingResponse.removed.length) {
    case 0: {
      messages.push({
        group: ReportGroup.Added,
        message: `There were no emails removed from your organisation.`,
      });
      break;
    }
    case 1: {
      messages.push({
        group: ReportGroup.Added,
        message: `One email was removed from your organisation.`,
      });
      break;
    }
    default: {
      messages.push({
        group: ReportGroup.Added,
        message: `There were ${processingResponse.removed.length} emails removed from your orgnaisation.`,
      });
    }
  }

  switch (processingResponse.errored.length) {
    case 0: {
      break;
    }
    case 1: {
      messages.push({
        group: ReportGroup.Errored,
        message: `One email was not removed because it is not linked to your organisation.`,
      });
      break;
    }
    default: {
      messages.push({
        group: ReportGroup.Errored,
        message: `${processingResponse.errored.length} emails were not removed because they are not linked to your organisation.`,
      });
    }
  }

  switch (processingResponse.validationErrors.length) {
    case 0: {
      break;
    }
    default: {
      processingResponse.validationErrors.forEach((validationError) => {
        messages.push({ group: ReportGroup.InValid, message: validationError });
      });
    }
  }
  return messages;
};
