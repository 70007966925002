import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getRandomColor, getResponseLayout } from '../../GroovVoice.utils';
import { PromptUserResponse } from '../../GroovVoice.d';
import { format } from 'date-fns';
import { PromptCTATypes } from 'pages/PromptBuilder';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  userRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(10),
  },
  HeadingRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(8),
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    marginRight: theme.spacing(5),
  },
  response: {
    flexGrow: 1,
    textAlign: 'left',
    textOverflow: 'clip',
    maxWidth: '80%',
    flexWrap: 'wrap',
    paddingTop: 4,
  },
  time: {
    textAlign: 'left',
    fontWeight: 400,
  },
  userContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
}));

interface UserResponsesProps {
  responseList: PromptUserResponse[];
  promptCTAType?: PromptCTATypes;
  isConfidential?: boolean;
}

const formatResponseTime = (date?: string | null) => {
  if (!date) {
    return '';
  }

  return format(new Date(date), 'yyyy-MM-dd hh:mm a');
};

const getResponseText = (response?: string | null) => {
  if (!response || response.trim() === '') {
    return <em>No response</em>;
  }

  return response;
};

export const UserResponses: React.FC<UserResponsesProps> = ({
  responseList,
  promptCTAType,
  isConfidential = false,
}) => {
  const classes = useStyles();
  const layout = useMemo(
    () => getResponseLayout(promptCTAType, isConfidential),
    [isConfidential, promptCTAType]
  );

  return (
    <Box className={classes.root}>
      <Grid container className={classes.HeadingRow}>
        {layout.map((section) => (
          <Grid item xs={section.gridSize} key={section.label}>
            <Typography variant="h4" className={classes.response}>
              {section.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {responseList.map((item, index) => (
        <Grid container key={item.id} className={classes.userRow}>
          {layout.map((section, sectionIndex) => (
            <Grid
              item
              xs={section.gridSize}
              key={section.label}
              className={sectionIndex === 0 ? classes.userContainer : ''}>
              <div
                className={classes.circle}
                hidden={sectionIndex !== 0}
                style={{ backgroundColor: getRandomColor(index) }}></div>
              <Typography variant="body1" className={classes.response}>
                {section.type === 'time'
                  ? formatResponseTime(item?.[section.fieldName])
                  : getResponseText(item?.[section.fieldName])}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );
};
