import React, { useEffect, useState } from 'react';
import { useAuthState } from 'common/hooks/useAuthState';
import { useHistory } from 'react-router-dom';
import { addTenantId, createCustomer, getIntegrationAuthorization } from '../api';
import {
  GraphRedirectUrlParams,
  Integration,
  ServicePrincipalInstallationState,
  ServicePrincipalInstallationStatus,
} from '../types';
import { EMPTY_UUID, formatConsentError, getRedirectUrlParams } from './microsoftUtils';
import { Ms365RedirectPageBody } from './MicrosoftRedirectBody';
import {
  ConsentUnknownError,
  CustomerCreateError,
  CustomerCreateSuccess,
  NoParams,
  PermissionOnlySuccess,
} from './microsoftStrings';

export const Ms365RedirectPage: React.FC = () => {
  const authState = useAuthState();
  const history = useHistory();

  const [redirectState, setRedirectState] = useState<ServicePrincipalInstallationState | undefined>(
    undefined
  );

  const handleCustomerCreateSuccess = () => {
    setRedirectState({
      status: ServicePrincipalInstallationStatus.Success,
      ...CustomerCreateSuccess,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCustomerCreateError = (error: any) => {
    if (error.response?.status === 409) {
      setRedirectState({
        status: ServicePrincipalInstallationStatus.Success,
        ...CustomerCreateSuccess,
      });
    } else {
      setRedirectState({
        status: ServicePrincipalInstallationStatus.Error,
        ...CustomerCreateError,
      });
    }
  };

  const handleConsentSuccess = (params: GraphRedirectUrlParams) => {
    const { tenant, state } = params;
    if (state === EMPTY_UUID) {
      setRedirectState({
        status: ServicePrincipalInstallationStatus.Success,
        ...CustomerCreateSuccess,
      });
      return;
    }
    addTenantId(authState.orgId, tenant!, state!, Integration.MsTeams)
      .then(async () => {
        const authId = await getIntegrationAuthorization(authState.orgId, Integration.Ms365);
        await createCustomer(authState.orgId, tenant!, authId, Integration.Ms365);
      })
      .then(() => {
        handleCustomerCreateSuccess();
      })
      .catch((err) => {
        handleCustomerCreateError(err);
      });
  };

  const handleConsentPermissionOnly = () => {
    setRedirectState({
      status: ServicePrincipalInstallationStatus.Success,
      ...PermissionOnlySuccess,
    });
  };

  const handleConsentError = (params: GraphRedirectUrlParams) => {
    setRedirectState({
      status: ServicePrincipalInstallationStatus.Error,
      ...formatConsentError(params),
    });
  };

  const handleConsentUnknown = () => {
    setRedirectState({ status: ServicePrincipalInstallationStatus.Error, ...ConsentUnknownError });
  };

  const handleConsentNoParams = () => {
    setRedirectState({ status: ServicePrincipalInstallationStatus.NoParams, ...NoParams });
  };

  const handleConsentRedirect = (params: GraphRedirectUrlParams) => {
    const { tenant, state, consent, error, errorDescription, errorUri } = params;

    const hasSuccessfulParams = !error && state && tenant && consent;
    const hasPermissionOnlyParams = !error && tenant && consent && !state;
    const hasErrorParams = !!error;
    const hasNoParams = !tenant && !state && !consent && !error && !errorDescription && !errorUri;

    if (hasNoParams) {
      handleConsentNoParams();
    } else if (hasErrorParams) {
      handleConsentError(params);
    } else if (hasSuccessfulParams) {
      handleConsentSuccess(params);
    } else if (hasPermissionOnlyParams) {
      handleConsentPermissionOnly();
    } else {
      handleConsentUnknown();
    }
  };

  useEffect(() => {
    setRedirectState({ status: ServicePrincipalInstallationStatus.InProgress });
    const params = getRedirectUrlParams(window.location.href);

    history.replace({
      search: '',
    });

    handleConsentRedirect(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Ms365RedirectPageBody redirectState={redirectState} />;
};
