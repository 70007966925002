// return month and year only in format "March 2021"
export const getShortDateString = (date: Date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];
  return `${month} ${date.getFullYear()}`;
};
