import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Button, Card, ContainerLayout } from 'common';
import { contextualNudgePrompts } from 'prompts/prompts';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store';
import { RoutePath } from 'types/routes';
import { ContextualNudgeListItem } from './components';
import { getNudges } from './ContextualNudge.utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    manageNudgeCompany: {
      marginTop: theme.spacing(3),
    },
    manageNudge: {
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      justifyContent: 'center',
      display: 'flex',
      marginTop: theme.spacing(5),
    },

    listContainer: {
      marginTop: theme.spacing(3),
    },
  })
);

const renderNoPromptMessage = () => {
  return (
    <Box p={10}>
      <Typography variant="body1" component="h2" align="center">
        {contextualNudgePrompts.noDataFound}
      </Typography>
    </Box>
  );
};
export function ContextualNudgeList() {
  const classes = useStyles();
  const history = useHistory();
  const { nudges: nudgesState } = useAppSelector((state) => state.contextualNudge);
  const navigateToCreatePrompt = () => {
    history.push(RoutePath.AddContextualNudge);
  };
  const nudges = useMemo(() => getNudges(nudgesState), [nudgesState]);
  return (
    <ContainerLayout>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.manageNudge}>
              <Typography
                className={classes.manageNudgeCompany}
                variant="h2"
                data-testid="nudges_page_title">
                {contextualNudgePrompts.manageNudge}
              </Typography>
              {nudges?.length === 0 && renderNoPromptMessage()}
              <Box className={classes.listContainer}>
                {nudges?.map((nudge) => <ContextualNudgeListItem item={nudge} key={nudge.name} />)}
              </Box>
              <div className={classes.buttonContainer}>
                <Button onClick={navigateToCreatePrompt} data-testid="nudges_create_button">
                  {contextualNudgePrompts.createNudge}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
    </ContainerLayout>
  );
}
