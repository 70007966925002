import { AxiosRequestConfig } from 'axios';
import { BasicRequest } from '../../../../services/apiRequest';
import { TEAMS_APP_TYPE } from 'pages/Integrations/Common/Microsoft/microsoftUtils';
import { EmailWithStatus } from './types';

const BASE_URL = `${process.env.REACT_APP_SERVICE_URL}`;
const platform = TEAMS_APP_TYPE;

export const installTeamsAppByEmails = async (
  orgId: string,
  emails?: string[]
): Promise<EmailWithStatus[]> => {
  const config: AxiosRequestConfig = {
    url: 'workplace-integration/InstallChannel',
    method: 'POST',
    data: {
      orgId: orgId,
      platform: platform,
      emails: emails,
    },
    baseURL: BASE_URL,
  };

  const res = await BasicRequest(config);
  return res.data.result;
};
