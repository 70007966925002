import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { HubBetaPrompts } from 'prompts/prompts';
import { OutlinedButton } from 'common';

import Analytics from '../../../../utils/AnalyticsHelper';
import newSurveyStyles from './styles';
import { HUB_MANAGE_ROUTES, HUB_ANALYTICS } from '../../constants';
import Markdown from 'markdown-to-jsx';

export const NewSurvey: React.FC<{
  text: string;
  isSurveyAvailable: boolean;
}> = (props) => {
  const classes = newSurveyStyles(props);
  const { text, isSurveyAvailable } = props;
  const history = useHistory();

  const handleNewSurveyClick = () => {
    Analytics.trackEvent(HUB_ANALYTICS.MANAGE_SURVEY.BUTTON_OPEN_EVENT);
    const routablePath = HUB_MANAGE_ROUTES.find((route) => route.isDefault)?.path;
    routablePath && history.push(routablePath);
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.leftSection}>
        <div className={classes.newSurveyInfo}>
          <InfoOutlinedIcon className={classes.newSurveyInfoIcon} />
          <div className="recommendations main">
            <Markdown>{text}</Markdown>
          </div>
        </div>
      </div>
      <div className={classes.rightSection}>
        {isSurveyAvailable && (
          <OutlinedButton
            onClick={handleNewSurveyClick}
            className={classes.newSurveyButton}
            data-testid="hub_manage_survey_button">
            {HubBetaPrompts.manageSurveysButtonText}
          </OutlinedButton>
        )}
      </div>
    </Grid>
  );
};
