import React, { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { IconButton } from 'common';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { UserRole } from '../../types';
import { sortRecords } from './GroovSettings.utils';

export type UserTableProps = {
  rows: UserRole[];
  maxHeight?: number | string;
  onRemove?: (id: string) => void;
};

export function UserTable({ rows, maxHeight = '45vh', onRemove }: UserTableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserRole>('name');

  const handleSortRequest = (property: keyof UserRole) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = sortRecords(rows, orderBy, order);

  return (
    <Box className={classes.container}>
      <TableContainer component={Paper} className={classes.tableContainer} style={{ maxHeight }}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleSortRequest('name')}>
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={() => handleSortRequest('email')}>
                  Email
                </TableSortLabel>
              </TableCell>
              {onRemove && (
                <TableCell align="left" className={classes.tableCell}>
                  {/* Placeholder for the delete icon column */}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row) => (
              <TableRow key={row.userId as string} className={classes.tableCell}>
                <TableCell scope="row" className={classes.tableCell}>
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {row.email}
                </TableCell>
                {onRemove && (
                  <TableCell align="left" className={classes.tableCell}>
                    <IconButton
                      onClick={() => onRemove(row.userId as string)}
                      className={classes.iconButton}>
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
    },
    tableContainer: {
      maxHeight: '45vh',
      boxShadow: 'none',
    },
    table: {
      borderCollapse: 'collapse',
    },
    tableCell: {
      borderBottom: 'none',
    },
    iconButton: {
      alignItems: 'center',
    },
    noRecords: {
      margin: theme.spacing(1),
    },
  })
);
