import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { CheckCircleIcon } from 'assets/icons/CheckCircleIcon';
import { WarnIcon } from 'assets/icons/WarnIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon';
import { colors } from 'styles/colors';
import {
  EmailWithStatus,
  TeamsInstallationStatus,
} from '../../pages/Integrations/Channels/Teams/types';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: '4px',
    borderBottomWidth: '2px',
    flex: 1,
    borderColor: 'transparent',
  },
  adornmentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '110px',
    marginRight: '16px',
    marginLeft: '8px',
  },
  emailIndexItem: {
    marginLeft: '8px',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '50px',
  },
  emailListItem: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginLeft: '8px',
  },
  adornmentListItem: {
    paddingRight: '4px',
  },
  emailListContainer: {
    flexDirection: 'column',
    width: '100%',
    marginBottom: '4px',
    overflow: 'auto',
    '&>*:nth-child(even)': {
      backgroundColor: colors.mysticGrey,
      borderColor: colors.mysticGrey,
    },
  },
}));

interface Props {
  results?: EmailWithStatus[];
}

export const EmailResultList = (props: Props) => {
  const classes = useStyles();
  const { results } = props;

  return (
    <div className={classes.emailListContainer}>
      {results?.map((result, index) => {
        return (
          <Box key={index} className={classes.container} borderBottom={1} borderTop={1}>
            <Typography variant="body1" className={classes.emailIndexItem}>
              <b>{index + 1}.</b>
            </Typography>
            <Typography variant="body1" className={classes.emailListItem}>
              {result.mail}
            </Typography>
            <Box className={classes.adornmentContainer}>
              <Typography variant="body1" className={classes.adornmentListItem}>
                {getText(result.status)}
              </Typography>
              {getIcon(result.status)}
            </Box>
          </Box>
        );
      })}
    </div>
  );
};

const getText = (status?: TeamsInstallationStatus) => {
  switch (status) {
    case TeamsInstallationStatus.Conflict:
      return 'Installed';
    case TeamsInstallationStatus.Failed:
      return 'Failed';
    case TeamsInstallationStatus.NotFound:
      return 'Not Found';
    case TeamsInstallationStatus.Success:
      return 'Success';
    default:
      return '';
  }
};

const getIcon = (status?: TeamsInstallationStatus) => {
  switch (status) {
    case TeamsInstallationStatus.Conflict:
      return <CheckCircleIcon />;
    case TeamsInstallationStatus.Failed:
      return <ErrorIcon />;
    case TeamsInstallationStatus.NotFound:
      return <WarnIcon />;
    case TeamsInstallationStatus.Success:
      return <CheckCircleIcon />;
    default:
      return null;
  }
};
