import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'styles/colors';
import { CloseIcon } from 'assets/icons/close-icon';
import { IconButton } from '../Button';

export const styles = makeStyles(() =>
  createStyles({
    modalIcon: {
      color: colors.black,
      cursor: 'pointer',
      borderRadius: 100,
      width: 40,
      height: 40,
      position: 'absolute',
      top: 12,
      right: 12,
    },
  })
);

export const GroovModalCloseButton: React.FC<{
  disabled?: boolean;
  onPressed: () => void;
}> = (props) => {
  const classes = styles(props);
  const { onPressed, disabled } = props;

  return (
    <IconButton className={classes.modalIcon} disabled={disabled} onClick={onPressed}>
      <CloseIcon width={40} height={40} />
    </IconButton>
  );
};
