import { useAppDispatch } from 'AppContext';
import { externalLoginProvider } from 'pages/ExternalLoginProvider';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { RouteType } from 'types/routes';

const PublicRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({
      type: 'setRouteType',
      payload: RouteType.Public,
    });
  }, [dispatch, rest.path]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default externalLoginProvider(PublicRoute);
