import { OrgSettingResponse } from './response';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiResponse, LoginRawResponse, LoginResponse, SetNewPasswordRequestType } from './types';
import { ApiRequest, EmbedTokenRequest } from './request';

const AUTH_BASE_URL = process.env.REACT_APP_AUTHORITY;
const BASE_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
const BASE_HUB_URL = process.env.REACT_APP_HUB_URL;
const BASE_RITUAL_URL = process.env.REACT_APP_BASE_RITUAL_URL;

const endpoints = {
  resetPassword: 'api/registration/password/reset',
  token: 'connect/token',
  user: 'user',
  domain: 'domain',
  userCsv: 'user/csv',
  resetUserPassword: 'api/registration/password',
  gracePeriod: 'gracePeriod',
  content: 'content',
  profile: '/user/me',
};

const clientConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
};

const adaptToLoginResponse = (result: AxiosResponse<LoginRawResponse>): LoginResponse => {
  return {
    accessToken: result.data.access_token,
    expiry: result.data.expires_in,
    status: result.status,
    statusText: result.statusText,
  };
};

export const authenticate = async (username: string, password: string): Promise<LoginResponse> => {
  const formData = new FormData();
  formData.append('client_id', clientConfig.clientId);
  formData.append('client_secret', clientConfig.clientSecret);
  formData.append('grant_type', 'password');
  formData.append('scope', 'orgId status org_admin content profile user partner');
  formData.append('username', username);
  formData.append('password', password);

  const config: AxiosRequestConfig = {
    url: endpoints.token,
    baseURL: AUTH_BASE_URL,
    method: 'POST',
    data: formData,
  };
  const result = await ApiRequest(config);
  const response = adaptToLoginResponse(result);
  return response;
};

export const exchangeToken = async (accessToken: string): Promise<LoginResponse> => {
  const formData = new FormData();
  formData.append('client_id', clientConfig.clientId);
  formData.append('client_secret', clientConfig.clientSecret);
  formData.append('grant_type', 'token_exchange');
  formData.append('scope', 'orgId status org_admin content profile user partner');
  formData.append('token', accessToken);

  const config: AxiosRequestConfig = {
    url: endpoints.token,
    baseURL: AUTH_BASE_URL,
    method: 'POST',
    data: formData,
  };

  const result = await ApiRequest(config);
  if (result.status !== 200) {
    throw new Error('Failed to login');
  }

  const response = adaptToLoginResponse(result);
  return response;
};

export interface UserProfile {
  id: string;
  email: string;
  org: string;
  subscriptions: string[];
  isWorkplace: boolean;
}

const getUserProfile = async (): Promise<UserProfileResponse | ApiResponse> => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: endpoints.profile,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export interface UserProfileResponse extends UserProfile, ApiResponse {}

const createUserProfile = async (): Promise<UserProfileResponse | ApiResponse> => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: endpoints.profile,
    method: 'POST',
  };
  const response = await ApiRequest(config);
  return response;
};

export const getOrCreateUserProfileIfNotExists = async (): Promise<UserProfile> => {
  let profile: UserProfileResponse | ApiResponse;
  profile = await getUserProfile();

  if (profile.status === 200) return profile.data as UserProfile;

  if (profile.status !== 404) throw new Error("error retrieving user's profile");

  await createUserProfile();
  profile = await getUserProfile();
  if (profile.status !== 200) throw new Error('failed to get newly created profile');

  return profile.data as UserProfile;
};

export const getPowerBIToken = async (reportId?: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: 'GET',
  };
  const result = await EmbedTokenRequest(config, reportId);
  return result;
};

const getAppToken = async (): Promise<LoginResponse> => {
  const formData = new FormData();
  formData.append('client_id', clientConfig.clientId);
  formData.append('client_secret', clientConfig.clientSecret);
  formData.append('grant_type', 'client_credentials');
  formData.append('scope', 'registration');

  const config: AxiosRequestConfig = {
    url: endpoints.token,
    baseURL: AUTH_BASE_URL,
    method: 'POST',
    data: formData,
    headers: { Authorization: '' },
  };

  const result = await ApiRequest(config);
  const response = adaptToLoginResponse(result);
  return response;
};

export const resetPassword = async (email: string): Promise<ApiResponse> => {
  const { accessToken } = await getAppToken();
  const config: AxiosRequestConfig = {
    url: endpoints.resetPassword,
    baseURL: AUTH_BASE_URL,
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    data: { email },
  };
  const result = await ApiRequest(config);
  const response = adaptToLoginResponse(result);
  return response;
};

export const addUser = async (email: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: endpoints.user,
    method: 'POST',
    data: { email },
  };
  const result = await ApiRequest(config);
  return result;
};

export const removeUser = async (email: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: endpoints.user,
    method: 'DELETE',
    data: { email },
  };
  const result = await ApiRequest(config);
  return result;
};

export const addUserCSV = async (file: File): Promise<AxiosResponse> => {
  const formData = new FormData();

  formData.append('file', file);

  const config: AxiosRequestConfig = {
    url: endpoints.userCsv,
    method: 'POST',
    data: formData,
  };
  const response = await ApiRequest(config);

  return response;
};

export const removeUserCSV = async (file: File): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('file', file);

  const config: AxiosRequestConfig = {
    url: endpoints.userCsv,
    method: 'DELETE',
    data: formData,
  };
  const response = await ApiRequest(config);
  return response;
};

export const addDomain = async (domain: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: endpoints.domain,
    method: 'POST',
    data: { domain },
  };
  const result = await ApiRequest(config);
  return result;
};

export const removeDomain = async (domainId: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: `${endpoints.domain}/${domainId}`,
    method: 'DELETE',
  };
  const result = await ApiRequest(config);
  return result;
};
export const getSubscription = async (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: '/',
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const getDomains = async (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: endpoints.domain,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const getSubscriptionUsers = async (params: any): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: endpoints.user,
    method: 'GET',
    params,
  };
  const result = await ApiRequest(config);
  return result;
};

export const downloadSubscriptionUsers = async (params: any): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url: endpoints.user,
    method: 'GET',
    params,
    headers: { 'x-mtm-content-type': 'text/csv' },
    responseType: 'text',
  };
  const result = await ApiRequest(config);
  return result;
};

export const setNewPassword = async (
  request: SetNewPasswordRequestType
): Promise<AxiosResponse> => {
  const { accessToken } = await getAppToken();
  const config: AxiosRequestConfig = {
    url: request.customUrl || endpoints.resetUserPassword,
    baseURL: AUTH_BASE_URL,
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    data: {
      Code: request.code,
      ConfirmPassword: request.password,
      Email: request.username,
      Password: request.password,
    },
  };
  const result = await ApiRequest(config);
  return result;
};

export const getOrgHighlights = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `${endpoints.content}/highlights/${orgId}`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const getRecommendationTexts = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `${endpoints.content}/recommendations/${orgId}`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const getSurveyResults = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `surveyresults/${orgId}`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const getStatsResults = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `stats/${orgId}`,
    method: 'GET',
  };
  return await ApiRequest(config);
};

export const getSurveys = async (orgId: string, survey_ids: string[]) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `surveys?org_id=${orgId}&survey_ids=${survey_ids.join()}`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const getRitualStats = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `stats/${orgId}/ritualsStats`,
    method: 'GET',
  };
  return await ApiRequest(config);
};

export const getTeamRituals = async (orgId: string, teamId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `stats/${orgId}/rituals/${teamId}`,
    method: 'GET',
  };
  return await ApiRequest(config);
};

export const getTopTenRituals = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `stats/${orgId}/rituals?orderBy=valuableRate&sort=DESC&limit=10`,
    method: 'GET',
  };
  return await ApiRequest(config);
};

export const getRitualOverview = async (orgId: string, teamId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `stats/${orgId}/ritualsOverview/${teamId}`,
    method: 'GET',
  };
  return await ApiRequest(config);
};

export const getModules = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `${endpoints.content}/modules/${orgId}`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const getRitualsTeams = async (orgId: string, orderBy?: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `stats/${orgId}/ritualsTeams?orderBy=${orderBy ? orderBy : 'name'}&sort=ASC`,
    method: 'GET',
  };
  return await ApiRequest(config);
};

export const getOrgSettings = async (orgId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `${endpoints.content}/organizations/${orgId}`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result.data as OrgSettingResponse;
};

export const addContactsCSV = async (file: string, orgId: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('file', file);

  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `surveys/contacts/${orgId}`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const response = await ApiRequest(config);
  return response;
};

export const reviewAndSend = async (
  surveyId: string,
  orgId: string,
  contactListId: string,
  collectorId: string | undefined,
  sendDate: string,
  emailSubject: string,
  emailTemplate: string,
  emailReminderDays: number[]
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_HUB_URL,
    url: `surveys/send`,
    method: 'POST',
    data: {
      survey_id: surveyId,
      org_id: orgId,
      contact_list_id: contactListId,
      collector_id: collectorId,
      send_date: sendDate,
      email_subject: emailSubject,
      email_template: emailTemplate,
      email_reminder_days: emailReminderDays,
    },
  };
  const response = await ApiRequest(config);
  return response;
};
export const getActiveRituals = async (
  isActive: boolean,
  limit: number,
  offset: number,
  orderBy: string
) => {
  const config: AxiosRequestConfig = {
    url: `${BASE_RITUAL_URL}/company-rituals?active=${isActive}&limit=${limit}&offset=${offset}&sortBy=name&orderBy=${orderBy}`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const createCompanyRitual = async (
  planDate: string,
  sponsorName: string,
  sponsorRole: string,
  keyMessage: string,
  trigger: string,
  action: string,
  status: boolean,
  actionPlan: string,
  comments: string
) => {
  const config: AxiosRequestConfig = {
    url: `${BASE_RITUAL_URL}/company-ritual`,
    method: 'POST',
    data: {
      planDate,
      sponsorName,
      sponsorRole,
      keyMessage,
      trigger,
      action,
      status,
      actionPlan,
      comments,
    },
  };
  const result = await ApiRequest(config);
  return result;
};

export const editCompanyRitual = async (
  planDate: string,
  sponsorName: string,
  sponsorRole: string,
  keyMessage: string,
  trigger: string,
  action: string,
  status: boolean,
  actionPlan: string,
  comments: string,
  id: string
) => {
  const config: AxiosRequestConfig = {
    url: `${BASE_RITUAL_URL}/company-ritual/${id}`,
    method: 'PUT',
    data: {
      planDate,
      sponsorName,
      sponsorRole,
      keyMessage,
      trigger,
      action,
      status,
      actionPlan,
      comments,
    },
  };
  const result = await ApiRequest(config);
  return result;
};

export const getCompanyAdmin = async () => {
  const config: AxiosRequestConfig = {
    url: `${BASE_RITUAL_URL}/admin/company`,
    method: 'GET',
  };
  const result = await ApiRequest(config);
  return result;
};

export const deleteCompanyritual = async (companyRitualId: string) => {
  const config: AxiosRequestConfig = {
    url: `${BASE_RITUAL_URL}/company-ritual/${companyRitualId}`,
    method: 'DELETE',
  };
  const result = await ApiRequest(config);
  return result;
};

export interface ResetPasswordRequest {
  username: string;
  password: string;
  code: string;
}
