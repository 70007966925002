import { useLDClient } from 'launchdarkly-react-client-sdk';
import { externalLoginProvider } from 'pages/ExternalLoginProvider';
import React, { useEffect, useMemo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { setRouteTypeAction } from 'slices/appState';
import { useAppDispatch, useAppSelector } from 'store';
import { parseAccessToken } from 'utils/JwtTokenHelper';
import { RoutePath, RouteType } from '../types/routes';

// If the user is authenticated, then they can navigate to any route, otherwise, only the login page
const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isAuthenticated, expiresAt } = useAppSelector((state) => state.auth);

  const isValidToken = useMemo(() => {
    return isAuthenticated && Date.now() < expiresAt;
  }, [expiresAt, isAuthenticated]);
  const dispatch = useAppDispatch();
  const featureFlagClient = useLDClient();
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  useEffect(() => {
    const user = parseAccessToken(accessToken);
    if (user && isValidToken) {
      featureFlagClient?.identify({
        key: user.sub,
        custom: {
          orgId: user.org_id,
          tier: user.sub_tier,
          userId: user.sub,
        },
      });
    }
  }, [featureFlagClient, accessToken, isValidToken]);

  useEffect(() => {
    dispatch(setRouteTypeAction(RouteType.Private));
  }, [dispatch, rest.path, isValidToken]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isValidToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: RoutePath.Login, state: { from: props.location } }} />
        )
      }
    />
  );
};

export default externalLoginProvider(PrivateRoute);
