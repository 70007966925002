import { EventHandler } from 'powerbi-client-react';
import Analytics from './AnalyticsHelper';
import { getStore } from 'store';
import { cloneDeep } from 'lodash';

function deepClean(obj: any, seen: WeakSet<object> = new WeakSet()): any {
  if (obj === null || typeof obj !== 'object' || seen.has(obj)) {
    return obj;
  }
  seen.add(obj);

  if (Array.isArray(obj)) {
    return obj.map((item) => deepClean(item, seen));
  }

  const cleanObj: Record<string, any> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      try {
        // Attempt to serialize the value to check if it's serializable
        JSON.stringify(obj[key]);
        cleanObj[key] = deepClean(obj[key], seen);
      } catch (e) {
        console.warn(`Filtered out unserializable property: ${key}`);
      }
    }
  }
  return cleanObj;
}

const trackEvent = (eventName: string, extraInfo: any) => {
  const userId = getStore().getState().auth.userId;
  const cleanExtraInfo = deepClean(cloneDeep(extraInfo));
  try {
    Analytics.trackEventV2(eventName, {
      userId,
      ...cleanExtraInfo,
    });
  } catch (error) {
    console.error(error);
  }
};

export const powerBIEventHandlers: Map<string, EventHandler> = new Map([
  [
    'buttonClicked',
    function (event) {
      trackEvent('buttonClicked', {
        name: 'Button clicked',
        ...event?.detail,
      });
    },
  ],
  [
    'commandTriggered',
    function (event) {
      trackEvent('commandTriggered', {
        name: 'Command triggered',
        ...event?.detail,
      });
    },
  ],
  [
    'dataHyperlinkClicked',
    function (event) {
      trackEvent('dataHyperlinkClicked', {
        name: 'Data hyperlink clicked',
        ...event?.detail,
      });
    },
  ],
  [
    'dataSelected',
    function (event) {
      trackEvent('dataSelected', {
        name: 'Data selected',
        ...event?.detail,
      });
    },
  ],
  [
    'pageChanged',
    function (event) {
      trackEvent('pageChanged', {
        name: 'Page changed',
        ...event?.detail,
      });
    },
  ],
  [
    'selectionChanged',
    function (event) {
      trackEvent('selectionChanged', {
        name: 'Selection changed',
        ...event?.detail,
      });
    },
  ],
  [
    'visualClicked',
    function (event) {
      trackEvent('visualClicked', {
        name: 'Visual clicked',
        ...event?.detail,
      });
    },
  ],
]);
