import React, { ReactElement } from 'react';
import { Backdrop, Modal, Fade, Box, createStyles, makeStyles } from '@material-ui/core';

import { colors } from 'styles/colors';

type ChildType = ReactElement | boolean | null;
type ChildTypeArray = (ReactElement | boolean | null)[];

const modalStyles = makeStyles(() =>
  createStyles({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      height: '90%',
    },
  })
);

export const GroovModal: React.FC<{
  children: ChildType | ChildTypeArray;
  isModalShowing: boolean;
  style?: React.CSSProperties;
  childStyle?: React.CSSProperties;
  onModalClosed?: () => void;
  backdropClick?: React.MouseEventHandler<HTMLDivElement>;
}> = (props) => {
  const classes = modalStyles(props);
  const { children, isModalShowing, style, backdropClick, childStyle, onModalClosed } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalShowing}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: colors.black,
          ...style,
        },
        onClick: backdropClick,
      }}>
      <Fade in={isModalShowing} onExited={onModalClosed}>
        <Box className={classes.modal} style={childStyle}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
