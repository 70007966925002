import React from 'react';
import { IntegrationCard, IntegrationCardProps } from '../../IntegrationCard';
import { IntegrationType, Integration } from '../../Common/types';
import SlackIcon from 'assets/icons/slack-logo';
import { getIntegrationAuthorization } from '../../Common/api';
import { AuthState } from 'auth';

const SLACK_APP_TYPE = 'slack';
const SLACK_INSTALL_URL = process.env.REACT_APP_SLACK_INSTALL_URL ?? '';

interface Props extends IntegrationCardProps {
  authState: AuthState;
}

export const SlackIntegrationCard: React.FC<Props> = (props) => {
  const integrationProps = {
    hasIntegration: props.hasIntegration,
    showEnabled: props.hasIntegration,
    type: IntegrationType.Channel,
    integration: Integration.Slack,
    title: 'Slack',
    description: 'Install Groov Anywhere into your Slack workspace.',
    icon: <SlackIcon />,
    buttonText: 'Add Slack',
    onClick: async () => {
      const authCode = await getIntegrationAuthorization(props.authState.orgId, SLACK_APP_TYPE);
      window.open(`${SLACK_INSTALL_URL}${authCode}`, '_blank');
    },
  };

  return <IntegrationCard {...integrationProps} />;
};
