import { combineReducers } from '@reduxjs/toolkit';
import { reducer as powerBIReducer } from '../slices/powerBI';
import { reducer as appStateReducer } from '../slices/appState';
import { contextualNudgeReducer } from 'pages/ContextualNudge';
import { promptBuilderReducer } from 'pages/PromptBuilder';
import { authReducer } from 'auth';
import { SettingsReducer } from 'pages/Settings/state';
import { groovVoiceReducer } from 'pages/GroovVoice';

const rootReducer = combineReducers({
  powerBI: powerBIReducer,
  appState: appStateReducer,
  contextualNudge: contextualNudgeReducer,
  promptBuilder: promptBuilderReducer,
  groovVoice: groovVoiceReducer,
  auth: authReducer,
  settings: SettingsReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
