import { useEffect, useState } from "react";
import { useAppSelector } from "store";
import { getSelectedPrompt } from "../api";
import { CommonSelectorOptionType } from "common";
import { PromptBuilderFormTypes } from "../PromptBuilder.types";
import { convertPromptItemToBuilderForm } from "../PromptBuilder.utils";

const usePrompt = (id: string, tags: CommonSelectorOptionType[]) => {
  const [prompt, setPrompt] = useState<PromptBuilderFormTypes | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { currentLocalPrompt } = useAppSelector((state) => state.promptBuilder);

  const isLocalPrompt = id.startsWith('local_') && currentLocalPrompt && id.split('local_')[1] === currentLocalPrompt.id;

  useEffect(() => {
    if (!id) {
      setError('Id is required');
      setPrompt(null);
      setIsLoading(false);
      return;
    }

    if (id.startsWith('local_') && !isLocalPrompt) {
      setError('Local prompt not found');
      setPrompt(null);
      setIsLoading(false);
      return;
    }

    if (isLocalPrompt) {
      setError(null);
      setPrompt(currentLocalPrompt);
      setIsLoading(false);
      return;
    }

    (async () => {
      const selectedPrompt = await getSelectedPrompt(id);
      if (!selectedPrompt) {
        setError('Prompt not found');
        setPrompt(null);
      } else {
        setError(null);
        setPrompt(convertPromptItemToBuilderForm(selectedPrompt, tags));
      }
      setIsLoading(false);
    })();
  }, [id, tags, currentLocalPrompt, isLocalPrompt]);

  return { isLoading, error, prompt, isLocalPrompt };
};

export default usePrompt;