import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const infoSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoText: {
      marginRight: theme.spacing(3),
      fontSize: '1rem',
    },
    infoTitle: {
      color: colors.darkestGreen,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.lightestGreen,
      borderRadius: '0.5rem !important',
      padding: theme.spacing(4),
      boxShadow: 'none',
      borderWidth: theme.spacing(0.25),
      borderStyle: 'solid',
      borderColor: colors.lighterGreen,
      '&:before': {
        content: 'none',
      },
    },
    expandIcon: {
      color: colors.darkestGreen,
    },
    expandCollapseButton: {
      marginTop: theme.spacing(3),
    },
  })
);

export default infoSectionStyles;
