import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import './index.css';
import './styles/fonts.css';
import { colors } from './styles/colors';

let theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.royalBlue,
    },
    success: {
      main: colors.positiveGreen,
    },
    warning: {
      main: colors.redWarn,
    },
    text: {
      primary: colors.slateGrey,
    },
    background: {
      default: colors.whisperWhite,
    },
    grey: {
      100: colors.gray1,
      400: colors.mysticGrey,
      500: colors.slateGrey,
      600: colors.slateGrey2,
      700: colors.slateGrey3,
    },
    common: {
      black: colors.black,
      white: colors.white,
    },
  },
  typography: {
    fontFamily: [
      'Averta',
      'Averta-Bold',
      'Averta-ExtraBold',
      'Helvetica',
      'Verdana',
      'sans-serif',
    ].join(','),
    fontSize: 14,
    h1: {
      fontSize: 36,
      fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      color: colors.darkGrey,
    },
    h2: {
      fontSize: 24,
      color: colors.darkGrey,
      fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
    },
    subtitle1: {
      fontSize: '1rem',
      fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      color: colors.slateGrey,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      color: colors.slateGrey,
    },
    h3: {
      fontSize: 20,
      fontWeight: 800,
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      color: colors.darkGrey,
    },
    h4: {
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      color: colors.darkGrey,
    },
  },
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 956,
      xl: 1920,
    },
  },
});

theme = responsiveFontSizes(theme, { breakpoints: ['xs', 'sm'] });

export default theme;
