import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { FilterType } from 'powerbi-models';
import React from 'react';
import { EmbedToken } from 'services/types';
import { powerBIEventHandlers } from 'utils/PowerBIEventHandler.utils';

type EmbeddedReportProps = {
  embedToken: EmbedToken;
  userOrg?: string;
  containerClassName: string;
  orgTableName?: string;
};

type ReportSectionProps = {
  reportSection: string;
};

export const PowerBIEmbedComp = (props: EmbeddedReportProps & ReportSectionProps) => {
  const { embedToken, userOrg, reportSection, orgTableName } = props;
  const filterProps = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: { table: orgTableName ?? '_ref_orgid', column: 'orgid' },
    filterType: FilterType.Basic,
    operator: userOrg ? 'In' : 'All',
    values: [userOrg ? userOrg.toString() : ''],
  };

  const paneProps = {
    filters: { expanded: false, visible: false },
    pageNavigation: { visible: false },
  };

  return (
    <PowerBIEmbed
      cssClassName={props.containerClassName}
      embedConfig={{
        type: 'report',
        id: embedToken.embedUrl[0].reportId,
        embedUrl: embedToken.embedUrl[0].embedUrl,
        accessToken: embedToken.accessToken,
        tokenType: models.TokenType.Embed,
        pageName: reportSection,
        filters: [filterProps],
        settings: {
          panes: paneProps,
          background: models.BackgroundType.Transparent,
        },
      }}
      eventHandlers={powerBIEventHandlers}
    />
  );
};
