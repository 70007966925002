import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/colors';

const manageSurveysStyles = makeStyles((theme: Theme) =>
  createStyles({
    surveys: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
      height: '29rem',
      overflowY: 'auto',
    },
    survey: {
      display: 'flex',
      marginBottom: theme.spacing(8),
    },
    content: {
      display: 'flex',
      padding: theme.spacing(5),
      borderRadius: theme.spacing(3),
      backgroundColor: colors.snow,
      flex: '1',
    },
    desc: {
      display: 'flex',
      flexDirection: 'column',
      flex: '2',
      userSelect: 'none',
    },
    title: {
      fontSize: '1.25rem',
    },
    meta: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    right: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      height: theme.spacing(10),
      marginLeft: theme.spacing(4),
      padding: theme.spacing(2.5, 7),
    },
    metaData: {
      marginRight: theme.spacing(12.5),
    },
    selected: {
      display: 'flex',
      flexDirection: 'column',
    },
    menuItem: {
      marginRight: theme.spacing(4),
      display: 'inline-block',
      cursor: 'pointer',
    },
    routeText: {
      color: colors.slateGrey,
      fontSize: 16,
      marginBottom: 4,
      fontWeight: 'bold',
      height: 20,
      letterSpacing: 0,
    },
    activeRouteText: {
      color: theme.palette.primary.main,
    },
    underline: {
      height: 2,
      marginTop: theme.spacing(2),
      backgroundColor: colors.blue,
      borderRadius: 1,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(10),
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: theme.spacing(3),
      backgroundColor: colors.snow,
      padding: theme.spacing(5),
      width: '27rem',
    },
    messageParent: {
      marginTop: theme.spacing(15),
      display: 'flex',
      justifyContent: 'center',
    },
    messageSegment: {
      lineHeight: 1.5,
    },
  })
);

export default manageSurveysStyles;
