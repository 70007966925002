import { Grid, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import { HubCardPrompts } from 'prompts/prompts';
import { colors } from 'styles/colors';
import {
  calculateScoreColor,
  calculateScorePercentage,
  numberOfDays,
  getScorePercent,
} from '../../utils';
import { ScoreCardProps, ScoreTypes, CardVariant, WellbeingCard } from '../../types';
import scoreCardStyles from './styles';

export const ScoreCard: React.FC<ScoreCardProps> = ({
  variant,
  score,
  header,
  closeOnDate,
}: ScoreCardProps) => {
  const classes = scoreCardStyles();
  const cardColor =
    variant === CardVariant.BLUE
      ? colors.blue
      : variant === CardVariant.DISABLED
        ? colors.darkBlue
        : colors.whisperWhite;
  const typoColor =
    variant === CardVariant.BLUE
      ? colors.whisperWhite
      : variant === CardVariant.DISABLED
        ? colors.gray1
        : colors.darkGrey;
  const surveyColor = variant === CardVariant.BLUE ? colors.whisperWhite : colors.blue;

  const getCardContent = () => {
    const getPercentData = (value: number | null) => {
      return (
        <Box className={classes.scoreRoot} style={{ color: typoColor }}>
          {value !== null ? (
            <>
              <span style={{ color: calculateScoreColor(score, variant) || typoColor }}>
                {value >= 1 ? value : HubCardPrompts.lessThanOneText}
              </span>
              <span className={classes.percent}>%</span>
            </>
          ) : (
            <Box width={8} height={3} style={{ backgroundColor: typoColor }}></Box>
          )}
        </Box>
      );
    };
    const getPercentBox = (title: string, value: number | null) => {
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.percentBox}
          key={`${title}-${value}`}>
          <Typography
            component="h4"
            variant="h4"
            style={{
              color: typoColor,
            }}>
            {title}
          </Typography>
          {getPercentData(value)}
        </Box>
      );
    };
    switch (score.scoreType) {
      case ScoreTypes.PERCENTAGE:
        const percentArr = [
          {
            title: HubCardPrompts.app_adoption,
            value: getScorePercent(score, WellbeingCard.Adoption),
          },
          {
            title: HubCardPrompts.modules,
            value: getScorePercent(score, WellbeingCard.Modules),
          },
          {
            title: HubCardPrompts.rituals,
            value: getScorePercent(score, WellbeingCard.Rituals),
          },
        ];
        return percentArr.map((percentObj) => getPercentBox(percentObj.title, percentObj.value));
      case ScoreTypes.SCORE:
        const percent = getScorePercent(score);
        return getPercentData(percent);
      case ScoreTypes.AVAILABLE:
        const remainingDays = Math.floor(numberOfDays(closeOnDate));
        let noDataTillDateText = HubCardPrompts.noDataTillDateText;
        if (remainingDays === 1) {
          noDataTillDateText = noDataTillDateText.replace('days', 'day');
        }
        return (
          <div className={classes.noDataContainer}>
            <p>{noDataTillDateText.replace('{num}', remainingDays.toString())}</p>
          </div>
        );
      case ScoreTypes.AVAILABLE_TODAY:
        return (
          <div className={classes.noDataContainer}>
            <p>{HubCardPrompts.noDataUntilTodayText}</p>
          </div>
        );
      case ScoreTypes.INDEFINITE_AVAILABLE:
        return (
          <div className={classes.noDataContainer}>
            <p>{HubCardPrompts.noDataWithoutDateText}</p>
          </div>
        );
      case ScoreTypes.UNAVAILABLE:
        return (
          <div className={classes.noDataContainer}>
            <p>{HubCardPrompts.noDataText}</p>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <Card
      className={classes.root}
      style={{
        backgroundColor: cardColor,
        cursor: variant !== CardVariant.DISABLED ? 'pointer' : 'default',
      }}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography
            component="h3"
            variant="h3"
            style={{
              color: typoColor,
              fontSize: '1.25rem',
              fontFamily: 'Averta-Extrabold',
              letterSpacing: 0,
              minHeight: score.scoreType === ScoreTypes.PERCENTAGE ? '2rem' : '2.875rem',
            }}>
            {header}
          </Typography>
          {score.scoreType === ScoreTypes.PERCENTAGE ? (
            getCardContent()
          ) : (
            <Box style={{ color: typoColor, paddingTop: '1rem' }}>{getCardContent()}</Box>
          )}
        </CardContent>
        <div className={classes.progressRoot}>
          {score.data.survey?.max !== 0 &&
          (score.scoreType === ScoreTypes.SCORE ||
            score.scoreType === ScoreTypes.AVAILABLE ||
            score.scoreType === ScoreTypes.AVAILABLE_TODAY ||
            score.scoreType === ScoreTypes.INDEFINITE_AVAILABLE) ? (
            <div>
              <p style={{ color: typoColor, margin: '5px 0 5px 0' }}>
                {HubCardPrompts.number_of_response}
              </p>
              <Grid container direction="row" justify="center" alignItems="baseline">
                <Grid item xs={2}>
                  <p
                    style={{ color: typoColor, textAlign: 'left' }}
                    className={classes.progressStat}>
                    {score.data.survey?.current}
                  </p>
                </Grid>
                <Grid item xs={8}>
                  <Box pr={2} pl={2}>
                    <ProgressBar
                      completed={calculateScorePercentage(score)}
                      isLabelVisible={false}
                      bgColor={surveyColor}
                      height={'10px'}
                      width={'100%'}
                    />
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <p
                    style={{ color: typoColor, textAlign: 'right' }}
                    className={classes.progressStat}>
                    {score.data.survey?.max}
                  </p>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </Card>
  );
};
