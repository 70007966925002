import React from 'react';

import { createStyles, Link, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(() =>
  createStyles({
    lowResponseAlert: {
      marginBottom: '20px',
    },
  })
);

const limit = (toLimit: number) => {
  const uBound = 100;
  const lBound = 0;

  return toLimit > uBound ? uBound : toLimit < lBound ? lBound : toLimit;
};

export interface LowResponseAlertProps {
  responseCount: number;
  recipientCount: number;
  requiredRate: number;
  responsesRequiredToMeetRate: number;
}
export const LowResponseAlert = (props: LowResponseAlertProps) => {
  const { responseCount, recipientCount, requiredRate, responsesRequiredToMeetRate } = props;
  const responseRate = parseFloat(limit((responseCount / recipientCount) * 100).toFixed(2));
  const styles = useStyles(props);

  return (
    <>
      <Alert severity="info" className={styles.lowResponseAlert}>
        <AlertTitle>Low response rate</AlertTitle>
        The response rate for this survey was {responseRate}% and did not meet the minimum
        recommended response rate of {limit(requiredRate)}%. Another {responsesRequiredToMeetRate}{' '}
        responses were required to meet the threshold and these results may not accurately reflect
        your workplace. Find out more{' '}
        <Link
          href="https://www.resources.groovnow.com/survey-faqs"
          aria-label="To learn more about survey response rates from our clinical team, visit the frequently asked questions on our resource site which opens in a new window.">
          here.
        </Link>
      </Alert>
    </>
  );
};
