export enum StandardPowerBIReportRouteName {
  KeyInsights = 'key_insights',
  ToolsContent = 'tools_content',
}

export type Highlights = {
  orgId: string;
  orgName: string;
  publishedDate: string;
  markdown: string;
};
