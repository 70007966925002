import { AutoEmail } from './AutoEmail';
import React from 'react';
import { setAutoEmail } from './api';
import { ToasterUtils } from '../../../../common';
import { AutoEmailPrompts } from './prompts';
import Analytics from '../../../../utils/AnalyticsHelper';
import { AUTO_EMAIL_ANALYTICS_SEGMENT, CONFIRM_AUTO_EMAIL_ANALYTICS_EVENT } from './constants';

interface Props {
  autoEmail: boolean;
  loading: boolean;
  updateAutoEmail: (autoEmail: boolean) => void;
}

export const AutoEmailContainer = ({ autoEmail, loading, updateAutoEmail }: Props) => {
  const handleChange = async () => {
    const updatedState = !autoEmail;
    try {
      updateAutoEmail(updatedState);
      await saveAutoEmail(updatedState);
      recordSuccessfulUpdateAnalytic(updatedState.toString());
      showSuccess();
    } catch (e) {
      showError();
      updateAutoEmail(!updatedState);
    }
  };

  const saveAutoEmail = async (autoEmailUpdate: boolean) => {
    const res = await setAutoEmail(autoEmailUpdate);
    if (!res) {
      throw Error('AUTO EMAIL API CALL FAILED');
    }
    return res;
  };

  const recordSuccessfulUpdateAnalytic = (updatedAutoEmail: string) => {
    Analytics.trackEvent(
      CONFIRM_AUTO_EMAIL_ANALYTICS_EVENT,
      AUTO_EMAIL_ANALYTICS_SEGMENT,
      updatedAutoEmail
    );
  };

  const showError = () => {
    ToasterUtils.toast(
      AutoEmailPrompts.toastSaveErrorMsg,
      'error',
      AutoEmailPrompts.toastSaveErrorTitle
    );
  };

  const showSuccess = () => {
    ToasterUtils.toast(AutoEmailPrompts.toastMsg, 'success', AutoEmailPrompts.toastTitle);
  };

  return <AutoEmail autoEmail={autoEmail} onAutoEmailChange={handleChange} loading={loading} />;
};
