import React from 'react';

import { createStyles, makeStyles, Typography } from '@material-ui/core';

import { ReportMessage } from './EmailUploadReportModal';

interface ReportMessageProps {
  reportGroup: ReportMessage[];
  icon: string;
  display: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      marginRight: '1rem',
      borderRadius: '25%',
      height: '30px',
      width: '30px',
    },
    message: {
      paddingBottom: '0.5rem',
    },
    flex: {
      display: 'flex',
      padding: '1rem',
    },
  })
);

export function CreateReportMessageText(props: ReportMessageProps) {
  const { reportGroup, icon, display } = props;
  const styles = useStyles(props);

  if (display) {
    return (
      <Typography component="div" className={styles.flex}>
        <Typography component="div">
          <Typography component="img" src={icon} className={styles.icon}></Typography>
        </Typography>
        <Typography component="div">
          {reportGroup.map((item) => (
            <Typography component="p" className={styles.message} key={item.message}>
              {item.message}
            </Typography>
          ))}
        </Typography>
      </Typography>
    );
  }
  return <Typography component="div" style={{ display: 'none' }}></Typography>;
}
