import { colors } from 'styles/colors';

export const toasterStyles = {
  snackbar: {
    height: 100,
    width: 400,
    background: 'rgb(255, 255, 255)',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.5)',
    borderRadius: 12,
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
    color: colors.darkGrey,
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
    color: colors.slateGrey,
  },
  iconGrid: {
    display: 'flex',
    marginLeft: 14,
    marginRight: 14,
  },
};
