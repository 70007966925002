import React from 'react';

import { Typography, Box } from '@material-ui/core';
import logo from '../assets/images/email.png';
import logo2x from '../assets/images/email@2x.png';
import logo3x from '../assets/images/email@3x.png';
import Layout from 'common/components/AuthScreenLayout';

export const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <Box component="div" mb={4}>
        <img src={logo} srcSet={`${logo2x} 2x, ${logo3x} 3x`} alt="Check email" />
      </Box>
      <Typography variant="h2" component="h1" align="center" gutterBottom>
        Page Not Found
      </Typography>
    </Layout>
  );
};
