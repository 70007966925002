import { Grid, Typography } from '@material-ui/core';
import { ContainerLayout } from 'common';
import React, { useEffect, useState } from 'react';
import { getSubscription } from 'services/api';
import { SubscriptionDataType } from 'types/subscriptionTypes';
import { AnimatedSpinner } from './AnimatedSpinner';
import { Card } from './Card';

export const CompanyAccountsSummary = () => {
  const [subscriptionData, setSubscriptionData] = useState({} as SubscriptionDataType);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = async () => {
    setIsLoading(true);
    const resp = await getSubscription();
    if (resp.status === 200) {
      setSubscriptionData(resp.data);
    }
    setIsLoading(false);
  };

  const renderLoader = () => (
    <Grid container spacing={2} justify="center">
      <AnimatedSpinner height={30} width={30} />
    </Grid>
  );

  const renderEmptySummary = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography variant="h3">{subscriptionData.org_name}</Typography>
      </Grid>
    </Grid>
  );
  const renderErrorMessage = () => {
    return (
      <Typography align="center" color="error">
        Something went wrong please try again
      </Typography>
    );
  };

  return (
    <ContainerLayout>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            {isLoading
              ? renderLoader()
              : Object.keys(subscriptionData).length !== 0
                ? renderEmptySummary()
                : renderErrorMessage()}
          </Card>
        </Grid>
      </Grid>
    </ContainerLayout>
  );
};
