export const getTypographyVariantFromFontWeight = (fontWeight: number) => {
  if (fontWeight && fontWeight >= 700) {
    return `h1`;
  }
  if (fontWeight && fontWeight >= 600) {
    return `h3`;
  }

  return `subtitle1`;
};
