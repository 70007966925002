import React from 'react';
import { Typography, Box } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import Switch from 'react-ios-switch';
import isAfter from 'date-fns/isAfter';

import { HubSurveyPrompts, HubBetaPrompts } from 'prompts/prompts';
import { LightOutlinedButton, Button, ToasterUtils } from 'common';
import { SURVEY_REMINDER_DAYS } from '../../../../constants';
import selectDateStyles from './styles';

export const SelectDate: React.FC<{
  message: string;
  selectedDate: Date;
  isReminderSelected: boolean;
  setSelectedDate: Function;
  setReminderSelected: Function;
}> = (props) => {
  const classes = selectDateStyles(props);
  const { message, selectedDate, isReminderSelected, setSelectedDate, setReminderSelected } = props;
  const {
    selectDate: selectDateLabel,
    selectDateReminderTitle,
    selectDateReminderMessage,
  } = HubSurveyPrompts.label;
  const buttonText = HubSurveyPrompts.buttonText;
  const dateFormat = HubBetaPrompts.selectDateFormat;
  const noPastTimeMessage = HubSurveyPrompts.message.noPastTime;
  const noPastTimeTitle = HubSurveyPrompts.title.noPastTime;

  const onChangeDate = (date: Date | null) => {
    setSelectedDate(date);
  };

  const onChangeTime = (date: Date | null) => {
    const dateNow = new Date();
    if (date && isAfter(date, dateNow)) {
      setSelectedDate(date);
    } else {
      ToasterUtils.toast(noPastTimeMessage, 'error', noPastTimeTitle);
      setSelectedDate(dateNow);
    }
  };

  const renderDatePicker = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          value={selectedDate}
          onChange={onChangeDate}
          format={dateFormat}
          animateYearScrolling
          minDate={new Date()}
          className="select-date-picker-input"
          DialogProps={{ className: 'select-date-picker-dialog' }}
          cancelLabel={
            <LightOutlinedButton
              className={classes.datePickerButton}
              data-testid="hub_modal_survey_date_picker_cancel_button">
              {buttonText.datePickerCancel}
            </LightOutlinedButton>
          }
          okLabel={
            <Button
              className={classes.datePickerButton}
              data-testid="hub_modal_survey_date_picker_done_button">
              {buttonText.datePickerDone}
            </Button>
          }
          disablePast
          InputProps={{ readOnly: true }}
          data-testid="hub_modal_survey_date_picker"
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderTimePicker = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          value={selectedDate}
          onChange={onChangeTime}
          className="select-time-picker-input"
          DialogProps={{ className: 'select-date-picker-dialog' }}
          cancelLabel={
            <LightOutlinedButton
              className={classes.datePickerButton}
              data-testid="hub_modal_survey_time_picker_cancel_button">
              {buttonText.datePickerCancel}
            </LightOutlinedButton>
          }
          okLabel={
            <Button
              className={classes.datePickerButton}
              data-testid="hub_modal_survey_time_picker_done_button">
              {buttonText.datePickerDone}
            </Button>
          }
          InputProps={{ readOnly: true }}
          data-testid="hub_modal_survey_time_picker"
        />
      </MuiPickersUtilsProvider>
    );
  };

  return (
    <>
      <Box className={classes.messageParent}>
        <Box className={classes.message}>
          <Typography variant="h4" component="p" className={classes.messageSegment}>
            {message}
          </Typography>
        </Box>
      </Box>
      <Box className={`${classes.box} ${classes.pickers}`}>
        <Typography variant="h4" component="p">
          {selectDateLabel.substring(0, selectDateLabel.indexOf('{date}'))}
        </Typography>
        {renderDatePicker()}
        <Typography variant="h4" component="p">
          {selectDateLabel.substring(
            selectDateLabel.indexOf('{date}') + 6,
            selectDateLabel.indexOf('{time}')
          )}
        </Typography>
        {renderTimePicker()}
      </Box>
      <Box className={`${classes.box} ${classes.reminder}`}>
        <Box className={classes.reminderInfo}>
          <Typography variant="h4" component="p" className={classes.reminderTitle}>
            {selectDateReminderTitle}
          </Typography>
          <Typography variant="h4" component="p">
            {selectDateReminderMessage.replace('{day}', SURVEY_REMINDER_DAYS[0].toString())}
          </Typography>
        </Box>
        <Box data-testid="hub_modal_survey_reminder_switch">
          <Switch onColor="#006AFF" checked={isReminderSelected} onChange={setReminderSelected} />
        </Box>
      </Box>
    </>
  );
};
