import { OrgUser } from 'common/components/FileUpload/FileUpload';
import { CommonSelectorOptionType } from 'common';
import { CTAType } from './CTASection';
import { PromptBuilderFormTypes, PromptCTATypes, PromptItemResponse } from './PromptBuilder.types';
import moment from 'moment';

const convertActionsToCTAType = (
  actions: { id: number; action: string; response: string }[]
): CTAType[] => {
  return actions.map((action) => ({
    id: action.id.toString(),
    name: action.action,
    description: action.response,
  }));
};

const convertRecipientsToOrgUsers = (
  recipients: { recipientEmail: string; orgId: string }[]
): OrgUser[] => {
  return recipients.map((recipient) => ({
    Email: recipient.recipientEmail,
    OrgId: recipient.orgId,
  }));
};

const convertTagsToCommonSelectorOptions = (
  promptTags: { prompt_tags_id: number }[],
  tagOptions: CommonSelectorOptionType[]
): CommonSelectorOptionType[] => {
  console.log({ promptTags, tagOptions });
  return promptTags.map((tag) => ({
    id: tag.prompt_tags_id.toString(),
    label: tagOptions.find((t) => t.id === tag.prompt_tags_id.toString())?.label ?? '',
  }));
};

const convertCTAToPromptCTATypes = (ctaType: string): PromptCTATypes => {
  switch (ctaType) {
    case '6pt_scale':
      return PromptCTATypes.sixPtScale;
    case 'open_text':
      return PromptCTATypes.openEndedText;
    case 'action_buttons':
      return PromptCTATypes.custom;
    case '6pt_scale_w_open_text':
      return PromptCTATypes.sixPtScaleAndOpenEndedText;
    case 'action_w_open_text':
      return PromptCTATypes.customAndOpenEndedText;
    default:
      throw new Error(`Unknown CTA Type: ${ctaType}`);
  }
};

export const convertPromptItemToBuilderForm = (
  prompt: PromptItemResponse,
  tags: CommonSelectorOptionType[]
): PromptBuilderFormTypes => {
  const promptItem = JSON.parse(prompt.prompt).data[0];
  return {
    id: promptItem.id.toString(),
    name: promptItem.name || '',
    body: promptItem.prompt,
    ctaType: convertCTAToPromptCTATypes(promptItem.properties.prompt_cta_type),
    ctaValue: convertActionsToCTAType(promptItem.actions),
    userList: convertRecipientsToOrgUsers(prompt.recipients),
    promptDateTime: moment(promptItem.properties.prompt_schedule_time).format('YYYY-MM-DDTHH:mm'),
    categoryId: promptItem.category.id,
    isGroovVoice: promptItem.groov_voice,
    isConfidential: promptItem.confidential,
    responseToOpenText: promptItem.response_to_open_text || '',
    promptTags: convertTagsToCommonSelectorOptions(promptItem.tags, tags),
  };
};
