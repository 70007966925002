import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Card } from 'common';
import React from 'react';
import { ContextualNudgeItemType } from '../ContextualNudge.types';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { RoutePath } from 'types/routes';
import { dispatch } from 'store';
import { removeNudge } from '../ContextualNudge.slice';
import { contextualNudgePrompts } from 'prompts/prompts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
    },
    triggers: {
      flexDirection: 'row',
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    triggerItem: {
      paddingRight: theme.spacing(2),
    },
    content: {
      marginBottom: theme.spacing(3),
    },
    editContainer: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
    },
    editContainerActions: {
      marginLeft: theme.spacing(6),
    },
    actionItem: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderWidth: 1,
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      borderRadius: theme.spacing(2),
    },
    actions: {
      flexDirection: 'row',
      display: 'flex',
      marginBottom: theme.spacing(3),
      flexWrap: 'wrap',
    },
  })
);

export function ContextualNudgeListItem({ item }: { item: Readonly<ContextualNudgeItemType> }) {
  const classes = useStyles();
  const history = useHistory();
  const handleEditNudge = () => {
    history.push(`${RoutePath.EditContextualNudge}/${item.id}`);
  };
  const handleDeleteNudge = () => {
    dispatch(removeNudge(item));
  };

  return (
    <Card className={classes.container}>
      <Box className={classes.editContainer}>
        <Typography className={classes.content} variant="h2">
          {item.name}
        </Typography>
        <Box>
          <EditOutlined
            onClick={handleEditNudge}
            className={classes.editContainerActions}
            color="primary"
          />
          <DeleteOutline
            onClick={handleDeleteNudge}
            className={classes.editContainerActions}
            color="error"
          />
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.content} variant="h4">
            {contextualNudgePrompts.PromptDescription}
          </Typography>
          <Typography className={classes.content} variant="body1">
            {item.description}
          </Typography>
          <Box>
            <Typography className={classes.content} variant="h4">
              {contextualNudgePrompts.PromptTrigger}
            </Typography>
            {item.triggers?.map((trigger) => (
              <Box key={trigger.type} className={classes.triggers}>
                <Typography variant="body1" className={classes.triggerItem}>
                  {trigger.type}
                </Typography>
                <Typography variant="body1" className={classes.triggerItem}>
                  {trigger.condition}
                </Typography>
                <Typography variant="body1" className={classes.triggerItem}>
                  {trigger.value}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box>
            <Typography className={classes.content} variant="h4">
              {contextualNudgePrompts.PromptSchedule}
            </Typography>
            {item.scheduleRules?.map((trigger) => (
              <Box key={trigger.type} className={classes.triggers}>
                <Typography variant="body1" className={classes.triggerItem}>
                  {trigger.type}
                </Typography>
                <Typography variant="body1" className={classes.triggerItem}>
                  {trigger.condition}
                </Typography>
                <Typography variant="body1" className={classes.triggerItem}>
                  {trigger.value}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box>
            <Typography className={classes.content} variant="h4">
              {contextualNudgePrompts.PromptGroup}
            </Typography>
            <Typography variant="body1">{item.group}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Typography className={classes.content} variant="body1">
              {item.promptDetails}
            </Typography>
            <Box className={classes.actions}>
              {item.actions?.map((trigger) => (
                <Box className={classes.actionItem} key={trigger.questions}>
                  <Typography variant="h4">{trigger.questions}</Typography>
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}
