import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const powerBIReportStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    reportInsights: {
      height: '1200px',
      width: '100%',
    },
    reportToolsContent: {
      height: '1200px',
      width: '100%',
    },
  })
);

export default powerBIReportStyles;
