import { RoutePath, RouteLabel, RouteId, Links } from '../../../types/routes';
import { DocumentIcon } from '../../../assets/icons/document';
import { UserAltIcon } from '../../../assets/icons/user-alt';
import { InboxIcon } from '../../../assets/icons/inbox';
import { GridIcon } from '../../../assets/icons/grid';
import { SettingsIcon } from '../../../assets/icons/settings-icon';
import { ChartIcon } from '../../../assets/icons/chart';
import NetworkIcon from 'assets/icons/network-icon';

export const HeaderLinks: Links = {
  [RouteLabel.HubBeta]: {
    id: RouteId.HubBeta,
    name: RouteLabel.HubBeta,
    route: RoutePath.Hub,
    icon: ChartIcon,
  },
  [RouteLabel.Insights]: {
    id: RouteId.Insights,
    name: RouteLabel.Insights,
    route: RoutePath.Insights,
    icon: DocumentIcon,
  },
  [RouteLabel.ManageUsers]: {
    id: RouteId.ManageUsers,
    name: RouteLabel.ManageUsers,
    route: RoutePath.ManageUsers,
    icon: UserAltIcon,
  },
  [RouteLabel.People]: {
    id: RouteId.People,
    name: RouteLabel.People,
    route: RoutePath.Registrations,
    icon: InboxIcon,
  },
  [RouteLabel.ManageDomains]: {
    id: RouteId.ManageDomains,
    name: RouteLabel.ManageDomains,
    route: RoutePath.ManageDomains,
    icon: GridIcon,
  },
  [RouteLabel.Ritual]: {
    id: RouteId.Ritual,
    name: RouteLabel.Ritual,
    route: RoutePath.ManageRituals,
    icon: GridIcon,
  },
  [RouteLabel.Settings]: {
    id: RouteId.Settings,
    name: RouteLabel.Settings,
    route: RoutePath.Settings,
    icon: SettingsIcon,
  },
  [RouteLabel.GroovVoice]: {
    id: RouteId.GroovVoice,
    name: RouteLabel.GroovVoice,
    route: RoutePath.GroovVoice,
    icon: DocumentIcon,
  },
  [RouteLabel.GAInsights]: {
    id: RouteId.GAInsights,
    name: RouteLabel.GAInsights,
    route: RoutePath.GAInsights,
    icon: DocumentIcon,
  },
  [RouteLabel.GASettings]: {
    id: RouteId.GASettings,
    name: RouteLabel.GASettings,
    route: RoutePath.GASettings,
    icon: SettingsIcon,
  },
  [RouteLabel.PromptBuilder]: {
    id: RouteId.PromptBuilder,
    name: RouteLabel.PromptBuilder,
    route: RoutePath.PromptBuilder,
    icon: DocumentIcon,
  },
  [RouteLabel.Help]: {
    id: RouteId.Help,
    name: RouteLabel.Help,
    route: RoutePath.Help,
    icon: DocumentIcon,
  },
};

export const ContextualNudge: Links = {
  [RouteLabel.ContextualNudge]: {
    id: RouteId.ContextualNudge,
    name: RouteLabel.ContextualNudge,
    route: RoutePath.ContextualNudge,
    icon: DocumentIcon,
  },
};

export const IntegrationLink: Links = {
  [RouteLabel.Integrations]: {
    id: RouteId.Integrations,
    name: RouteLabel.Integrations,
    route: RoutePath.Integrations,
    icon: NetworkIcon,
  },
};

export const HEADER_ANALYTICS_EVENT = 'HeaderTabPressed';
export const HEADER_ANALYTICS_SEGMENT = 'Tab';
