import React from 'react';
import { Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FeedBackIcon } from './FeedbackIcon';
import { colors } from 'styles/colors';
import { VariantType } from 'notistack';
import { ToasterUtils } from 'common';
import { CloseToasterButton } from './CloseToasterButton';
import { Button } from './Button';
import { toasterStyles } from './styles/toasterStyles';

const useStyles = makeStyles(() => ({
  ...toasterStyles,
  snackbar: {
    ...toasterStyles.snackbar,
    width: 480,
  },
  description: {
    ...toasterStyles.description,
    color: colors.slateGrey,
  },
  confirmButton: {
    background: colors.orange,
    borderRadius: 12,
    width: 132,
    height: 56,
    padding: 18,
    marginRight: 16,
    '&:hover': {
      background: colors.redWarn,
    },
  },
}));

interface Props {
  id: any;
  variant: VariantType | 'confirm';
  title?: string;
  message?: string;
  confirmButtonText?: string;
  onConfirm: () => Promise<void>;
}

export const ConfirmActionToaster = React.forwardRef(
  (
    {
      variant,
      onConfirm,
      title = 'Are you certain?',
      message = 'Please confirm',
      confirmButtonText = 'Confirm',
    }: Props,
    ref: any
  ) => {
    const classes = useStyles();

    const closeToaster = () => {
      ToasterUtils.close();
    };

    return (
      <div ref={ref}>
        <Grid container xs={12} className={classes.snackbar}>
          <Grid item className={classes.iconGrid} justify="center">
            <FeedBackIcon type={variant} />
          </Grid>
          <Grid item style={{ width: '45%' }}>
            <div className={classes.title}>{title}</div>
            <div className={classes.description}>{message}</div>
          </Grid>
          <Grid item>
            <Button
              className={classes.confirmButton}
              onClick={() => {
                closeToaster();
                onConfirm();
              }}
              data-testid="toast_confirm_button">
              {confirmButtonText}
            </Button>
            <CloseToasterButton onClick={closeToaster} />
          </Grid>
        </Grid>
      </div>
    );
  }
);
