import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import history from 'utils/history';
import { SnackbarProvider } from 'notistack';

import { IsAuthenticatedDefaultRoute, RoutePath } from 'types/routes';
import Layout from './Layout';
import { AppProvider } from './AppContext';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import {
  ManageUsers,
  ManageDomains,
  ManageRituals,
  Registration,
  Invoice,
  Help,
  OrgOverview,
  HubBetaMain,
  ContextualNudgeList,
  AddContextualNudge,
  EditContextualNudge,
} from 'pages/index';
import { NotFoundPage } from 'pages/NotFoundPage';
import './styles/variables.scss';
import { ToasterConfig } from 'common/components/ToasterUtil';
import { Settings } from './pages/Settings/Settings';
import { CreateCompanyRituals } from 'pages/CreateCompanyRituals';
import { CreateAdminAccount } from './pages/CreateAdminAccount/CreateAdminAccount';
import { useFeatureFlagProvider } from 'FeatureFlagContext';
import { IntegrationsPage } from 'pages/Integrations/IntegrationsPage';
import { TeamsInstallPage } from 'pages/Integrations/Channels/Teams/TeamsInstallPage';
import { Ms365RedirectPage } from 'pages/Integrations/Common/Microsoft/MicrosoftRedirectPage';
import { GAInsights } from 'pages/GAInsights';
import { GASettings } from 'pages/Settings/components/GroovSettings/GASettings';
import { PromptPreview, PromptBuilderList, CreatePrompt } from 'pages/PromptBuilder';
import { Login } from 'pages/Auth/Login';
import { LoginCallback } from 'pages/Auth/LoginCallback';
import { LogoutCallback } from 'pages/Auth/LogoutCallback';
import SetNewPassword from 'pages/SetNewPassword';
import SetNewPasswordResult from 'pages/SetNewPasswordResult';
import { GroovVoice, PromptDetails } from 'pages/GroovVoice';
import ErrorPage from 'pages/ErrorPage';

function App() {
  return (
    <AppProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={5000}>
        <ToasterConfig />
        <Router history={history}>
          <Layout>
            <Switch>
              <PrivateRoute path={RoutePath.ManageUsers} component={ManageUsers} />
              <PrivateRoute path={RoutePath.Registrations} component={Registration} />
              <PrivateRoute path={RoutePath.ManageDomains} component={ManageDomains} />
              <PrivateRoute path={RoutePath.AddContextualNudge} component={AddContextualNudge} />
              <PrivateRoute
                path={`${RoutePath.EditContextualNudge}/:id`}
                component={EditContextualNudge}
              />
              <PrivateRoute path={RoutePath.ContextualNudge} component={ContextualNudgeList} />
              <PrivateRoute path={RoutePath.ManageRituals} component={ManageRituals} />
              <PrivateRoute exact path={RoutePath.CreateCompany} component={CreateCompanyRituals} />
              <PrivateRoute path={RoutePath.EditCompany} component={CreateCompanyRituals} />
              <PrivateRoute path={RoutePath.Hub} component={HubBetaMain} />
              <PrivateRoute path={RoutePath.Invoices} component={Invoice} />
              <PrivateRoute path={RoutePath.Help} component={Help} />
              <PrivateRoute path={RoutePath.Insights} component={OrgOverview} />
              <PrivateRoute path={RoutePath.Settings} component={Settings} />
              <PrivateRoute path={RoutePath.TeamsInstallRedirect} component={Ms365RedirectPage} />
              <PrivateRoute path={RoutePath.TeamsInstall} component={TeamsInstallPage} />
              <PrivateRoute path={RoutePath.Integrations} component={IntegrationsPage} />
              <PrivateRoute path={RoutePath.HubBeta}>
                <Redirect to={RoutePath.Hub} />
              </PrivateRoute>
              <PrivateRoute path={RoutePath.GAInsights} component={GAInsights} exact />
              <PrivateRoute path={RoutePath.GASettings} component={GASettings} exact />
              <PrivateRoute path={RoutePath.PromptBuilder} component={PromptBuilderList} exact />
              <PrivateRoute path={RoutePath.CreatePrompt} component={CreatePrompt} exact />
              <PrivateRoute path={RoutePath.GroovVoice} component={GroovVoice} exact />
              <PrivateRoute path={`${RoutePath.GroovVoice}/:id`} component={PromptDetails} exact />
              <PrivateRoute
                path={`${RoutePath.PromptPreview}/:id`}
                component={PromptPreview}
                exact
              />
              <PrivateRoute
                path={RoutePath.Root}
                component={() => <Redirect to={IsAuthenticatedDefaultRoute} />}
                exact
              />
              <PublicRoute path={RoutePath.LoginCallback} component={LoginCallback} exact />
              <PublicRoute path={RoutePath.LogoutCallback} component={LogoutCallback} exact />
              <PublicRoute path={RoutePath.Login} component={Login} exact />
              <PublicRoute
                path={RoutePath.CreateAdminAccount}
                component={CreateAdminAccount}
                exact
              />
              <PublicRoute path={RoutePath.SetNewPassword} component={SetNewPassword} exact />
              <PublicRoute
                path={RoutePath.SetNewPasswordResult}
                component={SetNewPasswordResult}
                exact
              />
              <PublicRoute path={RoutePath.Error} component={ErrorPage} exact />
              <Route path="*" exact={true} component={NotFoundPage} />
            </Switch>
          </Layout>
        </Router>
      </SnackbarProvider>
    </AppProvider>
  );
}

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useFeatureFlagProvider(App);
