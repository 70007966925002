import React, { useEffect, useState } from 'react';
import { Box, Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import { DragAndDrop } from './DragAndDrop';
import { parseCsvContentLine, readCsvFile } from './utils';
import { promptBuilder } from 'prompts/prompts';

const USER_UPLOAD_HEADINGS = ['Email', 'OrgId'];

export type OrgUser = {
  Email: string;
  OrgId: string;
};

type Props = {
  onFileChange?: (rows: OrgUser[]) => void;
  error?: boolean;
  helperText?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
    helperText: {
      color: theme.palette.text.secondary,
    },
    uploadContainer: {
      margin: theme.spacing(2, 0),
    },
  })
);

export const CsvFileUpload = ({ onFileChange, error, helperText }: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const classes = useStyles();
  const [isCSVFileValid, setIsCSVFileValid] = useState(true);
  const icCsvFileValid = async (file: File) => {
    try {
      await readCsvFile(file, USER_UPLOAD_HEADINGS);
      setIsCSVFileValid(true);
      return true;
    } catch (err) {
      setIsCSVFileValid(false);
      return false;
    }
  };
  useEffect(() => {
    const handleFileChange = async (files: File[]) => {
      try {
        const parsedFiles = await Promise.all(
          files.map(async (file) => {
            return await readCsvFile(file, USER_UPLOAD_HEADINGS);
          })
        );

        const emailList = Array.from(
          new Set(
            parsedFiles.reduce((accList, parsedFile) => {
              return [...accList, ...parsedFile.contentLines];
            }, [] as string[])
          )
        );

        const usersWithOrg = emailList.map((contentRow) => {
          return parseCsvContentLine<OrgUser>(contentRow, USER_UPLOAD_HEADINGS);
        });

        onFileChange?.(usersWithOrg);
      } catch (error) {
        console.error(error);
      }
    };

    handleFileChange(files);
  }, [files, onFileChange]);
  return (
    <>
      <DragAndDrop onFilesSelected={setFiles} minHeight={200} handleValidateFile={icCsvFileValid} />
      <Box className={classes.uploadContainer}>
        {error && <Typography className={classes.errorText}>{helperText}</Typography>}
        {!isCSVFileValid && (
          <Typography className={classes.errorText}>
            {promptBuilder.promptUserListNotValid}
          </Typography>
        )}
        {!error && helperText && (
          <Typography className={classes.helperText}>{helperText}</Typography>
        )}
      </Box>
    </>
  );
};
