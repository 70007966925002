import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'styles/colors';

export const eapProviderStyles = makeStyles(() =>
  createStyles({
    addEapButton: {
      width: '177px',
      height: '52px',
      padding: '16px 16px',
      whiteSpace: 'nowrap',
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
    },
  })
);

export const eapTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      borderRadius: theme.spacing(3),
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
    },
    tableHeader: {
      backgroundColor: colors.lightGrey,
      borderRadius: theme.spacing(3),
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
    },
    tableHeaderText: {
      color: theme.palette.grey[500],
      fontSize: 14,
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
    },
    tableHeaderCellFirst: {
      borderTopLeftRadius: theme.spacing(3),
      borderBottomLeftRadius: theme.spacing(3),
    },
    tableHeaderCellLast: {
      borderTopRightRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(3),
    },
    tableBody: {
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
      maxHeight: 300,
    },
    tableBodyRow: {},
    tableBodyCellName: {
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
      letterSpacing: '2%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
    },
    tableBodyCell: {
      fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
    },
  })
);

export const eapDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    heading: {
      color: 'black',
      fontSize: 24,
      fontFamily: ['Averta-Bold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
    },
    description: {
      marginTop: '32px',
      color: theme.palette.grey[500],
      fontSize: 16,
      fontFamily: ['Averta', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      lineHeight: '24px',
      fontWeight: 400,
    },
    inputLabel: {
      marginTop: '16px',
      color: theme.palette.grey[500],
      fontSize: 16,
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      lineHeight: '24px',
      fontWeight: 400,
    },
    textInput: {
      width: '100%',
      marginTop: '2px',
    },
    inputRoot: {
      borderRadius: theme.spacing(3),
      borderColor: theme.palette.grey[400],
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: 'white',
    },
    inputRootArea: {
      borderRadius: theme.spacing(3),
      borderColor: theme.palette.grey[400],
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: 'white',
    },
    inputInner: {
      borderRadius: '0 !important',
      paddingLeft: 0,
      paddingright: 0,
    },
    addEapButton: {
      marginTop: theme.spacing(4),
      width: '177px',
      height: '52px',
      padding: '16px 16px',
      whiteSpace: 'nowrap',
      fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
      fontWeight: 400,
      '&:disabled': {
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[100],
      },
    },
    closeButton: {
      modalIcon: {
        color: colors.white,
        height: '3.25rem',
        width: '3.25rem',
        marginBottom: theme.spacing(10),
        cursor: 'pointer',
      },
    },
  })
);
