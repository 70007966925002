import Countly from 'countly-sdk-web';

const ADMIN_APP_VERSION = '0.1';

class AnalyticsHelper {
  private countly: any;
  constructor() {
    this.initCountly();
  }

  private initCountly = () => {
    this.countly = Countly || {};
    this.countly.q = Countly.q || [];

    this.countly.app_key = process.env.REACT_APP_ANALYTICS_API_KEY;
    this.countly.url = process.env.REACT_APP_ANALYTICS_API_URL;
    this.countly.app_version = ADMIN_APP_VERSION;

    console.log(`Initialising Countly at ${this.countly.url}`);

    this.countly.q.push(['track_pageview']);
    this.countly.q.push(['track_sessions']);
    this.countly.q.push(['track_clicks']);
    this.countly.q.push(['track_scrolls']);
    this.countly.remote_config = true;
    this.countly.init();
  };

  trackPageView = (pageName: string) => {
    this.countly.q.push(['track_pageview', pageName]);
  };

  setUserProperty = (key: string, value: string) => {
    this.countly.q.push(['userData.set', key, value]);
    this.countly.q.push(['userData.save']);
  };

  trackEvent = (eventName: string, segmentName?: string, eventValue?: string) => {
    const segName = segmentName ? segmentName : '';
    const segValue = eventValue ? eventValue : '';
    this.countly.q.push([
      'add_event',
      {
        key: eventName,
        segmentation: {
          [segName]: segValue,
        },
      },
    ]);
  };

  trackEventV2 = (eventName: string, extraInfo: { [index: string]: string }) => {
    this.countly.q.push([
      'add_event',
      {
        key: eventName,
        segmentation: { ...extraInfo },
      },
    ]);
  };

  loadRemoteConfig = (callback: (err: any, config: any) => void) => {
    console.log('Countly fetching remote config called....');
    this.countly.fetch_remote_config(callback);
  };

  trackPageViewWithDetails = (pageName: string, userId: string) => {
    this.countly.q.push([
      'track_pageview',
      pageName,
      null,
      {
        user_id: userId,
      },
    ]);
  };

  init = () => {};
}

const Analytics = new AnalyticsHelper();
export default Analytics;
