import { createSlice } from '@reduxjs/toolkit';
import { ContextualNudgeItemType } from './ContextualNudge.types';

export interface ContextualNudgeState {
  nudges: Record<string, ContextualNudgeItemType>;
}

const initialState: ContextualNudgeState = {
  nudges: {},
};

const slice = createSlice({
  name: 'contextualNudge',
  initialState,
  reducers: {
    setNudges: (state, action: { payload: ContextualNudgeItemType }) => {
      state.nudges = { ...state.nudges, [action.payload.id]: action.payload };
    },
    removeNudge: (state, action: { payload: ContextualNudgeItemType }) => {
      delete state.nudges[action.payload.id];
    },
  },
});

export const { reducer: contextualNudgeReducer } = slice;
export const { setNudges, removeNudge } = slice.actions;

export default slice;
