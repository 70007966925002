import React, { useCallback, useRef } from 'react';
import { Setting } from '../Setting';
import { Button } from '../../../../common';
import TeamsLogo from 'assets/icons/teams-logo';
import { Box, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../../styles/colors';
import { TeamsStrings } from './stringsOld';
import { AnimatedSpinner } from '../../../../common/components/AnimatedSpinner';

export const styles = (theme: Theme) => ({
  root: {
    marginRight: theme.spacing(4),
    flexGrow: 2,
    display: 'block',
    minWidth: '350px',
    marginBottom: theme.spacing(4),
  },
  inputRoot: {
    borderRadius: theme.spacing(3),
    borderColor: colors.mysticGrey,
    backgroundColor: 'white',
  },
  inner: {
    borderRadius: '0 !important',
  },
  button: {
    minWidth: '148px',
  },
});

const useStyles = makeStyles(styles);

type Props = {
  onAddTenantId: (id: string) => void;
  isLoading: boolean;
};
export const GroovAnywhereTeamsOld = ({ onAddTenantId, isLoading }: Props) => {
  const classes = useStyles();
  const getLogo: () => React.JSX.Element = useCallback(() => {
    return (
      <Box style={{ marginRight: 12 }}>
        <TeamsLogo />
      </Box>
    );
  }, []);

  const props = {
    classes: {
      root: classes.inputRoot,
      input: classes.inner,
    },
  };

  const textRef = useRef('');

  const onButtonClick = () => {
    onAddTenantId(textRef.current);
  };

  return (
    <Setting
      title={TeamsStrings.title}
      description={TeamsStrings.description}
      id={'teams'}
      icon={getLogo}>
      <Box>
        <TextField
          fullWidth={true}
          variant="outlined"
          className={classes.root}
          InputProps={props}
          placeholder={TeamsStrings.textInputPlaceholder}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            textRef.current = event.target.value;
          }}
        />

        <Button onClick={onButtonClick} disabled={isLoading} className={classes.button}>
          {isLoading && <AnimatedSpinner width={24} height={24} />}
          {!isLoading && TeamsStrings.addButtonText}
        </Button>
      </Box>
    </Setting>
  );
};
