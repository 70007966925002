import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

const overviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    cards: {
      display: 'flex',
      marginTop: '2rem',
    },
    card: {
      width: '14rem',
      height: '8.125rem',
      padding: '1rem 1.5rem',
      marginRight: '5rem',
    },
    overview: {
      display: 'flex',
      flexDirection: 'column',
      '& h1': {
        fontSize: '2rem',
      },
    },
    overviewLabel: {
      color: theme.palette.text.primary,
    },
  })
);

export default overviewStyles;
