import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'styles/colors';

const noDataSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.spacing(3),
      padding: '1.5rem 2rem',
      marginBottom: theme.spacing(6),
      boxShadow: 'none',
    },
    lightBackground: {
      backgroundColor: theme.palette.background.default,
      color: colors.black,
      '& h2, h4': {
        color: colors.black,
      },
    },
    darkBackground: {
      backgroundColor: colors.black,
      color: colors.white,
      '& h2, h4': {
        color: colors.white,
      },
    },
    handsPhone: {
      height: '8.5rem',
      width: '7.5rem',
      marginRight: '2rem',
    },
    title: {
      marginBottom: '1rem',
      lineHeight: '1.5',
    },
    learnMoreButton: {
      width: '9rem',
    },
  })
);

export default noDataSectionStyles;
